import { Data } from "../components/sidebar/sidebarData";

export const getObjectByPath = (path, role) => {
  for (const item of Data) {
    if (item.path === path && (!item.roles || item.roles.includes(role))) {
      return { title: item.title, subNav: null };
    }
    if (item.subNav) {
      for (const subItem of item.subNav) {
        if (
          subItem.path === path &&
          (!subItem.roles || subItem.roles.includes(role))
        ) {
          return { title: item.title, subNav: subItem };
        }
      }
    }
  }
  return null;
};
