import React, { useEffect, useState } from "react";
import {
    Grid,
    TextField,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Divider,
    IconButton,
    Dialog,
    Box,
    Tooltip,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const ShipmentViewModal = ({
    data,
    open,
    onClose,
}) => {
    const [shipment, setShipment] = useState({});

    useEffect(() => {
        if (data) {
            setShipment(data);
        }
    }, [data]);

    const onCloseClick = () => {
        setShipment({});
        onClose();
    };

    return (
        <>
            <Dialog open={open} onClose={onCloseClick} maxWidth="md">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <DialogTitle>
                        Shipment Details
                    </DialogTitle>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        <IconButton onClick={onCloseClick}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <Divider style={{ marginBottom: "10px" }} />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <CustomTypography
                                color="#11193F"
                                fontSize="14px"
                                fontWeight={400}
                            >
                                Shipment Id
                            </CustomTypography>
                            <TextField
                                type="text"
                                size="small"
                                margin="dense"
                                placeholder="shipment id"
                                value={shipment.shipment || ""}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomTypography
                                color={"#11193F"}
                                fontSize={14}
                                fontWeight={400}
                            >
                                Order Id
                            </CustomTypography>
                            <TextField
                                size="small"
                                autoFocus
                                margin="dense"
                                variant="outlined"
                                type="text"
                                fullWidth
                                value={shipment.order_id || ""}
                                InputProps={{
                                    readOnly: true,
                                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTypography
                                color="#11193F"
                                fontSize="14px"
                                fontWeight={400}
                            >
                                Weight
                            </CustomTypography>
                            <TextField
                                type="text"
                                size="small"
                                margin="dense"
                                placeholder="weight"
                                value={`${shipment?.weight}(${shipment?.weight_unit})` || ""}

                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTypography
                                color="#11193F"
                                fontSize="14px"
                                fontWeight={400}
                            >
                                Overpack
                            </CustomTypography>
                            <TextField
                                type="text"
                                size="small"
                                margin="dense"
                                placeholder="overpack"
                                value={shipment?.overpack || ""}

                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTypography
                                color="#11193F"
                                fontSize="14px"
                                fontWeight={400}
                            >
                                Manifest
                            </CustomTypography>
                            <TextField
                                type="text"
                                size="small"
                                margin="dense"
                                placeholder="manifest"
                                value={shipment?.manifest || ""}

                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: 10 }}>
                    <Button
                        onClick={onCloseClick}
                        variant="outlined"
                        style={{ textTransform: "none" }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ShipmentViewModal;
