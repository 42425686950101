import DownIcon from "@mui/icons-material/ArrowDownwardOutlined";
import UpIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CustomTypography from "../../globalStyles/fonts";
import SortingIcon from "../../globalStyles/icons/sorting-icon";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));
const WarehouseLocationModal = ({
  open,
  handleClose,
  omsLocations,
  providers,
  selectedWMS,
  setSelectedWMS,
  selectedLocation,
  setSelectedLocation,
  isEditMode,
  handleConfirm,
  primary_color,
  filterData,
  handleOpenFilter,
  paginatedWarehouses,
  currentPage,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  itemsPerPage,
  handleOptionsClick,
  oms_key,
}) => {
  return (
    <Modal open={open} onClose={handleClose} style={{ overflow: "visible" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: 700,
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <CustomTypography fontSize={22} fontWeight={600}>
            {isEditMode
              ? "Edit WMS & Location Mapping"
              : "WMS & Location Mapping"}
          </CustomTypography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" flexDirection="row" gap={2} mb={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id="first-select-label">Select WMS Provider</InputLabel>
            <Select
              key={isEditMode ? "edit-wms" : "add-wms"} // Set key based on edit mode
              label="Select WMS Provider"
              labelId="first-select-label"
              value={selectedWMS}
              onChange={(e) => setSelectedWMS(e.target.value)}
              autoFocus={isEditMode && !selectedWMS} // Auto-focus if in edit mode and WMS is not selected
            >
              {providers.map((x) => (
                <MenuItem key={x._id} value={x._id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small" fullWidth>
            <InputLabel id="second-select-label">Select Location</InputLabel>
            <Select
              key={isEditMode ? "edit-location" : "add-location"} // Set key based on edit mode
              label="Select Location"
              labelId="second-select-label"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              autoFocus={isEditMode && !selectedLocation} // Auto-focus if in edit mode and location is not selected
            >
              {omsLocations.map((x) => (
                <MenuItem key={x[oms_key]} value={x[oms_key]}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!selectedWMS || !selectedLocation}
          >
            {isEditMode ? "Update" : "Add"}
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <CustomTypography style={{ marginLeft: 1 }}>
                    SL NO
                  </CustomTypography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {filterData?.columns.find((x) => x?.key === "provider.name")
                      ?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData?.columns.find(
                        (x) => x?.key === "provider.name"
                      )?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography>WMS PROVIDER</CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) =>
                        handleOpenFilter(event, "provider.name")
                      }
                    >
                      <SortingIcon fill={primary_color || "#DC585C"} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {filterData?.columns.find((x) => x?.key === "location.name")
                      ?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData?.columns.find(
                        (x) => x?.key === "location.name"
                      )?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography>LOCATION</CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) =>
                        handleOpenFilter(event, "location.name")
                      }
                    >
                      <SortingIcon fill={primary_color || "#DC585C"} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTypography>ACTION</CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {paginatedWarehouses.map((warehouse, index) => (
                <StyledTableRow key={warehouse.id}>
                  <StyledTableCell>
                    <CustomTypography style={{ marginLeft: 1 }}>
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomTypography>
                      {warehouse.provider?.name}
                    </CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomTypography>
                      {warehouse.location?.name}
                    </CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      onClick={(e) => handleOptionsClick(e, warehouse)}
                      style={{ position: "relative" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Controls */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <IconButton onClick={handlePreviousPage} disabled={currentPage === 1}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="body2">
            Page {currentPage} of {totalPages}
          </Typography>
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default WarehouseLocationModal;
