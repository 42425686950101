import React, { useEffect, useState } from "react";
import { Card, CardContent, Box, Typography } from "@mui/material";
import { Bar, Line, Pie, Doughnut, PolarArea } from "react-chartjs-2";

import { isMobile } from "../../globalStyles/mobile";
import createLastSevenDaysLabels, {
  fetchColorByStatus,
} from "../../utils/utility";

const GraphCard = ({
  title,
  backgroundColor,
  smallRoundBoxBg,
  data_obj,
  type,
  minHeight,
  border,
  textColor,
}) => {
  const chartRef = React.useRef(null);
  const [options, setOptions] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data_obj.length && !data) {
      if (["pie", "polar", "doughnut"].includes(type)) {
        setData({
          labels: data_obj.map((x) => x.status),
          datasets: [
            {
              label: "Statuses",
              data: data_obj.map((x) => x.count || 0),
              backgroundColor: data_obj.map((x) =>
                fetchColorByStatus(x.status, "background_color")
              ),
              borderColor: data_obj.map((x) =>
                fetchColorByStatus(x.status, "border_color")
              ),
              borderWidth: 0,
            },
          ],
        });
        setOptions({
          responsive: true,
          maintainAspectRatio: false, // Important for responsiveness
          plugins: {
            legend: {
              position: isMobile() ? "top" : "right",
              labels: {
                font: {
                  size: isMobile() ? 10 : 14,
                  weight: 400,
                  fontFamily: "Poppins",
                },
              },
            },
          },
        });
      } else if (type === "line") {
        setOptions({
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
              },
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: isMobile() ? 10 : 14, // Adjust x-axis font size
                  weight: 400,
                  fontFamily: "Poppins",
                },
              },
            },
            y: {
              beginAtZero: true,
              max: 100,
              ticks: {
                callback: (value) => `${value}%`,
                font: {
                  size: isMobile() ? 10 : 12, // Adjust y-axis font size
                },
              },
              grid: {
                display: false,
              },
            },
          },
        });

        setData({
          labels: createLastSevenDaysLabels(),
          datasets: [
            {
              data: [0, 0, 0, 0, 0, 0, 0],
              fill: false,
              backgroundColor: "rgba(75,192,192,0.4)",
              borderColor: "rgba(75,192,192,1)",
            },
          ],
        });
      } else if (type === "bar") {
        setData({
          labels: data_obj.map((x) => x.status),
          datasets: [
            {
              label: "Previous Week",
              data: data_obj.map((x) => x.lastWeekCount),
              backgroundColor: "#025CB8",
              borderColor: "#025CB8",
              borderWidth: 1,
            },
            {
              label: "Current Week",
              data: data_obj.map((x) => x.currentWeekCount),
              backgroundColor: "#68B2FF",
              borderColor: "#68B2FF",
              borderWidth: 1,
            },
          ],
        });
        setOptions({
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: isMobile() ? "top" : "bottom",
              labels: {
                font: {
                  size: isMobile() ? 10 : 14, // Adjust legend font size
                  weight: 400,
                  fontFamily: "Poppins",
                },
              },
            },
            title: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                font: {
                  size: isMobile() ? 10 : 12, // Adjust y-axis font size
                },
              },
            },
            x: {
              ticks: {
                font: {
                  size: isMobile() ? 10 : 12, // Adjust x-axis font size
                },
              },
            },
          },
        });
      }
    }
  }, [title, data_obj, type]);

  const getDoughnutData = () => {
    return {
      labels: data_obj.map((x) => x.status),
      datasets: [
        {
          label: "Statuses",
          data: data_obj.map((x) => x.count || 0),
          backgroundColor: data_obj.map((x) =>
            fetchColorByStatus(x.status, "background_color")
          ),
          borderColor: data_obj.map((x) =>
            fetchColorByStatus(x.status, "borderColor")
          ),
          borderWidth: 0,
        },
      ],
    };
  };

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx;

      chart.data.datasets[0].backgroundColor = data_obj.map((x) =>
        fetchColorByStatus(x.status, "gradient", ctx)
      );
      chart.update();
    }
  }, []);

  return (
    <Card
      sx={{
        minWidth: isMobile() ? "95%" : 300,
        minHeight: minHeight || (isMobile() ? 200 : 280),
        padding: isMobile() ? 0.5 : 1,
        backgroundColor: backgroundColor || "#F3E8FF",
        borderRadius: "6px",
        position: "relative",
        margin: 1,
        maxWidth: "100%",
        border: border || "",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: isMobile() ? -20 : -30,
          right: isMobile() ? -5 : -10,
          width: isMobile() ? "60px" : "80px",
          height: isMobile() ? "60px" : "80px",
          borderRadius: "100%",
        }}
      />
      <CardContent>
        <div
          style={{
            width: "95%",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              fontSize={isMobile() ? 14 : 20}
              sx={{
                fontWeight: "600",
                color: textColor,
                textAlign: "left",
                fontFamily: "Poppins",
              }}
            >
              {title}
            </Typography>
          </div>
        </div>
        {data && options ? (
          <div
            style={{
              height: isMobile() ? 180 : minHeight, // Adjust chart height dynamically
            }}
          >
            {type === "pie" ? (
              <Pie data={data} options={options} />
            ) : type === "line" ? (
              <Line data={data} options={options} />
            ) : type === "bar" ? (
              <Bar data={data} options={options} />
            ) : type === "doughnut" ? (
              <Doughnut
                ref={chartRef}
                data={getDoughnutData(chartRef.current?.ctx)}
                options={options}
              />
            ) : type === "polar" ? (
              <PolarArea data={data} options={options} />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};

export default GraphCard;
