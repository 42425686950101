import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Button,
  Popover,
} from "@mui/material";
import { shippingPriceService } from "../../utils/services/shippingPriceService";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import AppContext from "../context/context";
import CustomTypography from "../../globalStyles/fonts";
import ShippingPriceDetailsModal from "../shipping-option-details-modal";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import CustomPagination from "../pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import { tableCellClasses } from "@mui/material/TableCell";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import { useTheme } from "@mui/styles";
import { styled } from "@mui/material/styles";

const ShipmentOptionListView = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedShipmentPrice,
  setSelectedShipmentPrice,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [shippigPriceData, setShippigPriceData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { user } = useContext(AppContext);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const startIndex = page * rowsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EEEEEE",
      border: "1px solid #EAECF0",
      padding: "4px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "6px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #EAECF0",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F6",
      border: "1px solid #EAECF0",
    },
    "&:lastChild td, &:lastChild th": {
      border: 0,
    },
  }));

  const handleViewClick = (shipping) => {
    setSelectedShipmentPrice(shipping);
    setAnchorEl(null);
    setOpenViewModal(true);
  };

  const handleEditClick = (item) => {
    setAnchorEl(null);
    onEditData && onEditData(item);
  };

  const handleDeleteClick = (item) => {
    setAnchorEl(null);
    setSelectedShipmentPrice(item);
    setDeletingItemId(item._id);
    setOpenConfirmation(true);
  };
  const handlePopoverOpen = (event, item) => {
    event.stopPropagation();
    setSelectedShipmentPrice(item);
    setAnchorEl(event.currentTarget);
    setAnchorPosition({
      top: event.clientY - 15,
      left: event.clientX - 15,
    });
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const deleteData = async () => {
    try {
      await shippingPriceService.delete(deletingItemId);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Shipment Options deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete Shipment Options");
      console.error("Delete failed:", error);
    }
  };
  const Action = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
        }}
      >
        <Button
          style={{
            color: "#333333",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="view"
          onClick={() => handleViewClick(selectedShipmentPrice)}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <ViewIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              color={"#333333"}
              fontWeight={500}
            >
              View
            </Typography>
          </Box>
        </Button>

        <Button
          style={{
            color: "#333333",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="edit"
          onClick={() => handleEditClick(selectedShipmentPrice)}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <EditIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              color={"#333333"}
              fontWeight={500}
            >
              Edit
            </Typography>
          </Box>
        </Button>
        <Button
          style={{
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="delete"
          onClick={() => handleDeleteClick(selectedShipmentPrice)}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <DeleteIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              fontWeight={500}
              color={"#333333"}
            >
              Delete
            </Typography>
          </Box>
        </Button>
      </div>
    );
  };
  useEffect(() => {
    setShippigPriceData(
      data?.map((item) => ({
        ...item,
        action: (
          <Box>
            <IconButton
              aria-label="more actions"
              onClick={(e) => handlePopoverOpen(e, item)}
              sx={{
                color:
                  anchorEl && selectedShipmentPrice?._id === item._id
                    ? "primary.main"
                    : "inherit",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={anchorEl}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorReference="anchorPosition"
              anchorPosition={anchorPosition}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableRestoreFocus
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  border: "1px solid #EEEEEE",
                  borderRadius: "4px",
                },
              }}
            >
              <Action />
            </Popover>
          </Box>
        ),
      }))
    );
  }, [data, anchorEl]);

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );

  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {shippigPriceData?.map((x) => (
        <Grid item xs={12} sm={6} md={3} key={x._id}>
          <Card sx={{ boxShadow: 3, cursor: "pointer" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    // marginLeft: 15,
                  }}
                >
                  <Tooltip title={x?.type} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.type}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {x.action}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {shippigPriceData.length === 0 ? (
          <NoDataImage />
        ) : (
          shippigPriceData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Type
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.type}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Min weight(kg)
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.min_weight}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Max weight(kg)
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.max_weight}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Min Days
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.min_days}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Max Days
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.max_days}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Price(USD)
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          :{" "}
                          {x?.pricing?.slice(0, 2).map((pricing, index) => (
                            <React.Fragment key={index}>
                              {pricing.price}
                              {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                            </React.Fragment>
                          ))}
                          {x?.pricing.length > 2 && (
                            <span
                              onClick={() => handleViewClick(x)}
                              style={{ cursor: "pointer", color: "#0073e6" }}
                            >
                              {` ... (${x?.pricing.length - 2} more)`}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={1} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        overflowY: "scroll",
        display: "flex",
        maxHeight: isMobile() ? `calc(100vh-270px)` : `calc(100vh - 310px)`,
        width: "100%",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::WebkitScrollbar": { width: 0 },
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        // <TableContainer
        //   className="custom-container"
        //   sx={{
        //     marginTop: 1,
        //     overflowY: "auto",
        //     paddingInline: 0.4,
        //   }}
        // >
        //   <Table
        //     stickyHeader
        //     aria-label="sticky table"
        //     sx={{
        //       borderCollapse: "separate",
        //       borderSpacing: "0 5px",
        //       paddingBottom: 7,
        //     }}
        //   >
        //     <TableHead>
        //       <TableRow
        //         style={{
        //           backgroundColor: "#EEEEEE",
        //           borderRadius: 8,
        //           boxShadow: "0px 0px 4px 0px #00000040",
        //           borderTopRightRadius: 8,
        //           borderBottomRightRadius: 8,
        //         }}
        //       >
        //         <TableCell
        //           style={{
        //             backgroundColor: "#EEEEEE",
        //             borderTopLeftRadius: "8px",
        //             borderBottomLeftRadius: 8,
        //             borderBottom: "none",
        //             textAlign: "center",
        //             padding: 10,
        //           }}
        //         >
        //           <CustomTypography
        //             fontSize="14px"
        //             title={true}
        //             color="#000"
        //             fontWeight={600}
        //           >
        //             Type
        //           </CustomTypography>
        //         </TableCell>
        //         <TableCell
        //           style={{
        //             backgroundColor: "#EEEEEE",
        //             borderBottom: "none",
        //             padding: 0,
        //             textAlign: "center",
        //           }}
        //         >
        //           <CustomTypography
        //             fontSize="14px"
        //             title={true}
        //             color="#000"
        //             fontWeight={600}
        //           >
        //             {"Min Weight (Kg)"}
        //           </CustomTypography>
        //         </TableCell>
        //         <TableCell
        //           style={{
        //             backgroundColor: "#EEEEEE",
        //             borderBottom: "none",
        //             padding: 0,
        //             textAlign: "center",
        //           }}
        //         >
        //           <CustomTypography
        //             fontSize="14px"
        //             title={true}
        //             color="#000"
        //             fontWeight={600}
        //           >
        //             {" Max Weight (Kg)"}
        //           </CustomTypography>
        //         </TableCell>
        //         <TableCell
        //           style={{
        //             backgroundColor: "#EEEEEE",
        //             borderBottom: "none",
        //             padding: 0,
        //             textAlign: "center",
        //           }}
        //         >
        //           <CustomTypography
        //             fontSize="14px"
        //             title={true}
        //             color="#000"
        //             fontWeight={600}
        //           >
        //             Min Days
        //           </CustomTypography>
        //         </TableCell>
        //         <TableCell
        //           style={{
        //             backgroundColor: "#EEEEEE",
        //             borderBottom: "none",
        //             padding: 0,
        //             textAlign: "center",
        //           }}
        //         >
        //           <CustomTypography
        //             fontSize="14px"
        //             title={true}
        //             color="#000"
        //             fontWeight={600}
        //           >
        //             Max Days
        //           </CustomTypography>
        //         </TableCell>
        //         <TableCell
        //           style={{
        //             backgroundColor: "#EEEEEE",
        //             borderBottom: "none",
        //             padding: 0,
        //             textAlign: "center",
        //           }}
        //         >
        //           <CustomTypography
        //             fontSize="14px"
        //             title={true}
        //             color="#000"
        //             fontWeight={600}
        //           >
        //             {"Price (USD)"}
        //           </CustomTypography>
        //         </TableCell>

        //         <TableCell
        //           style={{
        //             backgroundColor: "#EEEEEE",
        //             borderBottom: "none",
        //             textAlign: "center",
        //             borderTopRightRadius: 8,
        //             borderBottomRightRadius: 8,
        //             padding: 10,
        //           }}
        //         >
        //           <CustomTypography
        //             fontSize="14px"
        //             title={true}
        //             color="#000"
        //             fontWeight={600}
        //           >
        //             Actions
        //           </CustomTypography>
        //         </TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {shippigPriceData?.map((x, index) => (
        //         <React.Fragment>
        //           <TableRow
        //             key={index}
        //             style={{
        //               marginBottom: 10,
        //               backgroundColor: "#fff",
        //               borderRadius: 8,
        //               boxShadow: "0px 0px 4px 0px #00000040",
        //             }}
        //           >
        //             <TableCell
        //               style={{
        //                 borderBottom: "none",
        //                 textAlign: "center",
        //                 borderTopLeftRadius: 8,
        //                 borderBottomLeftRadius: 8,
        //                 padding: 4,
        //               }}
        //             >
        //               <CustomTypography
        //                 fontSize="13px"
        //                 color="#333333"
        //                 fontWeight={400}
        //               >
        //                 {x?.type}
        //               </CustomTypography>
        //             </TableCell>
        //             <TableCell
        //               component="th"
        //               scope="row"
        //               style={{
        //                 borderBottom: "none",
        //                 padding: 4,
        //                 textAlign: "center",
        //               }}
        //             >
        //               <CustomTypography
        //                 fontSize="13px"
        //                 color="#333333"
        //                 fontWeight={400}
        //               >
        //                 {x?.pricing?.slice(0, 2).map((pricing, index) => (
        //                   <React.Fragment key={index}>
        //                     {pricing.min_weight}
        //                     {index === 0 && x?.pricing.length > 1 ? ", " : ""}
        //                   </React.Fragment>
        //                 ))}
        //                 {x?.pricing.length > 2 && (
        //                   <span
        //                     onClick={() => handleViewClick(x)}
        //                     style={{ cursor: "pointer", color: "#0073e6" }}
        //                   >
        //                     {` ... (${x?.pricing.length - 2} more)`}
        //                   </span>
        //                 )}
        //               </CustomTypography>
        //             </TableCell>
        //             <TableCell
        //               component="th"
        //               scope="row"
        //               style={{
        //                 borderBottom: "none",
        //                 padding: 4,
        //                 textAlign: "center",
        //               }}
        //             >
        //               <CustomTypography
        //                 fontSize="13px"
        //                 color="#333333"
        //                 fontWeight={400}
        //               >
        //                 {x?.pricing?.slice(0, 2).map((pricing, index) => (
        //                   <React.Fragment key={index}>
        //                     {pricing.max_weight}
        //                     {index === 0 && x?.pricing.length > 1 ? ", " : ""}
        //                   </React.Fragment>
        //                 ))}
        //                 {x?.pricing.length > 2 && (
        //                   <span
        //                     onClick={() => handleViewClick(x)}
        //                     style={{ cursor: "pointer", color: "#0073e6" }}
        //                   >
        //                     {` ... (${x?.pricing.length - 2} more)`}
        //                   </span>
        //                 )}
        //               </CustomTypography>
        //             </TableCell>
        //             <TableCell
        //               component="th"
        //               scope="row"
        //               style={{
        //                 borderBottom: "none",
        //                 padding: 4,
        //                 textAlign: "center",
        //               }}
        //             >
        //               <CustomTypography
        //                 fontSize="13px"
        //                 color="#333333"
        //                 fontWeight={400}
        //               >
        //                 {x?.pricing?.slice(0, 2).map((pricing, index) => (
        //                   <React.Fragment key={index}>
        //                     {pricing.max_days}
        //                     {index === 0 && x?.pricing.length > 1 ? ", " : ""}
        //                   </React.Fragment>
        //                 ))}
        //                 {x?.pricing.length > 2 && (
        //                   <span
        //                     onClick={() => handleViewClick(x)}
        //                     style={{ cursor: "pointer", color: "#0073e6" }}
        //                   >
        //                     {` ... (${x?.pricing.length - 2} more)`}
        //                   </span>
        //                 )}
        //               </CustomTypography>
        //             </TableCell>
        //             <TableCell
        //               component="th"
        //               scope="row"
        //               style={{
        //                 borderBottom: "none",
        //                 padding: 4,
        //                 textAlign: "center",
        //               }}
        //             >
        //               <CustomTypography
        //                 fontSize="13px"
        //                 color="#333333"
        //                 fontWeight={400}
        //               >
        //                 {x?.pricing?.slice(0, 2).map((pricing, index) => (
        //                   <React.Fragment key={index}>
        //                     {pricing.max_days}
        //                     {index === 0 && x?.pricing.length > 1 ? ", " : ""}
        //                   </React.Fragment>
        //                 ))}
        //                 {x?.pricing.length > 2 && (
        //                   <span
        //                     onClick={() => handleViewClick(x)}
        //                     style={{ cursor: "pointer", color: "#0073e6" }}
        //                   >
        //                     {` ... (${x?.pricing.length - 2} more)`}
        //                   </span>
        //                 )}
        //               </CustomTypography>
        //             </TableCell>

        //             <TableCell
        //               component="th"
        //               scope="row"
        //               style={{
        //                 borderBottom: "none",
        //                 padding: 4,
        //                 textAlign: "center",
        //               }}
        //             >
        //               <CustomTypography
        //                 fontSize="13px"
        //                 color="#333333"
        //                 fontWeight={400}
        //               >
        //                 {x?.pricing?.slice(0, 2).map((pricing, index) => (
        //                   <React.Fragment key={index}>
        //                     {pricing.price}
        //                     {index === 0 && x?.pricing.length > 1 ? ", " : ""}
        //                   </React.Fragment>
        //                 ))}
        //                 {x?.pricing.length > 2 && (
        //                   <span
        //                     onClick={() => handleViewClick(x)}
        //                     style={{ cursor: "pointer", color: "#0073e6" }}
        //                   >
        //                     {` ... (${x?.pricing.length - 2} more)`}
        //                   </span>
        //                 )}
        //               </CustomTypography>
        //             </TableCell>

        //             <TableCell
        //               style={{
        //                 borderBottom: "none",
        //                 textAlign: "center",
        //                 padding: 4,
        //                 borderTopRightRadius: 8,
        //                 borderBottomRightRadius: 8,
        //               }}
        //             >
        //               {x.action}
        //             </TableCell>
        //           </TableRow>
        //         </React.Fragment>
        //       ))}
        //     </TableBody>
        //   </Table>
        // </TableContainer>
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                1 ? (
                  <Icon>
                    <UpIcon color="disabled" />
                  </Icon>
                ) : filterData.columns.find((x) => x.key === "name")
                    ?.sort === -1 ? (
                  <Icon>
                    <DownIcon color="disabled" />
                  </Icon>
                ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      TYPE
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                1 ? (
                  <Icon>
                    <UpIcon color="disabled" />
                  </Icon>
                ) : filterData.columns.find((x) => x.key === "email")
                    ?.sort === -1 ? (
                  <Icon>
                    <DownIcon color="disabled" />
                  </Icon>
                ) : null} */}
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    >
                      {"Min Weight (Kg)"}
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //   onClick={(event) => handleOpenFilter(event, "email")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                1 ? (
                  <Icon>
                    <UpIcon color="disabled" />
                  </Icon>
                ) : filterData.columns.find((x) => x.key === "name")
                    ?.sort === -1 ? (
                  <Icon>
                    <DownIcon color="disabled" />
                  </Icon>
                ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      {" Max Weight (Kg)"}
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                1 ? (
                  <Icon>
                    <UpIcon color="disabled" />
                  </Icon>
                ) : filterData.columns.find((x) => x.key === "name")
                    ?.sort === -1 ? (
                  <Icon>
                    <DownIcon color="disabled" />
                  </Icon>
                ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Min Days
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                1 ? (
                  <Icon>
                    <UpIcon color="disabled" />
                  </Icon>
                ) : filterData.columns.find((x) => x.key === "name")
                    ?.sort === -1 ? (
                  <Icon>
                    <DownIcon color="disabled" />
                  </Icon>
                ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Max Days
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      {"Price (USD)"}
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  >
                    Actions
                  </CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {shippigPriceData?.map((x, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <Tooltip title={x?.type}>
                    <StyledTableCell>
                      <Typography
                        style={{
                          // marginLeft: 22,
                          maxWidth: 100,
                          whiteSpace: "nowrap", // Ensure single line
                          overflow: "hidden", // Prevent text overflow
                          textOverflow: "ellipsis", // Show ellipsis for overflow text
                        }}
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        title={x?.type} // Add a title attribute to show full text on hover
                      >
                        {x?.type}
                      </Typography>
                    </StyledTableCell>
                  </Tooltip>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     overflow: "hidden",
                      //     textOverflow: "ellipsis",
                      //     maxWidth: "200px",
                      //   }}
                      ml={0.5}
                    >
                      {x?.pricing?.slice(0, 2).map((pricing, index) => (
                        <React.Fragment key={index}>
                          {pricing.min_weight}
                          {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                      {x?.pricing.length > 2 && (
                        <span
                          onClick={() => handleViewClick(x)}
                          style={{ cursor: "pointer", color: "#0073e6" }}
                        >
                          {` ... (${x?.pricing.length - 2} more)`}
                        </span>
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     overflow: "hidden",
                      //     textOverflow: "ellipsis",
                      //     maxWidth: "200px",
                      //   }}
                      ml={0.5}
                    >
                      {x?.pricing?.slice(0, 2).map((pricing, index) => (
                        <React.Fragment key={index}>
                          {pricing.max_weight}
                          {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                      {x?.pricing.length > 2 && (
                        <span
                          onClick={() => handleViewClick(x)}
                          style={{ cursor: "pointer", color: "#0073e6" }}
                        >
                          {` ... (${x?.pricing.length - 2} more)`}
                        </span>
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     overflow: "hidden",
                      //     textOverflow: "ellipsis",
                      //     maxWidth: "200px",
                      //   }}
                      ml={0.5}
                    >
                      {x?.pricing?.slice(0, 2).map((pricing, index) => (
                        <React.Fragment key={index}>
                          {pricing.max_days}
                          {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                      {x?.pricing.length > 2 && (
                        <span
                          onClick={() => handleViewClick(x)}
                          style={{ cursor: "pointer", color: "#0073e6" }}
                        >
                          {` ... (${x?.pricing.length - 2} more)`}
                        </span>
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     overflow: "hidden",
                      //     textOverflow: "ellipsis",
                      //     maxWidth: "200px",
                      //   }}
                      ml={0.5}
                    >
                      {x?.pricing?.slice(0, 2).map((pricing, index) => (
                        <React.Fragment key={index}>
                          {pricing.max_days}
                          {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                      {x?.pricing.length > 2 && (
                        <span
                          onClick={() => handleViewClick(x)}
                          style={{ cursor: "pointer", color: "#0073e6" }}
                        >
                          {` ... (${x?.pricing.length - 2} more)`}
                        </span>
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     overflow: "hidden",
                      //     textOverflow: "ellipsis",
                      //     maxWidth: "200px",
                      //   }}
                      ml={0.5}
                    >
                      {x?.pricing?.slice(0, 2).map((pricing, index) => (
                        <React.Fragment key={index}>
                          {pricing.price}
                          {index === 0 && x?.pricing.length > 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                      {x?.pricing.length > 2 && (
                        <span
                          onClick={() => handleViewClick(x)}
                          style={{ cursor: "pointer", color: "#0073e6" }}
                        >
                          {` ... (${x?.pricing.length - 2} more)`}
                        </span>
                      )}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                    {x?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "70vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
        {/* <TablePagination
          slotProps={{
            actions: {
              nextButton: {
                disabled: dataCount <= rowsPerPage * (page + 1)
              }
            }
          }}
          nextIconButton={
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          }
          backIconButton={
            <IconButton>
              <KeyboardArrowLeft />
            </IconButton>
          }
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#ffffff',
            padding: '2px',
            borderTop: '1px solid #dddddd'
          }}
        /> */}
        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedShipmentPrice?.type} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
        <ShippingPriceDetailsModal
          shippingPrice={selectedShipmentPrice}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        />
      </div>
    </>
  );
};

export default ShipmentOptionListView;
