import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import CustomPagination from "../../components/pagination";
import { assignUserService } from "../../utils/services/assignUserService";
import AssignUserModal from "../../components/assign-user-modal";
import UserList from "../../components/assignUser-list";

export default function AssignUser() {
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [openModal, setOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const clientId = localStorage.getItem("client_id");
  const [viewMode, setViewMode] = useState("list");

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await assignUserService.fetch(
        rowsPerPage,
        skip,
        clientId,
        searchValue
      );
      setUserData(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [page, rowsPerPage, searchValue]);

  return (
    <DashboardContainer
      filterButton={false}
      searchBarPlaceholder={"Search by User Name"}
      hideFilterClient
      onSearchData={(x) => {
        setSearchValue(x);
        setPage(0);
      }}
      onClickAddButton={() => setOpenModal(true)}
      hideUploadButton={true}
      header={"User List"}
      title={"+ Add User"}
      setViewMode={setViewMode}
    >
      <AssignUserModal
        selectedUser={selectedUser}
        handleClose={() => {
          setOpenModal(false);
          setSelectedUser(null);
        }}
        open={openModal}
        onAddData={fetchUserData}
      />
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <UserList
        onDeleteData={() => fetchUserData()}
        onEditData={(e) => {
          setSelectedUser(e);
          setOpenModal(true);
        }}
        data={userData}
        viewMode={viewMode}
        deleteTitle={"Delete User"}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        currentPage={page}
        itemsPerPage={rowsPerPage}
      />

      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        // disableNext={dataCount <= (page + 1) * filterData.limit}
      />
    </DashboardContainer>
  );
}
