import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { isMobile } from "../../globalStyles/mobile";

const MonthlyOrderComparision = ({ data }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOption, setChartOption] = useState(null);

  useEffect(() => {
    if (data && data.length) {
      setChartOption({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: isMobile() ? "top" : "bottom",
            labels: {
              font: {
                size: isMobile() ? 10 : 14,
              },
            },
          },
          title: {
            display: true,
            text: "",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: isMobile() ? 10 : 14,
              },
            },
            title: {
              display: false,
              text: "Count",
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: isMobile() ? 10 : 14,
              },
            },
            title: {
              display: false,
              text: "Status",
            },
          },
        },
      });
      setChartData({
        labels: data.map((x) => x.status),
        datasets: [
          {
            label: "Last Month",
            data: data.map((x) => x.lastMonth),
            backgroundColor: "#156082",
            borderColor: "#156082",
            borderWidth: 1,
          },
          {
            label: "Current Month",
            data: data.map((x) => x.currentMonth),
            backgroundColor: "#E97132",
            borderColor: "#E97132",
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data]);

  return (
    <Card
      sx={{
        minWidth: "100%",
        padding: isMobile() ? 0.5 : 1,
        backgroundColor: "#E9F9ED",
        borderRadius: "6px",
        position: "relative",
        minHeight: isMobile() ? 250 : 350,
        border: "1px solid #2B7A4B",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: isMobile() ? -20 : -30,
          right: isMobile() ? -5 : -10,
          width: isMobile() ? "60px" : "80px",
          height: isMobile() ? "60px" : "80px",
          borderRadius: "100%",
        }}
      />
      <CardContent>
        <div
          style={{
            width: "95%",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              fontSize={isMobile() ? "14px" : "25px"}
              sx={{ fontWeight: "600", color: "#2B7A4B", textAlign: "left" }}
            >
              {`Order Processed (Last Month vs Current Month)`}
            </Typography>
          </div>
          <Box
            sx={{
              height: isMobile() ? 200 : 300,
              width: "100%",
            }}
          >
            {chartData && chartOption ? (
              <Bar options={chartOption} data={chartData} />
            ) : (
              <></>
            )}
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default MonthlyOrderComparision;
