import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Drawer,
  Popover,
  Avatar
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AppContext from '../context/context'
import Sidebar from '../sidebar/sidebar'
import Breadcrumbs from '../breadcrumbs'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import CustomTypography from '../../globalStyles/fonts'
import NotificationModal from '../notificationModal'
import SearchBar from '../headerSearchbar'
import { styled } from '@mui/material/styles'
import AccountSettings from '../account-setting'
import AccountSettingsModal from '../account-setting'
import { clientService } from '../../utils/services/clientService'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
const ResponsiveImage = styled('img')({
  width: '100px',
  height: '50px',
  maxWidth: '100%',
  maxHeight: '100%'
})

export default function Layout({ children, title }) {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [notifications, setNotifications] = React.useState([])
  const [showDrawer, setShowDrawer] = useState(true)
  const [drawerWidth, setDrawerWidth] = useState(231)
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null)
  const [openAccountSettingModal, setOpenAccountSettingModal] = useState(false)
  const [clientDetails, setClientDetails] = useState(null)
  const navigate = useNavigate()
  const { user, setIsAuthenticated, setIsSidebarOpen, isSidebarOpen } = React.useContext(AppContext)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    const adminData = localStorage.getItem('adminUser')
    const adminToken = localStorage.getItem('adminToken')

    if (adminData) {
      const token = localStorage.getItem('token')
      localStorage.clear()
      localStorage.setItem('user', adminData)
      localStorage.setItem('token', adminToken)
      window.location.reload()
    } else {
      setIsAuthenticated(false)
      navigate('/login', { replace: true })
      localStorage.clear()
    }
  }

  const handleNotificationClick = (event) => {
    setAnchorNotificationEl(event.currentTarget)
  }

  const handleNotificationClose = () => {
    setAnchorNotificationEl(null)
  }
  const fetchClientById = async () => {
    let client = await clientService.get(localStorage.getItem('client_id'))
    setClientDetails(client)
  }

  useEffect(() => {
    if (localStorage.getItem('client_id')) {
      fetchClientById()
    }
  }, [user])
  const isMobile = useMediaQuery('(max-width:700px)')

  const handleSearchData = (searchTerm) => {
    console.log(searchTerm)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <AppBar
          sx={{
            display: 'flex',
            width: isMobile ? '100vw' : drawerWidth === 231 ? `calc(100% - 231px)` : `calc(100% - 80px)`,
            height: 60,
            justifyContent: 'center',
            backgroundColor: '#f0f0f0',
            color: 'gray',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            boxShadow: 'none', // Removes shadow
            borderLeft: 'none',
            borderBottom: '1px solid #E0E0E0',
            flexDirection: 'row'
          }}
          position="fixed"
        >
          {isMobile ? (
            <img
              onClick={() => {
                setOpen(!open)
                setShowDrawer(!showDrawer)
                setIsSidebarOpen(!isSidebarOpen)
              }}
              style={{ marginLeft: 10, height: 40, width: 60, marginTop: 10 }}
              src={clientDetails?.logo ? `${clientDetails?.logo}` : '/DropSkipLogo.png'}
              alt="Logo"
            />
          ) : (
            <></>
          )}

          <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Box sx={{ marginRight: 10 }}>
              <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="notifications"
                onClick={handleNotificationClick}
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar
                src={user?.image || 'user.png'}
                alt="Profile Image"
                sx={{ width: 48, height: 38, borderRadius: 12 }}
              />
              <Box onClick={handleMenuClick} sx={{ display: 'flex', flexDirection: 'column' }}>
                <CustomTypography color="#333333" fontSize={'15px'} fontWeight={500}>
                  {user?.name}
                </CustomTypography>
                {/* <CustomTypography color="#333333" fontSize={'12px'} fontWeight={400}>
                  {user?.role}
                </CustomTypography> */}
              </Box>
            </Box>
            <Box>
              <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <ArrowDropDownIcon color="#151D48" />
              </IconButton>
              <Menu id="menu-appbar" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {JSON.parse(localStorage.getItem('user')).role === 'admin' ? (
                  <></>
                ) : (
                  <MenuItem
                    sx={{ color: '#000' }}
                    onClick={() => {
                      localStorage.setItem('path', `/account-setting`)
                      navigate(`/account-setting`)
                      setAnchorEl(null)
                      if (!isMobile) {
                        setOpen(!open)
                      }
                    }}
                  >
                    Account Setting
                  </MenuItem>
                )}
                <MenuItem sx={{ color: '#000' }} onClick={logout}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            marginTop: '60px',
            borderRight: '1px solid #E0E0E0'
          }}
        >
          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={open}
            onClose={toggleDrawer}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              width: isMobile ? 200 : drawerWidth,
              borderRight: isMobile ? '' : '1px solid #E0E0E0',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: isMobile ? 200 : drawerWidth,
                boxSizing: 'border-box',
                borderRight: '1px solid #E0E0E0'
              }
            }}
          >
            {isMobile ? (
              <></>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                  marginLeft: isSidebarOpen ? 2 : 0,
                  padding: 1,
                  justifyContent: 'space-between'
                }}
              >
                {!isSidebarOpen ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 10,
                      borderRadius: 100,
                      backgroundColor: '#C4C4C4',
                      width: 40,
                      height: 40,
                      color: '#FFF',
                      cursor: 'pointer'
                    }}
                  >
                    {user.name.toString()[0]}
                  </div>
                ) : user.role === 'admin' ? (
                  <ResponsiveImage src="/DropSkipLogo.png" alt="Logo" />
                ) : clientDetails?.logo ? (
                  <ResponsiveImage
                    style={{ borderRadius: 10, objectFit: 'contain' }}
                    src={`${clientDetails?.logo}`}
                    alt="Logo"
                  />
                ) : (
                  <strong>{clientDetails?.business_name}</strong>
                )}
                {isSidebarOpen ? (
                  <div
                    style={{
                      border: '1px solid #eaeaea',
                      height: 35,
                      width: 35,
                      borderRadius: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setDrawerWidth(drawerWidth === 231 ? 80 : 231)
                      setOpen(!open)
                      setShowDrawer(!showDrawer)
                      setIsSidebarOpen(!isSidebarOpen)
                    }}
                  >
                    <ChevronLeftIcon
                      sx={{
                        height: 25,
                        width: 25,
                        color: '#000'
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {!isSidebarOpen ? (
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      borderRadius: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      right: 10
                    }}
                    onClick={() => {
                      setDrawerWidth(drawerWidth === 231 ? 80 : 231)
                      setOpen(!open)
                      setShowDrawer(!showDrawer)
                      setIsSidebarOpen(!isSidebarOpen)
                    }}
                  >
                    <ChevronRightIcon
                      sx={{
                        height: 25,
                        width: 25,
                        color: '#000',
                        cursor: 'pointer'
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {/* <SearchBar
                  onSearchData={handleSearchData}
                  searchIconColor="#5D5FEF"
                  backgroundColor="#F7FAFC"
                  borderColor="#EEEEEE"
                /> */}
              </Box>
            )}
            <Box
              sx={{
                height: '100%',
                overflowY: 'auto'
              }}
            >
              <Sidebar
                hideSideBar={isMobile ? false : !showDrawer}
                onClickMenu={toggleDrawer}
                onClickSubMenu={toggleDrawer}
              />
            </Box>
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: 2,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              backgroundColor: '#F7F8F9',
              height: '90vh',
              overflow: 'hidden',
              overflowX: 'hidden'
            }}
          >
            <div>
              <Breadcrumbs />
            </div>
            {children}
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorNotificationEl)}
          anchorEl={anchorNotificationEl}
          onClose={handleNotificationClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationModal onClose={handleNotificationClose} />
        </Popover>
        <AccountSettingsModal
          user={user}
          handleClose={() => setOpenAccountSettingModal(false)}
          open={openAccountSettingModal}
        ></AccountSettingsModal>
      </Box>
    </>
  )
}
