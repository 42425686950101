import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Network } from "vis-network/standalone";
import "vis-network/styles/vis-network.css";
import { eventService } from "../../utils/services/eventService";
import ReactJson from "react-json-view";
import CustomTypography from "../../globalStyles/fonts";
import { toast } from "react-toastify";
import JsonForm from "../../components/json-form";
import ExpandIcon from "@mui/icons-material/Expand";
import { Box, IconButton, CircularProgress, useTheme } from "@mui/material";
import JsonFormModal from "../../components/json-form-modal";
import DashboardContainer from "../../components/dashboard-container";
const OrderWorkFlow = () => {
  const networkContainer = useRef(null);
  const { id } = useParams();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [selectedNodeStatus, setSelectedNodeStatus] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [expandView, setExpandView] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  function formatString(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const options = {
    nodes: {
      shape: "box",
      font: { size: 16, color: "#FFFFFF" },
      margin: 10,
    },
    edges: {
      arrows: { to: { enabled: true, scaleFactor: 1.5 } },
      smooth: { type: "continuous" },
    },
    physics: { enabled: false },
    interaction: {
      hover: true,
      dragNodes: true,
      dragView: true,
      zoomView: true,
    },
    layout: {
      hierarchical: {
        direction: "UD", // 'UD' means Up-to-Down
        sortMethod: "directed", // Ensures edges are directed properly
        nodeSpacing: 100, // Adjust spacing between nodes
        levelSeparation: 150, // Adjust vertical distance between levels
      },
    },
  };
  console.log(id);

  const fetchEventLogs = async () => {
    setSelectedNodeData(null);
    setLoading(true);
    try {
      const response = await eventService.getEventLogs(id);

      const processedIds = new Set();
      const nodeList = response.data.map((event, index) => {
        const { step, event_name, status, ...rest } = event;

        // Generate a unique ID for each step
        let uniqueId = step;
        let counter = 1;
        while (processedIds.has(uniqueId)) {
          uniqueId = `${step}_${counter}`;
          counter++;
        }

        processedIds.add(uniqueId);

        return {
          id: uniqueId, // Use the uniqueId here
          label: formatString(event_name),
          color: status === "Success" ? "#018749" : "#FF6347",
          message: rest,
          status,
          originalStep: step,
        };
      });

      // Sort nodeList based on the `step` value to ensure the correct order
      const sortedNodeList = nodeList.sort(
        (a, b) => a.originalStep - b.originalStep
      );

      const edgeList = [];

      // Create edges by connecting the sorted nodes
      for (let i = 0; i < sortedNodeList.length - 1; i++) {
        const fromNode = sortedNodeList[i];
        const toNode = sortedNodeList[i + 1];

        edgeList.push({
          from: fromNode.id,
          to: toNode.id,
        });
      }

      setNodes(sortedNodeList);
      setEdges(edgeList);
    } catch (error) {
      console.error("Error fetching event logs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchEventLogs();
    }
  }, [id]);

  useEffect(() => {
    if (nodes.length && edges.length) {
      const data = { nodes, edges };
      const network = new Network(networkContainer.current, data, options);

      // Add a click event listener to the network
      network.on("click", function (event) {
        const clickedNodeId = event.nodes[0];
        if (clickedNodeId) {
          const clickedNode = nodes.find((node) => node.id === clickedNodeId);

          if (clickedNode) {
            setSelectedNodeId(clickedNode.id);
            setSelectedNodeData(clickedNode);
            setSelectedNodeStatus(clickedNode.status);
          }
        }
      });
    }
  }, [nodes, edges]);

  const handleRetrigger = async (data) => {
    if (!selectedNodeId || !selectedNodeData) {
      toast.error(
        "No node selected for re-trigger. Please choose any of the node to re-trigger."
      );
    }
    setLoading(true);
    try {
      const response = await eventService.retrigger(
        data
          ? {
              id: selectedNodeData.message._id,
              input: data.input,
              extra_data: data.extra_data,
            }
          : { id: selectedNodeData.message._id }
      );
      fetchEventLogs();
      // alert(`Event with ID ${selectedNodeId} retriggered successfully.`);
      // Optionally refresh the event logs after retriggering
      // fetchEventLogs();
    } catch (error) {
      console.error(
        `Failed to retrigger event with ID ${selectedNodeId}:`,
        error
      );
      // toast.error("Failed to retrigger event. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
        color: "white",
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  ) : (
    <DashboardContainer
      workflow
      onClickRefresh={fetchEventLogs}
      headerButtons={
        selectedNodeData ? (
          <>
            {selectedNodeData?.message?.error?.error ? (
              <button
                style={{
                  margin: "5px",
                  backgroundColor: theme.palette?.primary?.main || "#FF6347",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  padding: 5,
                  fontSize: 14,
                  fontFamily: "Poppins",
                }}
                onClick={() => {}}
              >
                Expose to client
              </button>
            ) : (
              <></>
            )}
            <button
              style={{
                margin: "5px",
                backgroundColor: theme.palette?.primary?.main || "#FF6347",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                padding: 5,
                fontSize: 14,
                fontFamily: "Poppins",
              }}
              onClick={handleRetrigger}
            >
              Retrigger Event
            </button>
          </>
        ) : (
          <></>
        )
      }
      hideUploadButton
      hideAddButton
      dedicatedPage={true}
      header={"Work flow"}
    >
      <div style={{ display: "flex", height: "78vh" }}>
        <div
          style={{
            height: "90%",
            width: "70%",
            border: "1px dotted",
            marginTop: 15,
          }}
          ref={networkContainer}
        ></div>
        <div
          style={{
            width: "40%",
            borderRight: "1px solid #ccc",
            borderTop: "1px solid #ccc",
            marginTop: 15,
            padding: "10px",
          }}
        >
          <div>
            {selectedNodeData ? (
              <CustomTypography style={{ fontWeight: "500" }}>
                {`${selectedNodeData?.label} Data`}{" "}
                <IconButton
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpandView(true)}
                >
                  <ExpandIcon></ExpandIcon>
                </IconButton>
              </CustomTypography>
            ) : (
              <></>
            )}
          </div>

          <div
            style={{
              overflow: "auto",
              height: "90%",
            }}
          >
            {selectedNodeData ? (
              <div>
                <JsonForm renderData={selectedNodeData.message}></JsonForm>
                {/* <ReactJson
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  width: "100%",
                }}
                displayDataTypes={false}
                quotesOnKeys={false}
                displayObjectSize
                iconStyle="square"
                src={selectedNodeData.message}
                theme="bright"
                collapsed={2}
              /> */}
                {/* {selectedNodeStatus === "Failed" && ( */}

                {/* )} */}
              </div>
            ) : (
              <CustomTypography style={{ fontWeight: "500" }}>
                Click on a node to see its details
              </CustomTypography>
            )}
          </div>
        </div>
        <JsonFormModal
          onSaveData={(data) => handleRetrigger(data)}
          isModalOpen={expandView}
          renderData={selectedNodeData?.message}
          setModalOpen={setExpandView}
        ></JsonFormModal>
      </div>
    </DashboardContainer>
  );
};

export default OrderWorkFlow;
