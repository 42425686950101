import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { portService } from "../../utils/services/portService";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import CustomPagination from "../../components/pagination";
import PortModal from "../../components/port-modal";
import PortList from "../../components/port-list";

export default function Port() {
  const [portData, setPortData] = useState([]);
  const [selectedPort, setSelectedPort] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [openModal, setOpenModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [searchValue, setSearchValue] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const [viewMode, setViewMode] = useState("list");

  const fetchPortData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await portService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      );
      setPortData(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPortData();
  }, [page, rowsPerPage, clientFilter, searchValue]);

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );

  return (
    <DashboardContainer
      searchBarPlaceholder={"Search by Port Name"}
      hideFilterClient
      onSearchData={(x) => {
        setSearchValue(x);
        setPage(0);
      }}
      onClickAddButton={() => setOpenModal(true)}
      hideUploadButton={true}
      header={"Port List"}
      onSelectClient={(e) => {
        setClientFilter(e);
      }}
      selectedClient={selectedClient?.name}
      title={"+ Add Port"}
      setViewMode={setViewMode}
    >
      <PortModal
        selectedPort={selectedPort}
        handleClose={() => {
          setOpenModal(false);
          setSelectedPort(null);
        }}
        open={openModal}
        onAddData={fetchPortData}
      />

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      {portData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <NoDataImage />
        </div>
      ) : (
        <PortList
          onDeleteData={() => fetchPortData()}
          onEditData={(e) => {
            setSelectedPort(e);
            setOpenModal(true);
          }}
          data={portData}
          viewMode={viewMode}
          deleteTitle={"Delete Port"}
          selectedPort={selectedPort}
          setSelectedPort={setSelectedPort}
          currentPage={page}
          itemsPerPage={rowsPerPage}
        />
      )}

      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        // disableNext={dataCount <= (page + 1) * filterData.limit}
      />
    </DashboardContainer>
  );
}
