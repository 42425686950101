import './App.css'
import LoginPage from './pages/login/index'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ProtectedRoute from './protectedRoute'
import { AppProvider } from './components/context/context'
import RateCard from './pages/rate-card'
import ZipZone from './pages/zip-zone'
import Carrier from './pages/carrier'
import PortLookup from './pages/port-lookup'
import RateLookup from './pages/rate-lookup'
import Simulation from './pages/simulation'
import ServiceManagement from './pages/carrier-service-managemnet'
import Zone from './pages/zone'
import Port from './pages/port'
import Client from './pages/client'
import ForgotPassword from './pages/forgot-password'
import Product from './pages/Product'
import OrderManagement from './pages/order-management'
import Shipment from './pages/shipment'
import ShipmentOptions from './pages/shipment-options'
import DashboardScreen from './pages/dashboard-screen'
import WeightPackage from './pages/package-mapping'
import ShippingCost from './pages/shipping-cost'
import OrderTracking from './pages/track-order'
import OrderTrackingHistory from './pages/order-tracking-history'
import ForeCast from './pages/forecast'
import Transaction from './pages/transaction'
import Partner from './pages/partner'
import CustomClearance from './pages/custom-clearance'
import CustomProvider from './pages/custom-provider'
import FreightBroker from './pages/frieght-broker'
import Supplier from './pages/supplier'
// import LandingPage from './pages/home-page'
import ImportProduct from './pages/import-product'
import OnBoardLogin from './pages/onboard-login'
import AccountSettings from './pages/account-setting'
import AlertsAndNotification from './pages/alerts-and-notifications'
import Billing from './pages/billing'
import EmailTemplateScreen from './pages/email-template'
import ManagePO from './pages/manage-po'
import ManageProduct from './pages/manage-product'
import GlobalNetworkDesign from './pages/global-network-design'
import Provider from './pages/provider'
import ProviderEventMapping from './pages/provider-event-mapping'
import OrderWorkFlow from './pages/order-flow'
import TrackingEvents from './pages/tracking-event'
import Events from './pages/events'
import Rules from './pages/rules'
import AssignUser from './pages/assign-user'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ArcElement,
  BarElement,
  RadialLinearScale
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import Manifest from './pages/manifest'
import Overpack from './pages/overpack'
import Shipments from './pages/shipments'
import ShopifyAppConfig from './pages/shopify-app-config'
import ClientOnboard from './pages/client-onboard'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ArcElement,
  BarElement,
  RadialLinearScale
)

function App() {
  return (
    <div className="App">
      <AppProvider>
        <Router>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route element={<Product />} path="/product" />
              <Route element={<ForeCast />} path="/forecast" />
              <Route element={<DashboardScreen />} path="/" />
              <Route element={<Client />} path="/client" />
              <Route element={<Port />} path="/port" />
              <Route element={<Zone />} path="/zone" />
              <Route element={<RateCard />} path="/rate-card-management" exact />
              <Route element={<ZipZone />} path="/zip-zone" />
              <Route element={<Carrier />} path="/carrier" />
              <Route element={<PortLookup />} path="/port-lookup" />
              <Route element={<RateLookup />} path="/rate-lookup" />
              <Route element={<Simulation />} path="/simulation" />
              <Route element={<OrderManagement />} path="/order-management" />
              <Route element={<Shipment />} path="/shipment" />

              <Route element={<Shipments />} path="/shipments" />
              <Route element={<ShipmentOptions />} path="/shipment-options" />
              <Route element={<ShippingCost />} path="/shipment-cost" />
              <Route element={<Transaction />} path="/shipment-transaction" />
              <Route element={<Partner />} path="/partner" />
              <Route element={<OrderTracking />} path="/track-order/:id" />
              <Route element={<WeightPackage />} path="/package-maping" />
              <Route element={<ServiceManagement />} path="/service-management" />
              <Route element={<OrderTrackingHistory />} path="/order-tracking-history/:id" />
              <Route element={<ServiceManagement />} path="/service-management" />
              <Route element={<Supplier />} path="/supplier" />
              <Route element={<CustomClearance />} path="/custom-clearance" />
              <Route element={<CustomProvider />} path="/custom-provider" />
              <Route element={<FreightBroker />} path="/freight-broker" />
              <Route element={<AccountSettings />} path="/account-setting" />
              <Route element={<AssignUser />} path="/assign-user" />
              <Route element={<AlertsAndNotification />} path="/alert-and-notification" />
              <Route element={<Billing />} path="/billing" />
              <Route element={<EmailTemplateScreen />} path="/email-template" />
              <Route element={<ManagePO />} path="/manage-po" />
              <Route element={<ManageProduct />} path="/manage-product/:id" />
              <Route element={<GlobalNetworkDesign />} path="/global-network-design" />
              <Route element={<TrackingEvents />} path="/tracking-event" />
              <Route element={<Provider />} path="/provider" />
              <Route element={<ProviderEventMapping />} path="/event-mapping" />
              <Route element={<OrderWorkFlow />} path="/order-flow/:id" />
              <Route element={<Events />} path="/events" />
              <Route element={<Rules />} path="/rules" />
              <Route element={<Overpack />} path="/overpack" />
              <Route element={<Manifest />} path="/manifest" />
              <Route element={<ShopifyAppConfig />} path="/shopify-app-config" />
            </Route>
            {/* <Route element={<LandingPage />} path="/home-page" /> */}
            <Route element={<LoginPage />} path="/login" />
            <Route element={<ForgotPassword />} path="/forgot-password" />
            <Route element={<OrderTrackingHistory />} path="/tracking-history/:id" />
            <Route element={<ImportProduct />} path="/import-product/:id" />
            <Route element={<OnBoardLogin />} path="/onboard-login" />
            <Route element={<ClientOnboard />} path="/client-onboard" />
          </Routes>
        </Router>
      </AppProvider>
    </div>
  )
}

export default App
