import React, { useEffect, useReducer, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { Box, CircularProgress } from "@mui/material";
import buildQuery from "../../utils/query-builder";
import { manifestService } from "../../utils/services/manifestService";
import ManifestList from "../../components/manifest-list";

const initialState = {
  columns: [
    {
      name: "Entry Point",
      key: "manifest.entry_point",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        manifestService.fetchEntryPoint(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      name: "Carrier",
      key: "manifest.carrier",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit, skip) =>
        manifestService.fetchCarrier(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
  ],
  limit: 15,
  //  populate: ["client_id"],
};
function filterReducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        columns: state?.columns.map((x) =>
          x?.key === action?.payload?.key ? { ...x, ...action.payload } : x
        ),
      };
    case "SET_OPTIONS":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key === action.payload.key
            ? { ...col, options: action.payload.options }
            : col
        ),
      };
    case "UPDATE_LIMIT":
      return { ...state, limit: action.payload };

    default:
      return state;
  }
}
const Manifest = () => {
  const [filterData, dispatch] = useReducer(filterReducer, initialState);
  const [manifestList, setManifestList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [clientFilter, setClientFilter] = useState(null);
  const [fetchData, setFetchData] = useState(0);

  const fetchManifest = async (signal) => {
    setLoading(true);
    try {
      const response = await manifestService.fetchManifest(
        clientFilter?._id,
        buildQuery(filterData, page),
        { signal }
      );

      setManifestList(response.data);
      setDataCount(response?.total || 0);
    } catch (error) {
      console.error("Fetch failed:", error);
      setManifestList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchManifest(signal);
    return () => {
      controller.abort();
    };
  }, [page, filterData, searchValue, clientFilter, fetchData]);

  const handleFilterChange = (newFilter) => {
    dispatch({ type: "SET_FILTER", payload: newFilter });
    setPage(0);
  };

  return (
    <>
      <DashboardContainer
        onChangeFilter={handleFilterChange}
        filterData={filterData}
        fields={filterData.columns}
        header={"Manifest List"}
        hideUploadButton
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        selectedClient={clientFilter?.name}
        hideAddButton
        manifest
        onClickRefresh={() => setFetchData((prev) => prev + 1)}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <ManifestList
            dataCount={dataCount}
            page={page}
            rowsPerPage={filterData.limit}
            setRowsPerPage={(e) =>
              dispatch({
                type: "UPDATE_LIMIT",
                payload: e,
              })
            }
            setPage={setPage}
            data={manifestList}
            onChangeFilter={handleFilterChange}
            filterData={filterData}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default Manifest;
