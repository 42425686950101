import { SvgIcon } from "@mui/material";
import React, { useState } from "react";

const OrderTrackingIcon = ({ fill }) => {
  return (
    <SvgIcon>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7807 3.36354C12.8571 3.42369 12.9328 3.48474 13.0078 3.54664C13.0458 3.57781 13.0839 3.60898 13.1231 3.64109C14.0333 4.42856 14.5779 5.55553 14.6875 6.74977C14.7562 8.00192 14.4405 9.17433 13.6328 10.1482C13.6069 10.1795 13.5811 10.2108 13.5545 10.2431C13.1949 10.6594 12.772 11.0235 12.3623 11.3894C12.1809 11.5525 12.0023 11.7187 11.8233 11.8844C11.7161 11.9837 11.6088 12.0828 11.5014 12.1819C11.4804 12.2013 11.4593 12.2208 11.4377 12.2408C11.259 12.4056 11.0799 12.5699 10.9005 12.734C10.8158 12.8116 10.7314 12.8896 10.6472 12.9677C10.6248 12.9885 10.6023 13.0094 10.5792 13.0308C10.5363 13.0706 10.4935 13.1104 10.4507 13.1503C10.3171 13.2742 10.1798 13.392 10.039 13.5076C9.68894 13.3459 9.41197 13.0094 9.1331 12.7482C8.92878 12.5569 8.7222 12.3682 8.51558 12.1795C8.43824 12.1087 8.36094 12.0379 8.28365 11.9671C8.2644 11.9494 8.24516 11.9318 8.22533 11.9136C8.05944 11.7615 7.89409 11.6089 7.72878 11.4561C7.58685 11.325 7.44464 11.1943 7.3022 11.0637C7.28313 11.0462 7.26406 11.0287 7.24441 11.0107C7.14599 10.9203 7.0472 10.8305 6.94774 10.7413C6.02276 9.90957 5.40539 8.8285 5.31246 7.57008C5.24223 6.20436 5.64725 4.97479 6.56124 3.94856C8.13698 2.21978 10.9042 1.99705 12.7807 3.36354ZM8.50688 5.84523C8.15496 6.27218 7.98134 6.78367 8.00777 7.33571C8.02466 7.44031 8.04316 7.53867 8.0688 7.64088C8.07558 7.66789 8.08236 7.6949 8.08934 7.72273C8.15902 7.97729 8.27821 8.18025 8.43746 8.39039C8.46727 8.43229 8.49708 8.47418 8.52779 8.51735C8.8846 8.88245 9.37457 9.13155 9.88834 9.14401C10.4947 9.14916 10.9974 8.96935 11.4453 8.54664C11.821 8.09374 12.0077 7.61897 11.9921 7.02321C11.9248 6.42427 11.6529 5.9634 11.1947 5.57637C10.3531 4.94846 9.21878 5.0882 8.50688 5.84523Z"
          fill="#FD7E14"
        />
        <path
          d="M11.1717 14.8356C11.5051 15.0907 11.7906 15.3911 11.8748 15.8122C12.8416 15.8122 13.8084 15.8122 14.8045 15.8122C14.8303 15.7477 14.8561 15.6833 14.8827 15.6169C15.1358 15.2101 15.4724 14.9753 15.9314 14.8425C16.3469 14.7547 16.8013 14.7953 17.1741 15.0074C17.4904 15.224 17.7228 15.4664 17.8905 15.8122C17.9037 15.8384 17.9169 15.8647 17.9306 15.8917C18.0953 16.2856 18.0526 16.8057 17.9007 17.1959C17.8243 17.352 17.7327 17.4799 17.617 17.6091C17.5974 17.6327 17.5779 17.6563 17.5577 17.6806C17.2694 18.0026 16.8547 18.1648 16.4313 18.2034C15.9346 18.2231 15.5287 18.0518 15.1521 17.7354C15.0004 17.592 14.8963 17.4448 14.8045 17.2575C14.8045 17.2317 14.8045 17.2059 14.8045 17.1794C13.8377 17.1794 12.8709 17.1794 11.8748 17.1794C11.8619 17.2438 11.8491 17.3083 11.8358 17.3747C11.5646 17.9005 11.1472 18.2798 10.5809 18.466C10.0065 18.5988 9.41999 18.5275 8.91341 18.2243C8.55519 17.9784 8.31198 17.6913 8.12484 17.2966C8.12484 17.2579 8.12484 17.2192 8.12484 17.1794C7.15804 17.1794 6.19124 17.1794 5.19515 17.1794C5.16937 17.2567 5.14359 17.3341 5.11703 17.4137C4.83693 17.812 4.45721 18.0793 3.9804 18.1781C3.52835 18.2456 3.09574 18.165 2.7101 17.9133C2.32516 17.6243 2.10479 17.2956 1.99203 16.8278C1.93544 16.3231 2.01529 15.8843 2.31414 15.4657C2.65275 15.0546 3.03786 14.8569 3.56505 14.803C4.02619 14.7827 4.4493 14.936 4.8149 15.2159C4.97122 15.364 5.09895 15.5412 5.19515 15.7341C5.19515 15.7598 5.19515 15.7856 5.19515 15.8122C6.16195 15.8122 7.12874 15.8122 8.12484 15.8122C8.13773 15.7477 8.15062 15.6833 8.1639 15.6169C8.43896 15.0901 8.83151 14.7468 9.39132 14.5529C9.99377 14.3749 10.6558 14.4748 11.1717 14.8356ZM9.47982 16.0929C9.37681 16.2646 9.31907 16.4365 9.34768 16.6383C9.41316 16.8493 9.54384 16.9813 9.7264 17.1012C9.92096 17.1661 10.0898 17.1637 10.2806 17.0964C10.4613 16.9759 10.5772 16.8455 10.6444 16.6398C10.6818 16.45 10.6408 16.2919 10.5467 16.1247C10.3883 15.9465 10.251 15.8592 10.0096 15.839C9.78415 15.8424 9.64283 15.9387 9.47982 16.0929Z"
          fill="#FD7E14"
        />
      </svg>
    </SvgIcon>
  );
};

export default OrderTrackingIcon;
