import buildQuery from "./query-builder";
import { orderService } from "./services/orderService";
import { productService } from "./services/productService";
import { supplierService } from "./services/supplierService";

export const orderFilterInitialState = {
  columns: [
    {
      key: "order_id",
      sort: 0,
      id: "_id",
      filter: [],
      displayKey: "_id",
      fetchApi: (searchKey = "", client_id, limit = 15, skip = 0) =>
        orderService.fetchOrderId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "shipping_address.name",
      sort: 0,
      id: "_id",
      filter: [],
      displayKey: "_id",
      fetchApi: (searchKey = "", client_id, limit = 15, skip = 0) =>
        orderService.fetchCustomerName(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "shipping_address.province",
      sort: 0,
      id: "_id",
      filter: [],
      displayKey: "_id",
      fetchApi: (searchKey = "", client_id, limit = 15, skip = 0) =>
        orderService.fetchCustomerState(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "order_date",
      sort: 0,
      id: "_id",
      filter: {
        startDate: null,
        endDate: null,
        // startDate: changeDateFormat(
        //   new Date(new Date().setMonth(new Date().getMonth() - 2))
        // ),
        // endDate: changeDateFormat(
        //   new Date(new Date().setDate(new Date().getDate() + 2))
        // ),
        key: "selection",
      },
      loadMoreButton: false,
      type: "date-range",
    },
    {
      key: "shipments.shipment",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit = 15, skip = 0) =>
        orderService.fetchShipmentId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "status",
      filter: [],
      options: [
        { name: "Ordered" },
        { name: "Accepted" },
        { name: "Confirmed" },
        { name: "Fulfilled" },
        { name: "Shipped" },
        { name: "In Transit" },
        { name: "Out for Delivery" },
        { name: "On Hold" },
        { name: "Delivered" },
        { name: "Cancelled" },
      ],
      sort: 0,
      id: "name",
      displayKey: "name",
      excludeValues: ["all"],
      loadMoreButton: false,
      searchKey: "",
    },
    {
      key: "shipments.tracking_number",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit = 15, skip = 0) =>
        orderService.fetchTrackingNumber(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
  ],
  limit: 15,
  populate: ["client_id"],
};

export const productFilterInitialState = {
  columns: [
    {
      key: "product_type",
      name: "Category",
      sort: 0,
      id: "_id",
      filter: [],
      displayKey: "_id",
      fetchApi: (searchKey = "", client_id, limit = 15, skip = 0) =>
        productService.fetchCategory(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "status",
      name: "Status",
      filter: [],
      options: [{ name: "active" }, { name: "draft" }],
      sort: 0,
      id: "name",
      displayKey: "name",
      excludeValues: ["all"],
      loadMoreButton: false,
      searchKey: "",
    },
    {
      key: "vendor",
      name: "Supplier",
      sort: 0,
      id: "_id",
      filter: [],
      displayKey: "name",
      fetchApi: (searchKey, client_id, limit, skip) =>
        supplierService.fetch(
          searchKey,
          client_id,
          buildQuery({ limit, skip })
        ),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "no_of_sku",
      name: "No Of SKU",
      filter: [],
      options: Array.from({ length: 100 }).map((x, i) => ({
        name: (i + 1).toString(),
      })),
      sort: 0,
      id: "name",
      displayKey: "name",
      loadMoreButton: false,
      searchKey: "",
    },
    {
      key: "compliance_status",
      name: "Compliance Status",
      filter: [],
      options: [
        { name: "Not Started" },
        { name: "In Progress" },
        { name: "Compliant" },
      ],
      sort: 0,
      id: "name",
      displayKey: "name",
      loadMoreButton: false,
      searchKey: "",
    },
    {
      key: "name",
      name: "Name",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "_id",
      fetchApi: (searchKey, client_id, limit = 15, skip = 0) =>
        productService.fetchNames(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
  ],
  limit: 15,
  populate: ["client_id", "vendor"],
};
