import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Collapse,
  Typography,
  Tooltip,
  Card,
  CardContent,
  Button,
  Grid,
  Popover,
  useTheme,
  Icon,
  Badge
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import OrderDetailsModal from '../orderDetailsModal.js'
import DeleteConfirmationModal from '../deleteConfirmationModal/index.js'
import { orderService } from '../../utils/services/orderService.js'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { isMobile } from '../../globalStyles/mobile/index.js'
import WarehouseList from '../warehouse-order-list/index.js'
import SortingIcon from '../../globalStyles/icons/sorting-icon.js'
import FilterModal from '../filterModal/index.js'
import CustomPagination from '../pagination/index.js'
import UpIcon from '@mui/icons-material/ArrowUpwardOutlined'
import DownIcon from '@mui/icons-material/ArrowDownwardOutlined'
import OrderFlowIcon from '@mui/icons-material/AccountTreeOutlined'
import WarehouseIcon from '../../globalStyles/icons/warehouse-icon.js'
import OrderTrackIcon from '../../globalStyles/icons/order-tracking-icon.js'
import ViewIcon from '../../globalStyles/icons/vieewIcon.js'
import CancelIcon from '@mui/icons-material/Cancel'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    // color: theme.palette.common.white,
    padding: '6px'
    //textAlign: "start",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px'
    // textAlign: "left",
  }
}))
const StyledTableRow = styled(TableRow)(({ theme, isExpandable }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: isExpandable ? '#D7E9FF' : '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: isExpandable ? '#D7E9FF' : '#F6F6F6',
    border: '1px solid #EAECF0'
  },

  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))

const OrderList = ({
  data,
  style = {},
  fetchOrderData,
  onChangeFilter,
  filterData,
  setPage,
  setRowsPerPage,
  rowsPerPage = 15,
  page = 0,
  dataCount
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [expandedRow, setExpandedRow] = useState(null)
  const [trackingDetails, setTrackingDetails] = useState({})
  const [errorData, setErrorData] = useState({})
  const [edit, setEdit] = useState(false)
  const [showCancelOrderConfirmModal, setShowCancelOrderConfirmModal] = useState(false)
  const [orderTrail, setOrderTrail] = useState([])
  const [showWarehouseDataModal, setShowWarehouseDataModal] = useState(false)
  const [orderData, setOrderData] = useState([])
  const [anchorElPopover, setAnchorElPopover] = useState(null)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: '',
    filter: [],
    sort: 0,
    id: '_id',
    displayKey: 'name',
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: '',
    type: 'list'
  })
  const [anchorPositionFilter, setAnchorPositionFilter] = useState(null)
  const startIndex = page * rowsPerPage
  const navigate = useNavigate()
  const theme = useTheme()
  const defaultColor = '#D13438'
  const iconColor = theme.palette?.primary?.main || defaultColor
  const user = JSON.parse(localStorage.getItem('user'))
  const [popoverPosition, setPopoverPosition] = useState(null)
  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget)
    setOpenFilterModal(true)
    setSelectedFilterData(filterData?.columns.find((x) => x?.key == filterKey))
  }

  const updateFilterData = () => {
    localStorage.setItem(
      'order-filter-data',
      JSON.stringify({
        populate: filterData.populate,
        filters: filterData.columns.map((x) => ({
          key: x.key,
          values: x.filter
        })),
        limit: filterData.limit
      })
    )
  }
  const handleSaveFilter = (selectedOptions, filterKey, sortOrder, searchKey) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey
    })
    handleCloseFilter()
  }
  const handleCloseFilter = () => {
    setAnchorElFilter(null)
    setOpenFilterModal(false)
  }

  const handlePopoverOpen = (e, order, position) => {
    e.stopPropagation()
    setSelectedOrder(order)
    setPopoverPosition(position)
    setAnchorElPopover(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElPopover(null)
    setSelectedOrder(null)
  }

  const handleViewClick = (order) => {
    setModalOpen(true)
    setSelectedOrder(order)
  }
  let dummyTrackingDetails = [
    { label: 'Ordered', sl: 1 },
    { label: 'Accepted', sl: 2 },
    { label: 'Confirmed', sl: 3 },
    { label: 'Fulfilled', sl: 4 },
    { label: 'Shipped', sl: 5 },
    { label: 'In Transit', sl: 6 },
    { label: 'Out for Delivery', sl: 7 },
    { label: 'Delivered', sl: 8 }
  ]
  const handleRowClick = async (order) => {
    const orderId = order.order_id
    setErrorData({ error: false })
    if (expandedRow === orderId) {
      setExpandedRow(null)
    } else {
      if (!trackingDetails[orderId]) {
        // Simulate fetching tracking details with dummy data

        if (order.error) {
          setErrorData({
            error: true,
            message: order.message
          })
        } else {
          setErrorData({ error: false })
          setTrackingDetails((prevDetails) => ({
            ...prevDetails,
            [orderId]: dummyTrackingDetails
          }))
        }
        // Replace with actual call to orderService.trackOrder(orderId);
      }
      setExpandedRow(orderId)
    }
  }
  const cancelOrder = async () => {
    try {
      const response = await orderService.cancel({
        ...selectedOrder
      })
      if (response.status === 'Cancelled') {
        toast.success('Order cancelled successfully')
      }
      setShowCancelOrderConfirmModal(false)
      fetchOrderData()
    } catch (error) {
      console.error('Error canceling order:', error)
    }
  }

  const fetchOrderTrail = async (id) => {
    const response = await orderService.fetchOrderTrail(id)
    setOrderTrail(response)
  }
  const getFormattedDate = (date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const d = new Date(date)
    const hours = String(d.getHours()).padStart(2, '0')
    const minutes = String(d.getMinutes()).padStart(2, '0')
    const time = `${hours}:${minutes}`
    const dayName = days[d.getDay()]
    const day = d.getDate()
    const monthName = months[d.getMonth()]
    const dateStr = `${dayName} ${day} ${monthName}`

    return { time, dateStr }
  }
  const handleOrderFLow = (order) => {
    updateFilterData()
    localStorage.setItem('path', `/order-flow/${selectedOrder?.order_id}`)
    navigate(`/order-flow/${encodeURIComponent(selectedOrder?.order_id)}`)
  }
  const handleOrderTrack = () => {
    updateFilterData()

    const trackingData = {
      status: `${selectedOrder?.shipments[0]?.tracking_number}`
    }
    const queryParams = new URLSearchParams(trackingData).toString()
    localStorage.setItem('path', `/order-tracking-history/${selectedOrder?._id}?${queryParams}`)
    navigate(`/order-tracking-history/${selectedOrder?._id}?${queryParams}`)
  }
  const menuRef = useRef(null)
  const iconRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handlePopoverClose()
      }
    }

    if (anchorElPopover) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [anchorElPopover])
  useEffect(() => {
    setOrderData(
      data?.map((order) => ({
        ...order,
        action: (
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <IconButton
              ref={iconRef}
              aria-label="more actions"
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect()
                handlePopoverOpen(e, order, {
                  top: rect.bottom + window.scrollY,
                  left: rect.right + window.scrollX,
                  right: window.innerWidth - rect.right - window.scrollX
                })
              }}
              sx={{
                color: anchorElPopover && selectedOrder?._id === order._id ? 'primary.main' : 'inherit'
              }}
            >
              <MoreVertIcon />
            </IconButton>

            {anchorElPopover && selectedOrder?._id === order._id && (
              <Box
                ref={menuRef}
                sx={{
                  position: 'fixed',
                  top: popoverPosition.top,
                  right: popoverPosition.right,
                  zIndex: 9999,
                  width: '90%',
                  maxWidth: '170px',
                  backgroundColor: 'white',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  border: '1px solid #EAECF0',
                  borderRadius: '4px'
                }}
              >
                {/* Order Flow */}
                <Button
                  sx={{
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    handleOrderFLow()
                    e.stopPropagation()
                    setAnchorElPopover(null)
                  }}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <OrderFlowIcon color="#037847" />
                    <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                      Order Flow
                    </Typography>
                  </Box>
                </Button>

                {/* Warehouse Order */}
                <Button
                  sx={{
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                  onClick={(e) => {
                    setShowWarehouseDataModal(true)
                    e.stopPropagation()
                    setAnchorElPopover(null)
                  }}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <WarehouseIcon />
                    <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                      Warehouse
                    </Typography>
                  </Box>
                </Button>

                {/* Track Order */}
                <Button
                  sx={{
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                  onClick={(e) => {
                    handleOrderTrack()
                    e.stopPropagation()
                    setAnchorElPopover(null)
                  }}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <OrderTrackIcon />
                    <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                      Order Track
                    </Typography>
                  </Box>
                </Button>

                {/* View Order */}
                <Button
                  sx={{
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                  onClick={(e) => {
                    handleViewClick(order)
                    e.stopPropagation()
                    setAnchorElPopover(null)
                  }}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <ViewIcon />
                    <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                      Order Details
                    </Typography>
                  </Box>
                </Button>

                {/* Cancel Order */}
                <Button
                  sx={{
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    width: '100%',
                    color:
                      ['Ordered', 'Accepted'].includes(order.status) ||
                      (order.error && order.status !== 'Cancelled')
                        ? '#D13438'
                        : 'grey',
                    backgroundColor:
                      ['Ordered', 'Accepted'].includes(order.status) ||
                      (order.error && order.status !== 'Cancelled')
                        ? '#FFF'
                        : '#FFF',
                    cursor:
                      ['Ordered', 'Accepted'].includes(order.status) ||
                      (order.error && order.status !== 'Cancelled')
                        ? 'pointer'
                        : 'not-allowed'
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (
                      ['Ordered', 'Accepted'].includes(order.status) ||
                      (order.error && order.status !== 'Cancelled')
                    ) {
                      setSelectedOrder(order)
                      setShowCancelOrderConfirmModal(true)
                    }
                    setAnchorElPopover(null)
                  }}
                >
                  <Box display={'flex'} gap={1} alignItems={'centers'}>
                    <CancelIcon
                      sx={{
                        color:
                          ['Ordered', 'Accepted'].includes(order.status) ||
                          (order.error && order.status !== 'Cancelled')
                            ? '#D13438'
                            : 'grey'
                      }}
                    />
                    <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                      Order Cancel
                    </Typography>
                  </Box>
                </Button>
              </Box>
            )}
          </Box>
        )
      }))
    )
  }, [data, anchorElPopover, navigate, selectedOrder])

  // useEffect(() => {
  //   setOrderData(
  //     data?.map((order) => ({
  //       ...order,
  //       action: (
  //         <Box sx={{ position: 'relative', display: 'inline-block' }}>
  //           <IconButton
  //             aria-label="more actions"
  //             onClick={(e) => {
  //               e.stopPropagation()
  //               handlePopoverOpen(e, order)
  //             }}
  //             sx={{
  //               color: anchorElPopover && selectedOrder?._id === order._id ? 'primary.main' : 'inherit'
  //             }}
  //           >
  //             <MoreVertIcon />
  //           </IconButton>

  //           {anchorElPopover && selectedOrder?._id === order._id && (
  //             <Box
  //               ref={menuRef}
  //               sx={{
  //                 position: 'fixed', // Change from absolute to fixed
  //                 top: 'auto',
  //                 bottom: 'auto',
  //                 left: 'auto',
  //                 right: '25px',
  //                 zIndex: 9999, // Increase z-index
  //                 width: '90%', // Responsive width
  //                 maxWidth: '170px', // Limit maximum width,
  //                 backgroundColor: 'white',
  //                 boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  //                 border: '1px solid #EAECF0',
  //                 borderRadius: '4px'
  //               }}
  //             >
  //               {/* Order Flow */}
  //               <Button
  //                 sx={{
  //                   textTransform: 'none',
  //                   justifyContent: 'flex-start',
  //                   width: '100%'
  //                 }}
  //                 onClick={(e) => {
  //                   e.preventDefault()
  //                   handleOrderFLow()
  //                   e.stopPropagation()
  //                   setAnchorElPopover(null)
  //                 }}
  //               >
  //                 <Box display={'flex'} gap={1} alignItems={'center'}>
  //                   <OrderFlowIcon color="#037847" />
  //                   <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
  //                     Order Flow
  //                   </Typography>
  //                 </Box>
  //               </Button>

  //               {/* Warehouse Order */}
  //               <Button
  //                 sx={{
  //                   textTransform: 'none',
  //                   justifyContent: 'flex-start',
  //                   width: '100%'
  //                 }}
  //                 onClick={(e) => {
  //                   setShowWarehouseDataModal(true)
  //                   e.stopPropagation()
  //                   setAnchorElPopover(null)
  //                 }}
  //               >
  //                 <Box display={'flex'} gap={1} alignItems={'center'}>
  //                   <WarehouseIcon />
  //                   <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
  //                     Warehouse
  //                   </Typography>
  //                 </Box>
  //               </Button>

  //               {/* Track Order */}
  //               <Button
  //                 sx={{
  //                   textTransform: 'none',
  //                   justifyContent: 'flex-start',
  //                   width: '100%'
  //                 }}
  //                 onClick={(e) => {
  //                   handleOrderTrack()
  //                   e.stopPropagation()
  //                   setAnchorElPopover(null)
  //                 }}
  //               >
  //                 <Box display={'flex'} gap={1} alignItems={'center'}>
  //                   <OrderTrackIcon />
  //                   <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
  //                     Order Track
  //                   </Typography>
  //                 </Box>
  //               </Button>

  //               {/* View Order */}
  //               <Button
  //                 sx={{
  //                   textTransform: 'none',
  //                   justifyContent: 'flex-start',
  //                   width: '100%'
  //                 }}
  //                 onClick={(e) => {
  //                   handleViewClick(order)
  //                   e.stopPropagation()
  //                   setAnchorElPopover(null)
  //                 }}
  //               >
  //                 <Box display={'flex'} gap={1} alignItems={'center'}>
  //                   <ViewIcon />
  //                   <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
  //                     Order Details
  //                   </Typography>
  //                 </Box>
  //               </Button>

  //               {/* Cancel Order */}
  //               <Button
  //                 sx={{
  //                   textTransform: 'none',
  //                   justifyContent: 'flex-start',
  //                   width: '100%',
  //                   color:
  //                     ['Ordered', 'Accepted'].includes(order.status) ||
  //                     (order.error && order.status !== 'Cancelled')
  //                       ? '#D13438'
  //                       : 'grey',
  //                   backgroundColor:
  //                     ['Ordered', 'Accepted'].includes(order.status) ||
  //                     (order.error && order.status !== 'Cancelled')
  //                       ? '#D1343833'
  //                       : '#FFF',
  //                   cursor:
  //                     ['Ordered', 'Accepted'].includes(order.status) ||
  //                     (order.error && order.status !== 'Cancelled')
  //                       ? 'pointer'
  //                       : 'not-allowed'
  //                 }}
  //                 onClick={(e) => {
  //                   e.stopPropagation()
  //                   if (
  //                     ['Ordered', 'Accepted'].includes(order.status) ||
  //                     (order.error && order.status !== 'Cancelled')
  //                   ) {
  //                     setSelectedOrder(order)
  //                     setShowCancelOrderConfirmModal(true)
  //                   }
  //                   setAnchorElPopover(null)
  //                 }}
  //               >
  //                 <Box display={'flex'} gap={1} alignItems={'centers'}>
  //                   <CancelIcon
  //                     sx={{
  //                       color:
  //                         ['Ordered', 'Accepted'].includes(order.status) ||
  //                         (order.error && order.status !== 'Cancelled')
  //                           ? '#D13438'
  //                           : 'grey'
  //                     }}
  //                   />
  //                   <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
  //                     Order Cancel
  //                   </Typography>
  //                 </Box>
  //               </Button>
  //             </Box>
  //           )}
  //         </Box>
  //       )
  //     }))
  //   )
  // }, [data, anchorElPopover, navigate, selectedOrder])

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {data?.map((row, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: '#ffff',
              borderRadius: 4,
              position: 'relative'
            }}
          >
            <CardContent>
              <Box position="absolute" top={2} right={5}>
                {row?.action}
              </Box>
              <Box display="flex" flexDirection="column">
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Order Id
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color={row.error ? '#ba090c' : '#333333'}
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {row?.order_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Customer
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      :
                      {row?.customer?.name ||
                        `${row?.customer?.first_name || ''} ${row?.customer?.last_name || ''}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Order Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {dayjs(row?.order_date).format('YYYY-MM-DD')}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Shipment ID
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {[...new Set(row?.shipments?.map((x) => x?.shipment))].join(', ')}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {row?.status}
                    </Typography>
                  </Grid>
                  {row?.error ? (
                    <>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Error
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {row.message}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    )
  }
  return (
    <>
      <div
        onScroll={() => setAnchorElPopover(null)}
        style={{
          overflowY: 'scroll',
          display: 'flex',
          maxHeight: `calc(100vh - 285px)`,
          width: '100%',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::WebkitScrollbar': { width: 0 }
        }}
      >
        {isMobile() ? (
          <MobileCardView data={orderData} />
        ) : (
          <TableContainer
            sx={{
              marginTop: 1,
              maxHeight: '100%',
              overflowY: 'scroll',
              paddingInline: 0.4,
              ...style
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                border: '1px solid #EAECF0'
              }}
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                      SL NO
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                      }}
                    >
                      {filterData.columns.find((x) => x.key === 'order_id')?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find((x) => x.key === 'order_id')?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}

                      <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                        ORDER ID
                      </Typography>
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={
                          !filterData?.columns?.find((item) => item.key.toLowerCase() === 'order_id')?.filter
                            ?.length
                        }
                        overlap="circular"
                      >
                        <IconButton
                          aria-label="filter"
                          size="small"
                          onClick={(event) => handleOpenFilter(event, 'order_id')}
                        >
                          <SortingIcon fill={iconColor || '#DC585C'} />
                        </IconButton>
                      </Badge>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                      }}
                    >
                      {filterData.columns.find((x) => x.key === 'shipping_address.name')?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find((x) => x.key === 'shipping_address.name')?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}
                      <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                        CUSTOMER NAME
                      </Typography>
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={
                          !filterData?.columns?.find(
                            (item) => item.key.toLowerCase() === 'shipping_address.name'
                          )?.filter?.length
                        }
                        overlap="circular"
                      >
                        <IconButton
                          aria-label="filter"
                          size="small"
                          onClick={(event) => handleOpenFilter(event, 'shipping_address.name')}
                        >
                          <SortingIcon fill={iconColor || '#DC585C'} />
                        </IconButton>
                      </Badge>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                      }}
                    >
                      {filterData.columns.find((x) => x.key === 'shipments.tracking_number')?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find((x) => x.key === 'shipments.tracking_number')?.sort ===
                        -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}
                      <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                        TRACKING NUMBER
                      </Typography>
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={
                          !filterData?.columns?.find(
                            (item) => item.key.toLowerCase() === 'shipments.tracking_number'
                          )?.filter?.length
                        }
                        overlap="circular"
                      >
                        <IconButton
                          aria-label="filter"
                          size="small"
                          onClick={(event) => handleOpenFilter(event, 'shipments.tracking_number')}
                        >
                          <SortingIcon fill={iconColor || '#DC585C'} />
                        </IconButton>
                      </Badge>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                      }}
                    >
                      {filterData.columns.find((x) => x.key === 'shipping_address.province')?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find((x) => x.key === 'shipping_address.province')?.sort ===
                        -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}

                      <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                        CUSTOMER STATE
                      </Typography>
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={
                          !filterData?.columns?.find(
                            (item) => item.key.toLowerCase() === 'shipping_address.province'
                          )?.filter?.length
                        }
                        overlap="circular"
                      >
                        <IconButton
                          aria-label="filter"
                          size="small"
                          onClick={(event) => handleOpenFilter(event, 'shipping_address.province')}
                        >
                          <SortingIcon fill={iconColor || '#DC585C'} />
                        </IconButton>
                      </Badge>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                      }}
                    >
                      {filterData.columns.find((x) => x.key === 'order_date')?.sort === 1 ? (
                        <Icon>
                          <UpIcon color="disabled" />
                        </Icon>
                      ) : filterData.columns.find((x) => x.key === 'order_date')?.sort === -1 ? (
                        <Icon>
                          <DownIcon color="disabled" />
                        </Icon>
                      ) : null}

                      <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                        ORDER DATE
                      </Typography>
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={
                          !(
                            filterData?.columns?.find((item) => item.key.toLowerCase() === 'order_date')
                              ?.filter?.startDate &&
                            filterData?.columns?.find((item) => item.key.toLowerCase() === 'order_date')
                              ?.filter?.endDate
                          )
                        }
                        overlap="circular"
                      >
                        <IconButton
                          aria-label="filter"
                          size="small"
                          onClick={(event) => handleOpenFilter(event, 'order_date')}
                        >
                          <SortingIcon fill={iconColor || '#DC585C'} />
                        </IconButton>
                      </Badge>
                    </div>
                  </StyledTableCell>
                  {user?.role === 'admin' ? (
                    <StyledTableCell>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center'
                        }}
                      >
                        {filterData.columns.find((x) => x.key === 'shipments.shipment')?.sort === 1 ? (
                          <Icon>
                            <UpIcon color="disabled" />
                          </Icon>
                        ) : filterData.columns.find((x) => x.key === 'shipments.shipment')?.sort === -1 ? (
                          <Icon>
                            <DownIcon color="disabled" />
                          </Icon>
                        ) : null}

                        <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                          SHIPMENT ID
                        </Typography>
                        <Badge
                          color="primary"
                          variant="dot"
                          invisible={
                            !filterData?.columns?.find(
                              (item) => item.key.toLowerCase() === 'shipments.shipment'
                            )?.filter?.length
                          }
                          overlap="circular"
                        >
                          <IconButton
                            aria-label="filter"
                            size="small"
                            onClick={(event) => handleOpenFilter(event, 'shipments.shipment')}
                          >
                            <SortingIcon fill={iconColor || '#DC585C'} />
                          </IconButton>
                        </Badge>
                      </div>
                    </StyledTableCell>
                  ) : (
                    <></>
                  )}

                  <StyledTableCell>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                      }}
                    >
                      <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                        STATUS
                      </Typography>
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={
                          !filterData?.columns?.find((item) => item.key.toLowerCase() === 'status')?.filter
                            ?.length
                        }
                        overlap="circular"
                      >
                        <IconButton
                          aria-label="filter"
                          size="small"
                          onClick={(event) => handleOpenFilter(event, 'status')}
                        >
                          <SortingIcon fill={iconColor || '#DC585C'} />
                        </IconButton>
                      </Badge>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography fontSize="14px" title={true} color="#333333" fontWeight={500}></Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {orderData?.map((row, index) => {
                  if (row.status === 'Cancelled') {
                    dummyTrackingDetails = [
                      { label: 'Ordered', sl: 1 },
                      { label: 'Cancelled', sl: 2 }
                    ]
                  } else {
                    dummyTrackingDetails = [
                      { label: 'Ordered', sl: 1 },
                      { label: 'Accepted', sl: 2 },
                      { label: 'Confirmed', sl: 3 },
                      { label: 'Fulfilled', sl: 4 },
                      { label: 'Shipped', sl: 5 },
                      { label: 'In Transit', sl: 6 },
                      { label: 'Out for Delivery', sl: 7 },
                      { label: 'Delivered', sl: 8 }
                    ]
                  }
                  let progress = dummyTrackingDetails.find((x) => x.label === row.status)
                  return (
                    <>
                      <StyledTableRow
                        sx={{ cursor: 'pointer' }}
                        isExpandable={expandedRow === row.order_id}
                        onClick={() => {
                          if (expandedRow === row.order_id) {
                            setExpandedRow(null)
                          } else {
                            handleRowClick(row)
                            fetchOrderTrail(row?._id)
                          }
                        }}
                        key={row.order_id}
                      >
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={'poppins'}
                            textAlign={'start'}
                          >
                            {startIndex + index + 1}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color={row.status === 'On Hold' ? '#ba090c' : '#667085'}
                            fontWeight={400}
                            fontFamily={'poppins'}
                            //textAlign={"start"}
                          >
                            {row.order_id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                            {row.customer?.name ||
                              `${row?.customer?.first_name || ''} ${row?.customer?.last_name || ''}`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                            {`${row.shipments?.length ? row.shipments[0]?.tracking_number : ''}`}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                            {`${row.shipping_address?.province || ''}`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                            {dayjs(row.order_date).format('YYYY-MM-DD')}
                          </Typography>
                        </StyledTableCell>
                        {user?.role === 'admin' ? (
                          <StyledTableCell>
                            <Typography
                              fontSize="12px"
                              color="#667085"
                              fontWeight={400}
                              fontFamily={'poppins'}
                            >
                              {new Set(row?.shipments?.map((x) => x.shipment))}
                            </Typography>
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}
                        <StyledTableCell>
                          {row.status ? (
                            <Typography fontSize="12px" fontWeight={400} fontFamily={'poppins'}>
                              <span
                                style={{
                                  padding: '5px 10px',
                                  display: 'inline-block',
                                  backgroundColor:
                                    row.status === 'Confirmed'
                                      ? '#D7E9FF'
                                      : row.status === 'Shipped'
                                      ? '#F6F6F6'
                                      : row.status === 'Cancelled'
                                      ? '#FFDEDF'
                                      : '',
                                  color:
                                    row.status === 'Confirmed'
                                      ? '#0070FF'
                                      : row.status === 'Shipped'
                                      ? '#037847'
                                      : row.status === 'Cancelled'
                                      ? '#D13438'
                                      : '#333333',
                                  borderRadius: '14px'
                                }}
                              >
                                {row.status}
                              </span>
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </StyledTableCell>

                        <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                          {row?.action}
                        </StyledTableCell>
                      </StyledTableRow>
                      {expandedRow === row.order_id && (
                        <TableRow key={row._id}>
                          <TableCell
                            style={{ width: '100%' }}
                            colSpan={8}
                            //style={{ padding: 0, borderBottom: "none" }}
                          >
                            <Collapse in={expandedRow === row.order_id} timeout="auto" unmountOnExit>
                              <Box
                                marginBottom={6}
                                marginTop={1}
                                marginLeft={10}
                                sx={{
                                  maxWidth: '100%',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  display: 'flex',
                                  minHeight: 70
                                }}
                              >
                                <div
                                  style={{
                                    height: '100%',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  {row.status === 'On Hold' && errorData.error && errorData.message.length ? (
                                    <Typography>{errorData.message}</Typography>
                                  ) : (
                                    [...dummyTrackingDetails]?.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            width: '100%',
                                            position: 'relative'
                                          }}
                                        >
                                          <div>
                                            <div
                                              style={{
                                                backgroundColor:
                                                  progress?.sl >= item?.sl
                                                    ? row.status === 'Cancelled'
                                                      ? 'red'
                                                      : 'green'
                                                    : '#AAA7A7',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: 30,
                                                width: 30,
                                                borderRadius: 100
                                              }}
                                            >
                                              <div style={{ color: '#FFF' }}>{index + 1}</div>
                                            </div>
                                            <div>
                                              <span
                                                style={{
                                                  position: 'absolute',
                                                  left: -15
                                                }}
                                              >
                                                {item?.label}
                                              </span>
                                            </div>
                                            <div>
                                              <span
                                                style={{
                                                  position: 'absolute',
                                                  left: -5,
                                                  marginTop: 20
                                                }}
                                              >
                                                {orderTrail.find((x) => x.status === item.label)
                                                  ? getFormattedDate(
                                                      orderTrail.find((x) => x.status === item.label)?.parent
                                                        ?.createdAt
                                                    ).time
                                                  : ''}
                                                <br />
                                                <span
                                                  style={{
                                                    position: 'absolute',
                                                    right: -40,
                                                    minWidth: 100
                                                  }}
                                                >
                                                  {orderTrail.find((x) => x.status === item.label)
                                                    ? getFormattedDate(
                                                        orderTrail.find((x) => x.status === item.label)
                                                          ?.parent?.createdAt
                                                      ).dateStr
                                                    : ''}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                          {index + 1 === dummyTrackingDetails?.length ? (
                                            <></>
                                          ) : (
                                            <div
                                              style={{
                                                height: 1,
                                                width: '82%',
                                                position: 'absolute',
                                                left: '15%',
                                                top: 15,
                                                border:
                                                  progress?.sl >= item?.sl
                                                    ? `1px dotted ${
                                                        row.status === 'Cancelled' ? 'red' : 'green'
                                                      }`
                                                    : '1px dotted #C0C3D7'
                                              }}
                                            ></div>
                                          )}
                                        </div>
                                      )
                                    })
                                  )}
                                </div>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={filterData.limit}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        disableNext={dataCount <= (page + 1) * filterData.limit}
      />
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableScrollLock={true}
      >
        <FilterModal
          open={openFilterModal}
          onClose={handleCloseFilter}
          options={selectedfilterData?.options}
          selectedValues={selectedfilterData?.filter}
          onSave={handleSaveFilter}
          currentSortOrder={selectedfilterData?.sort}
          filterKey={selectedfilterData?.key}
          hasMore={selectedfilterData?.loadMoreButton}
          displayKey={selectedfilterData?.displayKey}
          id={selectedfilterData?.id}
          fetchApi={selectedfilterData?.fetchApi}
          searchKey={selectedfilterData?.searchKey}
          type={selectedfilterData?.type}
        />
      </Popover>

      <OrderDetailsModal
        open={modalOpen}
        edit={edit}
        fetchOrderData={fetchOrderData}
        onClose={() => {
          setModalOpen(false)
          setEdit(false)
          setSelectedOrder(null)
        }}
        order={selectedOrder}
      />
      <DeleteConfirmationModal
        handleDelete={() => cancelOrder()}
        handleClose={() => setShowCancelOrderConfirmModal(false)}
        title={`Are you sure to cancel this order ${selectedOrder?.order_id}`}
        open={showCancelOrderConfirmModal}
        deleteButtonTitle={'Cancel Order'}
      ></DeleteConfirmationModal>
      <WarehouseList
        handleClose={() => setShowWarehouseDataModal(false)}
        openModal={showWarehouseDataModal}
        selectedOrder={selectedOrder}
      ></WarehouseList>
    </>
  )
}

export default OrderList
