import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  debounce,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { productService } from "../../utils/services/productService";
import { inventoryService } from "../../utils/services/inventory.service";
import { Bar } from "react-chartjs-2";
import { generateColor, shadeColor } from "../../utils/utility";
import { isMobile } from "../../globalStyles/mobile";

const InventoryCard = ({}) => {
  const [skuList, setSKUList] = useState([]);
  const [inventory, setInventory] = useState([]);
  const clientId = localStorage.getItem("client_id");
  const autocompleteRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [skuSearchValue, setskuSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedSKU, setSelectedSKU] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [chartOption, setChartOption] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchSKU = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await productService.fetchSKU(
          clientId,
          searchQuery,
          10,
          pageNumber
        );
        if (pageNumber === 0) {
          setSKUList(response.data);
        } else {
          setSKUList((prevSuppliers) => [...prevSuppliers, ...response.data]);
        }
        setTotal(response.data.length || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [clientId]
  );

  const fetchInventory = async (data) => {
    try {
      let inventory = await inventoryService.get(data._id, data.sku);
      setInventory(inventory.data);
      setChartOption({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              font: {
                size: isMobile() ? 10 : 12,
              },
            },
          },
          title: {
            display: true,
            text: "",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: isMobile() ? 10 : 14,
              },
            },
            title: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: isMobile() ? 10 : 14,
              },
            },
            title: {
              display: false,
            },
          },
        },
      });
      setChartData({
        labels: ["On Hand", "Available", "Inbound to warehouse"],
        datasets: inventory.data.map((x) => {
          let color = generateColor();
          return {
            label: x.provider.name,
            data: [x.in_hand, x.quantity, x.in_transit],
            backgroundColor:
              x.provider?.name === "rl"
                ? "#68B2FF"
                : x.provider?.name.toLowerCase() === "evcargo"
                ? "#025CB8"
                : color,
            borderColor:
              x.provider?.name === "rl"
                ? "#68B2FF"
                : x.provider?.name.toLowerCase() === "evcargo"
                ? "#025CB8"
                : color,
            borderWidth: 1,
          };
        }),
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchSKU(skuSearchValue, page);
  }, [skuSearchValue, page, fetchSKU]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setskuSearchValue(value);
    setPage(0);
    setSKUList([]);
  };

  const handleLoadMoreClick = (event, value) => {
    if (value?.loadMore) {
      setPage((prevPage) => prevPage + 1);
      setOpen(true); // Keep dropdown open
    } else {
      setSelectedSKU(value);
      fetchInventory(value);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (selectedSKU) {
      fetchInventory({ sku: selectedSKU.variants.sku, _id: selectedSKU._id });
    }
  }, [selectedSKU]);

  return (
    <Card
      sx={{
        minWidth: "100%",
        padding: 1,
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
        position: "relative",
        minHeight: isMobile() ? "250px" : 350,
        marginBottom: 5,
        border: "1px solid #D9D9D9",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -30,
          right: -10,
          width: "80px",
          height: "80px",
          borderRadius: "100%",
        }}
      />
      <CardContent>
        <div
          style={{
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isMobile() ? "column" : "row",
            }}
          >
            <Typography
              fontWeight={600}
              fontSize={isMobile() ? 18 : 20}
              sx={{ fontWeight: "600", color: "#474747", textAlign: "left" }}
            >
              {`Inventory`}
            </Typography>
            <Autocomplete
              style={{
                width: isMobile() ? "90%" : 300,
                marginTop: isMobile() ? 10 : 0,
              }}
              sx={{
                "& .MuiAutocomplete-tag": {
                  backgroundColor: "#1976d2", // Blue background for selected value
                  color: "#fff", // White text color
                  borderRadius: "4px", // Rounded corners
                  // padding: "2px 6px", // Padding
                  fontSize: 12,
                  fontFamily: "Poppins",
                },
              }}
              ref={autocompleteRef}
              size="small"
              open={open}
              onOpen={() => setOpen(true)}
              onClose={(event, reason) => {
                console.log(event.target.textContent, reason);
                // Only close if it's not from selecting "Load More"
                if (reason === "blur") {
                  setOpen(false);
                }
              }}
              options={[...skuList, { label: "Load More...", loadMore: true }]}
              getOptionLabel={(option) =>
                option.loadMore
                  ? ""
                  : `${option?.variants?.sku || ""} ${
                      option?.name ? `(${option.name})` : ""
                    }`
              }
              value={selectedSKU}
              onChange={(event, value) => {
                handleLoadMoreClick(event, value);
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  {option.loadMore && total === 10 ? (
                    <span style={{ color: "#1976d2", fontWeight: "bold" }}>
                      Load More...
                    </span>
                  ) : loading ? (
                    <CircularProgress sx={{ color: "#151D48" }} size={20} />
                  ) : (
                    <Tooltip title={option.name || ""}>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          fontFamily: "Poppins",
                          fontSize: 14,
                        }}
                      >
                        {`${option?.variants?.sku || ""} ${
                          option?.name ? `(${option.name})` : ""
                        }`}
                      </span>
                    </Tooltip>
                  )}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Item Number"
                  variant="outlined"
                  onChange={handleSearchChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  margin="dense"
                />
              )}
            />
          </div>
          {chartData && chartOption ? (
            <div
              style={{
                maxWidth: "100%",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  minWidth: isMobile() ? 400 : "100%",
                  height: isMobile() ? 250 : 350,
                }}
              >
                <Bar options={chartOption} data={chartData} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default InventoryCard;
