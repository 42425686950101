import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  TablePagination,
  Tooltip,
  Box,
  Button,
  Popover,
} from "@mui/material";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomTypography from "../../globalStyles/fonts";
import { tableCellClasses } from "@mui/material/TableCell";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import { useTheme } from "@mui/styles";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import { styled } from "@mui/material/styles";
import ShippingCostDetailsModal from "../shipping-cost-details-modal";
import { shippingCostService } from "../../utils/services/shippingCostService";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ShipmentCostListView = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedShipmentCost,
  setSelectedShipmentCost,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [shippigCostData, setShippigCostData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const startIndex = rowsPerPage * page;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EEEEEE",
      border: "1px solid #EAECF0",
      padding: "4px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "4px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #EAECF0",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F6",
      border: "1px solid #EAECF0",
    },
    "&:lastChild td, &:lastChild th": {
      border: 0,
    },
  }));

  const deleteData = async () => {
    try {
      await shippingCostService.delete(deletingItemId);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Shipment Cost deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete Shipment Cost");
      console.error("Delete failed:", error);
    }
  };
  const handleViewClick = (shipping) => {
    setSelectedShipmentCost(shipping);
    setOpenViewModal(true);
  };
  const handleDeleteClick = (item) => {
    setSelectedShipmentCost(item);
    setDeletingItemId(item._id);
    setOpenConfirmation(true);
  };
  const handlePopoverOpen = (event, item) => {
    event.stopPropagation();
    setSelectedShipmentCost(item);
    setAnchorEl(event.currentTarget);
    setAnchorPosition({
      top: event.clientY - 15,
      left: event.clientX - 15,
    });
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const Action = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
        }}
      >
        <Button
          style={{
            color: "#333333",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="view"
          onClick={() => {
            handleViewClick(selectedShipmentCost);
            handlePopoverClose();
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <ViewIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              color={"#333333"}
              fontWeight={500}
            >
              View
            </Typography>
          </Box>
        </Button>

        <Button
          style={{
            color: "#333333",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="edit"
          onClick={() => {
            onEditData && onEditData(selectedShipmentCost);
            handlePopoverClose();
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <EditIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              color={"#333333"}
              fontWeight={500}
            >
              Edit
            </Typography>
          </Box>
        </Button>
        <Button
          style={{
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="delete"
          onClick={() => {
            handleDeleteClick(selectedShipmentCost);
            handlePopoverClose();
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <DeleteIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              fontWeight={500}
              color={"#333333"}
            >
              Delete
            </Typography>
          </Box>
        </Button>
      </div>
    );
  };
  useEffect(() => {
    setShippigCostData(
      data?.map((item) => ({
        ...item,
        action: (
          <Box>
            <IconButton
              aria-label="more actions"
              onClick={(e) => handlePopoverOpen(e, item)}
              sx={{
                color:
                  anchorEl && selectedShipmentCost?._id === item._id
                    ? "primary.main"
                    : "inherit",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorReference="anchorPosition"
              anchorPosition={anchorPosition}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              disableRestoreFocus
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  border: "1px solid #EEEEEE",
                  borderRadius: "4px",
                },
              }}
            >
              {/* <Box
                display="flex"
                flexDirection="column"
                padding="8px"
                minWidth="150px"
              >
                <Button
                  style={{
                    color: "#333333",
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  aria-label="view"
                  onClick={() => {
                    handleViewClick(selectedShipmentCost);
                    handlePopoverClose();
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    gap={1}
                  >
                    <ViewIcon />
                    <Typography
                      fontFamily={"poppins"}
                      fontSize={"16px"}
                      color={"#333333"}
                      fontWeight={500}
                    >
                      View
                    </Typography>
                  </Box>
                </Button>

                <Button
                  style={{
                    color: "#333333",
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  aria-label="edit"
                  onClick={() => {
                    onEditData && onEditData(selectedShipmentCost);
                    handlePopoverClose();
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    gap={1}
                  >
                    <EditIcon />
                    <Typography
                      fontFamily={"poppins"}
                      fontSize={"16px"}
                      color={"#333333"}
                      fontWeight={500}
                    >
                      Edit
                    </Typography>
                  </Box>
                </Button>
                <Button
                  style={{
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  aria-label="delete"
                  onClick={() => {
                    handleDeleteClick(selectedShipmentCost);
                    handlePopoverClose();
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    gap={1}
                  >
                    <DeleteIcon />
                    <Typography
                      fontFamily={"poppins"}
                      fontSize={"16px"}
                      fontWeight={500}
                      color={"#333333"}
                    >
                      Delete
                    </Typography>
                  </Box>
                </Button>
              </Box> */}
              <Action />
            </Popover>
          </Box>
        ),
      }))
    );
  }, [data, anchorEl]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );

  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {shippigCostData?.map((x) => (
        <Grid item xs={12} sm={6} md={3} key={x._id}>
          <Card sx={{ boxShadow: 3, cursor: "pointer" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    // marginLeft: 15,
                  }}
                >
                  <Tooltip title={x?.shipment_service?.type} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.shipment_service?.type}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {x.action}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {shippigCostData.length === 0 ? (
          <NoDataImage />
        ) : (
          shippigCostData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Shipment Service
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.shipment_service?.type}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Min weight(kg)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.pricing?.min_weight}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Max weight(kg)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.pricing?.max_weight}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Price(USD)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.pricing.price}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={1} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          className="custom-container"
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Client
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Shipment Service
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Min Weight
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Max Weight
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    {"Price (USD)"}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {shippigCostData?.map((x, index) => (
                <React.Fragment>
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.client_id?.business_name || x?.client_id?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.shipment_service?.type}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x.pricing.min_weight}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x.pricing.max_weight}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x.pricing.price}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                      {x?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "60vh" : `calc(100vh - 270px)`,
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
        <TablePagination
          slotProps={{
            actions: {
              nextButton: {
                disabled: dataCount < rowsPerPage ? true : false,
              },
            },
          }}
          nextIconButton={
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          }
          backIconButton={
            <IconButton>
              <KeyboardArrowLeft />
            </IconButton>
          }
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "2px",
            borderTop: "1px solid #dddddd",
          }}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedShipmentCost?.shipment_service?.type} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
        <ShippingCostDetailsModal
          shippingCost={selectedShipmentCost}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        />
      </div>
    </>
  );
};

export default ShipmentCostListView;
