import React, { useState, useContext, useEffect } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { useTheme } from '@mui/styles'
import ViewIcon from '../../globalStyles/icons/vieewIcon'
import EditIcon from '../../globalStyles/icons/editIcon'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import UpIcon from '@mui/icons-material/ArrowUpwardOutlined'
import DownIcon from '@mui/icons-material/ArrowDownwardOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Icon,
  Button
} from '@mui/material'
import AppContext from '../context/context'
import CustomTypography from '../../globalStyles/fonts'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import { isMobile } from '../../globalStyles/mobile'
import { globalNetworkService } from '../../utils/services/globalNetworkService'
import GlobalNetworkDesignDetailsModal from '../global-network-design-details-modal'
import DeleteIcon from '../../globalStyles/icons/deleteIcon'
import CustomPagination from '../pagination'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    padding: '6px',
    textAlign: 'start'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px',
    textAlign: 'start'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },
  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))
const NetworkTableCell = ({ network, handleViewClick, handleEditClick, handleDeleteClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const handleActionClick = (action) => {
    handlePopoverClose()
    switch (action) {
      case 'view':
        handleViewClick(network)
        break
      case 'edit':
        handleEditClick(network)
        break
      case 'delete':
        handleDeleteClick(network)
        break
      default:
        break
    }
  }

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? 'primary.main' : 'inherit'
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
          <Button
            onClick={() => handleActionClick('view')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <ViewIcon />
              <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
                View
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick('edit')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <EditIcon />
              <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
                Edit
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick('delete')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <DeleteIcon />
              <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
                Delete
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

const GlobalNetworkDesignList = ({ data, onEditData, onDeleteData, viewMode, currentPage, itemsPerPage }) => {
  const [networkData, setNetworkData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [carrierName, setCarrierName] = useState('')
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AppContext)
  const [openRow, setOpenRow] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const client = localStorage.getItem('client_id')
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const secondary_color = theme.palette?.secondary?.main || defaultColor
  const startIndex = currentPage * itemsPerPage

  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_carrier.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '340px' }}
      />
    </div>
  )

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index)
  }

  const handleEditClick = (network) => {
    onEditData(network)
  }

  const handleDeleteClick = (network) => {
    setSelectedNetwork(network)
    setOpenConfirmation(true)
  }
  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await globalNetworkService.delete(selectedNetwork._id)
      setAnchorEl(null)
      setOpenConfirmation(false)
      onDeleteData && onDeleteData()
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleViewClick = (network) => {
    setSelectedNetwork(network)
    setOpenViewModal(true)
  }
  useEffect(() => {
    setNetworkData(
      data?.map((network) => ({
        ...network,
        action: (
          <NetworkTableCell
            network={network}
            handleViewClick={handleViewClick}
            handleEditClick={onEditData}
            handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: '1px',
        maxHeight: '100%',
        paddingBottom: 5,
        overflow: 'auto'
      }}
      container
      spacing={2}
    >
      {networkData?.map((network, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              position: 'relative',
              borderRadius: '6px',
              border: '1px solid #D0D5DD'
            }}
          >
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography fontWeight="500" sx={{ textAlign: 'start' }} fontFamily="Poppins" color="#333333">
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10
                  }}
                >
                  {network?.action}
                </div>
              </div>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Supplier Location
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    noWrap
                    sx={{ textAlign: 'start' }}
                    fontWeight="500"
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    : {network?.supplier_location}
                  </Typography>
                </Grid>

                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Fulfillment Location
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    noWrap
                    sx={{ textAlign: 'start' }}
                    fontWeight="500"
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    : {network?.fulfillment_location}
                  </Typography>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Shipping Service
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    noWrap
                    sx={{ textAlign: 'start' }}
                    fontWeight="500"
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    : {network?.shipping_service}
                  </Typography>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Package Type
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    noWrap
                    sx={{ textAlign: 'start' }}
                    fontWeight="500"
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    : {network?.package_type}
                  </Typography>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Weight
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    noWrap
                    sx={{ textAlign: 'start' }}
                    fontWeight="500"
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    : {network?.weight}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  // const MobileCardView = ({ data }) => {
  //   return (
  //     <Box sx={{ width: "100%", padding: 0.5 }}>
  //       {data?.map((network, index) => (
  //         <Card key={index} sx={{ marginBottom: 2 }}>
  //           <CardContent>
  //             <div style={{ width: "100%" }}>
  //               <Box
  //                 display="flex"
  //                 flexDirection="column"
  //                 alignItems="flex-start"
  //               >
  //                 <Box display="flex" alignItems="center" mb={1}>
  //                   <Typography>
  //                     Supplier Location: {network?.supplier_location}
  //                   </Typography>
  //                   <Typography>
  //                     Shipping Service: {network?.shipping_service}
  //                   </Typography>
  //                   <Typography>
  //                     Package Type: {network?.package_type}
  //                   </Typography>
  //                   <Typography>
  //                     Fulfillment Location: {network?.fulfillment_location}
  //                   </Typography>
  //                   <Typography>Weight: {network?.weight}</Typography>
  //                 </Box>
  //               </Box>

  //               <Box mt={2} justifyContent={"flex-end"} display="flex">
  //                 <IconButton
  //                   style={{
  //                     color: "#007DFF",
  //                     padding: 0,
  //                     background: "#C3E1FF",
  //                     height: 30,
  //                     width: 30,
  //                     borderRadius: 5,
  //                     marginLeft: 10,
  //                     boxShadow: "0px 0px 4px 0px #00000040",
  //                   }}
  //                   aria-label="view"
  //                   onClick={() => {
  //                     handleViewClick(network);
  //                   }}
  //                 >
  //                   <img
  //                     style={{ height: 14, width: 20 }}
  //                     alt="view"
  //                     src="/eye.png"
  //                   ></img>
  //                 </IconButton>

  //                 <>
  //                   <IconButton
  //                     style={{
  //                       color: "#FEC50F",
  //                       padding: 0,
  //                       background: "#FEC50F33",
  //                       height: 30,
  //                       width: 30,
  //                       borderRadius: 5,
  //                       marginLeft: 15,
  //                       boxShadow: "0px 0px 4px 0px #00000040",
  //                       // marginTop: 5,
  //                     }}
  //                     aria-label="edit"
  //                     onClick={() => handleEditClick(network)}
  //                   >
  //                     <BorderColorIcon sx={{ height: 20, width: 20 }} />
  //                   </IconButton>
  //                   <IconButton
  //                     style={{
  //                       color: "#D13438",
  //                       padding: 0,
  //                       background: "#D1343833",
  //                       height: 30,
  //                       width: 30,
  //                       borderRadius: 5,
  //                       marginLeft: 10,
  //                       boxShadow: "0px 0px 4px 0px #00000040",
  //                       // marginTop: 5,
  //                     }}
  //                     aria-label="delete"
  //                     onClick={() => handleDeleteClick(network)}
  //                   >
  //                     <img
  //                       style={{ height: 15, width: 15 }}
  //                       alt="delete"
  //                       src="/delete.png"
  //                     ></img>
  //                   </IconButton>
  //                 </>
  //               </Box>
  //             </div>
  //           </CardContent>
  //         </Card>
  //       ))}
  //     </Box>
  //   );
  // };

  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 20,
        marginTop: 5
      }}
    >
      {/* {isMobile() ? (
        <MobileCardView data={data} />
      ) : ( */}
      <TableContainer
        sx={{
          overflowY: 'auto',
          paddingInline: 0.4,
          maxHeight: '100%'
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: '1px solid #EAECF0'
          }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  SL NO
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  SUPPLIER LOCATION
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  FULFILLMENT LOCATION
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  SHIPPING SERVICE
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  PACKAGE TYPE
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  WEIGHT
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <CustomTypography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                ></CustomTypography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {networkData.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={8} align="center">
                  <NoDataImage />
                </TableCell>
              </StyledTableRow>
            ) : (
              networkData?.map((network, index) => (
                <React.Fragment>
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        ml={0.5}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        ml={0.5}
                      >
                        {network.supplier_location}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        ml={0.5}
                        style={{
                          maxWidth: 300,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {network.fulfillment_location}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        ml={0.5}
                      >
                        {network.shipping_service}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        ml={0.5}
                      >
                        {network.package_type}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        ml={0.5}
                      >
                        {network.weight}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                      {network?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* )} */}
    </div>
  )

  return (
    <div sx={{ minWidth: '100%' }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: 'scroll',
            display: 'flex',
            maxHeight: `calc(100vh - 250px)`,
            width: '100%',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::WebkitScrollbar': { width: 0 }
          }}
        >
          {viewMode === 'grid' || isMobile() ? renderGridView() : renderListView()}
        </div>
      )}

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete this item ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          confirmDelete(e)
        }}
      />

      <GlobalNetworkDesignDetailsModal
        network={selectedNetwork}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  )
}

export default GlobalNetworkDesignList
