import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Typography from "@mui/material/Typography/Typography";
import { clientService } from "../../utils/services/clientService";
import { statusTypes } from "../../utils/services/statusTypes";
import AppContext from "../../components/context/context";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { imageService } from "../../utils/services/imageService";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { currencyTypes } from "../../utils/services/currencyTypes";
import CustomTypography from "../../globalStyles/fonts";
import { countryService } from "../../utils/services/allCountry";
import SupplierAutocomplete from "../../components/supplierAutocomplete";
import { isMobile } from "../../globalStyles/mobile";
import InputWithUnit from "../../components/Input-with-unit";
import { toast } from "react-toastify";
import { productService } from "../../utils/services/productService";
import ClientAutocomplete from "../../components/clientAutocomplete";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import DimensionsAndWeightInputWithUnit from "../../components/dimension-input";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
const defaultImage = "/product.png";
const ManageProduct = ({ onClickAdd }) => {
  const initialData = {
    name: "",
    short_description: "",
    pga_flag: "",
    description: "",
    images: [],
    status: "",
    product_type: "",
    variants: [],
    currency: "",
    //vendor: null,
  };
  const [productDetails, setProductDetails] = useState(initialData);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [selectedImages, setSelectedImages] = useState([]);
  const [coverImageIndex, setCoverImageIndex] = useState(-1);
  const [imageUrls, setImageUrls] = useState([]);
  const [editingImageIndex, setEditingImageIndex] = useState(null);
  const [variants, setVariants] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [productId, setProductId] = useState("");
  const { user } = useContext(AppContext);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [validated, setValidated] = useState(false);
  const [hsCodeErrors, setHsCodeErrors] = useState("");
  const [featureImages, setFeatureImages] = useState([]);
  const clientId = localStorage.getItem("client_id");
  const navigate = useNavigate();
  const containerRef = useRef(null);
  useEffect(() => {
    // Scroll to the top of the container when the component is rendered
    if (containerRef.current) {
      containerRef.current.scrollTop = 0; // Ensure it scrolls to the top
    }
  }, []);
  useEffect(() => {
    if (variants?.length === 0) {
      setVariants([
        {
          option: [
            {
              option_type: "General",
              value: "",
            },
          ],
          price: "",
          weight: "",
          weight_unit: "",
          height: "",
          height_unit: "",
          width: "",
          width_unit: "",
          length: "",
          length_unit: "",
          sku: "",
          inventory: 0,
          hs_code: "",
          country_of_origin: "",
          hts_code: "",
        },
      ]);
    }
  }, [setVariants]);
  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`;
    }
    return defaultImage;
  };
  const fetchProductId = async (id) => {
    setLoading(true);
    try {
      const response = await productService.fetchById(id);
      setSelectedProduct(response);
      setLoading(false);
    } catch (error) {
      console.error("Fetch failed:", error);
      toast.error(error);
    }
  };
  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    setProductId(lastPart);
    if (lastPart === "create") {
      return;
    } else {
      fetchProductId(lastPart);
    }
  }, [productId]);

  useEffect(() => {
    if (selectedProduct) {
      setProductDetails({
        name: selectedProduct.name,
        currency: selectedProduct.currency,
        description: selectedProduct.description,
        product_type: selectedProduct.product_type,
        status: selectedProduct.status,
        short_description: selectedProduct.short_description,
        pga_flag: selectedProduct.pga_flag,
      });
      setVariants(selectedProduct.variants);
      setVendor(selectedProduct.vendor);
      setSelectedClient({
        name:
          selectedProduct?.client_id?.business_name ||
          selectedProduct?.client_id?.name ||
          "",
        _id: selectedProduct.client_id?._id,
      });

      const fetchedImages = selectedProduct.images.map(fetchImage);
      setSelectedImages(
        selectedProduct.images.map((img) => ({
          image: img.image,
          feature_image: img.feature_image,
        }))
      );
      setImageUrls(fetchedImages);
      const coverIndex = selectedProduct.images.findIndex(
        (img) => img.feature_image
      );
      setCoverImageIndex(coverIndex);
      setFeatureImages(selectedProduct.images.map((img) => img.feature_image));
    }
  }, [selectedProduct]);
  const handleChooseImage = (index = null) => {
    setEditingImageIndex(index);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
    }
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const file = files[0];
      try {
        const imageData = await imageService.upload(file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImages((prevImages) => {
          const newImages = [...prevImages];
          if (editingImageIndex !== null) {
            newImages[editingImageIndex] = {
              image: imageData.key,
              feature_image: editingImageIndex === coverImageIndex,
            };
          } else {
            newImages.push({
              image: imageData.key,
              feature_image: false,
            });
          }
          setImageUrl(newImages);
          return newImages;
        });
        setImageUrls((prevUrls) => {
          const newUrls = [...prevUrls];
          if (editingImageIndex !== null) {
            newUrls[editingImageIndex] = imageUrl;
          } else {
            newUrls.push(imageUrl);
          }
          return newUrls;
        });
        setEditingImageIndex(null);
      } catch (error) {
        console.error("Image upload failed:", error);
      } finally {
        e.target.value = null;
      }
    }
  };

  const handleCheckCoverImage = (index) => {
    setCoverImageIndex(index === coverImageIndex ? -1 : index);
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.forEach((image, i) => {
        image.feature_image = i === index;
      });
      return updatedImages;
    });
  };

  const removeSelectedImage = (indexToRemove) => {
    // Remove the selected image from both `selectedImages` and `imageUrls`
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );

    setImageUrls((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );

    // Update `selectedProduct.images` (if applicable)
    if (selectedProduct?.images) {
      const updatedImages = [...selectedProduct.images];
      updatedImages.splice(indexToRemove, 1);
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        images: updatedImages,
      }));
    }

    // Handle cover image index
    if (coverImageIndex === indexToRemove) {
      setCoverImageIndex(-1);
    } else if (coverImageIndex > indexToRemove) {
      setCoverImageIndex((prev) => prev - 1);
    }
  };

  const addVariant = () => {
    setVariants((prevState) => [
      ...prevState,
      {
        option: [
          {
            option_type: "General",
            value: "",
          },
        ],
        price: "",
        weight: "",
        weight_unit: "",
        height: "",
        height_unit: "",
        width: "",
        width_unit: "",
        length: "",
        length_unit: "",
        sku: "",
        inventory: 0,
        hs_code: "",
        country_of_origin: "",
        hts_code: "",
      },
    ]);
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    if (updatedVariants[index]) {
      updatedVariants[index][field] = value;
      setVariants(updatedVariants);
    }
    if (field === "hs_code") {
      const hsCodeLength = value.toString().length;
      const errorMessage =
        hsCodeLength < 6 || hsCodeLength > 12
          ? "HS Code length must be between 6 and 12 characters."
          : "";
      setHsCodeErrors((prevErrors) => ({
        ...prevErrors,
        [index]: errorMessage,
      }));
    }
  };

  const resetSelectedImage = () => {
    setSelectedImages([]);
    setImageUrls([]);
    setEditingImageIndex();
  };

  const removeVariant = (indexToRemove) => {
    setVariants((prevVariants) =>
      prevVariants.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeVariantOption = (variantIndex, optionIndexToRemove) => {
    setVariants((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].option = updatedVariants[
        variantIndex
      ].option.filter((_, optionIndex) => optionIndex !== optionIndexToRemove);
      return updatedVariants;
    });
  };
  const updateVariantOption = (variantIndex, optionIndex, key, value) => {
    setVariants((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].option[optionIndex][key] = value;
      return updatedVariants;
    });
  };
  const addVariantOption = (variantIndex) => {
    setVariants((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].option.push({
        option_type: "General",
        value: "",
      });
      return updatedVariants;
    });
  };

  const fetchClientData = async (name = "") => {
    try {
      const response = await clientService.fetch(null, null, name);
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };
  useEffect(() => {
    fetchClientData();
  }, []);

  const handleVendorChange = (e) => {
    // console.log(e,"directly");
    setVendor(e);
  };
  const validateForm = () => {
    let hasValidVariant = false;

    // Check product details
    if (
      !productDetails.name ||
      !productDetails.currency ||
      !productDetails.status
    ) {
      return false;
    }

    // Check variants
    for (let index = 0; index < variants.length; index++) {
      const variant = variants[index];

      const hasAnyDimension =
        variant.height || variant.width || variant.length || variant.weight;

      const isDimensionValid =
        variant.height_unit &&
        variant.height >= 0 &&
        variant.width_unit &&
        variant.width >= 0 &&
        variant.length_unit &&
        variant.length >= 0 &&
        variant.weight_unit &&
        variant.weight >= 0;

      const areDimensionsAndWeightValid =
        !hasAnyDimension ||
        (hasAnyDimension &&
          variant.height &&
          variant.width &&
          variant.length &&
          variant.weight);

      const isVariantValid =
        variant.option &&
        variant.price >= 0 &&
        variant.sku &&
        variant.hs_code &&
        variant.hs_code.toString().length >= 6 &&
        variant.hs_code.toString().length <= 12 &&
        areDimensionsAndWeightValid;
      if (isVariantValid) {
        hasValidVariant = true;
        break;
      }
    }

    if (!hasValidVariant) {
      return false;
    }

    if (clientId) {
      if (!vendor?._id) {
        return false;
      }
    } else {
      if (!selectedClient?.name || !vendor?._id) {
        // Check client and vendor
        return false;
      }
    }

    // If all checks passed, return true
    return true;
  };

  useEffect(() => {
    setValidated(validateForm());
  }, [productDetails, variants]);
  const createProduct = async (e) => {
    e.preventDefault();
    // if (!validateForm()) return
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      await productService.create({
        ...productDetails,
        client_id: client_id,
        variants: variants,
        images: imageUrl,
        vendor: vendor._id,
      });
      toast.success("Product Created successfully!");
      setImageUrl([]);
      setVariants([]);
      setProductDetails(initialData);
      setVendor(null);
      setSelectedClient({ name: "" });
      localStorage.setItem("path", `/product`);
      navigate("/product");
    } catch (error) {
      console.error("Create failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEditProduct = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.warning("Please fill all the fields");
      return;
    }

    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;

      const imageField =
        selectedImages.length > 0
          ? selectedImages.map((image) => ({
              image: image.image,
              feature_image: image.feature_image,
            }))
          : selectedProduct.images;

      const response = await productService.edit(
        {
          ...productDetails,
          images: imageField,
          variants: variants,
          vendor: vendor?._id,
          client_id: client_id,
        },
        selectedProduct._id
      );

      toast.success("Product Edited successfully!");
      setImageUrl([]);
      setVariants([]);
      setProductDetails(initialData);
      setVendor(null);
      setSelectedClient({ name: "" });
      localStorage.setItem("path", `/product`);
      navigate("/product");
    } catch (error) {
      console.error("Edit failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeDimensionAndWight = (index, field, value) => {
    if (
      ["weight", "height", "width", "length", "weight_unit"].includes(field)
    ) {
      handleVariantChange(index, field, value);
    } else if (field === "dimension_unit") {
      const updatedVariants = [...variants];
      if (updatedVariants[index]) {
        updatedVariants[index]["height_unit"] = value;
        updatedVariants[index]["width_unit"] = value;
        updatedVariants[index]["length_unit"] = value;

        setVariants(updatedVariants);
      }
    }
  };
  return (
    <Box>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontWeight: 500,
            color: "#000000",
          }}
        >
          <CustomTypography fontWeight={500} fontSize={20}>
            {`${productId === "create" ? "Create" : "Edit"} Product`}
          </CustomTypography>
        </DialogTitle>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <div
        ref={containerRef}
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          scrollBehavior: "smooth",
          padding: 5,
        }}
        sx={{
          overflowY: "auto",
          "&::WebkitScrollbar": {
            width: "7px",
          },
          "&::WebkitScrollbar-track": {
            backgroundColor: "#D9D9D9",
            borderRadius: 14,
          },
          "&::WebkitScrollbar-thumb": {
            backgroundColor: "#273894",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Name <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              sx={{
                marginTop: 1,
                fontSize: 13,
                fontStyle: "Poppins",
                backgroundColor: "#FFFFFF",
              }}
              size="small"
              placeholder={`Enter Product Name`}
              //label="Name"
              variant="outlined"
              value={productDetails.name}
              onChange={(e) =>
                setProductDetails({ ...productDetails, name: e.target.value })
              }
              fullWidth
              autoFocus
              //   error={!!formErrors.name}
              //   helperText={formErrors.name}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Category
            </CustomTypography>
            <TextField
              sx={{
                input: {
                  color: "black", // Default text color
                  backgroundColor: "transparent", // Default background color
                  "&:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 1000px transparent inset", // Removes yellow autofill background
                    WebkitTextFillColor: "black", // Autofill text color
                  },
                },
                backgroundColor: "#FFFFFF",
              }}
              InputProps={{
                sx: {
                  color: "black", // Default input text color when autofill is not active
                  "&:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 1000px transparent inset", // Same here for InputProps if needed
                    WebkitTextFillColor: "black", // Text color for autofilled content
                    caretColor: "black", // Caret color
                  },
                },
              }}
              value={productDetails.product_type}
              size="small"
              margin="dense"
              placeholder=" Enter Type"
              type="text"
              fullWidth
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  product_type: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Short Description
            </CustomTypography>
            <TextField
              sx={{
                marginTop: 1,
                fontSize: 13,
                fontStyle: "Poppins",
                backgroundColor: "#FFFFFF",
              }}
              size="small"
              placeholder={`Enter Short Description`}
              //label="Name"
              variant="outlined"
              value={productDetails.short_description}
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  short_description: e.target.value,
                })
              }
              fullWidth

              //   error={!!formErrors.name}
              //   helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              PGA Flag
            </CustomTypography>
            <TextField
              sx={{
                marginTop: 1,
                fontSize: 13,
                fontStyle: "Poppins",
                backgroundColor: "#FFFFFF",
              }}
              size="small"
              placeholder={`Enter PGA Flag`}
              //label="Name"
              variant="outlined"
              value={productDetails.pga_flag}
              onChange={(e) =>
                setProductDetails({
                  ...productDetails,
                  pga_flag: e.target.value,
                })
              }
              fullWidth
              //   error={!!formErrors.name}
              //   helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Description
            </CustomTypography>
            <div style={{ marginBottom: "30px" }}>
              <ReactQuill
                theme="snow"
                value={productDetails.description}
                onChange={(e) =>
                  setProductDetails({ ...productDetails, description: e })
                }
                placeholder="Enter product description"
                style={{
                  marginTop: 5,
                  backgroundColor: "#FFFFFF",
                  height: isMobile() ? "70px" : "120px",
                }}
              />
              <style>
                {`
  .ql-container {
    background-color: #FFFFFF !important;
    height: 100%;
  }
  .ql-editor {
    min-height: 100%;
    background-color: #FFFFFF !important;
    padding: 10px;
    border-radius: 8px;
  }
  .ql-tooltip {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important; /* Center the tooltip */
    max-width: 90vw; /* Ensure it fits within the viewport */
    width: auto;
    z-index: 1000;
  }
  .ql-tooltip a {
    cursor: pointer; /* Change cursor to pointer for links */
    text-decoration: underline; /* Optional: underline the link for better visibility */
  }
  `}
              </style>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: isMobile() ? 5 : 2 }} sm={12}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Media
            </CustomTypography>
            <Box
              sx={{
                width: "100%",
                border: 1,
                borderColor: "#D1D1D1",
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "150px",
                p: 2,
                backgroundColor: "#FFFFFF",
                marginTop: 1,
              }}
            >
              <input
                id="productImageInput"
                type="file"
                accept="image/*"
                ref={fileInputRef}
                //multiple
                style={{ display: "none" }}
                onChange={handleImageChange}
              />

              {selectedImages.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: 2,
                      mt: 2,
                      maxHeight: 250,
                      overflowY: "auto",
                      border: "1px solid #000",
                      borderRadius: 1,
                      p: 2,
                    }}
                  >
                    {selectedImages?.map((image, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          p: 1,
                          border: 1,
                          borderRadius: 1,
                          maxWidth: { xs: "100%", sm: "auto" },
                        }}
                      >
                        <img
                          src={imageUrls[index]}
                          alt={`Selected image ${index}`}
                          style={{
                            maxWidth: 100,
                            maxHeight: 100,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <IconButton onClick={() => handleChooseImage(index)}>
                            <EditIcon color="primary" />
                          </IconButton>
                          <input
                            type="checkbox"
                            checked={index === coverImageIndex}
                            onChange={() => handleCheckCoverImage(index)}
                          />
                          <IconButton
                            onClick={() => removeSelectedImage(index)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <IconButton onClick={() => handleChooseImage(null)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  sx={{
                    minWidth: { xs: 150, sm: 200 },
                    color: "#707070",
                    textTransform: "none",
                  }}
                  color="primary"
                  onClick={() => handleChooseImage(null)}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <IconButton aria-label="upload" sx={{ mb: 1, p: 0 }}>
                      <InsertPhotoIcon />
                    </IconButton>
                    <Typography variant="body2">Upload</Typography>
                  </Box>
                </Button>
              )}
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Currency <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <Autocomplete
              value={productDetails.currency}
              disableClearable={!productDetails.currency}
              onChange={(e, newValue) =>
                setProductDetails({ ...productDetails, currency: newValue })
              }
              size="small"
              options={currencyTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //   label="$ 00.00"
                  variant="outlined"
                  // error={formErrors?.currency}
                  // helperText={formErrors?.currency}
                  margin="dense"
                  style={{ backgroundColor: "#ffff" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Status <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <Autocomplete
              disableClearable={!productDetails.status}
              value={productDetails.status}
              onChange={(e, newValue) =>
                setProductDetails({ ...productDetails, status: newValue })
              }
              size="small"
              options={statusTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  //   label="Status"
                  variant="outlined"
                  // error={formErrors?.status}
                  // helperText={formErrors?.status}
                  style={{ backgroundColor: "#ffff" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CustomTypography
              style={{ textAlign: "left", fontWeight: "500" }}
              fontSize={15}
            >
              Supplier <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <SupplierAutocomplete
              hideLabel
              selectedSupplier={vendor}
              onSupplierChange={handleVendorChange}
              width="100%"
              label="Select Supplier"
              backgroundColor={"#ffff"}
              clientId={clientId}
            />
            {/* {formErrors.vendor && <FormHelperText error>{formErrors.vendor}</FormHelperText>} */}
          </Grid>
          {clientId ? (
            <></>
          ) : (
            <Grid item xs={6} sm={6}>
              <CustomTypography
                style={{ textAlign: "left", fontWeight: "500" }}
                fontSize={15}
              >
                Select Client <span style={{ color: "red" }}>*</span>
              </CustomTypography>
              <ClientAutocomplete
                hideLabel
                selectedClient={selectedClient}
                onClientChange={(e) => {
                  setSelectedClient(e);
                }}
                width={"100%"}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <Divider sx={{ color: "#979797", mt: 3 }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography
              fontFamily={"poppins"}
              fontWeight={400}
              fontSize={"20px"}
              textAlign={"left"}
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              Variants
            </Typography>
            {variants?.map((variant, index) => (
              <Card
                key={index}
                style={{
                  marginBottom: 10,
                  backgroundColor: "#EEEEEEEE",
                  marginTop: 10,
                }}
              >
                <CardContent>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton onClick={() => removeVariant(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>

                  <Card
                    style={{
                      marginBottom: 10,
                      backgroundColor: "#F6F6F6",
                      marginTop: 10,
                      padding: 10,
                    }}
                  >
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{ textAlign: "left", fontWeight: 400 }}
                        fontSize={14}
                      >
                        Options
                      </Typography>
                    </Grid>
                    {variant.option.map((o, optionIndex) => (
                      <Grid
                        container
                        spacing={2}
                        display={"flex"}
                        alignItems={"center"}
                        key={optionIndex}
                        mt={0.5}
                      >
                        <Grid item xs={12} sm={5.5}>
                          <Typography
                            style={{ textAlign: "left", fontWeight: 400 }}
                            fontSize={14}
                          >
                            Type
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              margin="dense"
                              type="text"
                              size="small"
                              placeholder="Type"
                              variant="outlined"
                              value={o.option_type}
                              onChange={(e) =>
                                updateVariantOption(
                                  index,
                                  optionIndex,
                                  "option_type",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={5.5}>
                          <Typography
                            style={{ textAlign: "left", fontWeight: 400 }}
                            fontSize={14}
                          >
                            Value
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              margin="dense"
                              type="text"
                              size="small"
                              placeholder="Value"
                              variant="outlined"
                              value={o.value}
                              onChange={(e) =>
                                updateVariantOption(
                                  index,
                                  optionIndex,
                                  "value",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={1} mt={2}>
                          {optionIndex === variant.option.length - 1 ? (
                            <IconButton
                              onClick={() => addVariantOption(index)}
                              disableRipple
                              disableFocusRipple
                              sx={{
                                backgroundColor: "#DC585C",
                                border: "1px solid #DC585C",
                                borderRadius: "4px",
                                padding: 0,
                                "&:hover": {
                                  backgroundColor: "#DC585C", // Keeps the same background color on hover
                                  border: "1px solid #DC585C", // Keeps the same border on hover
                                },
                              }}
                            >
                              <AddIcon sx={{ color: "#ffff" }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() =>
                                removeVariantOption(index, optionIndex)
                              }
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Card>

                  <Grid container spacing={2.5}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        style={{ textAlign: "left", fontWeight: 400 }}
                        fontSize={14}
                      >
                        {"Price (USD)"} <span style={{ color: "red" }}>*</span>
                      </CustomTypography>
                      <FormControl fullWidth>
                        <TextField
                          sx={{
                            marginTop: 0.5,
                            backgroundColor: "#FFFFFF",
                          }}
                          type="number"
                          size="small"
                          placeholder="Enter Price"
                          value={variant.price}
                          onChange={(e) =>
                            handleVariantChange(index, "price", e.target.value)
                          }
                          inputProps={{ min: 0 }}
                          fullWidth
                          // error={!!formErrors[`price-${index}`]}
                        />
                        {/* {formErrors[`price-${index}`] && (
                              <FormHelperText error>{formErrors[`price-${index}`]}</FormHelperText>
                            )} */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        style={{ textAlign: "left", fontWeight: 400 }}
                        fontSize={14}
                      >
                        SKU <span style={{ color: "red" }}>*</span>
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                        type="text"
                        size="small"
                        variant="outlined"
                        placeholder="Enter SKU"
                        value={variant.sku}
                        onChange={(e) =>
                          handleVariantChange(index, "sku", e.target.value)
                        }
                        fullWidth
                        // error={!!formErrors[`sku-${index}`]}
                      />
                      {/* {formErrors[`sku-${index}`] && (
                            <FormHelperText error>{formErrors[`sku-${index}`]}</FormHelperText>
                          )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        style={{ textAlign: "left", fontWeight: 400 }}
                        fontSize={14}
                      >
                        HS Code <span style={{ color: "red" }}>*</span>
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                        // type="number"
                        size="small"
                        variant="outlined"
                        placeholder="Enter HS Code"
                        value={variant.hs_code}
                        onChange={(e) =>
                          handleVariantChange(index, "hs_code", e.target.value)
                        }
                        fullWidth
                        // error={!!formErrors[`inventory-${index}`]}
                        error={!!hsCodeErrors[index]}
                        helperText={hsCodeErrors[index] || ""}
                      />
                      {/* {formErrors[`inventory-${index}`] && (
                        <FormHelperText error>{formErrors[`inventory-${index}`]}</FormHelperText>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        style={{ textAlign: "left", fontWeight: 400 }}
                        fontSize={14}
                      >
                        HTS Code
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                        // type="number"
                        size="small"
                        variant="outlined"
                        placeholder="Enter HTS Code"
                        value={variant.hts_code}
                        onChange={(e) =>
                          handleVariantChange(index, "hts_code", e.target.value)
                        }
                        fullWidth
                        // error={!!formErrors[`inventory-${index}`]}
                      />
                      {/* {formErrors[`inventory-${index}`] && (
                        <FormHelperText error>{formErrors[`inventory-${index}`]}</FormHelperText>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        style={{ textAlign: "left", fontWeight: 400 }}
                        fontSize={14}
                      >
                        Country of Origin
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                        // type="number"
                        size="small"
                        variant="outlined"
                        placeholder="Enter Country of Origin"
                        value={variant.country_of_origin}
                        onChange={(e) =>
                          handleVariantChange(
                            index,
                            "country_of_origin",
                            e.target.value
                          )
                        }
                        fullWidth
                        // error={!!formErrors[`inventory-${index}`]}
                      />
                      {/* {formErrors[`inventory-${index}`] && (
                        <FormHelperText error>{formErrors[`inventory-${index}`]}</FormHelperText>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        style={{ textAlign: "left", fontWeight: 400 }}
                        fontSize={14}
                      >
                        Inventory
                      </CustomTypography>
                      <TextField
                        sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                        type="number"
                        size="small"
                        variant="outlined"
                        placeholder="Enter Inventory"
                        value={variant.inventory}
                        onChange={(e) =>
                          handleVariantChange(
                            index,
                            "inventory",
                            e.target.value
                          )
                        }
                        fullWidth
                        // error={!!formErrors[`inventory-${index}`]}
                      />
                      {/* {formErrors[`inventory-${index}`] && (
                        <FormHelperText error>{formErrors[`inventory-${index}`]}</FormHelperText>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <DimensionsAndWeightInputWithUnit
                        dimensions={{
                          height: variant.height,
                          length: variant.length,
                          width: variant.width,
                        }}
                        weight={variant.weight}
                        onChange={(e) =>
                          onChangeDimensionAndWight(index, e.field, e.value)
                        }
                        dimensionUnitValue={variant.height_unit}
                        weightUnitValue={variant.weight_unit}
                        // weightUnitError={formErrors[`weight-${index}`]}
                        // dimensionUnitError={formErrors[`dimension-${index}`]}
                        requiredField={true}
                      ></DimensionsAndWeightInputWithUnit>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        numberType
                        unitValue={variant.weight_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "weight_unit", e)
                        }
                        inputValue={variant.weight}
                        onChangeText={(e) =>
                          handleVariantChange(index, "weight", e)
                        }
                        unitType={"weight"}
                        fieldName={"Weight"}
                        // errorMessage={formErrors[`weight-${index}`]}
                        // unitError={formErrors[`weight_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid>  <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        unitValue={variant.height_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "height_unit", e)
                        }
                        inputValue={variant.height}
                        onChangeText={(e) =>
                          handleVariantChange(index, "height", e)
                        }
                        unitType={"distance"}
                        fieldName={"Height"}
                        numberType
                        // errorMessage={formErrors[`height-${index}`]}
                        // unitError={formErrors[`height_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        unitValue={variant.width_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "width_unit", e)
                        }
                        inputValue={variant.width}
                        onChangeText={(e) =>
                          handleVariantChange(index, "width", e)
                        }
                        unitType={"distance"}
                        fieldName={"Width"}
                        numberType
                        // errorMessage={formErrors[`width-${index}`]}
                        // unitError={formErrors[`width_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputWithUnit
                        requiredField={true}
                        unitStyle={{ fontWeight: "500" }}
                        style={{ textAlign: "left", fontWeight: "500" }}
                        unitValue={variant.length_unit}
                        onChangeUnit={(e) =>
                          handleVariantChange(index, "length_unit", e)
                        }
                        inputValue={variant.length}
                        onChangeText={(e) =>
                          handleVariantChange(index, "length", e)
                        }
                        unitType={"distance"}
                        fieldName={"Length"}
                        numberType
                        // errorMessage={formErrors[`length-${index}`]}
                        // unitError={formErrors[`length_unit-${index}`]}
                      ></InputWithUnit>
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
            ))}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={addVariant}
                style={{
                  textTransform: "none",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                <AddIcon
                  fontSize="small"
                  sx={{ color: (theme) => theme.palette.text.main }}
                />
                <Typography
                  fontFamily={"poppins"}
                  fontSize={"14px"}
                  fontWeight={400}
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    marginLeft: "8px",
                  }}
                >
                  Add more Variants
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                padding: 5,
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 15,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSelectedClient({});
                  resetSelectedImage();
                  navigate("/product");
                }}
                style={{ textTransform: "none" }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color={validated ? "primary" : "grey"} // Changes color based on validation
                onClick={
                  productId === "create" ? createProduct : handleEditProduct
                }
                style={{ textTransform: "none", marginLeft: 10 }}
                disabled={!validated} // Disables the button if not validated
              >
                {selectedProduct ? "Save" : "Add"}
              </Button>
            </Box>
          </Grid>
        </Grid>
        {/* {isMobile() ? (
          <Box
            style={{
              padding: 5,
              display: 'flex',
              justifyContent: 'end',
              // marginRight: 15,
              boxShadow: '0px -4px 6px -2px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setSelectedClient({})
                resetSelectedImage()
                navigate('/product')
              }}
              className="cancel-button-style"
              style={{ textTransform: 'none' }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color={validated ? 'primary' : 'grey'} // Changes color based on validation
              onClick={productId === 'create' ? createProduct : handleEditProduct}
              className="add-button-style"
              style={{ textTransform: 'none', marginLeft: 10 }}
              disabled={!validated} // Disables the button if not validated
            >
              {selectedProduct ? 'Save' : 'Add'}
            </Button>
          </Box>
        ) : (
          <></>
        )} */}
      </div>
    </Box>
  );
};

export default ManageProduct;
