import { Button, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/styles";
import FileSaver from "file-saver";
import React, { useEffect, useReducer, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import OrderList from "../../components/order-management-list";
import DownloadIcon from "../../globalStyles/icons/download-icon";
import { isMobile } from "../../globalStyles/mobile";
import buildQuery from "../../utils/query-builder";
import { orderService } from "../../utils/services/orderService";
import { orderFilterInitialState } from "../../utils/filter-config";
import { useLocation, useNavigate } from "react-router-dom";

function filterReducer(state, action) {
  console.log(action);

  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        columns: state.columns.map((x) =>
          x.key === action.payload.key ? { ...x, ...action.payload } : x
        ),
      };
    case "UPDATE_LIMIT":
      return { ...state, limit: action.payload };
    case "EXISTING_DATA":
      return {
        ...state,
        columns: state.columns.map((x) => ({
          ...x,
          filter:
            action.data.filters.find((y) => y.key === x.key)?.values ||
            x.filter,
        })),
        limit: action.data.limit || state.limit,
      };

    default:
      return state;
  }
}

const OrderManagement = () => {
  const [filterData, dispatch] = useReducer(
    filterReducer,
    orderFilterInitialState
  );
  const [clientFilter, setClientFilter] = useState(null);

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [dataCount, setDataCount] = useState(15);
  const clientId = localStorage.getItem("client_id");
  const theme = useTheme();
  const location = useLocation();
  const defaultColor = "#D13438";
  const primary_color = theme?.palette?.primary?.main || defaultColor;
  const text_color = theme?.palette?.text?.primary || "#FFF";
  const [fetchData, setFetchData] = useState(0);

  const fetchOrderData = async (signal) => {
    setLoading(true);
    try {
      const response = await orderService.fetch(
        clientFilter?._id,
        searchValue,
        buildQuery(filterData, page),
        { signal }
      );
      setDataCount(response.total);
      setOrderData(response.data);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("order-filter-data");
    if (location.search.includes("child") && data) {
      localStorage.removeItem("order-filter-data");
      dispatch({
        type: "EXISTING_DATA",
        data: JSON.parse(data),
      });
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchOrderData(signal);
    return () => {
      controller.abort();
    };
  }, [filterData, searchValue, page, clientFilter, fetchData]);

  const handleExport = async () => {
    setLoading(true);
    try {
      const response = await orderService.exportOrders(
        clientId || "",
        buildQuery(filterData, page)
      );
      let sliceSize = 1024;
      let byteCharacters = atob(response.file);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      let blob = new Blob(byteArrays, { type: response.contentType });
      FileSaver.saveAs(new Blob([blob], {}), response.filename);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (newFilter) => {
    dispatch({ type: "SET_FILTER", payload: newFilter });
    setPage(0);
  };
  return (
    <DashboardContainer
      searchBarPlaceholder={"Search by Order Id"}
      headerButtons={
        isMobile() ? (
          <IconButton onClick={handleExport}>
            <DownloadIcon fill={primary_color} />
          </IconButton>
        ) : (
          <Button
            size="small"
            style={{
              textTransform: "none",
            }}
            variant="contained"
            color="primary"
            onClick={handleExport}
          >
            <DownloadIcon fill={"#ffff"} />
            <Typography
              fontSize="14px"
              fontWeight={500}
              sx={{
                color: text_color,
                marginLeft: 1,
              }}
              fontFamily={"poppins"}
            >
              Download
            </Typography>
          </Button>
        )
      }
      hideUploadButton={true}
      // hideFilterClient={true}
      hideAddButton={true}
      order
      header={"Orders"}
      onSearchData={setSearchValue}
      onClickExport={handleExport}
      onClickRefresh={() => setFetchData(fetchData + 1)}
      onSelectClient={(e) => {
        setClientFilter(e);
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <OrderList
          data={orderData}
          fetchOrderData={() => setFetchData(fetchData + 1)}
          page={page}
          rowsPerPage={filterData.limit}
          setRowsPerPage={(e) =>
            dispatch({
              type: "UPDATE_LIMIT",
              payload: e,
            })
          }
          setPage={setPage}
          onChangeFilter={handleFilterChange}
          filterData={filterData}
          dataCount={dataCount}
        />
      )}
    </DashboardContainer>
  );
};

export default OrderManagement;
