import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { isMobile } from "../../globalStyles/mobile";

const DataCard = ({
  backgroundColor,
  smallRoundBoxBg,
  minWidth,
  cardTitle,
  cardSubTitle,
  cardCount,
  showIconButton,
  cardContent,
  height,
  textColor,
  border,
}) => (
  <Card
    sx={{
      minWidth: isMobile() ? "95%" : 300,
      minHeight: height || 280,
      padding: 1,
      backgroundColor: backgroundColor || "#F3E8FF",
      borderRadius: "6px",
      position: "relative",
      margin: 1,
      maxWidth: "100%",
      border: border || "",
    }}
  >
    <Box
      sx={{
        position: "absolute",
        top: -30,
        right: -10,
        width: "80px",
        height: "80px",
        //   backgroundColor: smallRoundBoxBg || '#BF82FF',
        borderRadius: "100%",
      }}
    />
    <CardContent>
      <div
        style={{
          width: "95%",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={isMobile() ? "14px" : 25}
            sx={{ fontWeight: "600", color: textColor, textAlign: "left" }}
          >
            {cardTitle}
          </Typography>
          {showIconButton ? (
            <IconButton>
              <MoreVertIcon color="primary" />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
        <CustomTypography
          fontSize={25}
          sx={{ fontWeight: "600", color: "#151D48", textAlign: "left" }}
        >
          {cardSubTitle}
        </CustomTypography>
      </div>
      <div
        style={{
          marginTop: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <CustomTypography
        fontSize={isMobile() ? "30px" : 40}
        fontWeight={500}
        color={"#151D48"}
      >
        {cardCount}
      </CustomTypography>
      {cardContent ? (
        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTypography fontSize={20} fontWeight={500} color={"#151D48"}>
            {cardContent}
          </CustomTypography>
        </div>
      ) : (
        <></>
      )}
    </CardContent>
  </Card>
);

export default DataCard;
