import React, { useState } from "react";
import { Box, Button, Typography, TextField, MenuItem } from "@mui/material";
import { weightUnits } from "../../utils/weightUnits";

const WeightFilterComponent = ({
  onCancel,
  onConfirm,
  initialFilter = {
    from: { min_weight: null, max_weight: null, unit: null },
    to: { min_weight: null, max_weight: null, unit: null },
  },
  currentSortOrder = 0,
}) => {
  const [formValues, setFormValues] = useState(initialFilter);
  const [sortOrder, setSortOrder] = useState(currentSortOrder);

  const handleChange = (section, field, value) => {
    setFormValues((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const handleSortChange = (order) => {
    setSortOrder(sortOrder === order ? 0 : order);
  };

  const handleConfirm = () => {
    const convertedFrom = {
      min_weight: formValues.from.min_weight,
      max_weight: formValues.from.max_weight,
      unit: formValues.from.unit,
      valid:
        (formValues.from.min_weight || formValues.from.max_weight) &&
        formValues.from.unit
          ? true
          : false,
    };

    const convertedTo = {
      min_weight: formValues.from.min_weight,
      max_weight: formValues.from.max_weight,
      unit: formValues.to.unit,
      valid:
        (formValues.to.min_weight || formValues.to.max_weight) &&
        formValues.to.unit
          ? true
          : false,
    };

    const filterCriteria = {
      from: convertedFrom,
      to: convertedTo,
    };

    onConfirm({ filters: filterCriteria, sortOrder });
  };

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "16px",
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Button
          onClick={() => handleSortChange(1)}
          variant={sortOrder === 1 ? "contained" : "outlined"}
          size="small"
          fullWidth
          sx={{ textTransform: "none", fontSize: "14px" }}
        >
          Sort Lowest to Highest
        </Button>
        <Button
          onClick={() => handleSortChange(-1)}
          variant={sortOrder === -1 ? "contained" : "outlined"}
          size="small"
          fullWidth
          sx={{ textTransform: "none", fontSize: "14px" }}
        >
          Sort Highest to Lowest
        </Button>
      </Box>

      <Typography
        sx={{
          marginBottom: "8px",
        }}
        color={"#333333"}
        fontFamily={"poppins"}
        fontSize={"14px"}
        fontWeight={400}
      >
        From
      </Typography>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <TextField
          label="Min Weight"
          value={formValues?.from?.min_weight || ""}
          onChange={(e) => handleChange("from", "min_weight", e.target.value)}
          size="small"
          type="number"
        />
        <TextField
          label="Max Weight"
          value={formValues?.from?.max_weight || ""}
          onChange={(e) => handleChange("from", "max_weight", e.target.value)}
          size="small"
          type="number"
        />

        <TextField
          select
          label="Unit"
          value={formValues?.from?.unit || ""}
          onChange={(e) => handleChange("from", "unit", e.target.value)}
          size="small"
          sx={{ minWidth: "100px" }}
        >
          {weightUnits.map((unit) => (
            <MenuItem key={unit.abbreviation} value={unit.abbreviation}>
              {unit.name} ({unit.abbreviation})
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Typography
        sx={{ marginBottom: "8px", marginTop: "10px" }}
        color={"#333333"}
        fontFamily={"poppins"}
        fontSize={"14px"}
        fontWeight={400}
      >
        To
      </Typography>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <TextField
          label="Min Weight"
          type="number"
          value={formValues?.to?.min_weight || ""}
          onChange={(e) => handleChange("to", "min_weight", e.target.value)}
          size="small"
        />
        <TextField
          label="Max Weight"
          type="number"
          value={formValues?.to?.max_weight || ""}
          onChange={(e) => handleChange("to", "max_weight", e.target.value)}
          size="small"
        />

        <TextField
          select
          label="Unit"
          value={formValues?.to?.unit || ""}
          onChange={(e) => handleChange("to", "unit", e.target.value)}
          size="small"
          sx={{ minWidth: "100px" }}
        >
          {weightUnits.map((unit) => (
            <MenuItem key={unit.abbreviation} value={unit.abbreviation}>
              {unit.name} ({unit.abbreviation})
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          marginTop: "16px",
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="primary">
          OK
        </Button>
      </Box>
    </Box>
  );
};

export default WeightFilterComponent;
