import React, { useState, useEffect, useContext } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Popover,
  Button,
  styled,
  tableCellClasses,
  Icon,
  Badge
} from '@mui/material'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import AppContext from '../context/context'
import { isMobile } from '../../globalStyles/mobile'
import { toast } from 'react-toastify'
import Pagination from '../pagination'
import { eventService } from '../../utils/services/eventService'
import EventDetailsModal from '../eventDetailsModal'
import SortingIcon from '../../globalStyles/icons/sorting-icon'
import FilterModal from '../filterModal'
import { useTheme } from '@mui/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '../../globalStyles/icons/editIcon'
import DeleteIcon from '../../globalStyles/icons/deleteIcon'
import ViewIcon from '../../globalStyles/icons/vieewIcon'
import UpIcon from '@mui/icons-material/ArrowUpwardOutlined'
import DownIcon from '@mui/icons-material/ArrowDownwardOutlined'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    // color: theme.palette.common.black,
    padding: '6px'
    // textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px'
    // textAlign: "start",
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },
  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))

const EventList = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedEvent,
  setSelectedEvent,
  onChangeFilter,
  filterData
}) => {
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const [deletingItemId, setDeletingItemId] = useState(null)
  const [eventData, setEventData] = useState([])
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const { user } = useContext(AppContext)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: '',
    filter: [],
    sort: 0,
    id: '_id',
    displayKey: 'name',
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: ''
  })
  const [anchorElPopover, setAnchorElPopover] = useState(null)
  const clientId = localStorage.getItem('client_id')
  const openPopover = Boolean(anchorElPopover)
  const startIndex = page * rowsPerPage
  const [menuPosition, setMenuPosition] = useState(null)
  const menuRef = React.useRef(null)

  const handleMenuOpen = (event, item) => {
    const rect = event.currentTarget.getBoundingClientRect()
    const menuHeight = 120 // Approx height of the menu (adjust as needed)
    const menuWidth = 200 // Approx width of the menu (adjust as needed)
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth

    let calculatedX = rect.left + window.scrollX
    let calculatedY = rect.top + window.scrollY + rect.height

    // Adjust for bottom boundary overflow
    if (calculatedY + menuHeight > viewportHeight) {
      calculatedY = viewportHeight - menuHeight - 10 // 10px padding
    }

    // Adjust for right boundary overflow
    if (calculatedX + menuWidth > viewportWidth) {
      calculatedX = viewportWidth - menuWidth - 10 // 10px padding
    }

    setMenuPosition({ x: calculatedX, y: calculatedY })
    setSelectedEvent(item)
  }

  const handleMenuClose = () => {
    setMenuPosition(null)
    setSelectedEvent(null)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleMenuClose()
      }
    }

    if (menuPosition) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuPosition])

  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget)
    setOpenFilterModal(true)
    setSelectedFilterData(filterData.columns.find((x) => x.key == filterKey))
  }

  const handleSaveFilter = (selectedOptions, filterKey, sortOrder, searchKey) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey
    })
    handleCloseFilter()
  }

  const handleCloseFilter = () => {
    setAnchorElFilter(null)
    setOpenFilterModal(false)
  }

  const deleteData = async () => {
    try {
      await eventService.delete(deletingItemId)
      setOpenConfirmation(false)
      handlePopoverClose()
      onDeleteData && onDeleteData()
      toast.success('Event deleted successfully!')
    } catch (error) {
      toast.error('Failed to delete')
      console.error('Delete failed:', error)
    }
  }
  const handleViewClick = (event) => {
    // setSelectedEvent(event);
    setOpenViewModal(true)
    handlePopoverClose()
  }
  const handleEditClick = (item) => {
    //setSelectedEvent(item);
    onEditData && onEditData(item)
    handlePopoverClose()
  }
  const handlePopoverOpen = (event, item) => {
    event.stopPropagation()
    setAnchorElPopover(event.currentTarget)
    setSelectedEvent(item)
  }

  const handlePopoverClose = () => {
    setAnchorElPopover(null)
  }
  const Action = ({ item }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          boxShadow: 'none',
          gap: 1
        }}
      >
        <Button
          style={{
            color: '#333333',
            justifyContent: 'flex-start',
            textTransform: 'none'
          }}
          onClick={() => handleViewClick(item)}
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" gap={1}>
            <ViewIcon />
            <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
              View
            </Typography>
          </Box>
        </Button>

        <Button
          style={{
            color: '#333333',
            justifyContent: 'flex-start',
            textTransform: 'none'
          }}
          onClick={() => handleEditClick(selectedEvent)}
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" gap={1}>
            <EditIcon />
            <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
              Edit
            </Typography>
          </Box>
        </Button>
        {user.role == 'admin' ? (
          <Button
            style={{
              color: '#333333',
              justifyContent: 'flex-start',
              textTransform: 'none'
            }}
            onClick={() => {
              // setSelectedEvent(item);
              setDeletingItemId(item._id)
              setOpenConfirmation(true)
              handlePopoverClose()
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%" gap={1}>
              <DeleteIcon />
              <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
                Delete
              </Typography>
            </Box>
          </Button>
        ) : (
          <></>
        )}
      </Box>
    )
  }
  useEffect(() => {
    setEventData(
      data?.map((item) => ({
        ...item,
        action: (
          <Box sx={{ display: 'flex', boxShadow: 'none' }}>
            <IconButton
              aria-label="more actions"
              onClick={(e) => handlePopoverOpen(e, item)}
              sx={{
                color: openPopover ? 'primary' : 'inherit'
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorElPopover)}
              anchorEl={anchorElPopover}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: isMobile() ? 'bottom' : 'top',
                horizontal: isMobile() ? 'center' : 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{
                '& .MuiPaper-root': {
                  boxShadow: 'none',
                  border: '1px solid #EAECF0',
                  borderRadius: '4px'
                }
              }}
            >
              <Action item={item}></Action>
            </Popover>
          </Box>
        )
      }))
    )
  }, [data, anchorElPopover])

  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: '1px',
        maxHeight: '100%',
        paddingBottom: 5,
        overflow: 'auto'
      }}
      container
      spacing={2}
    >
      {eventData?.map((x, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              position: 'relative',
              borderRadius: '6px',
              border: '1px solid #D0D5DD'
            }}
          >
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography fontWeight="500" sx={{ textAlign: 'start' }} fontFamily="Poppins" color="#333333">
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10
                  }}
                >
                  {x?.action}
                </div>
              </div>
              <Grid container spacing={1} mt={1}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Leg
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'leg')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'leg')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={x?.leg} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {x?.leg}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Code
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'code')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'code')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={x?.code} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {x?.code}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={x?.description} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {x?.description}
                    </Typography>
                  </Tooltip>
                </Grid>
                {user.role == 'client' ? (
                  <Grid item xs={5} display={'flex'} alignItems={'center'}>
                    <Typography
                      fontWeight="500"
                      sx={{ textAlign: 'start' }}
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      Client Description
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {user.role == 'client' ? (
                  <Grid item xs={7}>
                    <Tooltip title={x?.client_description} arrow>
                      <Typography
                        noWrap
                        sx={{ textAlign: 'start' }}
                        fontWeight="500"
                        fontFamily={'poppins'}
                        color={'#333333'}
                      >
                        : {x?.client_description}
                      </Typography>
                    </Tooltip>
                  </Grid>
                ) : (
                  <></>
                )}

                {user.role == 'admin' ? (
                  <Grid item xs={5} display={'flex'} alignItems={'center'}>
                    <Typography
                      fontWeight="500"
                      sx={{ textAlign: 'start' }}
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      Optional
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {user.role == 'admin' ? (
                  <Grid item xs={7}>
                    <Tooltip title={x?.optional} arrow>
                      <Typography
                        noWrap
                        sx={{ textAlign: 'start' }}
                        fontWeight="500"
                        fontFamily={'poppins'}
                        color={'#333333'}
                      >
                        : {x?.optional}
                      </Typography>
                    </Tooltip>
                  </Grid>
                ) : (
                  <></>
                )}
                {user.role == 'admin' ? (
                  <Grid item xs={5} display={'flex'} alignItems={'center'}>
                    <Typography
                      fontWeight="500"
                      sx={{ textAlign: 'start' }}
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      System Generated
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {user.role == 'admin' ? (
                  <Grid item xs={7}>
                    <Tooltip title={x?.system_generated} arrow>
                      <Typography
                        noWrap
                        sx={{ textAlign: 'start' }}
                        fontWeight="500"
                        fontFamily={'poppins'}
                        color={'#333333'}
                      >
                        : {x?.system_generated}
                      </Typography>
                    </Tooltip>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: 'fill', minHeight: '100%' }}
      />
    </div>
  )

  // const MobileCardView = ({ data }) => {
  //   return (
  //     <Box sx={{ width: "100%", padding: 0.5 }}>
  //       {eventData.length === 0 ? (
  //         <NoDataImage />
  //       ) : (
  //         eventData?.map((x, index) => (
  //           <Card
  //             key={index}
  //             sx={{
  //               marginBottom: 2,
  //               backgroundColor: "#ffff",
  //               borderRadius: 4,
  //             }}
  //           >
  //             <CardContent>
  //               <div style={{ width: "100%" }}>
  //                 <Box
  //                   display="flex"
  //                   flexDirection="column"
  //                   alignItems="flex-start"
  //                 >
  //                   <Grid container spacing={2} sx={{ marginTop: 1 }}>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#333333"
  //                         fontWeight={600}
  //                         textAlign="left"
  //                       >
  //                         Leg
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="Poppins"
  //                         color="#606060"
  //                         fontWeight={500}
  //                         noWrap
  //                         overflow="hidden"
  //                         textOverflow="ellipsis"
  //                         textAlign="left"
  //                       >
  //                         : {x?.leg}
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="##333333"
  //                         fontWeight={600}
  //                         textAlign="left"
  //                       >
  //                         Code
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="Poppins"
  //                         color="#606060"
  //                         fontWeight={500}
  //                         noWrap
  //                         overflow="hidden"
  //                         textOverflow="ellipsis"
  //                         textAlign="left"
  //                       >
  //                         : {x?.code}
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="##333333"
  //                         fontWeight={600}
  //                         textAlign="left"
  //                       >
  //                         Description
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="Poppins"
  //                         color="#606060"
  //                         fontWeight={500}
  //                         noWrap
  //                         overflow="hidden"
  //                         textOverflow="ellipsis"
  //                         textAlign="left"
  //                       >
  //                         : {x?.description}
  //                       </Typography>
  //                     </Grid>
  //                     {user.role == "admin" ? (
  //                       <Grid item xs={6}>
  //                         <Typography
  //                           fontSize={14}
  //                           fontFamily="poppins"
  //                           color="##333333"
  //                           fontWeight={600}
  //                           textAlign="left"
  //                         >
  //                           Optional
  //                         </Typography>
  //                       </Grid>
  //                     ) : null}
  //                     {user.role == "admin" ? (
  //                       <Grid item xs={6}>
  //                         <Typography
  //                           fontSize={14}
  //                           fontFamily="Poppins"
  //                           color="#606060"
  //                           fontWeight={500}
  //                           noWrap
  //                           overflow="hidden"
  //                           textOverflow="ellipsis"
  //                           textAlign="left"
  //                         >
  //                           : {x?.optional == true ? "Yes" : "No"}
  //                         </Typography>
  //                       </Grid>
  //                     ) : (
  //                       <> </>
  //                     )}
  //                     {user.role == "admin" ? (
  //                       <Grid item xs={6}>
  //                         <Typography
  //                           fontSize={14}
  //                           fontFamily="poppins"
  //                           color="##333333"
  //                           fontWeight={600}
  //                           textAlign="left"
  //                         >
  //                           Sysytem Generated
  //                         </Typography>
  //                       </Grid>
  //                     ) : (
  //                       <></>
  //                     )}
  //                     {user.role == "admin" ? (
  //                       <Grid item xs={6}>
  //                         <Typography
  //                           fontSize={14}
  //                           fontFamily="Poppins"
  //                           color="#606060"
  //                           fontWeight={500}
  //                           noWrap
  //                           overflow="hidden"
  //                           textOverflow="ellipsis"
  //                           textAlign="left"
  //                         >
  //                           : {x?.system_generated == true ? "Yes" : "No"}
  //                         </Typography>
  //                       </Grid>
  //                     ) : (
  //                       <></>
  //                     )}
  //                   </Grid>
  //                 </Box>

  //                 <Box mt={2} justifyContent={"flex-end"} display="flex">
  //                   <IconButton
  //                     aria-haspopup="true"
  //                     onClick={(e) => handleMenuOpen(e, x)}
  //                   >
  //                     <MoreVertIcon />
  //                   </IconButton>
  //                 </Box>
  //               </div>
  //             </CardContent>
  //           </Card>
  //         ))
  //       )}
  //       {menuPosition && (
  //         <div
  //           ref={menuRef}
  //           style={{
  //             position: "absolute",
  //             top: menuPosition.y,
  //             left: menuPosition.x - 20,
  //             backgroundColor: "white",
  //             border: "1px solid #ccc",
  //             borderRadius: "8px",
  //             boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  //             zIndex: 1000,
  //             width: "200px",
  //           }}
  //         >
  //           <Action></Action>
  //         </div>
  //       )}
  //     </Box>
  //   );
  // };

  const renderListView = () => (
    <div
      onScroll={() => {
        if (isMobile()) {
          setMenuPosition(null)
        }
      }}
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 20,
        marginTop: 5
      }}
    >
      {/* {isMobile() ? (
        <MobileCardView data={eventData} />
      ) : ( */}
      <TableContainer
        sx={{
          overflowY: 'auto',
          paddingInline: 0.4,
          maxHeight: '100%'
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: '1px solid #EAECF0'
          }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  SL NO
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}
                >
                  {' '}
                  {filterData.columns.find((x) => x.key === 'leg')?.sort === 1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'leg')?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={'poppins'}
                  >
                    LEG
                  </Typography>
                  <Badge
                    color="primary"
                    variant="dot"
                    invisible={
                      !filterData?.columns?.find((item) => item.key.toLowerCase() === 'leg')?.filter?.length
                    }
                    overlap="circular"
                  >
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, 'leg')}
                    >
                      <SortingIcon fill={primary_color || '#DC585C'} />
                    </IconButton>
                  </Badge>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}
                >
                  {filterData.columns.find((x) => x.key === 'code')?.sort === 1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'code')?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={'poppins'}
                  >
                    CODE
                  </Typography>
                  <Badge
                    color="primary"
                    variant="dot"
                    invisible={
                      !filterData?.columns?.find((item) => item.key.toLowerCase() === 'code')?.filter?.length
                    }
                    overlap="circular"
                  >
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, 'code')}
                    >
                      <SortingIcon fill={primary_color || '#DC585C'} />
                    </IconButton>
                  </Badge>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                >
                  DESCRIPTION
                </Typography>
              </StyledTableCell>
              {clientId && (
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={'poppins'}
                  >
                    CLIENT DESCRIPTION
                  </Typography>
                </StyledTableCell>
              )}
              {user.role == 'admin' ? (
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={'poppins'}
                  >
                    Optional
                  </Typography>
                </StyledTableCell>
              ) : (
                <></>
              )}
              {user.role == 'admin' ? (
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={'poppins'}
                  >
                    System Generated
                  </Typography>
                </StyledTableCell>
              ) : (
                <></>
              )}

              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                ></Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {eventData.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={7} align="center">
                  <NoDataImage />
                </TableCell>
              </StyledTableRow>
            ) : (
              eventData?.map((x, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={'Poppins'}
                      ml={1}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'Poppins'}>
                      {x?.leg}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'Poppins'}>
                      {x?.code}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins',
                        maxWidth: 200
                      }}
                    >
                      {x?.description}
                    </Typography>
                  </StyledTableCell>
                  {clientId && (
                    <StyledTableCell>
                      <Typography
                        style={{
                          fontSize: '12px',
                          color: '#667085',
                          fontWeight: 400,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontFamily: 'poppins',
                          maxWidth: 200
                        }}
                      >
                        {x?.client_description}
                      </Typography>
                    </StyledTableCell>
                  )}
                  {user.role == 'admin' ? (
                    <StyledTableCell>
                      <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'Poppins'}>
                        {x?.optional ? 'Yes' : 'No'}
                      </Typography>
                    </StyledTableCell>
                  ) : (
                    <></>
                  )}
                  {user.role == 'admin' ? (
                    <StyledTableCell>
                      <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'Poppins'}>
                        {x?.system_generated ? 'Yes' : 'No'}
                      </Typography>
                    </StyledTableCell>
                  ) : (
                    <></>
                  )}
                  <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>{x?.action}</StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* )} */}
    </div>
  )
  return (
    <>
      <div sx={{ minWidth: '100%' }}>
        <div
          style={{
            overflowY: 'scroll',
            display: 'flex',
            maxHeight: `calc(100vh - 250px)`,
            width: '100%',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::WebkitScrollbar': { width: 0 }
          }}
        >
          {viewMode === 'grid' || isMobile() ? renderGridView() : renderListView()}
        </div>

        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          disableNext={dataCount <= (page + 1) * filterData.limit}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedEvent?.leg} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData()
          }}
        />
        <EventDetailsModal
          event={selectedEvent}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
          clientId={clientId}
        />
        <Popover
          open={Boolean(anchorElFilter)}
          anchorEl={anchorElFilter}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          disableScrollLock={true}
        >
          <FilterModal
            open={openFilterModal}
            onClose={handleCloseFilter}
            options={selectedfilterData?.options}
            selectedValues={selectedfilterData?.filter}
            onSave={handleSaveFilter}
            currentSortOrder={selectedfilterData?.sort}
            filterKey={selectedfilterData?.key}
            hasMore={selectedfilterData?.loadMoreButton}
            displayKey={selectedfilterData?.displayKey}
            id={selectedfilterData?.id}
            fetchApi={selectedfilterData?.fetchApi}
            searchKey={selectedfilterData?.searchKey}
          />
        </Popover>
      </div>
    </>
  )
}

export default EventList
