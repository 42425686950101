import React, { useContext, useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  IconButton,
  Box,
  useTheme
} from '@mui/material'
import { portService } from '../../utils/services/portService'
import { zipZoneService } from '../../utils/services/zipZoneService'
import AppContext from '../context/context'
import { rateCardService } from '../../utils/services/rateCardService'
import { productService } from '../../utils/services/productService'
import CloseIcon from '@mui/icons-material/Close'
import CustomTypography from '../../globalStyles/fonts'
import { isMobile } from '../../globalStyles/mobile'
import FileSaver from 'file-saver'
import { color } from 'framer-motion'
const FileUploadModal = ({
  open,
  onClose,
  onSubmitUploadData,
  onSuccessUpload,
  rateCard,
  product,
  title,
  showUploadStatus,
  onClickStatus,
  selectedData
}) => {
  const [selectedValue, setSelectedValue] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [ports, setPorts] = useState([])
  const [selectedClient, setSelectedClient] = useState({ name: '' })
  const { user } = useContext(AppContext)
  const theme = useTheme()
  const defaultColor = '#D13438'
  const text_color = theme?.palette?.text?.primary || '#FFF'

  useEffect(() => {
    if (open) {
      setSelectedValue(null)
      setFileName('')
      setSelectedClient({ name: '' })
    }
  }, [open])

  const bulkUpload = async () => {
    const formData = new FormData()
    formData.append('file', selectedFile)

    if (!rateCard && !product) {
      formData.append('portId', selectedValue?._id || '')
    }

    formData.append('client_id', selectedClient?._id)
    try {
      let response
      if (rateCard) {
        response = await rateCardService.bulkUpload(formData)
      } else if (product) {
        response = await productService.bulkUpload(selectedFile)
        if (response) {
          onSuccessUpload && onSuccessUpload()
        }
      } else {
        response = await zipZoneService.bulkUpload(formData)
      }

      onSubmitUploadData && onSubmitUploadData()
    } catch (error) {
      console.error('Upload failed:', error)
    }
  }

  const fileUploadRef = React.useRef()
  const handleFileUpload = (event) => {
    event.preventDefault()
    fileUploadRef.current.click()
  }

  const uploadFile = async () => {
    try {
      const uploadedFile = fileUploadRef.current.files[0]
      setFileName(uploadedFile)
      setSelectedFile(uploadedFile)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPortData = async () => {
    const clientId = localStorage.getItem('client_id')
    const response = await portService.fetch(15, 0, clientId, '')
    setPorts(response.data)
  }

  useEffect(() => {
    fetchPortData()
  }, [])

  // const getSampleFileUrl = () => {
  //   if (product) {
  //     return "/product_sample.xlsx";
  //   } else if (rateCard) {
  //     return "/product_sample.xlsx";
  //   } else {
  //     return "/product_sample.xlsx";
  //   }
  // };

  const handleSampleDownload = async () => {
    try {
      const response = await productService.downloadSample()
      console.log(response)
      let sliceSize = 1024
      let byteCharacters = atob(response.base64)
      let bytesLength = byteCharacters.length
      let slicesCount = Math.ceil(bytesLength / sliceSize)
      let byteArrays = new Array(slicesCount)
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize
        let end = Math.min(begin + sliceSize, bytesLength)
        let bytes = new Array(end - begin)
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0)
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes)
      }
      let blob = new Blob(byteArrays, { type: 'application/vnd.ms-excel' })
      FileSaver.saveAs(new Blob([blob], {}), 'product-sample.xlsx')
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSelectedValue(null)
        setFileName('')
        setSelectedClient({ name: '' })
        onClose && onClose()
      }}
      fullWidth
      maxWidth="sm"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <DialogTitle>
          <Typography
            fontSize={isMobile() ? 14 : 18}
            fontWeight={500}
            color={'#000000'}
            fontFamily={'Poppins'}
          >
            {`Upload ${title} Data`}
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Divider />
      <DialogContent sx={{ minWidth: isMobile() ? '100%' : 500 }}>
        {/* {!rateCard && !product && (
          <Autocomplete
            size="small"
            sx={{ marginTop: 2 }}
            options={ports}
            value={selectedValue}
            getOptionLabel={(option) => option.name} // Access the "name" key
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
              <TextField {...params} label="Choose Port" fullWidth />
            )}
          />
        )} */}
        {/* {user.role === "admin" && (
          <Autocomplete
            size="small"
            value={selectedClient}
            onChange={(event, newValue) => {
              setSelectedClient(newValue);
              onSelectClient && onSelectClient(newValue);
            }}
            options={clientData}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography variant="body1">{option.name}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Choose Client" margin="normal" />
            )}
          />
        )} */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          {!isMobile() ? (
            <div>
              <CustomTypography color={'#11193F'} fontSize={16} fontWeight={400}>
                Upload
              </CustomTypography>
            </div>
          ) : (
            <></>
          )}

          <div>
            {/* {showUploadStatus ? (
              <Button
                size="small"
                style={{
                  backgroundColor: "#007DFF",
                  margin: 3,
                  textTransform: "none",
                  minWidth: 100,
                  padding: 6,
                }}
                variant="contained"
                onClick={(e) => onClickStatus && onClickStatus()}
              >
                <CustomTypography
                  fontSize="14px"
                  fontWeight={500}
                  color="#FFFFFF"
                >
                  Status
                </CustomTypography>
              </Button>
            ) : (
              <></>
            )} */}
            <Button
              variant="contained"
              style={{
                color: '#007613',
                textTransform: 'none',
                backgroundColor: '#CBFFD3',
                border: '1px solid #007613',
                gap: 5,
                padding: 5
              }}
              onClick={handleSampleDownload}
            >
              <img src="/excel-file.png" width={20} height={10} alt="Sample Excel" />
              <CustomTypography fontSize={14} fontWeight={400}>
                Sample Excel
              </CustomTypography>
            </Button>
          </div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <input
            onChange={uploadFile}
            type="file"
            id="excelFile"
            accept=".xls,.xlsx"
            ref={fileUploadRef}
            hidden
          />

          <Button
            style={{
              backgroundColor: fileName ? '#007DFF' : '#EBEBEB',
              marginTop: 5,
              justifyContent: 'flex-start',
              color: '#000000',
              textTransform: 'none',
              color: fileName ? '#fff' : '#000',
              border: '1px solid #D9D9D9'
            }}
            onClick={handleFileUpload}
          >
            <CustomTypography>{fileName?.name || 'Choose File'}</CustomTypography>
          </Button>
          <Box sx={{ marginLeft: { xs: 1, sm: 2 }, marginTop: 1 }}>
            {!fileName && (
              <CustomTypography color="#757477" fontSize={16} fontWeight={400}>
                No file chosen
              </CustomTypography>
            )}
          </Box>
        </Box>
        <Divider sx={{ mt: 3 }} />
        <div>
          {selectedData && selectedData.length > 0 ? (
            selectedData.map((item, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 10
                }}
              >
                <Typography fontFamily={'poppins'} color={'#000000'} fontWeight={500} fontSize={'14px'}>
                  Total Uploaded: {item.total_records || '0'}
                </Typography>
                <div>
                  <Typography fontFamily={'poppins'} color={'green'} fontWeight={500} fontSize={'14px'}>
                    Success: {item.success || '0'}
                  </Typography>
                  <Typography fontFamily={'poppins'} color={'red'} fontWeight={500} fontSize={'14px'}>
                    Error: {item.error || '0'}
                  </Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography fontFamily={'poppins'}>No data available</Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          onClick={() => {
            setSelectedValue(null)
            setFileName('')
            setSelectedClient({ name: '' })
            onClose && onClose()
          }}
          variant="outlined"
          style={{ textTransform: 'none', borderColor: 'red' }}
        >
          <CustomTypography style={{ color: 'red' }} fontSize={14} fontWeight={600}>
            Cancel
          </CustomTypography>
        </Button>
        <Button variant="contained" onClick={bulkUpload} style={{ textTransform: 'none' }}>
          <CustomTypography style={{ color: text_color }} fontSize={14} fontWeight={600}>
            Upload
          </CustomTypography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FileUploadModal
