import React, { useEffect } from 'react'

const ActivateKeyForm = ({ onChangeActivekey, onSubmit, clientActiveKey, apiSecretKey }) => {
  useEffect(() => {
    if (apiSecretKey) {
      onSubmit && onSubmit()
    }
  }, [apiSecretKey])
  console.log('apiSecretKey', apiSecretKey)
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '30px',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          textAlign: 'center'
        }}
      >
        <h2 style={{ marginBottom: '20px', fontSize: 23, fontWeight: 'bold' }}>Activate Your Account</h2>
        <p style={{ maxWidth: 450, marginBottom: '20px' }}>
          Welcome to DropSkip™️ APP (Powered by Redefined Logistics)! Please enter your activation key below
          to complete the setup of your account. The key was provided in the email you received.
        </p>
        <input
          disabled={apiSecretKey ? true : false}
          value={clientActiveKey || apiSecretKey || ''}
          onChange={(e) => onChangeActivekey && onChangeActivekey(e.target.value)}
          type="text"
          style={{
            width: '80%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            fontSize: '16px'
          }}
          placeholder="XXXX-XXXX-XXXX-XXXX-XXXX-XXXX"
        />
        <p
          style={{
            marginTop: '0',
            maxWidth: 450,
            marginBottom: '20px'
          }}
        >
          If you encounter any issues during the activation process, feel free to contact us at{' '}
          <a
            href="mailto:support@redefinedlogistics.com"
            style={{
              color: 'blue',
              textDecoration: 'none'
            }}
          >
            support@redefinedlogistics.com
          </a>
        </p>
        <button
          onClick={onSubmit}
          style={{
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px'
          }}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default ActivateKeyForm
