import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { zoneService } from "../../utils/services/zoneService";

const ZoneModal = ({ open, handleClose, onAddData, selectedZone }) => {
  const [zoneDetails, setZoneDetails] = useState({
    name: "",
    zone_number: "",
  });
  const initialData = () => {
    setZoneDetails({
      name: "",
      zone_number: "",
    });
    setFormErrors({});
  };
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!zoneDetails.name) errors.name = "Zone name is required.";
    if (!zoneDetails.zone_number) errors.number = "Zone Number is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (selectedZone) {
      setZoneDetails({
        name: selectedZone?.name || "",
        zone_number: selectedZone?.zone_number || "",
      });
    } else {
      initialData();
    }
  }, [selectedZone]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setZoneDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await zoneService.edit(
        {
          name: zoneDetails.name,
          zone_number: zoneDetails.zone_number,
        },
        selectedZone._id
      );
      onAddData && onAddData();
      toast.success("Zone edited successfully!");
      initialData();
    } catch (e) {
      console.error(e);
    }
    initialData();
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await zoneService.create({
        name: zoneDetails.name,
        zone_number: zoneDetails.zone_number,
      });
      onAddData && onAddData();
      toast.success("Zone created successfully!");
      initialData();
    } catch (e) {
      toast.error("Failed to create Zone.");
      console.error(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        initialData();
        handleClose();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedZone ? "Edit Zone" : "Create Zone"}
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Zone Name </CustomTypography>
            <TextField
              size="small"
              name="name"
              value={zoneDetails.name}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Zone Number</CustomTypography>
            <TextField
              size="small"
              name="zone_number"
              value={zoneDetails.zone_number}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.number}
              helperText={formErrors.number}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          onClick={() => {
            handleClose();
            initialData();
          }}
          variant="outlined"
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedZone ? handleEditData(e) : handleSave(e))}
          variant="contained"
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {selectedZone ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ZoneModal;
