// components/Breadcrumbs.js
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import React, { useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import CustomTypography from "../../globalStyles/fonts";
import { getObjectByPath } from "../../utils/getObjectByPath";
import { isMobile } from "../../globalStyles/mobile";
import HomeIcon from "../../globalStyles/icons/home-icon";
import { useTheme } from "@mui/styles";

const Breadcrumbs = () => {
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme?.palette?.primary?.main || defaultColor;
  const secondary_color = theme?.palette?.secondary?.main || defaultColor;

  const location = useLocation();
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  const path = location.pathname;
  function isMongoIdInUrl() {
    // Regular expression to match a MongoDB ObjectID
    const objectIdRegex = /\/[a-f\d]{24}$/;

    // Test the URL against the regex
    return objectIdRegex.test(path);
  }

  const breadcrumbData = path.toString().includes("/order-flow")
    ? {
        title: "Order",
        link: "/order-management?child=true",
        subNav: {
          title: "Order Work Flow",
          subNav2: { title: "" },
        },
      }
    : path.toString().includes("/account-setting")
    ? { title: "Account Setting", subNav: { title: "" } }
    : path.toString().includes("/manage-product")
    ? {
        title: "Product",
        subNav: {
          title: "Catalog",
          link: "/product",
          subNav2: {
            title: `${isMongoIdInUrl() ? "Edit" : "Add"} Product`,
          },
        },
      }
    : path.toString().includes("/order-tracking-history")
    ? {
        title: "Order",
        link: "/order-management?child=true",
        subNav: {
          title: "Order Tracking History",
        },
      }
    : path.toString().includes("/assign-user")
    ? {
        title: "Account Setting",
        link: "/account-setting",
        subNav: {
          title: "Assign User",
        },
      }
    : path.toString().includes("/track-order")
    ? { title: "Order Tracking", subNav: { title: "" } }
    : getObjectByPath(path, user?.role);

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" color="#0E1434" />}
    >
      <Link component={RouterLink} color={primary_color || "#DC585C"} to="/">
        <HomeIcon fill={primary_color || "#DC585C"} />
      </Link>
      {breadcrumbData?.title &&
        (breadcrumbData?.link ? (
          <Link
            component={RouterLink}
            to={breadcrumbData.link}
            style={{ textDecoration: "none", color: primary_color }}
          >
            <CustomTypography
              fontSize={isMobile() ? 12 : "14px"}
              fontWeight={600}
              color={primary_color}
            >
              {breadcrumbData.title}
            </CustomTypography>
          </Link>
        ) : (
          <CustomTypography
            color={primary_color}
            fontSize={isMobile() ? 12 : "14px"}
            fontWeight={600}
          >
            {breadcrumbData.title}
          </CustomTypography>
        ))}
      {breadcrumbData?.subNav?.title &&
        (breadcrumbData.subNav?.link ? (
          <Link
            component={RouterLink}
            to={breadcrumbData.subNav.link}
            style={{ textDecoration: "none", color: secondary_color }}
          >
            <CustomTypography
              color={
                breadcrumbData?.subNav?.subNav2?.title
                  ? primary_color
                  : secondary_color
              }
              fontSize={isMobile() ? 12 : "14px"}
              fontWeight={600}
            >
              {breadcrumbData.subNav.title}
            </CustomTypography>
          </Link>
        ) : (
          <CustomTypography
            color={secondary_color}
            fontSize={isMobile() ? 12 : "14px"}
            fontWeight={600}
          >
            {breadcrumbData.subNav.title}
          </CustomTypography>
        ))}
      {breadcrumbData && breadcrumbData?.subNav?.subNav2 && (
        <CustomTypography
          color={secondary_color || "#757575"}
          fontSize={isMobile() ? 12 : "14px"}
          fontWeight={600}
        >
          {breadcrumbData.subNav?.subNav2?.title}
        </CustomTypography>
      )}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
