import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import ClientAutocomplete from "../clientAutocomplete";
import { toast } from "react-toastify";
import { isMobile } from "../../globalStyles/mobile";
import { supplierService } from "../../utils/services/supplierService";

const SupplierModal = ({
  open,
  handleClose,
  onAddData,
  selectedSupplier,
  onSelectClient,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [selectedClient, setSelectedClient] = useState({
    name: "",
    business_name: "",
  });
  const [addressData, setAddressData] = useState({
    address1: "",
    address2: "",
    city: "",
    province: "",
    country: "",
    zipcode: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const clientId = localStorage.getItem("client_id");

  const validateForm = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = " Name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    }
    if (!addressData.address1) {
      errors.address1 = "Address1 is required";
    }
    if (!addressData.address2) {
      errors.address2 = "Address2 is required";
    }
    if (!addressData.city) {
      errors.city = "City is required";
    }
    if (!addressData.province) {
      errors.province = "Province is required";
    }
    if (!addressData.country) {
      errors.country = "Country is required";
    }
    if (!addressData.zipcode) {
      errors.zipcode = "Zipcode is required";
    }

    if (!clientId && !selectedClient) {
      errors.selectedClient = "Client is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (selectedSupplier) {
      setFormData({
        name: selectedSupplier?.name || "",
        email: selectedSupplier?.email || "",
      });
      setAddressData({
        address1: selectedSupplier?.address?.address1 || "",
        address2: selectedSupplier?.address?.address2 || "",
        city: selectedSupplier?.address?.city || "",
        province: selectedSupplier?.address?.province || "",
        country: selectedSupplier?.address?.country || "",
        zipcode: selectedSupplier?.address?.zipcode || "",
      });
      setSelectedClient({
        name: selectedSupplier?.client?.name || "",
        _id: selectedSupplier?.client?._id || "",
        business_name: selectedSupplier?.client?.business_name || "",
      });
    } else {
      initialData();
    }
  }, [selectedSupplier]);
  const initialData = () => {
    setFormData({ name: "", email: "" });
    setAddressData({
      address1: "",
      address2: "",
      city: "",
      province: "",
      country: "",
      zipcode: "",
    });
    setSelectedClient({ nam: "", business_name: "" });
    setFormErrors({});
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await supplierService.edit(selectedSupplier._id, {
        name: formData.name,
        email: formData.email,
        address: addressData,
        client: clientId ? clientId : selectedClient?._id,
      });
      onAddData && onAddData();
      toast.success("Supplier edited successfully!");
      initialData();
    } catch (e) {
      console.error(e);
    }
    initialData();
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const client_id = clientId ? clientId : selectedClient?._id;
      await supplierService.create({
        name: formData.name,
        email: formData.email,
        address: addressData,
        client: client_id,
      });
      onAddData && onAddData();
      toast.success("Supplier created successfully!");
      handleClose();
      initialData();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: isMobile() ? 16 : 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedSupplier ? "Edit" : "Create"} Supplier
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              fontSize="14px"
              fontWeight={400}
              fontFamily={"poppins"}
            >
              Name <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="name"
              autoFocus
              size="small"
              placeholder="Enter Name"
              type="text"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Select Client <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <ClientAutocomplete
              hideLabel={true}
              selectedClient={selectedClient}
              onClientChange={(client) => {
                setSelectedClient(client);
                onSelectClient && onSelectClient(client);
              }}
              errorMessage={formErrors.selectedClient}
              width={"100%"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Email <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              sx={{ marginTop: 1 }}
              name="email"
              type="email"
              size="small"
              placeholder="Enter Email"
              variant="outlined"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              autoFocus
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Address 1 <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="address1"
              margin="dense"
              type="address"
              size="small"
              placeholder="Enter Address 1"
              variant="outlined"
              value={addressData.address1}
              onChange={handleAddressChange}
              fullWidth
              autoFocus
              error={!!formErrors.address1}
              helperText={formErrors.address1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Address 2 <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="address2"
              margin="dense"
              type="address"
              size="small"
              placeholder="Enter Address 2"
              variant="outlined"
              value={addressData.address2}
              onChange={handleAddressChange}
              fullWidth
              autoFocus
              error={!!formErrors.address2}
              helperText={formErrors.address2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              City <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="city"
              margin="dense"
              type="address"
              size="small"
              placeholder="Enter City"
              variant="outlined"
              value={addressData.city}
              onChange={handleAddressChange}
              fullWidth
              autoFocus
              error={!!formErrors.city}
              helperText={formErrors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Province <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="province"
              margin="dense"
              type="address"
              size="small"
              placeholder="Enter Province"
              variant="outlined"
              value={addressData.province}
              onChange={handleAddressChange}
              fullWidth
              autoFocus
              error={!!formErrors.province}
              helperText={formErrors.province}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Country <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="country"
              margin="dense"
              type="address"
              size="small"
              placeholder="Enter Country"
              variant="outlined"
              value={addressData.country}
              onChange={handleAddressChange}
              fullWidth
              autoFocus
              error={!!formErrors.country}
              helperText={formErrors.country}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Zipcode <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="zipcode"
              margin="dense"
              type="address"
              size="small"
              placeholder="Enter Zipcode"
              variant="outlined"
              value={addressData.zipcode}
              onChange={handleAddressChange}
              fullWidth
              autoFocus
              error={!!formErrors.zipcode}
              helperText={formErrors.zipcode}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedSupplier ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedSupplier ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupplierModal;
