import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Button,
  Popover,
} from "@mui/material";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import AppContext from "../context/context";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import Pagination from "../pagination";
import { providerEventMappingService } from "../../utils/services/providerEventMappingService";
import ProviderEventMappingDetailsModal from "../providerEventMappingDetailsModal;";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "../../globalStyles/icons/editIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import { useTheme } from "@mui/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const ProviderEventTableCell = ({
  providerEvent,
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(AppContext);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "view":
        handleViewClick(providerEvent);
        break;
      case "edit":
        handleEditClick(providerEvent);
        break;
      case "delete":
        handleDeleteClick(providerEvent);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "inherit",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
          <Button
            onClick={() => handleActionClick("view")}
            sx={{ textTransform: "none", justifyContent: "flex-start" }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <ViewIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                View
              </Typography>
            </Box>
          </Button>
          {user.role == "admin" ? (
            <>
              <Button
                onClick={() => handleActionClick("edit")}
                sx={{ textTransform: "none", justifyContent: "flex-start" }}
              >
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <EditIcon />
                  <Typography
                    fontFamily={"poppins"}
                    fontSize={"16px"}
                    color={"#333333"}
                    fontWeight={500}
                  >
                    Edit
                  </Typography>
                </Box>
              </Button>
              <Button
                onClick={() => handleActionClick("delete")}
                style={{ textTransform: "none" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                  gap={1}
                >
                  <DeleteIcon />
                  <Typography
                    fontFamily={"poppins"}
                    fontSize={"16px"}
                    color={"#333333"}
                    fontWeight={500}
                  >
                    Delete
                  </Typography>
                </Box>
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Popover>
    </Box>
  );
};
const ProviderEventMappingList = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedMapping,
  setSelectedMapping,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [mappingData, setMappingData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const startIndex = rowsPerPage * page;
  const deleteData = async () => {
    try {
      await providerEventMappingService.delete(deletingItemId);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Partner deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete");
      console.error("Delete failed:", error);
    }
  };
  const handleViewClick = (item) => {
    setSelectedMapping(item);
    setOpenViewModal(true);
  };
  const handleEditClick = (item) => {
    // setAnchorEl(null);
    onEditData && onEditData(item);
  };

  const handleDeleteClick = (item) => {
    setSelectedMapping(item);
    // setAnchorEl(null);
    setDeletingItemId(item._id);
    setOpenConfirmation(true);
  };

  useEffect(() => {
    setMappingData(
      data?.map((item) => ({
        ...item,
        action: (
          <ProviderEventTableCell
            providerEvent={item}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            handleViewClick={handleViewClick}
          />
          // <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
          //   <IconButton
          //     style={{
          //       color: "#007DFF",
          //       padding: 0,
          //       background: "#C3E1FF",
          //       height: 30,
          //       width: 30,
          //       borderRadius: 5,
          //       // marginLeft: 10,
          //       boxShadow: "0px 0px 4px 0px #00000040",
          //     }}
          //     aria-label="view"
          //     onClick={() => {
          //       handleViewClick(item);
          //     }}
          //   >
          //     <img
          //       style={{ height: 14, width: 20 }}
          //       alt="view"
          //       src="/eye.png"
          //     ></img>
          //   </IconButton>

          //   {user.role == "admin" ? (
          //     <>
          //       <IconButton
          //         onClick={() => onEditData && onEditData(item)}
          //         style={{
          //           color: "#FEC50F",
          //           padding: 0,
          //           background: "#FEC50F33",
          //           height: 30,
          //           width: 30,
          //           borderRadius: 5,
          //           // marginLeft: 15,
          //           boxShadow: "0px 0px 4px 0px #00000040",
          //         }}
          //       >
          //         <BorderColorIcon sx={{ height: 20, width: 20 }} />
          //       </IconButton>
          //       <IconButton
          //         onClick={() => {
          //           setSelectedMapping(item);
          //           setDeletingItemId(item._id);
          //           setOpenConfirmation(true);
          //         }}
          //         style={{
          //           color: "#D13438",
          //           padding: 0,
          //           background: "#D1343833",
          //           height: 30,
          //           width: 30,
          //           borderRadius: 5,
          //           // marginLeft: 10,
          //           boxShadow: "0px 0px 4px 0px #00000040",
          //         }}
          //       >
          //         <img
          //           style={{ height: 15, width: 15 }}
          //           alt="delete"
          //           src="/delete.png"
          //         ></img>
          //       </IconButton>
          //     </>
          //   ) : (
          //     <></>
          //   )}
          // </Box>
        ),
      }))
    );
  }, [data]);

  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: "1px",
        maxHeight: "100%",
        paddingBottom: 5,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {mappingData?.map((x, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: "pointer",
              width: "100%",
              height: "100%",
              position: "relative",
              borderRadius: "6px",
              border: "1px solid #D0D5DD",
            }}
          >
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight="500"
                  sx={{ textAlign: "start" }}
                  fontFamily="Poppins"
                  color="#333333"
                >
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  {x?.action}
                </div>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Rl Event Code
                  </Typography>

                  {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")?.sort ===
                    -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={x?.rl_event_code?.code} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.rl_event_code?.code}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Provider
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={x?.provider?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.provider?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Provider Event Code
                  </Typography>
                  {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                  1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "email")
                      ?.sort === -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={x?.provider_event_code} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.provider_event_code}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {mappingData.length === 0 ? (
          <NoDataImage />
        ) : (
          mappingData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Rl Event Code
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.rl_event_code?.code}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Provider
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.provider?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Provider Event Code
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.provider_event_code}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={mappingData} />
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Rl Event Code
                  </Typography>
                </StyledTableCell>

                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Provider
                  </Typography>
                </StyledTableCell>

                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Provider Event Code
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {mappingData.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                mappingData?.map((x, index) => (
                  <React.Fragment>
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Typography
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                        >
                          {startIndex + index + 1}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                        >
                          {x?.rl_event_code?.code}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                        >
                          {x?.provider?.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                        >
                          {x?.provider_event_code}
                        </Typography>
                      </StyledTableCell>

                      <StyledTableCell
                        sx={{ width: "3%", textAlign: "center" }}
                      >
                        {x?.action}
                      </StyledTableCell>
                    </StyledTableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>

        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedMapping?.leg} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
        <ProviderEventMappingDetailsModal
          mapping={selectedMapping}
          open={openViewModal}
          onClose={() => {
            setOpenViewModal(false);
            setSelectedMapping(null);
          }}
        />
      </div>
    </>
  );
};

export default ProviderEventMappingList;
