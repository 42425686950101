import React, { useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Popover,
  Button,
  styled,
  tableCellClasses,
  Badge,
} from "@mui/material";
import AppContext from "../context/context";
import { isMobile } from "../../globalStyles/mobile";
import Pagination from "../pagination";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import FilterModal from "../filterModal";
import { useTheme } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import OverpackViewModal from "../overpack-view-modal";
import EditIcon from "../../globalStyles/icons/editIcon";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const OverpackList = ({
  data,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  onChangeFilter,
  filterData,
}) => {
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;
  const { user } = useContext(AppContext);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: "",
    filter: [],
    sort: 0,
    id: "_id",
    displayKey: "name",
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: "",
  });
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const startIndex = page * rowsPerPage;

  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilterModal(true);
    setSelectedFilterData(filterData.columns.find((x) => x.key == filterKey));
  };

  const handleSaveFilter = (
    selectedOptions,
    filterKey,
    sortOrder,
    searchKey
  ) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey,
    });
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilterModal(false);
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", minHeight: "100%" }}
      />
    </div>
  );

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      <TableContainer
        sx={{
          overflowY: "auto",
          paddingInline: 0.4,
          maxHeight: "100%",
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: "1px solid #EAECF0",
          }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  color="#333333"
                  fontWeight={500}
                  fontFamily={"poppins"}
                >
                  SL NO
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Type
                  </Typography>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Text
                  </Typography>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Details
                  </Typography>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Message
                  </Typography>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={7} align="center">
                  <NoDataImage />
                </TableCell>
              </StyledTableRow>
            ) : (
              data?.map((x, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                      ml={1}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                    >
                      {x?.type}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                    >
                      {x?.text}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    {x?.type === "shipment" ? (
                      <div>
                        <Typography
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                        >
                          {`Order# : ${x?.data?.order_id}`}
                        </Typography>
                        <Typography
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                        >
                          {`Shipment : ${x?.data?.event?.id}`}
                        </Typography>
                        <Typography
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                        >
                          {`Tracking Number : ${x?.data?.event?.tracking_number}`}
                        </Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                    >
                      {x?.message}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {renderListView()}
        </div>

        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          disableNext={dataCount <= (page + 1) * filterData.limit}
        />
        <Popover
          open={Boolean(anchorElFilter)}
          anchorEl={anchorElFilter}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
        >
          <FilterModal
            open={openFilterModal}
            onClose={handleCloseFilter}
            options={selectedfilterData?.options}
            selectedValues={selectedfilterData?.filter}
            onSave={handleSaveFilter}
            currentSortOrder={selectedfilterData?.sort}
            filterKey={selectedfilterData?.key}
            hasMore={selectedfilterData?.loadMoreButton}
            displayKey={selectedfilterData?.displayKey}
            id={selectedfilterData?.id}
            fetchApi={selectedfilterData?.fetchApi}
            searchKey={selectedfilterData?.searchKey}
          />
        </Popover>
      </div>
    </>
  );
};

export default OverpackList;
