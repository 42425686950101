import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Button,
  Popover,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import AppContext from "../context/context";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import Pagination from "../pagination";
import { providerService } from "../../utils/services/providerService";
import ProviderDetailsModal from "../providerDetailsModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "../../globalStyles/icons/editIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import { useTheme } from "@mui/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const ProviderTableCell = ({
  provider,
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(AppContext);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "view":
        handleViewClick(provider);
        break;
      case "edit":
        handleEditClick(provider);
        break;
      case "delete":
        handleDeleteClick(provider);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "inherit",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
          <Button
            onClick={() => handleActionClick("view")}
            sx={{ textTransform: "none", justifyContent: "flex-start" }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <ViewIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                View
              </Typography>
            </Box>
          </Button>
          {user.role == "admin" ? (
            <>
              <Button
                onClick={() => handleActionClick("edit")}
                sx={{ textTransform: "none", justifyContent: "flex-start" }}
              >
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <EditIcon />
                  <Typography
                    fontFamily={"poppins"}
                    fontSize={"16px"}
                    color={"#333333"}
                    fontWeight={500}
                  >
                    Edit
                  </Typography>
                </Box>
              </Button>
              <Button
                onClick={() => handleActionClick("delete")}
                style={{ textTransform: "none" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                  gap={1}
                >
                  <DeleteIcon />
                  <Typography
                    fontFamily={"poppins"}
                    fontSize={"16px"}
                    color={"#333333"}
                    fontWeight={500}
                  >
                    Delete
                  </Typography>
                </Box>
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

const ProviderList = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedProvider,
  setSelectedProvider,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [providerData, setProviderData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  // const { user } = useContext(AppContext);
  const [openViewModal, setOpenViewModal] = useState(false);
  const startIndex = page * rowsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;
  const deleteData = async () => {
    try {
      await providerService.delete(deletingItemId);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Provider deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete");
      console.error("Delete failed:", error);
    }
  };
  const handleViewClick = (provider) => {
    setSelectedProvider(provider);
    setOpenViewModal(true);
  };

  const handleDeleteClick = (item) => {
    // setAnchorEl(null);
    setDeletingItemId(item._id);
    setOpenConfirmation(true);
  };

  const handleEditClick = (item) => {
    // setAnchorEl(null);
    onEditData && onEditData(item);
  };
  useEffect(() => {
    setProviderData(
      data?.map((item) => ({
        ...item,
        action: (
          <ProviderTableCell
            provider={item}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            handleViewClick={handleViewClick}
          />
        ),
      }))
    );
  }, [data]);

  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {providerData?.map((x) => (
        <Grid item xs={12} sm={6} md={3} key={x._id}>
          <Card sx={{ boxShadow: 3, cursor: "pointer" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    // marginLeft: 15,
                  }}
                >
                  <Tooltip title={x?.name} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={x?.type} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.type}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {x.action}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {providerData.length === 0 ? (
          <NoDataImage />
        ) : (
          providerData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Type
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.type}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 100,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={providerData} />
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
            marginTop:2
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")
                      ?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      NAME
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")
                      ?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      TYPE
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      ACTION
                    </Typography>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {providerData?.map((x, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <Tooltip title={x?.name}>
                    <StyledTableCell>
                      <Typography
                        style={{
                          // marginLeft: 22,
                          maxWidth: 100,
                          whiteSpace: "nowrap", // Ensure single line
                          overflow: "hidden", // Prevent text overflow
                          textOverflow: "ellipsis", // Show ellipsis for overflow text
                        }}
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        title={x?.name} // Add a title attribute to show full text on hover
                      >
                        {x?.name}
                      </Typography>
                    </StyledTableCell>
                  </Tooltip>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     overflow: "hidden",
                      //     textOverflow: "ellipsis",
                      //     maxWidth: "200px",
                      //   }}
                      ml={0.5}
                    >
                      {x?.type}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                    {x?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "70vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>

        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedProvider?.name} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
        <ProviderDetailsModal
          provider={selectedProvider}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        />
      </div>
    </>
  );
};

export default ProviderList;
