import React, { useEffect, useReducer, useState } from "react";
import WeightPackageModal from "../../components/weightPackageModal";
import DashboardContainer from "../../components/dashboard-container";
import { weightPackageService } from "../../utils/services/weightPackageService";
import WeightPackageListView from "../../components/weight-package-list";
import { Box, CircularProgress } from "@mui/material";
import { clientService } from "../../utils/services/clientService";
import buildWeightQuery from "../../utils/weightQueryBuilder";

const initialState = {
  columns: [
    {
      key: "client_id",
      name: "Client",
      filter: [],
      sort: 0,
      id: "_id",
      displayKey: "business_name",
      fetchApi: (searchKey = "", client_id = "", limit, skip) =>
        clientService.fetchBusinessName(searchKey, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "dimension",
      name: "Dimension",
      filter: {
        from: {
          length: null,
          width: null,
          height: null,
          unit: null,
          valid: false,
        },
        to: {
          length: null,
          width: null,
          height: null,
          unit: null,
          valid: false,
        },
      },
      sort: 0,
      id: "_id",
      loadMoreButton: false,
      type: "dimension",
    },
    {
      key: "weight",
      name: "Weight",
      filter: {
        from: {
          minweight: null,
          max_weight: null,
          unit: null,
          valid: false,
        },
        to: {
          minweight: null,
          max_weight: null,
          unit: null,
          valid: false,
        },
      },
      sort: 0,
      id: "_id",
      loadMoreButton: false,
      type: "weight",
    },
    {
      key: "package_weight",
      name: "Package Weight",
      filter: {
        from: {
          weight: null,
          unit: null,
          valid: false,
        },
        to: {
          weight: null,
          unit: null,
          valid: false,
        },
      },
      sort: 0,
      id: "_id",
      loadMoreButton: false,
    },
  ],
  limit: 15,
  populate: ["client_id", "warehouse"],
};

function filterReducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        columns: state?.columns.map((x) =>
          x?.key === action?.payload?.key ? { ...x, ...action.payload } : x
        ),
      };
    case "SET_DIMENSION_FILTER":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key == "dimension"
            ? {
                ...col,
                filter: action.payload.filters,
                sort: action.payload.sort,
              }
            : col
        ),
      };
    case "SET_WEIGHT_FILTER":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key == "weight"
            ? {
                ...col,
                filter: action.payload.filters,
                sort: action.payload.sort,
              }
            : col
        ),
      };
    case "SET_PACKAGE_WEIGHT_FILTER":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key == "package_weight"
            ? {
                ...col,
                filter: action.payload.filters,
                sort: action.payload.sort,
              }
            : col
        ),
      };
    case "SET_OPTIONS":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key === action.payload.key
            ? { ...col, options: action.payload.options }
            : col
        ),
      };
    case "UPDATE_LIMIT":
      return { ...state, limit: action.payload };
    default:
      return state;
  }
}

const WeightPackage = () => {
  const [filterData, dispatch] = useReducer(filterReducer, initialState);
  const [selectedWeightPackage, setSelectedWeightPackage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [weightPackageList, setWeightPackageList] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");

  const fetchWeightPackage = async () => {
    setLoading(true);
    try {
      const weightPackage = await weightPackageService.fetch(
        searchValue,
        clientId || clientFilter?._id,
        buildWeightQuery(filterData, page)
      );
      setWeightPackageList(weightPackage?.data);
      setLoading(false);
      setDataCount(weightPackage?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWeightPackage();
  }, [page, filterData, searchValue, clientFilter, clientId]);

  const handleFilterChange = (newFilter) => {
    dispatch({ type: "SET_FILTER", payload: newFilter });
    setPage(0);
  };
  const handleDimensionFilterChange = (newDimensionFilter) => {
    dispatch({ type: "SET_DIMENSION_FILTER", payload: newDimensionFilter });
    setPage(0);
  };
  const handleWeightFilterChange = (newDimensionFilter) => {
    dispatch({ type: "SET_WEIGHT_FILTER", payload: newDimensionFilter });
    setPage(0);
  };
  const handlePackageWeightFilterChange = (newDimensionFilter) => {
    dispatch({
      type: "SET_PACKAGE_WEIGHT_FILTER",
      payload: newDimensionFilter,
    });
    setPage(0);
  };
  return (
    <DashboardContainer
      hideUploadButton
      header={"Weight Package"}
      title={"+ Add Weight Package"}
      onClickAddButton={() => setOpenModal(true)}
      setViewMode={setViewMode}
      onSearchData={(x) => {
        if (x.trim()) {
          setSearchValue(x);
          setPage(0);
        } else {
          setSearchValue("");
        }
      }}
      onSelectClient={(e) => setClientFilter(e)}
    >
      <WeightPackageModal
        selectedWeightPackage={selectedWeightPackage}
        handleClose={() => {
          setOpenModal(false);
          setSelectedWeightPackage(null);
        }}
        open={openModal}
        onAddData={fetchWeightPackage}
      />
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <WeightPackageListView
        dataCount={dataCount}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        onDeleteData={fetchWeightPackage}
        onEditData={(e) => {
          setSelectedWeightPackage(e);
          setOpenModal(true);
        }}
        data={weightPackageList}
        viewMode={viewMode}
        selectedWeightPackage={selectedWeightPackage}
        setSelectedWeightPackage={setSelectedWeightPackage}
        onChangeFilter={handleFilterChange}
        onChangeDimensionFilter={handleDimensionFilterChange}
        filterData={filterData}
        onChangeWeightFilter={handleWeightFilterChange}
        onChangePackageWeightFilter={handlePackageWeightFilterChange}
      />
    </DashboardContainer>
  );
};

export default WeightPackage;
