import axiosInstance from "../axiosInstance";
export const notificationService = {
  fetch: async (client_id, query = "", signal) => {
    try {
      let query_obj = `$sort[createdAt]=-1&${query}`;
      if (client_id) {
        query_obj += `&client_id=${client_id}`;
      }
      let response = await axiosInstance.get(
        `/notification?${query_obj}`,
        signal
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
