import { Box, Button, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/styles";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../../components/context/context";
import CreateASN from "../../components/create-asn";
import DashboardContainer from "../../components/dashboard-container";
import ProductUploadModal from "../../components/file-upload-modal";
import ProductList from "../../components/product-list";
import UploadStatusChartDialog from "../../components/upload-status";
import CustomTypography from "../../globalStyles/fonts";
import ASNIcon from "../../globalStyles/icons/asn-icon";
import ImportIcon from "../../globalStyles/icons/importIcon";
import UploadIcon from "../../globalStyles/icons/upload-icon";
import { isMobile } from "../../globalStyles/mobile";
import { productFilterInitialState } from "../../utils/filter-config";
import buildQuery from "../../utils/query-builder";
import { productService } from "../../utils/services/productService";

function filterReducer(state, action) {
  console.log(action);

  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        columns: state?.columns.map((x) =>
          x?.key === action?.payload?.key ? { ...x, ...action.payload } : x
        ),
      };
    case "SET_OPTIONS":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key === action.payload.key
            ? { ...col, options: action.payload.options }
            : col
        ),
      };
    case "UPDATE_LIMIT":
      return { ...state, limit: action.payload };

    default:
      return state;
  }
}

export default function Product() {
  const [filterData, dispatch] = useReducer(
    filterReducer,
    productFilterInitialState
  );
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [dataCount, setDataCount] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [clientFilter, setClientFilter] = useState(null);
  const [viewMode, setViewMode] = useState("list");
  const clientId = localStorage.getItem("client_id");
  const { user } = useContext(AppContext);
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const [showASNModal, setShowASNModal] = useState(false);
  const [uploadStatusData, setUploadStatusData] = useState([]);
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;
  const [fetchData, setFetchData] = useState(0);
  const fetchProductData = async (signal) => {
    setLoading(true);
    try {
      const response = await productService.fetch(
        clientId ? clientId : clientFilter?._id,
        searchValue,
        buildQuery(filterData, page),
        { signal }
      );

      setProductData(response.data);
      setDataCount(response.total);
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchProductData(signal);
    return () => {
      controller.abort();
    };
  }, [filterData, searchValue, page, clientFilter, fetchData]);

  const syncProductData = async () => {
    setLoading(true);
    try {
      const response = await productService.syncProduct({
        client_id: clientId,
        core: true,
      });
      console.log(response);
      toast.success("Product import has been successfully initiated!");
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (newFilter) => {
    dispatch({ type: "SET_FILTER", payload: newFilter });
    setPage(0);
  };

  return (
    <>
      <DashboardContainer
        searchBarPlaceholder={"Search by Product Name"}
        onChangeFilter={handleFilterChange}
        filterData={filterData}
        fields={filterData.columns}
        hideUploadButton
        headerButtons={
          <>
            {user?.role === "client" ? (
              isMobile() ? (
                <Box sx={{ display: "flex" }}>
                  <IconButton onClick={syncProductData}>
                    <ImportIcon fill={primary_color} />
                  </IconButton>

                  <IconButton
                    style={{
                      color: primary_color,
                    }}
                    onClick={() => setShowASNModal(true)}
                  >
                    <ASNIcon fill={primary_color} />
                  </IconButton>

                  <IconButton onClick={() => setOpenUploadModal(true)}>
                    <UploadIcon fill={primary_color} />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap={1}
                  justifyContent="flex-start"
                >
                  <Button
                    size="small"
                    style={{
                      textTransform: "none",
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={syncProductData}
                  >
                    <ImportIcon fill={primary_color} />
                    <CustomTypography
                      fontSize="14px"
                      fontWeight={500}
                      color={"primary"}
                    >
                      Import
                    </CustomTypography>
                  </Button>

                  <Button
                    size="small"
                    style={{
                      textTransform: "none",
                    }}
                    color="primary"
                    variant="outlined"
                    onClick={() => setShowASNModal(true)}
                  >
                    <ASNIcon fill={primary_color} />
                    <CustomTypography
                      fontSize="14px"
                      fontWeight={500}
                      color={"primary"}
                    >
                      ASN
                    </CustomTypography>
                  </Button>

                  <Button
                    size="small"
                    style={{
                      textTransform: "none",
                    }}
                    variant="contained"
                    onClick={() => setOpenUploadModal(true)}
                  >
                    <UploadIcon fill="#fff" />
                    <CustomTypography
                      fontSize="14px"
                      fontWeight={500}
                      color="#FFFFFF"
                    >
                      Upload
                    </CustomTypography>
                  </Button>
                </Box>
              )
            ) : null}
          </>
        }
        showUploadStatus
        onSearchData={(x) => {
          // Check if there’s a search term
          if (x.trim()) {
            setSearchValue(x);
            setPage(0); // Reset to page 0 when there’s a search term
          } else {
            setSearchValue(""); // Clear the search value if empty search term
          }
        }}
        onClickAddButton={() => {
          // localStorage.setItem('path', `/manage-product/create`)
          navigate("/manage-product/create");
        }}
        header={"Product List"}
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        selectedClient={clientFilter?.name}
        onHandleUploadButton={() => setOpenUploadModal(true)}
        product
        title={"+ Product"}
        setViewMode={setViewMode}
        onClickRefresh={() => setFetchData(fetchData + 1)}
        onClickSyncProduct={syncProductData}
        onClickCreateASN={() => {
          setShowASNModal(true);
        }}
      >
        <CreateASN
          openDialog={showASNModal}
          handleCloseDialog={() => setShowASNModal(false)}
        ></CreateASN>
        {/* <ImportProductModal
          open={showImportModal}
          setOpen={() => setShowImportModal(!showImportModal)}
          selectedOption={selectedOption}
          setSelectedOption={(e) => setSelectedOption(e)}
          handleConfirm={importProduct}
          handleDiscardAll={discardAll}
        /> */}
        <ProductUploadModal
          onClickStatus={() => setShowUploadStatus(true)}
          showUploadStatus
          onSuccessUpload={() => setFetchData(fetchData + 1)}
          product
          onSubmitUploadData={() => setOpenUploadModal(false)}
          onClose={() => setOpenUploadModal(false)}
          open={openUploadModal}
          title={"Product List"}
          selectedData={uploadStatusData}
        ></ProductUploadModal>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <ProductList
          data={productData}
          onEditData={() => setFetchData(fetchData + 1)}
          onDeleteData={() => setFetchData(fetchData + 1)}
          client={clientId}
          viewMode={viewMode}
          //setFilter={setFilter}
          deleteTitle={"Delete Product"}
          dataCount={dataCount}
          page={page}
          rowsPerPage={filterData.limit}
          setRowsPerPage={(e) =>
            dispatch({
              type: "UPDATE_LIMIT",
              payload: e,
            })
          }
          setPage={setPage}
          onChangeFilter={handleFilterChange}
          filterData={filterData}
        />
        <UploadStatusChartDialog
          onClose={() => setShowUploadStatus(false)}
          open={showUploadStatus}
        ></UploadStatusChartDialog>
      </DashboardContainer>
    </>
  );
}
