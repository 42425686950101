import React, { useEffect, useState, useContext } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { zoneService } from "../../utils/services/zoneService";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import AppContext from "../../components/context/context";
import CustomPagination from "../../components/pagination";
import ZoneModal from "../../components/zone-modal";
import ZonetList from "../../components/zone-list";

export default function Zone() {
  const [zoneData, setZoneData] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [clientFilter, setClientFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [viewMode, setViewMode] = useState("list");

  const clientId = localStorage.getItem("client_id");
  const { user } = useContext(AppContext);

  const fetchZoneData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await zoneService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      );
      setZoneData(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchZoneData();
  }, [page, rowsPerPage, clientFilter, searchValue]);

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_zone.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );

  return (
    <DashboardContainer
      searchBarPlaceholder={"Search by Zone Name"}
      onSearchData={(p) => {
        setSearchValue(p);
        setPage(0);
      }}
      onClickAddButton={() => {
        setOpenModal(true);
      }}
      hideUploadButton={true}
      header={"Zone List"}
      onSelectClient={(e) => {
        setClientFilter(e);
      }}
      selectedClient={selectedClient?.name}
      title={" + Add Zone"}
      setViewMode={setViewMode}
      hideFilterClient
    >
      <ZoneModal
        selectedZone={selectedZone}
        handleClose={() => {
          setOpenModal(false);
          setSelectedZone(null);
        }}
        open={openModal}
        onAddData={fetchZoneData}
      />
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {zoneData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <NoDataImage />
        </div>
      ) : (
        <>
          <ZonetList
            onDeleteData={() => fetchZoneData()}
            onEditData={(e) => {
              setSelectedZone(e);
              setOpenModal(true);
            }}
            data={zoneData}
            viewMode={viewMode}
            deleteTitle={"Delete Zone"}
            selectedZone={selectedZone}
            setSelectedZone={setSelectedZone}
            currentPage={page}
            itemsPerPage={rowsPerPage}
          />
        </>
      )}

      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </DashboardContainer>
  );
}
