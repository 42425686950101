import { convertDistance } from "./distanceUnits";
import { convertWeight } from "./weightUnits";

export default function buildWeightQuery(obj, page) {
  let query = [];
  const limit = obj.limit || 15;
  query.push(`$limit=${limit}`);
  const skip = obj.skip || page * limit || 0;
  query.push(`$skip=${skip}`);

  if (obj.columns) {
    obj.columns.forEach((x) => {
      if (x.key === "client_id" && x.filter?.length > 0) {
        x.filter.forEach((value) => {
          query.push(`client_id[$in][]=${value}`);
        });
      } else if (
        x.key === "dimension" &&
        x.filter &&
        x.filter.from.valid &&
        x.filter.to.valid
      ) {
        if (x.filter.from.height) {
          query.push(
            `dimension.height_inch[$gte]=${convertDistance(
              x.filter.from.unit,
              "in",
              x.filter.from.height
            )}`
          );
        }
        if (x.filter.from.width) {
          query.push(
            `dimension.width_inch[$gte]=${convertDistance(
              x.filter.from.unit,
              "in",
              x.filter.from.width
            )}`
          );
        }
        if (x.filter.from.length) {
          query.push(
            `dimension.length_inch[$gte]=${convertDistance(
              x.filter.from.unit,
              "in",
              x.filter.from.length
            )}`
          );
        }
        if (x.filter.to.height) {
          query.push(
            `dimension.height_inch[$lte]=${convertDistance(
              x.filter.to.unit,
              "in",
              x.filter.to.height
            )}`
          );
        }
        if (x.filter.to.length) {
          query.push(
            `dimension.length_inch[$lte]=${convertDistance(
              x.filter.to.unit,
              "in",
              x.filter.to.length
            )}`
          );
        }
        if (x.filter.to.width) {
          query.push(
            `dimension.width_inch[$lte]=${convertDistance(
              x.filter.to.unit,
              "in",
              x.filter.to.width
            )}`
          );
        }
      } else if (
        x.key === "weight" &&
        x.filter &&
        x.filter.from.valid &&
        x.filter.to.valid
      ) {
        if (x.filter.from.min_weight) {
          query.push(
            `min_weight_kg[$gte]=${convertWeight(
              x.filter.from.unit,
              "kg",
              x.filter.from.min_weight
            )}`
          );
        }
        if (x.filter.from.max_weight) {
          query.push(
            `max_weight_kg[$gte]=${convertWeight(
              x.filter.from.unit,
              "kg",
              x.filter.from.max_weight
            )}`
          );
        }

        if (x.filter.to.min_weight) {
          query.push(
            `min_weight_kg[$lte]=${convertWeight(
              x.filter.to.unit,
              "kg",
              x.filter.to.min_weight
            )}`
          );
        }
        if (x.filter.to.max_weight) {
          query.push(
            `max_weight_kg[$lte]=${convertWeight(
              x.filter.to.unit,
              "kg",
              x.filter.to.max_weight
            )}`
          );
        }
      } else if (
        x.key === "package_weight" &&
        x.filter &&
        x.filter.from.valid &&
        x.filter.to.valid
      ) {
        if (x.filter.from.weight) {
          query.push(
            `pkg_weight_kg[$gte]=${convertWeight(
              x.filter.from.unit,
              "kg",
              x.filter.from.weight
            )}`
          );
        }

        if (x.filter.to.weight) {
          query.push(
            `pkg_weight_kg[$lte]=${convertWeight(
              x.filter.to.unit,
              "kg",
              x.filter.to.weight
            )}`
          );
        }
      }

      if (x.sort) {
        query.push(`$sort[${x.key}]=${x.sort}`);
      }
    });
  }

  if (obj.populate) {
    obj.populate.forEach((x) => {
      query.push(`$populate=${x}`);
    });
  }

  return query.join("&");
}
