import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import { weightUnits } from "../../utils/weightUnits";
import { distanceUnits } from "../../utils/distanceUnits";

const InputWithUnit = ({
  onChangeText,
  onChangeUnit,
  inputValue,
  fieldName,
  numberType,
  unitType,
  unitValue,
  errorMessage,
  unitError,
  requiredField,
  hideUnit,
}) => {
  const [unitData, setUnitData] = useState([]);

  useEffect(() => {
    if (unitType.toLowerCase() === "weight") {
      return setUnitData(weightUnits);
    }
    if (unitType.toLowerCase() === "distance") {
      return setUnitData(distanceUnits);
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Typography
          fontSize="14px"
          fontWeight={400}
          textAlign={"left"}
          fontFamily="poppins"
        >
          {fieldName}{" "}
          {requiredField ? <span style={{ color: "red" }}>*</span> : null}
        </Typography>
        <TextField
          sx={{ backgroundColor: "#FFF" }}
          size="small"
          placeholder={`Enter ${fieldName}`}
          type={numberType ? "number" : "text"}
          value={inputValue}
          onChange={(e) => onChangeText(e.target.value)}
          fullWidth
          margin="dense"
          inputProps={{ min: 0 }}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </div>

      {hideUnit ? (
        <></>
      ) : (
        <div style={{ marginLeft: 10 }}>
          <CustomTypography fontSize="14px" fontWeight={400}>
            {`Unit`}{" "}
            {requiredField ? <span style={{ color: "red" }}>*</span> : null}
          </CustomTypography>
          <FormControl
            sx={{ minWidth: 70, backgroundColor: "#FFF" }}
            fullWidth
            size="small"
            margin="dense"
            error={!!unitError}
          >
            <Select
              value={unitValue}
              onChange={(e) => onChangeUnit(e.target.value)}
            >
              {unitData.map((x) => (
                <MenuItem key={x.abbreviation} value={x.abbreviation}>
                  {x.abbreviation}
                </MenuItem>
              ))}
            </Select>
            {unitError && <FormHelperText>{unitError}</FormHelperText>}
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default InputWithUnit;
