import React, { useState, useEffect, useContext, useRef } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Button from '@mui/material/Button/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AppContext from '../context/context'
import { productService } from '../../utils/services/productService'
import config from '../../config'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UpIcon from '@mui/icons-material/ArrowUpwardOutlined'
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Popover,
  Icon,
  Badge
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import 'react-quill/dist/quill.snow.css'
import Checkbox from '@mui/material/Checkbox'
import CustomTypography from '../../globalStyles/fonts'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import ProductDetailsModal from '../productViewDetailsModal'
import { isMobile } from '../../globalStyles/mobile'
import ASNDataModal from '../ASN-data-modal'
import { useNavigate } from 'react-router-dom'
import NotStarted from '../../globalStyles/icons/not-started-icon'
import Started from '../../globalStyles/icons/started-icon'
import InProgress from '../../globalStyles/icons/in-progress-icon'
import Compliant from '../../globalStyles/icons/compliant-icon'
import CustomPagination from '../pagination'
import SortingIcon from '../../globalStyles/icons/sorting-icon'
import { styled } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FilterModal from '../filterModal'
import DownIcon from '@mui/icons-material/ArrowDownwardOutlined'
import { useTheme } from '@mui/styles'
import ViewIcon from '../../globalStyles/icons/vieewIcon'
import DeleteIcon from '../../globalStyles/icons/deleteIcon'
import EditIcon from '../../globalStyles/icons/editIcon'
const defaultImage = '/product.png'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    color: theme.palette.common.white,
    padding: '6px 6px'
    // textAlign: "center",
    // verticalAlign: "middle",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '4px 4px'
    // textAlign: "center",
    // verticalAlign: "middle",
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },

  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))

const ProductTableCell = React.memo(({ product, handleViewClick, handleEditClick, handleDeleteClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleActionClick = (action) => {
    handlePopoverClose() // Close the popover before executing the action
    switch (action) {
      case 'view':
        handleViewClick(product) // Pass `product` directly
        break
      case 'edit':
        handleEditClick(product)
        break
      case 'delete':
        handleDeleteClick(product)
        break
      default:
        break
    }
  }

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? 'primary.main' : 'inherit'
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: isMobile() ? 'bottom' : 'top',
          horizontal: isMobile() ? 'center' : 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableRestoreFocus={true}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
          <Button
            onClick={() => handleActionClick('view')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <ViewIcon />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                View
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick('edit')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <EditIcon />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                Edit
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick('delete')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <DeleteIcon />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                Delete
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
})

const ProductList = ({
  data,
  onDeleteData,
  viewMode,
  filter,
  setFilter,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  onChangeFilter,
  filterData
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [openViewModal, setOpenViewModal] = useState(false)
  const [openASNDataModal, setOpenASNDataModal] = useState(false)
  const { user } = useContext(AppContext)
  const navigate = useNavigate()
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const secondary_color = theme.palette?.secondary?.main || defaultColor
  const [productData, setProductData] = useState([])
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: '',
    name: '',
    filter: [],
    sort: 0,
    id: '_id',
    displayKey: 'name',
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: ''
  })

  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget)
    setOpenFilterModal(true)
    setSelectedFilterData(filterData.columns.find((x) => x.key == filterKey))
  }

  const handleSaveFilter = (selectedOptions, filterKey, sortOrder, searchKey) => {
    if (filterKey.toLowerCase() === 'no_of_sku') {
      if (selectedOptions?.includes('All')) {
        return onChangeFilter({
          key: filterKey,
          filter: selectedOptions?.filter((x) => x !== 'All'),
          sort: sortOrder,
          searchKey: searchKey
        })
      } else {
        onChangeFilter({
          key: filterKey,
          filter: selectedOptions,
          sort: sortOrder,
          searchKey: searchKey
        })
      }
    } else {
      onChangeFilter({
        key: filterKey,
        filter: selectedOptions,
        sort: sortOrder,
        searchKey: searchKey
      })
    }
    handleCloseFilter()
  }

  const handleCloseFilter = () => {
    setAnchorElFilter(null)
    setOpenFilterModal(false)
  }

  const handleViewClick = (product) => {
    setSelectedProduct(product)
    setOpenViewModal(true)
  }
  const handleEditClick = (product) => {
    localStorage.setItem('path', `/manage-product/${product?._id}`)
    navigate(`/manage-product/${product?._id}`)
  }

  const handleDeleteClick = (product) => {
    setSelectedProduct(product)
    setOpenConfirmation(true) // Open confirmation dialog before deleting carrier
  }

  useEffect(() => {
    setProductData(
      data?.map((product) => ({
        ...product,
        action: (
          <ProductTableCell
            product={product}
            handleViewClick={handleViewClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])

  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await productService.delete(selectedProduct._id)
      onDeleteData && onDeleteData()
      toast.success('Product Deleted successfully!')
      setAnchorEl(null)
      setOpenConfirmation(false)
      setLoading(false)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`
    }
    return defaultImage
  }
  const fetchProductImage = (images) => {
    if (images.length) {
      const featureImage = images?.find((img) => img.feature_image)
      if (featureImage && featureImage.image) {
        return `${config.baseURL}/fetch-file/${featureImage.image}`
      } else {
        return `${config.baseURL}/fetch-file/${images[0].image}`
      }
    }
    return defaultImage
  }
  function stripHtmlTags(html) {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    const plainText = tempDiv.textContent || tempDiv.innerText || ''
    return plainText.replace(/\s\s+/g, ' ').trim()
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedItems(productData.map((product) => product._id))
    } else {
      setSelectedItems([])
    }
  }

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1))
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1))
    } else {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      )
    }

    setSelectedItems(newSelected)
  }
  const handleDelete = async () => {
    try {
      const response = await productService.deleteMultipleProduct({
        ids: selectedItems
      })
      if (response) {
        onDeleteData(selectedItems)
        setSelectedItems([])
      }
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }

  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '300px' }}
      />
    </div>
  )
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: '1px',
        maxHeight: '100%',
        paddingBottom: 5,
        overflow: 'auto'
      }}
      container
      spacing={2}
    >
      {productData?.map((product, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              position: 'relative',
              borderRadius: '6px',
              border: '1px solid #D0D5DD'
            }}
          >
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 10
                }}
              >
                <img
                  src={fetchImage(product.images.find((img) => img.feature_image) || {})}
                  alt={product.name}
                  style={{
                    width: 60,
                    height: 60,
                    objectFit: 'cover',
                    borderRadius: '50%',
                    border: '2px solid #18E7CA',
                    padding: 1
                  }}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10
                }}
              >
                {product?.action}
              </div>

              <Grid container spacing={1}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Name
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'name')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'name')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={product?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {product?.name}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Category
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'product_type')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'product_type')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={stripHtmlTags(product?.product_type)} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {product?.product_type}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Supplier
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'vendor')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'vendor')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={product?.vendor?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {product?.vendor?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    SKU
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'no_of_sku')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'no_of_sku')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={product?.no_of_sku} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {product?.no_of_sku}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Compliance
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'compliance_status')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'compliance_status')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={product?.compliance_status} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {product?.compliance_status}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Grid item xs={5} display={'flex'} alignContent={'center '}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Status
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'status')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'status')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={product?.status} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {product?.status}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((product, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: '#ffffff',
                borderRadius: 4,
                position: 'relative'
              }}
            >
              <CardContent>
                <Box position="absolute" top={2} right={5}>
                  {product?.action}
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Checkbox
                      checked={selectedItems.indexOf(product._id) !== -1}
                      onChange={(event) => handleCheckboxClick(event, product._id)}
                    />
                  </Box>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={fetchProductImage(product.images)}
                      alt={product.name}
                      style={{
                        width: 80,
                        height: 90,
                        objectFit: 'contain',
                        marginBottom: 20
                      }}
                    />
                    <div style={{ width: '70%' }}>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Box
                          display="flex"
                          alignItems="center"
                          mb={1}
                          sx={{
                            maxWidth: '90%'
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            variant="h6"
                          >
                            {product?.name}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={1}>
                          <Typography>
                            Status :{' '}
                            <Chip
                              label={product.status}
                              sx={{
                                padding: '2px 4px',
                                borderRadius: '4px',
                                backgroundColor:
                                  product.status.toLowerCase() === 'active' ? '#CBFFD3' : '#E1E1E1'
                              }}
                            />
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mb={1}
                          sx={{
                            maxWidth: '80%'
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            Supplier : {product.vendor?.name}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Box>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    )
  }
  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        // paddingBottom: 100,
        paddingBottom: 20
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isMobile() ? 'column' : 'row'
        }}
      >
        <div style={{ width: '100%' }}></div>
        <Box sx={{ mt: 1 }}>
          {selectedItems.length > 0 && (
            <Box sx={{ display: 'flex', gap: 2, mb: 2, mr: 1 }}>
              <Button
                onClick={() => setSelectedItems([])}
                style={{
                  borderWidth: 1,
                  width: 100,
                  color: '#333333',
                  textTransform: 'none'
                }}
                variant="outlined"
                color="primary"
              >
                <CustomTypography
                  fontSize={14}
                  fontWeight={600}
                  style={{ color: (theme) => theme.palette.text.main }}
                >
                  Cancel
                </CustomTypography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDelete}
                style={{ textTransform: 'none', width: 100 }}
              >
                <CustomTypography
                  fontSize={14}
                  fontWeight={600}
                  style={{ color: (theme) => theme.palette.text.main }}
                >
                  Delete
                </CustomTypography>
              </Button>
            </Box>
          )}
        </Box>
      </div>

      {isMobile() ? (
        <MobileCardView data={productData}></MobileCardView>
      ) : (
        <TableContainer
          sx={{
            // maxHeight: "70vh",
            overflowY: 'auto',
            paddingInline: 0.4,
            maxHeight: '100%',
            paddingBottom: selectedItems ? 7 : 0
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: '1px solid #EAECF0'
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Checkbox
                    indeterminate={selectedItems.length > 0 && selectedItems.length < productData.length}
                    checked={productData.length > 0 && selectedItems.length === productData.length}
                    onChange={handleSelectAllClick}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'name')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'name')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}

                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      NAME
                    </CustomTypography>

                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'name')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'name')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'product_type')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'product_type')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      CATEGORY
                    </CustomTypography>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'category')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'product_type')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'status')?.sort === 1 ? (
                      <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'status')?.sort === -1 ? (
                      <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      STATUS
                    </CustomTypography>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'status')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'status')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'vendor')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'vendor')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      SUPPLIER
                    </CustomTypography>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'supplier')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'vendor')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      No of SKU's
                    </CustomTypography>
                    {filterData.columns.find((x) => x.key === 'no_of_sku')?.sort === 1 ? (
                      <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'no_of_sku')?.sort === -1 ? (
                      <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'no of sku')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'no_of_sku')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {' '}
                    {filterData.columns.find((x) => x.key === 'compliance_status')?.sort === 1 ? (
                      <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'compliance_status')?.sort === -1 ? (
                      <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      COMPLIANCE STATUS
                    </CustomTypography>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'compliance status')
                          ?.filter?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'compliance_status')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {productData.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                productData?.map((product, index) => (
                  // <TableRow
                  //   key={index}
                  //   style={{
                  //     marginBottom: 10,
                  //     backgroundColor: product.variants.find((x) => !x.sku)
                  //       ? "#a61c1c"
                  //       : "#fff",
                  //     borderRadius: 8,
                  //     boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  //     border: "none",
                  //     padding: 0,
                  //     "&:lastChild td, &:lastChild th": {
                  //       border: "none",
                  //     },
                  //   }}
                  // >
                  <StyledTableRow>
                    <StyledTableCell>
                      <Checkbox
                        checked={selectedItems.indexOf(product._id) !== -1}
                        onChange={(event) => handleCheckboxClick(event, product._id)}
                        sx={{ ml: 0.5 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'left',
                          alignItems: 'center',
                          maxWidth: 250
                        }}
                      >
                        <img
                          src={fetchProductImage(product.images)}
                          alt={product.name}
                          style={{
                            width: 30,
                            height: 30,
                            objectFit: 'cover',
                            marginRight: 10,
                            borderRadius: '4px'
                          }}
                        />
                        <Typography
                          style={{
                            fontSize: '12px',
                            color: '#667085',
                            fontWeight: 400,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontFamily: 'poppins'
                          }}
                        >
                          {product.name}
                        </Typography>
                      </div>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'poppins'}
                        textAlign={'left'}
                      >
                        {product.product_type || ''}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {product.status === 'active' || product.status === 'Active' ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src="/Dot.png" alt="dot" style={{ marginRight: '8px' }} />
                          <CustomTypography fontSize="12px" color="#007613" fontWeight={400}>
                            {product.status}
                          </CustomTypography>
                        </div>
                      ) : (
                        <CustomTypography fontSize="12px" color="#626262" fontWeight={400}>
                          <span
                            style={{
                              // backgroundColor: "#E1E1E1",
                              padding: '5px 10px',
                              // borderColor: "#747474",
                              display: 'inline-block'
                              // borderWidth: 2,
                            }}
                          >
                            {product.status}
                          </span>
                        </CustomTypography>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <CustomTypography fontSize="12px" color="#667085" fontWeight={400}>
                        {product.vendor?.name}
                      </CustomTypography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="##667085"
                        fontWeight={400}
                        fontFamily={'poppins'}
                        textAlign={'left'}
                      >
                        {product.no_of_sku}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: 5
                        }}
                      >
                        {product.compliance_status.toLowerCase() === 'not started' ? (
                          <NotStarted></NotStarted>
                        ) : product.compliance_status.toLowerCase() === 'started' ? (
                          <Started></Started>
                        ) : product.compliance_status.toLowerCase() === 'in progress' ? (
                          <InProgress></InProgress>
                        ) : product.compliance_status.toLowerCase() === 'compliant' ? (
                          <Compliant></Compliant>
                        ) : (
                          <></>
                        )}
                        <CustomTypography
                          style={{ marginLeft: 2 }}
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                        >
                          {product.compliance_status}
                        </CustomTypography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                      {product?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )

  return (
    <div sx={{ minWidth: '100%' }}>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      <div
        style={{
          overflowY: 'scroll',
          display: 'flex',
          maxHeight: isMobile() ? '60vh' : `calc(100vh - 270px)`,
          width: '100%',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::WebkitScrollbar': { width: 0 }
        }}
      >
        {viewMode === 'grid' ? renderGridView() : renderListView()}
      </div>

      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={filterData.limit}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        disableNext={dataCount <= (page + 1) * filterData.limit}
      />

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete  ${selectedProduct?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          // if (client) {
          //   handleDeleteProduct && handleDeleteProduct(selectedProduct);
          //   setOpenConfirmation(false);
          // } else {
          confirmDelete(e)
          // }
        }}
      />
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableScrollLock={true}
      >
        <FilterModal
          open={openFilterModal}
          onClose={handleCloseFilter}
          options={selectedfilterData?.options}
          selectedValues={selectedfilterData?.filter}
          onSave={handleSaveFilter}
          currentSortOrder={selectedfilterData?.sort}
          filterKey={selectedfilterData?.key}
          hasMore={selectedfilterData?.loadMoreButton}
          displayKey={selectedfilterData?.displayKey}
          id={selectedfilterData?.id}
          fetchApi={selectedfilterData?.fetchApi}
          searchKey={selectedfilterData?.searchKey}
        />
      </Popover>

      <ProductDetailsModal
        product={selectedProduct}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
      <ASNDataModal
        selectedProduct={selectedProduct}
        handleClose={() => setOpenASNDataModal(false)}
        open={openASNDataModal}
      ></ASNDataModal>
    </div>
  )
}

export default ProductList
