import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Autocomplete,
  InputLabel,
  Tooltip,
  InputBase,
  Box,
  Typography,
  Divider,
  InputAdornment
} from '@mui/material'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import { Close as CloseIcon, Add as AddIcon } from '@mui/icons-material'
import debounce from 'lodash.debounce'
import { wmsService } from '../../utils/services/wmsProvider'
import { productService } from '../../utils/services/productService'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import config from '../../config'
import axios from 'axios'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import DeleteIcon from '../../globalStyles/icons/deleteIcon'
import ItemAutocomplete from '../skuAutocomplete'

const CreateASN = ({ openDialog, handleCloseDialog, stockData }) => {
  const clientId = localStorage.getItem('client_id')
  const [wmsProviders, setWMSProviders] = useState([])
  const [selectedWMSProvider, setSelectedWMSProvider] = useState(null)
  const [stockinNumber, setStockInNumber] = useState('')
  const [expectedDate, setExpectedDate] = useState(null)

  useEffect(() => {
    if (clientId) fetchWMSProviders()
  }, [clientId])

  const fetchWMSProviders = async () => {
    try {
      const res = await wmsService.fetch(clientId)
      setWMSProviders(res.data)
    } catch (e) {
      console.error(e)
    }
  }

  const initalState = [
    {
      ...stockData,
      variant: null,
      PKG: '',
      InnerPKG: '',
      Unit: '',
      InnerUnit: ''
    }
  ]
  const [rows, setRows] = useState(initalState)

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        ...stockData,
        variant: null,
        PKG: '',
        InnerPKG: '',
        Unit: '',
        InnerUnit: ''
      }
    ])
  }

  const validateForm = () => {
    if (!stockinNumber || !selectedWMSProvider || !expectedDate) {
      return false
    }
    return true
  }
  const handleAddOrUpdateStock = async () => {
    try {
      if (!validateForm()) {
        toast.warning('Please fill all the fields')
        return
      }
      await axios.post(`${config.warehouseBaseURL}/stock-in`, {
        client_id: clientId,
        stockData: {
          stockInData: rows.map((x) => ({
            PKG: x.PKG,
            InnerPKG: x.InnerPKG,
            Unit: x.Unit,
            InnerUnit: x.InnerUnit,
            ItemNumber: x.variant.variants.sku,
            product: x.variant._id,
            Description: x.variant.description,
            UnitCurrency: x.variant.currency || 'USD',
            UnitPrice: x.variant.variants.price,
            client_id: clientId
          })),
          stockInNo: stockinNumber,
          expectedDeliveryDate: expectedDate
        },
        provider: selectedWMSProvider.provider._id
      })
      toast.success('ASN created successfully')
      handleClose()
    } catch (e) {
      console.error(e)
      toast.error('Failed to add stock')
    }
  }

  const handleRowChange = (index, name, value) => {
    const newRows = [...rows]
    if (newRows[index]) {
      newRows[index][name] = value
      setRows(newRows)
    }
  }

  const handleRemoveRow = (index) => {
    const newRows = [...rows]
    newRows.splice(index, 1)
    setRows(newRows)
  }
  const datePickerRef = useRef(null)
  const [skuList, setSKUList] = useState([])
  const [skuSearchValue, setskuSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(null)
  const [showCalendar, setShowCalendar] = useState(false)

  const calendarRef = useRef(null)

  // const fetchSKU = useCallback(
  //   debounce(async (searchQuery, pageNumber) => {
  //     setLoading(true);
  //     try {
  //       const response = await productService.fetchSKU(
  //         clientId,
  //         searchQuery,
  //         10,
  //         pageNumber
  //       );
  //       if (pageNumber === 0) {
  //         setSKUList(response.data);
  //       } else {
  //         setSKUList((prevSuppliers) => [...prevSuppliers, ...response.data]);
  //       }
  //       setTotal(response.data.length || 0);
  //     } catch (error) {
  //       console.error("Fetch failed:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }, 300),
  //   [clientId]
  // );
  const toggleCalendar = () => setShowCalendar((prev) => !prev)

  // useEffect(() => {
  //   fetchSKU(skuSearchValue, page);
  // }, [skuSearchValue, page, fetchSKU]);
  // useEffect(() => {
  //   if (skuSearchValue === "") {
  //     fetchSKU("", 0);
  //   }
  // }, [skuSearchValue, fetchSKU]);

  const handleSupplierChange = (index, value) => {
    if (rows[index]) {
      // Check if row exists
      handleRowChange(index, 'variant', value)
    }
    setskuSearchValue('') // Reset search value if needed
  }
  // const handleSearchChange = (event) => {
  //   const value = event.target.value;
  //   setskuSearchValue(value);
  //   setPage(0);
  //   setSKUList([]);
  // };
  const autocompleteRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false)
      }
    }

    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [showCalendar])
  const handleClose = () => {
    setSelectedWMSProvider(null)
    setStockInNumber('')
    setExpectedDate(null)
    setRows(initalState)
    handleCloseDialog && handleCloseDialog()
  }
  // const handleLoadMoreClick = (event, value, index) => {
  //   if (value?.loadMore) {
  //     if (total == 10 && !loading) {
  //       setPage((prevPage) => prevPage + 1);
  //       autocompleteRef.current.focus();
  //     }
  //   } else {
  //     return handleSupplierChange(index, value);
  //   }
  // };

  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'inherit'
        }}
      >
        <Typography fontFamily={'poppins'} fontWeight={500} fontSize={'20px'} color={'#333333'}>
          Create ASN
        </Typography>
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent onScroll={() => setShowCalendar(false)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              size="small"
              name="StockIn Number"
              label="StockIn Number"
              value={stockinNumber}
              onChange={(e) => setStockInNumber(e.target.value)}
              inputProps={{ min: 0 }}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              fullWidth
              size="small"
              options={wmsProviders}
              getOptionLabel={(option) => option.provider.name || ''}
              value={selectedWMSProvider}
              onChange={(event, newValue) => setSelectedWMSProvider(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select WMS Provider"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              size="small"
              name="Expected Arrival Date"
              label="Expected Arrival Date"
              value={
                expectedDate
                  ? dayjs(expectedDate).format('YYYY-MM-DD')
                  : new Date().toISOString().split('T')[0]
              }
              onFocus={toggleCalendar}
              inputProps={{ min: 0, readOnly: true }}
              fullWidth
              margin="dense"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={toggleCalendar}>
                      <CalendarIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>

        {showCalendar && (
          <div
            ref={calendarRef}
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 3000,
              backgroundColor: 'white',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
              padding: '0.5rem'
            }}
          >
            <Calendar
              value={new Date(expectedDate)}
              onChange={(e) => {
                setExpectedDate(dayjs(e).format('YYYY-MM-DD'))
                setShowCalendar(false)
              }}
              minDate={new Date()}
              tileClassName="custom-calendar-tile"
              className="custom-calendar"
            />
          </div>
        )}
        {rows.map((row, index) => (
          <Box
            key={row._id}
            sx={{
              backgroundColor: '#F6F6F6',
              borderRadius: '6px',
              padding: 1,
              marginBottom: 2,
              marginTop: 2
            }}
          >
            <Grid container spacing={2} alignItems="center" key={`${row.variant}+${index}`}>
              {/* <Grid
          gap={2}
              container
              //spacing={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                marginTop: 1,
                borderRadius: 2,
                backgroundColor: "#E0E0E0",
              }}
              key={`${row.variant}+${index}`}
            > */}
              <Grid item xs={12} sx={3} md={3}>
                {/* <Autocomplete
                  ref={autocompleteRef}
                  disabled={
                    !selectedWMSProvider || !stockinNumber || !expectedDate
                  }
                  size="small"
                  options={[
                    ...skuList,
                    { label: "Load More...", loadMore: true },
                  ]}
                  getOptionLabel={(option) =>
                    option.loadMore
                      ? ""
                      : `${option?.variants?.sku || ""} ${
                          option?.name ? `(${option.name})` : ""
                        }`
                  }
                  value={row.variant || null}
                  onChange={(event, value) => {
                    handleLoadMoreClick(event, value, index);
                    if (value && value.loadMore) {
                      setTimeout(() => {
                        autocompleteRef.current.focus(); // Refocus to open dropdown
                      }, 0);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.loadMore && total === 10 ? (
                        <span style={{ color: "#1976d2", fontWeight: "bold" }}>
                          Load More...
                        </span>
                      ) : loading ? (
                        <CircularProgress sx={{ color: "#151D48" }} size={30} />
                      ) : (
                        <Tooltip title={option.name || ""}>
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }}
                          >
                            {`${option?.variants?.sku || ""} ${
                              option?.name ? `(${option.name})` : ""
                            }`}
                          </span>
                        </Tooltip>
                      )}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Item Number"
                      variant="outlined"
                      onChange={handleSearchChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      style={{ backgroundColor: "#ffff" }}
                      margin="dense"
                    />
                  )}
                /> */}
                <ItemAutocomplete
                  selectedItem={row.variant}
                  clientId={clientId}
                  onItemChange={(value) => handleSupplierChange(index, value)}
                />
              </Grid>

              <Grid item xs={12} sx={2} md={2}>
                <TextField
                  disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                  size="small"
                  name="PKG"
                  label="PKG"
                  value={row.PKG}
                  onChange={(e) => handleRowChange(index, 'PKG', e.target.value)}
                  type="number"
                  inputProps={{ min: 0 }}
                  fullWidth
                  margin="dense"
                  style={{ backgroundColor: '#ffff' }}
                />
              </Grid>

              <Grid item xs={12} sx={2} md={2}>
                <FormControl
                  disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                  size="small"
                  fullWidth
                  margin="dense"
                  style={{ backgroundColor: '#ffff' }}
                >
                  <InputLabel>Unit</InputLabel>
                  <Select
                    label="Unit"
                    value={row.Unit}
                    onChange={(e) => handleRowChange(index, 'Unit', e.target.value)}
                  >
                    {['CTN', 'PKG'].map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={2} md={2}>
                <TextField
                  disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                  size="small"
                  name="InnerPKG"
                  label="Inner PKG"
                  value={row.InnerPKG}
                  onChange={(e) => handleRowChange(index, 'InnerPKG', e.target.value)}
                  type="number"
                  inputProps={{ min: 0 }}
                  fullWidth
                  margin="dense"
                  style={{ backgroundColor: '#ffff' }}
                />
              </Grid>

              <Grid item xs={12} sx={2} md={2}>
                <FormControl
                  disabled={!selectedWMSProvider || !stockinNumber || !expectedDate}
                  size="small"
                  fullWidth
                  margin="dense"
                  style={{ backgroundColor: '#ffff' }}
                >
                  <InputLabel>Inner Unit</InputLabel>
                  <Select
                    label="Inner Unit"
                    value={row.InnerUnit}
                    onChange={(e) => handleRowChange(index, 'InnerUnit', e.target.value)}
                    size="small"
                  >
                    {['PCS', 'BOX'].map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Action Column for Delete and Add Icon */}
              <Grid
                item
                xs={12}
                sx={1}
                md={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end' // Align to the right
                }}
              >
                {/* Delete Icon - visible when there are multiple rows */}
                {rows.length > 1 && index !== rows.length - 1 && (
                  <IconButton color="secondary" onClick={() => handleRemoveRow(index)} size="large">
                    <DeleteIcon />
                  </IconButton>
                )}

                {/* Add Icon - visible only for the last row */}
                {index === rows.length - 1 && (
                  <IconButton
                    color="primary"
                    onClick={handleAddRow}
                    size="large"
                    style={{
                      backgroundColor: '#DC585C',
                      color: '#fff',
                      border: '1px solid #DC585C',
                      height: 30,
                      width: 30,
                      borderRadius: 4,
                      marginRight: 10
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => handleAddOrUpdateStock(rows)}
          variant="contained"
          color="primary"
          disabled={!validateForm()}
        >
          {'Add Stock'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateASN
