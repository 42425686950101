import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import RateCardModal from "../../components/rate-card-fields";
import { rateCardService } from "../../utils/services/rateCardService";
import RateCardDataTable from "../../components/rate-card-data-table";
import { Box, CircularProgress } from "@mui/material";
import RateCardFileUploadModal from "../../components/file-upload-modal";

export default function RateCard({}) {
  const [rateCardData, setRateCardData] = useState([]);
  const [selectedRateCard, setSelectedRateCard] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [searchData, setSearchData] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const clientId = localStorage.getItem("client_id");

  const fetchRateCardData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await rateCardService.fetch(
        rowsPerPage,
        skip,
        selectedServiceType,
        selectedZone,
        selectedCarrier,
        clientId ? clientId : clientFilter?._id,
        searchData,
        selectedFilter ==="weight"? sortOrder : null,
        selectedFilter==="price"?sortOrder:null,
      );
      setRateCardData(response.data);
      setLoading(false);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };
  const fetchUploadStatus = async () => {
    setLoading(true);
    try {
      const response = await rateCardService.bulkUploadStatus(clientId);
      setUploadStatus(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Upload Status failed:", error);
    }
  };
  const onUploadFile = async (data) => {
    setLoading(true);
    try {
      const response = await rateCardService.bulkUpload(data);
      if (response) {
        fetchUploadStatus();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Upload failed:", error);
    }
  };
  const deleteStaus = async (id) => {
    setLoading(true);
    try {
      const response = await rateCardService.deleteStatus(id);
      fetchUploadStatus();
      setLoading(false);
    } catch (error) {
      console.error("Delete failed:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRateCardData();
  }, [
    selectedZone,
    selectedServiceType,
    selectedCarrier,
    clientFilter,
    page,
    rowsPerPage,
    searchData,
    selectedFilter,
    sortOrder,
  ]);
  useEffect(() => {
    fetchUploadStatus();
  }, []);
  const [openModal, setOpenModal] = useState(false);

  const handleSortFilter = (sortField, sortOrder, carrier, zone) => {
    setSelectedFilter(sortField);
    setSortOrder(sortOrder);
  };

  return (
    <DashboardContainer
      searchBarPlaceholder={"Search by weight..."}
      onSearchData={(x) => {
        setSearchData(x);
        setPage(0);
      }}
      rateCard
      onHandleUploadButton={() => setOpenUploadModal(true)}
      onClickAddButton={() => setOpenModal(true)}
      // onClickUpload={(x) => onUploadFile(x)}
      title={"+ Add Rate Card"}
      onSelectClient={(e) => {
        setClientFilter(e);
      }}
      //selectedClient={selectedClient?.name}
      header={"Rate Card List"}
      hideFilterClient
    >
      <RateCardModal
        selectedRateCard={selectedRateCard}
        onAddSuccess={fetchRateCardData}
        handleClose={() => {
          setOpenModal(false);
          setSelectedRateCard(null);
        }}
        open={openModal}
      />
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <div style={{ height: "100%", overflow: "auto" }}>
        <RateCardDataTable
          onClickUploadStatus={() => fetchUploadStatus()}
          dataCount={dataCount}
          onDeleteStatus={(id) => deleteStaus(id)}
          uploadData={uploadStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          onFilterZone={(e) => setSelectedZone(e)}
          onFilterServiceType={(e) => setSelectedServiceType(e)}
          onFilterCarrier={(e) => setSelectedCarrier(e)}
          onDeleteRateCard={fetchRateCardData}
          onEditData={(e) => {
            setSelectedRateCard(e);
            setOpenModal(true);
          }}
          data={rateCardData}
          onFilterClient={(e) => setClientFilter(e)}
          sortFilter={handleSortFilter}
          currentPage={page}
          itemsPerPage={rowsPerPage}
        />
      </div>
      <RateCardFileUploadModal
        rateCard
        onSubmitUploadData={() => setOpenUploadModal(false)}
        onClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
        title={"Rate Card"}
      ></RateCardFileUploadModal>
    </DashboardContainer>
  );
}
