import React, { useEffect, useState } from 'react'
import './index.css'
import { ThankyouImage } from '../../images/thank-you-image'
import { Typography } from '@mui/material'
const ThankyouMessage = () => {
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          marginLeft: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          position: 'absolute',
          left: 0,
          top: 20
        }}
      >
        <img
          src="/DropSkipLogo.png"
          alt="Company Logo"
          className="max-w-full aspect-[2.0] w-[137px]"
          style={{ marginBottom: 10, marginLeft: 20 }}
        />
        <Typography
          fontFamily={'poppins'}
          fontSize={'16px'}
          fontWeight={600}
          color={'black'}
          style={{ textAlign: 'center' }}
        >
          Powered by Redefined Logistics
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'auto'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img style={{ height: '25%', width: '25%' }} src={`${ThankyouImage()}`}></img>
        </div>

        <div
          style={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            alignItems: 'center'
          }}
        >
          <p style={{ fontSize: 24, fontFamily: 'Poppins', fontWeight: '600' }} className="title">
            {`Thank You for Installing DropSkip™️ APP`}
          </p>
          <p
            style={{ fontSize: 24, fontFamily: 'Poppins', marginTop: 15, fontWeight: '600' }}
            className="title"
          >
            {`(Powered by Redefined Logistics).`}
          </p>
          <p
            style={{
              fontSize: 16,
              fontFamily: 'Poppins',
              textAlign: 'center',
              color: '#757477',
              marginTop: 25
            }}
            className="subtitle"
          >
            {`We’re thrilled to have you join us. We appreciate your trust in our service. 
          Our operations team will now review your information and business details before initiating the set up process.
        
          `}
          </p>
          <p
            style={{
              fontSize: 16,
              fontFamily: 'Poppins',
              textAlign: 'left',
              color: '#757477',
              marginTop: 25
            }}
            className="subtitle"
          >
            {`      
          We’ll be in touch shortly to review the next steps with you. 

          `}
          </p>
          <p
            style={{
              fontSize: 16,
              fontFamily: 'Poppins',
              textAlign: 'left',
              color: '#757477',
              marginTop: 25,
              fontStyle: 'italic'
            }}
            className="subtitle"
          >
            {`
          In the meantime, if you have any questions or need assistance,
          please don't hesitate to reach out to us at
          `}{' '}
            <a style={{ fontSize: 17, color: 'blue' }} href="mailto:product@redefinedlogistics.com">
              product@redefinedlogistics.com.
            </a>
          </p>
          <p
            style={{
              fontSize: 20,
              fontFamily: 'Poppins',
              fontWeight: '500',
              marginTop: 40,
              fontStyle: 'italic',
              color: '#333333',
              lineHeight: '30px'
            }}
            className="title"
          >
            {`Welcome again to the Redefined Logistics family!`}
          </p>
        </div>
      </div>

      {/* <p style={{ fontSize: 28 }} className="title">
        {`Thank You for Installing DropSkip™️ APP (Powered by Redefined Logistics).`}
      </p>
     
      <p style={{ marginInline: 30, fontStyle: 'italic' }} className="subtitle">
        {`We’re thrilled to have you join us. We appreciate your trust in our service. 
          Our operations team will now review your information and business details before initiating the set up process.
        
          `}
      </p>
      <p style={{ marginInline: 30, fontStyle: 'italic' }} className="subtitle">
        {`      
          We’ll be in touch shortly to review the next steps with you. 

          `}
      </p>
      <p style={{ marginInline: 30, fontStyle: 'italic' }} className="subtitle">
        {`
          In the meantime, if you have any questions or need assistance,
          please don't hesitate to reach out to us at 
          `}
      </p>
      <a style={{ fontSize: 17 }} href="mailto:product@redefinedlogistics.com">
        product@redefinedlogistics.com.
      </a>
      <p style={{ marginInline: 30, fontStyle: 'italic' }} className="subtitle">
        {`
          Welcome again to the Redefined Logistics family!
          `}
      </p> */}
    </div>
  )
}

export default ThankyouMessage
