import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  IconButton,
  Grid,
  Paper,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import { omsService } from "../../utils/services/omsProvider";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
const OMSManagementForm = ({
  selectedClient,
  handleClose,
  selectedData,
  onAddProviderData,
  onDeleteSuccess,
  setLoading,
  providerList = [],
}) => {
  const InitialData = [{ provider: "", shop_name: "" }];
  const [providers, setProviders] = useState(InitialData);
  const handleAddProvider = () => {
    setProviders((prevProviders) => [
      ...prevProviders,
      { provider: {}, shop_name: "" },
    ]);
  };

  const handleRemoveProvider = async (index, provider) => {
    setLoading(true);
    if (selectedData?.length) {
      try {
        const omsResponse = await omsService.delete(provider?._id);
        if (omsResponse) {
          onDeleteSuccess && onDeleteSuccess();
          setProviders((prevProviders) =>
            prevProviders.filter((_, i) => i !== index)
          );
          setLoading(false);
          handleClose && handleClose();
        }
      } catch (error) {
        console.error("Delete failed:", error);
        handleClose && handleClose();
        throw error;
      }
      setLoading(false);
    } else {
      setProviders((prevProviders) =>
        prevProviders.filter((_, i) => i !== index)
      );
      setLoading(false);
    }
  };

  const handleChange = (index, field, value) => {
    const newProviders = [...providers];
    newProviders[index][field] = value;
    setProviders(newProviders);
  };

  useEffect(() => {
    setProviders(selectedData);
  }, [selectedData]);

  async function addConfig(configurations) {
    for (const config of configurations) {
      if (selectedData?.length) {
        try {
          const response = await omsService.edit(
            {
              provider: config?.provider,
              shop_name: config?.shop_name,
            },
            config?._id
          );
          console.log(
            `Successfully added configuration for ${config.provider}`
          );
          console.log(response.data);
          handleClose && handleClose();
        } catch (error) {
          console.error(
            `Error adding configuration for ${config.provider}:`,
            error.message
          );
        }
      } else {
        try {
          const response = await omsService.create(config);
          console.log(
            `Successfully added configuration for ${config.provider}`
          );
          console.log(response.data);
          handleClose && handleClose();
        } catch (error) {
          console.error(
            `Error adding configuration for ${config.provider}:`,
            error.message
          );
          return;
        }
      }
      setProviders(InitialData);
      onAddProviderData && onAddProviderData();
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    addConfig(
      providers.map((item) => ({ ...item, client_id: selectedClient?._id }))
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      {providers.map((provider, index) => (
        <Paper
          key={index}
          variant="outlined"
          style={{
            padding: "16px",
            marginTop: "16px",
            background: "#EEEEEE",
            borderRadius: "4px",
          }}
        >
          {
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  fontFamily={"poppins"}
                  fontSize={"16px"}
                  fontWeight={500}
                >{`Provider${index + 1}`}</Typography>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveProvider(index, provider)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                    Select Provider <span style={{ color: "red" }}>*</span>
                  </label>

                  <Select
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      backgroundColor: "white",
                      height: 33,
                    }}
                    placeholder="Select Provider"
                    size="small"
                    type="text"
                    value={provider.provider}
                    onChange={(e) =>
                      handleChange(index, "provider", e.target.value)
                    }
                  >
                    {providerList.map((data) => (
                      <MenuItem value={data._id} sx={{ height: 30 }}>
                        {data?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                    Shop Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    placeholder="Enter Shop Name"
                    style={{
                      border: "1px solid #D1D1D1",
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13,
                    }}
                    type="text"
                    value={provider.shop_name}
                    size="small"
                    onChange={(e) =>
                      handleChange(index, "shop_name", e.target.value)
                    }
                  />
                </div>
              </Grid>
            </Grid>
          }
        </Paper>
      ))}
      <div
        style={{
          marginTop: "16px",
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {selectedData?.length ? (
          <></>
        ) : (
          <Button onClick={handleAddProvider} style={{ textTransform: "none" }}>
            <CustomTypography
              fontSize={"14px"}
              color={"#0038FF"}
              fontWeight={400}
            >{`+ Add Provider`}</CustomTypography>
          </Button>
        )}
      </div>

      <DialogActions style={{ padding: "16px" }}>
        <Button
          style={{
            fontSize: "12px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          style={{
            fontSize: "12px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </form>
  );
};

const OMSProviderModal = ({
  setOpen,
  onClose,
  selectedClient,
  selectedData,
  onAddProviderData,
  setLoading,
  loading,
  onDeleteSuccess,
  providerList = [],
}) => {
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <OMSManagementForm
      onDeleteSuccess={onDeleteSuccess}
      setLoading={setLoading}
      loading={loading}
      onAddProviderData={onAddProviderData}
      selectedData={selectedData}
      handleClose={handleClose}
      selectedClient={selectedClient}
      providerList={providerList}
    />
  );
};

export default OMSProviderModal;
