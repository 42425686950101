import { useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { toast } from 'react-toastify'
import './index.css'
import WelcomeScreen from '../../components/shopify-client-onboard-components/welcome-screen'
import config from '../../config'
import { Box, useMediaQuery, Typography, Button } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import SignUpForm from '../../components/shopify-client-onboard-components/sign-up-form'
import ThankyouMessage from '../../components/shopify-client-onboard-components/thank-you-message'
import ActivateKeyForm from '../../components/shopify-client-onboard-components/active-key-form'
import { clientService } from '../../utils/services/clientService'

export default function ClientOnboard() {
  const [clientActiveKey, setClientActiveKey] = useState('')
  const [message, setMessage] = useState('')
  const [clientInfo, setClientInfo] = useState(null)
  const [showOptions, setShowOptions] = useState(false)
  const [registered, setRegistered] = useState(false)
  const [countries, setCountries] = useState([])
  const [thankyouMsg, setThankyouMsg] = useState(false)
  const [email, setEmail] = useState('')
  const [apiSecretKey, setApiSecretKey] = useState('')
  const autocompleteRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [shopName, setShopName] = useState('')
  const [clientId, setClientId] = useState('')
  const isMobile = useMediaQuery('(max-width:750px)')
  const [clientToken, setClientToken] = useState('')
  // const getCountryPhoneCodes = async () => {
  //   try {
  //     // Fetch data from the REST Countries API
  //     const response = await fetch('https://restcountries.com/v3.1/all')
  //     const data = await response.json()

  //     // Process the data to create an array of objects with country names and phone codes
  //     const countryPhoneCodes = data.reduce((acc, country) => {
  //       if (country.name.common && country.idd.root) {
  //         const phoneCode = country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
  //         acc.push({
  //           name: country.name.common,
  //           code: phoneCode
  //         })
  //       }

  //       return acc
  //     }, [])

  //     // Sort the result alphabetically by country name
  //     countryPhoneCodes.sort((a, b) => a.name.localeCompare(b.name))

  //     // Convert the result to JSON
  //     const jsonResult = JSON.stringify(countryPhoneCodes, null, 2)
  //     setCountries(countryPhoneCodes)
  //     return jsonResult
  //   } catch (error) {
  //     console.error('Error fetching country data:', error)
  //   }
  // }
  // useEffect(() => {
  //   getCountryPhoneCodes()
  // }, [])

  function isBusinessEmail(email) {
    // Convert email to lowercase for case-insensitive matching
    email = email?.toLowerCase()

    // List of common free email providers
    const freeEmailProviders = [
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'outlook.com',
      'aol.com',
      'icloud.com',
      'mail.com',
      'protonmail.com',
      'zoho.com'
    ]

    // Extract the domain from the email
    const domain = email?.split('@')[1]

    // Check if the domain is not in the list of free email providers
    return !freeEmailProviders.includes(domain)
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location?.href.split('?')[1])
    const storeUrl = urlParams.get('store')
    const client_id = urlParams.get('client_id')
    const token = urlParams.get('token')
    setShopName(storeUrl)
    setClientId(client_id)
    setClientToken(token)
  }, [])
  const checkIfRegistered = async (id) => {
    try {
      const clientData = await clientService.checkIfRegisted(id)
      setThankyouMsg(clientData?.profile_completion === 'Initialized' ? true : false)
      setRegistered(clientData?.profile_completion === 'Registered' ? true : false)
      setEmail(clientData.email)
    } catch (e) {
      console.error(e)
      throw e
    }
  }
  useEffect(() => {
    if (clientId) {
      checkIfRegistered(clientId)
    }
  }, [clientId, thankyouMsg, registered])
  const handleSubmit = async (data) => {
    // if (!isBusinessEmail(data.email)) {
    //   return toast.error('Please enter a business email')
    // }
    setLoading(true)
    try {
      const response = await axios.patch(
        `${config.baseURL}/client/${clientId}`,
        { ...data, profile_completion: 'Registered' },
        {
          headers: {
            clientid: clientId,
            Authorization: `Bearer ${clientToken}`
          }
        }
      )

      setThankyouMsg(true)
      setLoading(false)
      window.location.reload()
      console.log(response.data)
    } catch (err) {
      toast.error(err.response?.data.message)
      console.error(err)
      return err
    } finally {
      setLoading(false)
    }
  }

  const verifyApi = async (clientData) => {
    setLoading(true)
    try {
      const responseData = await axios.get(`${config.omsBaseURL}/verify-api-key?api_key=${clientActiveKey}`)
      if (responseData.data?._id) {
        document.location.reload()
        // window.open(`${config.coreUrl}onboard-login?email=${email}`, '_blank')
      }
    } catch (err) {
      // toast.error(err.response.message)
      console.error(err)
      return 'error occurred'
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <main
      style={{ backgroundColor: '#fff' }}
      className="flex flex-row justify-center items-center w-full min-h-screen"
    >
      {!isMobile && !registered ? (
        <div
          style={{
            backgroundColor: '#fff',
            display: 'flex',
            flex: 1,
            height: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              marginTop: 20,
              marginLeft: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start'
            }}
          >
            <img
              src="/DropSkipLogo.png"
              alt="Company Logo"
              className="max-w-full aspect-[2.0] w-[137px]"
              style={{ marginBottom: 10, marginLeft: 20 }}
            />
            <Typography
              fontFamily={'poppins'}
              fontSize={'16px'}
              fontWeight={600}
              color={'black'}
              style={{ textAlign: 'center' }}
            >
              Powered by Redefined Logistics
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1
            }}
          >
            <img src="/sign-up-logo.png" alt="Company Logo" />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: registered ? '#FFF' : '#151D48',
          height: '100vh',
          position: 'relative'
        }}
      >
        {isMobile && (
          <div style={{ position: 'absolute', top: 20, left: 20 }}>
            <img src="./DropSkipLogo.png" alt="Dropskip Logo" className="max-w-full aspect-[2.0] w-[100px]" />
            <Typography
              fontFamily={'poppins'}
              fontSize={'10px'}
              fontWeight={500}
              color={'#fff'}
              style={{ marginTop: 5, textAlign: 'left' }}
            >
              Powered by Redefined Logistics
            </Typography>
          </div>
        )}

        <Button
          variant="outlined"
          style={{
            backgroundColor: '#FEC50F',
            color: '#000',
            textTransform: 'none',
            position: 'absolute',
            right: 20,
            top: 25,
            fontFamily: 'poppins',
            fontSize: 14,
            fontWeight: 500
          }}
          onClick={() => window.open('https://www.redefinedlogistics.com/contact', '_blank')}
        >
          Contact Us
        </Button>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            padding: 20
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              overflow: 'auto',
              maxHeight: '83vh',
              scrollbarWidth: 'none', // For Firefox
              msOverflowStyle: 'none' // For Inte
              // backgroundColor: 'red'
            }}
          >
            {registered ? (
              <ThankyouMessage></ThankyouMessage>
            ) : (
              <SignUpForm
                email={email}
                shopName={shopName}
                handleSubmitData={(data) => handleSubmit(data)}
              ></SignUpForm>
            )}
          </div>
        </div>
      </div>
    </main>
  )
}
