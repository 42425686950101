import React, { useEffect, useReducer, useState } from "react";
import { Grid, Box, Paper, Typography } from "@mui/material";
import DataCard from "../../components/data-card";
import GraphCard from "../../components/graph-card";
import { orderService } from "../../utils/services/orderService";
import { isMobile } from "../../globalStyles/mobile";
import buildQuery from "../../utils/query-builder";
import { changeDateFormat } from "../../utils/utility";
import InventoryCard from "../../components/dashboard/inventory-chart";
import MonthlyOrderComparision from "../../components/dashboard/month-comparision";
import { styled } from "@mui/system";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"; // Example icon
import LocalShippingIcon from "@mui/icons-material/LocalShipping"; // Example icon
import InventoryIcon from "@mui/icons-material/Inventory"; // Example icon
import { useNavigate } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  cursor: "pointer",
  textAlign: "center",
  borderRadius: "15px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  border: "2px solid #e0e0e0",
  backgroundColor: "#f5f5f5",
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    // transform: "translateY(-5px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  },
}));

// Custom icon style
const IconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  backgroundColor: "#1976d2", // Blue background for the icon
  color: "#fff", // White icon color
}));

const DashboardScreen = () => {
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const [stats, setStats] = useState({});
  const navigate = useNavigate();
  const fetchStats = async () => {
    try {
      const response = await orderService.fetchStats({ client: clientId });
      setStats(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [clientFilter]);

  const handleClientFilterChange = (client) => {
    setClientFilter(client);
  };
  const customScrollbarStyle = {
    height: isMobile() ? "calc(100vh - 120px)" : "calc(100vh - 100px)",
    overflowX: "auto",
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#273894 #D9D9D9",
    "&::-webkit-scrollbar": {
      width: "7px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#D9D9D9",
      borderRadius: "14px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#273894",
      borderRadius: "14px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#273894",
    },
  };

  return (
    <Box sx={customScrollbarStyle}>
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
          margin: 0,
          height: "100%",
          flexWrap: "wrap",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ marginTop: 1, paddingLeft: 1, paddingRight: 1 }}
        >
          <Grid item xs={12} md={4}>
            <StyledPaper>
              <Grid container alignItems="center" spacing={2}>
                {/* Icon Section */}
                <Grid item xs={3}>
                  <IconWrapper style={{ backgroundColor: "#FFE0E1" }}>
                    <ShoppingCartIcon
                      sx={{ color: "#DC585C" }}
                      fontSize="large"
                    />
                  </IconWrapper>
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  onClick={() => {
                    navigate(`/order-management`);
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                      fontSize: 30,
                      fontWeight: 700,
                      fontFamily: "Poppins",
                    }}
                  >
                    {stats.totalOrders || 0}+
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#555",
                      fontSize: 14,
                      fontFamily: "Poppins",
                    }}
                  >
                    Total Orders
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledPaper>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <IconWrapper style={{ backgroundColor: "#DAEBFF" }}>
                    <LocalShippingIcon
                      fontSize="large"
                      style={{ color: "#2064BA" }}
                    />
                  </IconWrapper>
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  onClick={() => {
                    localStorage.setItem(
                      "order-filter-data",
                      JSON.stringify({
                        filters: [
                          {
                            key: "status",
                            values: ["Delivered"],
                          },
                        ],
                      })
                    );
                    navigate(`/order-management?child=true`);
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                      fontSize: 30,
                      fontWeight: 700,
                      fontFamily: "Poppins",
                    }}
                  >
                    {`${stats.deliveredOrders || 0}+`}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#555",
                      fontSize: 14,
                      fontFamily: "Poppins",
                    }}
                  >
                    Total Delivery
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledPaper>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <IconWrapper style={{ backgroundColor: "#D5FFD9" }}>
                    <InventoryIcon
                      fontSize="large"
                      style={{ color: "#06B217" }}
                    />
                  </IconWrapper>
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                      fontSize: 30,
                      fontWeight: 700,
                      fontFamily: "Poppins",
                    }}
                  >
                    {stats.totalInventory || 0}+
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#555",
                      fontSize: 14,
                      fontFamily: "Poppins",
                    }}
                  >
                    Total Inventory
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {stats && stats.weekComparision && (
            <Box
              sx={{
                flex: { xs: "1 1 100%", sm: "1 1 50%", lg: "1 1 33.33%" },
                minWidth: "300px",
                boxSizing: "border-box",
              }}
            >
              <GraphCard
                minHeight={isMobile() ? "250px" : 320}
                data_obj={stats?.weekComparision || []}
                type={"bar"}
                title={"Orders"}
                backgroundColor={"#FFFFFF"}
                textColor={"#474747"}
                border={"1px solid #D9D9D9"}
              />
            </Box>
          )}

          {stats && stats.currentWeekStatusCounts && (
            <Box
              sx={{
                flex: { xs: "1 1 100%", sm: "1 1 50%", lg: "1 1 33.33%" }, // Matches the first card's behavior
                minWidth: "300px",
                boxSizing: "border-box",
              }}
            >
              <GraphCard
                minHeight={isMobile() ? "250px" : 320}
                data_obj={stats?.currentWeekStatusCounts || []}
                type={"doughnut"}
                title={"Delivery"}
                backgroundColor={"#FFFFFF"}
                textColor={"#474747"}
                border={"1px solid #D9D9D9"}
              />
            </Box>
          )}
        </Grid>
        <Grid sx={{ marginRight: 1, marginLeft: -1 }} item xs={12}>
          <InventoryCard />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardScreen;
