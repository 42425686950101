import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import IconButton from "@mui/material/IconButton/IconButton";
import Button from "@mui/material/Button/Button";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import UpIcon from "@mui/icons-material/ArrowUpwardOutlined";
import DownIcon from "@mui/icons-material/ArrowDownwardOutlined";
import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Icon,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import { isMobile } from "../../globalStyles/mobile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import FilterModal from "../filterModal";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import { useTheme } from "@mui/styles";
import { assignUserService } from "../../utils/services/assignUserService";
import UserDetailsModal from "../assign-user-details-modal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const UserTableCell = ({
  user,
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "view":
        handleViewClick(user);
        break;
      case "edit":
        handleEditClick(user);
        break;
      case "delete":
        handleDeleteClick(user);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "#000",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
          <Button
            onClick={() => handleActionClick("edit")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <EditIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Edit
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("view")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ViewIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                View
              </Typography>
            </Box>
          </Button>

          {/* <Button
            onClick={() => handleActionClick("delete")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <DeleteIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Delete
              </Typography>
            </Box>
          </Button> */}
        </Box>
      </Popover>
    </Box>
  );
};
const UserList = ({
  data,
  onEditData,
  onDeleteData,
  viewMode,
  selectedUser,
  setSelectedUser,
  currentPage,
  itemsPerPage,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const startIndex = currentPage * itemsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setOpenConfirmation(true);
  };

  const confirmDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await assignUserService.delete(selectedUser._id);
      onDeleteData && onDeleteData();
      setOpenConfirmation(false);
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (user) => {
    setSelectedUser(user);
    setOpenViewModal(true);
  };

  useEffect(() => {
    setSelectedUserData(
      data?.map((user) => ({
        ...user,
        action: (
          <UserTableCell
            user={user}
            handleViewClick={handleViewClick}
            handleEditClick={onEditData}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      }))
    );
  }, [data]);

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "300px" }}
      />
    </div>
  );
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: "1px",
        maxHeight: "100%",
        paddingBottom: 5,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {selectedUserData?.map((x, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: "pointer",
              width: "100%",
              height: "100%",
              position: "relative",
              borderRadius: "6px",
              border: "1px solid #D0D5DD",
            }}
          >
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight="500"
                  sx={{ textAlign: "start" }}
                  fontFamily="Poppins"
                  color="#333333"
                >
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  {XMLHttpRequestEventTarget?.action}
                </div>
              </div>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Name
                  </Typography>

                  {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")?.sort ===
                    -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={x?.user?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.user?.name}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Email
                  </Typography>
                  {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                  1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "email")
                      ?.sort === -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={x?.user?.email} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.user?.email}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Mobile
                  </Typography>
                  {/* {filterData.columns.find((x) => x.key === "address.address1")
                    ?.sort === 1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find(
                      (x) => x.key === "address.address1"
                    )?.sort === -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={x?.mobile} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.user?.phone_no}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {selectedUserData?.map((x, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffffff",
              borderRadius: 4,
              position: "relative",
            }}
          >
            <CardContent>
              <Box position="absolute" top={2} right={5}>
                {x?.action}
              </Box>
              <Box display="flex" flexDirection="column" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      : {x?.user?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      : {x?.user?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#000000"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Mobile
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#606060"
                      fontWeight={500}
                      textAlign="left"
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      : {x?.user?.phone_no}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={selectedUserData} />
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
            marginTop: 1,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                    1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "name")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      NAME
                    </Typography>
                    {/* <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton> */}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                    1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "email")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    >
                      EMAIL
                    </CustomTypography>
                    {/* <IconButton
                      aria-label="filter"
                      size="small"
                      //   onClick={(event) => handleOpenFilter(event, "email")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton> */}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                    textAlign={"start"}
                  >
                    MOBILE
                  </Typography>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {selectedUserData?.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                selectedUserData?.map((x, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        // style={{ marginLeft: 10 }}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <Tooltip title={x?.user?.name}>
                      <StyledTableCell>
                        <Typography
                          style={{
                            maxWidth: 100,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                          title={x?.name}
                        >
                          {x?.user?.name}
                        </Typography>
                      </StyledTableCell>
                    </Tooltip>

                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"poppins"}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                      >
                        {x?.user?.email}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"poppins"}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "250px",
                        }}
                      >
                        {x?.user?.phone_no}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                      {x?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <div sx={{ minWidth: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
      )}

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedUser?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          confirmDelete(e);
        }}
      />
      <UserDetailsModal
        user={selectedUser}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  );
};

export default UserList;
