import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { omsLocationService } from "../../utils/services/omsLocationService";
import FilterModal from "../filterModal";
import WarehouseLocationModal from "./warehouse-location-mapping";

const WarehouseSelectionModal = ({
  open,
  onClose,
  providers,
  omsData,
  filterData,
  selectedfilterData,
  handleSaveFilter,
  anchorElFilter,
  openFilterModal,
  setAnchorElFilter,
  setOpenFilterModal,
  setSelectedFilterData,
  selectedProvider,
  selectedClient,
  setSelectedProvider,
}) => {
  const [selectedWMS, setSelectedWMS] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [currentWarehouse, setCurrentWarehouse] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [anchorPositionFilter, setAnchorPositionFilter] = useState(null);
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;
  const [omsLocations, setOmsLocations] = useState([]);
  const [warehouseLocation, setWarehouseLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 5;
  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget);
    setAnchorPositionFilter({
      top: event.clientY + 10,
      left: event.clientX,
    });
    setOpenFilterModal(true);
    setSelectedFilterData(filterData?.columns.find((x) => x?.key == filterKey));
  };
  const handleCloseFilter = () => {
    setAnchorPosition(null);
    setOpenFilterModal(false);
  };
  const handleCloseFilterPopover = () => {
    setAnchorPositionFilter(null);
  };

  const fetchOmsLocations = async (data) => {
    setLoading(true);
    try {
      const response = await omsLocationService.fetch({
        ...data,
        email: selectedClient.email,
        client_uid: selectedClient.client_uid,
      });

      setOmsLocations(
        response[
          selectedProvider?.provider?.name == "Shopify"
            ? "locations"
            : selectedProvider?.provider?.name == "RL_OMS"
            ? "data"
            : ""
        ]
      );
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWarehouseLocation = async (data) => {
    setLoading(true);
    try {
      const response = await omsLocationService.fetchLocation(data._id);
      setWarehouseLocation(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedProvider?._id) {
      fetchOmsLocations(selectedProvider);
      fetchWarehouseLocation(selectedProvider);
    }
  }, [selectedProvider]);

  const addOmsLocation = async () => {
    try {
      const response = await omsLocationService.create({
        client_id: selectedProvider.client_id,
        oms_config: selectedProvider._id,
        location: omsLocations?.find(
          (x) =>
            x[selectedProvider?.provider?.name === "Shopify" ? "id" : "_id"] ==
            selectedLocation
        ),
        provider: selectedWMS,
      });
      onAddSuccess();
      return response;
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };

  const updateOmsLocation = async () => {
    try {
      const response = await omsLocationService.edit(
        {
          client_id: selectedProvider.client_id,
          oms_config: selectedProvider._id,
          location: omsLocations?.find(
            (x) =>
              x[
                selectedProvider?.provider?.name === "Shopify" ? "id" : "_id"
              ] == selectedLocation
          ),
          provider: selectedWMS,
        },
        currentWarehouse?._id
      );
      onAddSuccess();
      return response;
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const deleteOmsLocation = async () => {
    try {
      await omsLocationService.delete(currentWarehouse?._id);

      setDeleteConfirmOpen(false);
      onAddSuccess();
    } catch (error) {
      console.error("Delete failed:", error);
    }
  };

  const handleConfirm = async () => {
    console.log(selectedLocation, selectedWMS, isEditMode);
    if (isEditMode) {
      await updateOmsLocation();
    } else {
      await addOmsLocation();
    }
    handleReset();
  };

  const handleReset = () => {
    setSelectedWMS("");
    setSelectedLocation(null);
    setIsEditMode(false);
    setCurrentWarehouse(null);
  };

  const handleClose = () => {
    handleReset();
    setSelectedProvider(null);
    onClose();
  };

  const handleEdit = (warehouse) => {
    setCurrentWarehouse(warehouse);
    setSelectedWMS(warehouse.provider._id);
    setSelectedLocation(
      warehouse.location[
        selectedProvider?.provider?.name === "Shopify" ? "id" : "_id"
      ]
    );
    setIsEditMode(true);
  };

  const handleDelete = (warehouse) => {
    setCurrentWarehouse(warehouse);
    setDeleteConfirmOpen(true);
  };

  // Pagination logic
  const totalPages = Math.ceil((warehouseLocation?.length || 0) / itemsPerPage);
  const paginatedWarehouses =
    warehouseLocation?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ) || [];

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(totalPages, prevPage + 1));
  };

  const handleOptionsClick = (event, warehouse) => {
    setCurrentWarehouse(warehouse);
    setAnchorEl(event.currentTarget);
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };
  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  const onAddSuccess = () => {
    fetchWarehouseLocation(selectedProvider);
  };

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
        color: "white",
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  ) : (
    <>
      <WarehouseLocationModal
        omsLocations={omsLocations}
        handleClose={handleClose}
        open={open}
        providers={providers}
        selectedData={currentWarehouse}
        onAddSuccess={onAddSuccess}
        handleConfirm={handleConfirm}
        isEditMode={isEditMode}
        primary_color={primary_color}
        filterData={filterData}
        handleOpenFilter={handleOpenFilter}
        paginatedWarehouses={paginatedWarehouses}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        itemsPerPage={itemsPerPage}
        handleOptionsClick={handleOptionsClick}
        setSelectedWMS={setSelectedWMS}
        setSelectedLocation={setSelectedLocation}
        selectedLocation={selectedLocation}
        selectedWMS={selectedWMS}
        oms_key={selectedProvider?.provider?.name === "Shopify" ? "id" : "_id"}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOptionsClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
      >
        <MenuItem onClick={() => handleEdit(currentWarehouse)}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={() => handleDelete(currentWarehouse)}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} color="error" /> Delete
        </MenuItem>
      </Menu>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete this ${currentWarehouse?.provider?.name} and ${currentWarehouse?.location?.name} mapping?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteOmsLocation} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={Boolean(anchorPositionFilter)}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilterPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPositionFilter
            ? { top: anchorPositionFilter.top, left: anchorPositionFilter.left }
            : undefined
        }
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        container={document.querySelector(".MuiModal-root")}
      >
        <FilterModal
          open={openFilterModal}
          onClose={handleCloseFilter}
          options={selectedfilterData?.options}
          selectedValues={selectedfilterData?.filter}
          onSave={handleSaveFilter}
          currentSortOrder={selectedfilterData?.sort}
          filterKey={selectedfilterData?.key}
          hasMore={selectedfilterData?.loadMoreButton}
          displayKey={selectedfilterData?.displayKey}
          id={selectedfilterData?.id}
          fetchApi={selectedfilterData?.fetchApi}
          searchKey={selectedfilterData?.searchKey}
        />
      </Popover>
    </>
  );
};

export default WarehouseSelectionModal;
