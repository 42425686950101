import React, { useEffect, useState } from 'react'
import './index.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { toast } from 'react-toastify'
// import PasswordGeneratorModal from '../generate-password'
import axios from 'axios'
import config from '../../config'
import { WelcomeImage } from '../../images/welcome-screen-shopify'
import PasswordGeneratorModal from '../password-generation-modal'
const WelcomeScreen = ({ email, apiSecretKey, apiKey }) => {
  const [data, setData] = useState({
    token: '',
    clientId: ''
  })
  const [emailAddress, setEmailAddress] = useState('')
  const [openPasswordGenerateModal, setOpenPasswordGenerateModal] = useState(false)
  const [validateKey, setValidateKey] = useState('')
  const [loading, setLoading] = useState(false)
  function extractShop(jsonString) {
    const obj = JSON.parse(jsonString)
    const shopUrl = obj.shop.replace('.myshopify.com', '')
    return shopUrl
  }
  //   const { apiKey } = useLoaderData()
  useEffect(() => {
    setEmailAddress(email)
  }, [email])
  const fetchClient = async () => {
    setLoading(true)
    try {
      const formdata = new FormData()
      formdata.append('shop', extractShop(sessionStorage.getItem('app-bridge-config')))
      const response = await fetch(`/api/getClient`, {
        method: 'POST',
        body: formdata
      })
      if (response.status == 200) {
        let result = await response.json()
        setData({
          clientId: result?.clientId,
          token: result?.token
        })
        const passwordGenerationResult = axios
          .get(`${config.baseURL}/client-password-check/${result?.clientId}`, {
            headers: {
              clientid: result?.clientId,
              Authorization: `Bearer ${result?.token}`
            }
          })
          .then((res) => {
            setOpenPasswordGenerateModal(res.data?.pending)
            setValidateKey(res.data.validate_key)
          })
          .catch((err) => console.error(err))
        setLoading(false)
        console.log(passwordGenerationResult)
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
      return err
    }
  }
  useEffect(() => {
    fetchClient()
  }, [])
  const reGenerateRefreshToken = async () => {
    const tokenFormdata = new FormData()
    tokenFormdata.append('apiKey', apiKey)
    tokenFormdata.append('token', data.token)
    tokenFormdata.append('clientId', data.clientId)
    tokenFormdata.append('shop', extractShop(sessionStorage.getItem('app-bridge-config')))
    tokenFormdata.append('regenerate', 'true')
    try {
      const response = await fetch('/api/offline-token', {
        method: 'POST',
        body: tokenFormdata
      })
      if (response) {
        toast.success('Token regenerated successfully')
      }
      return response
    } catch (err) {
      console.error(err)
      toast.error('Unable to regenerate token')
      return err
    }
  }
  return loading ? (
    <></>
  ) : (
    <>
      {/* {!openPasswordGenerateModal ? (
        <div style={{ backgroundColor: '#FFF', width: '100%', height: '100vh' }}>
          <div className="container" style={{ backgroundColor: '#FFF' }}>
            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img style={{ height: '30vh', width: '70vh' }} src={`${WelcomeImage()}`}></img>
            </div>
            <p style={{ fontSize: 33, fontWeight: '600' }} className="title">
              Welcome to <span style={{ color: 'red' }}>Drop</span>
              <span style={{ color: '#1877F2' }}>Skip&#8482;</span>
            </p>
            <h3
              style={{ marginTop: 30, fontSize: 27 }}
              className="title"
            >{`(Powered by Redefined Logistics)`}</h3>
            <p style={{ fontStyle: 'italic' }} className="subtitle">
              We're glad you're here. Explore and enjoy!
            </p>
            <div className="warning">
              <p>Warning: If you can't see all the tabs, please refresh the window.</p>
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: '#1877F2',
                padding: 7,
                marginTop: 35,
                borderRadius: 10,
                alignItems: 'center',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                minWidth: 250,
                justifyContent: 'center',
                cursor: 'pointer',
                height: 45
              }}
              onClick={() => reGenerateRefreshToken()}
            >
              <p style={{ color: '#FFF', fontSize: 16 }}>Regenerate Refresh Token</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )} */}
      <PasswordGeneratorModal
        validateKey={validateKey}
        email={emailAddress}
        apiSecretKey={apiSecretKey}
        href={`${config.coreUrl}onboard-login?email=${emailAddress}&validate_key=${validateKey}`}
        setOpen={setOpenPasswordGenerateModal}
        open={openPasswordGenerateModal}
      ></PasswordGeneratorModal>
    </>
  )
}

export default WelcomeScreen
