import React, { useCallback, useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { zipZoneService } from "../../utils/services/zipZoneService";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import ZoneAutocomplete from "../zoneAutocomplete/index.js";
import PortAutocomplete from "../portAutocomplete/index.js";
const ZipZoneModal = ({
  open,
  handleClose,
  onAddSuccess,
  selectedZipZoneData,
  onSelectClient,
}) => {
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [serviceDay, setServiceDay] = useState("");
  const [zone, setZone] = useState({ zone_name: "" });
  const [port, setPort] = useState({ name: "" });
  const [state, setState] = useState("");
  const [formErrors, setFormErrors] = useState({});



  const initialdata = () => {
    setSource("");
    setDestination("");
    setServiceDay("");
    setZone();
    setPort();
    setFormErrors({});
    setState("");

  };
  const clientId = localStorage.getItem("client_id");
  const validateForm = () => {
    const errors = {};
    if (!destination) {
      errors.destination = "Destination is required";
    } else if (destination < 0) {
      errors.destination = "Destination should not be nagative";
    }
    if (!serviceDay) {
      errors.serviceDay = "Service Day is required";
    } else if (isNaN(serviceDay)) {
      errors.serviceDay = "Service Day must be a number";
    } else if (serviceDay < 0) {
      errors.serviceDay = "Service Day must be greater than 0";
    }
    if (!state) {
      errors.state = "State is required";
    }
    if (!zone?.name) {
      errors.zone = "Zone is required";
    }
    if (!port?.name) {
      errors.port = "Port is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
 
  useEffect(() => {
    if (selectedZipZoneData) {
      setSource(selectedZipZoneData.source || "");
      setDestination(selectedZipZoneData.destination || "");
      setServiceDay(selectedZipZoneData.service_day || "");
      setZone({ name: selectedZipZoneData.zone?.name || "" });
      setPort({ name: selectedZipZoneData.port?.name || "" });
      setState(selectedZipZoneData?.state || "");
    }
  }, [selectedZipZoneData]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const response = await zipZoneService.create({
        destination: destination,
        state: state,
        service_day: Number(serviceDay),
        zone: zone._id,
        port: port._id,
      });
      onAddSuccess && onAddSuccess();
      toast.success("ZipZone created successfully!")
      initialdata();
    } catch (error) {
      toast.error("Failed to create ZipZone")
      console.error("Delete failed:", error);
    }
    initialdata();
    handleClose();
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const response = await zipZoneService.edit(
        {
          state: state,
          destination: destination,
          service_day: Number(serviceDay),
          zone: zone._id ? zone._id : selectedZipZoneData.zone?._id,
          port: port._id ? port._id : selectedZipZoneData.port?._id,
        },
        selectedZipZoneData._id
      );
      onAddSuccess && onAddSuccess();
      toast.success("ZipZone edited successfully!")
      initialdata();
    } catch (error) {
      toast.error("Failed to edit ZipZone")
      console.error("Delete failed:", error);
    }
    initialdata();
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose && handleClose();
        initialdata();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >{`${
          selectedZipZoneData ? "Edit" : "Create"
        } Zip Zone Data`}</DialogTitle>
        <IconButton onClick={() => {
            handleClose && handleClose();
            initialdata();
          }}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">
              Destination <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="number"
              inputProps={{ min: 0 }}
              size="small"
              placeholder="Enter Destination"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              fullWidth
              error={!!formErrors.destination}
              helperText={formErrors.destination}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">
              Service Day <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              inputProps={{ min: 0 }}
              size="small"
              placeholder="Enter Service Day"
              type="number" // Set type to "number" for numeric input
              value={serviceDay}
              onChange={(e) => setServiceDay(e.target.value)}
              fullWidth
              error={!!formErrors.serviceDay}
              helperText={formErrors.serviceDay}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">
              State <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              sx={{ marginBottom: 2 }}
              size="small"
              placeholder="Enter State"
              type="text" // Set type to "number" for numeric input
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              error={!!formErrors.state}
              helperText={formErrors.state}
              margin="dense"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">
              Choose Zone <span style={{ color: "red" }}>*</span>
            </Typography>
            <ZoneAutocomplete
              width={"100%"}
              onZoneChange={(zone) => {
                setZone(zone);
              }}
              selectedZone={zone}
              errorMessage={formErrors.zone}
              hideLabel={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">
              Choose Port <span style={{ color: "red" }}>*</span>
            </Typography>
            <PortAutocomplete
              width={"100%"}
              onPortChange={(port) => {
                setPort(port);
              }}
              selectedPort={port}
              errorMessage={formErrors.port}
              hideLabel={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose && handleClose();
            initialdata();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedZipZoneData ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedZipZoneData ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ZipZoneModal;
