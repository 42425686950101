import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Button from '@mui/material/Button/Button'
import { supplierService } from '../../utils/services/supplierService'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import UpIcon from '@mui/icons-material/ArrowUpwardOutlined'
import DownIcon from '@mui/icons-material/ArrowDownwardOutlined'
import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Icon,
  Badge
} from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import { isMobile } from '../../globalStyles/mobile'
import SupplierDetailsModal from '../supplier-details-modal'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SortingIcon from '../../globalStyles/icons/sorting-icon'
import FilterModal from '../filterModal'
import { useTheme } from '@mui/styles'
import ViewIcon from '../../globalStyles/icons/vieewIcon'
import EditIcon from '../../globalStyles/icons/editIcon'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    // color: theme.palette.common.black,
    padding: '4px',
    textAlign: 'start'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '4px',
    textAlign: 'start'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },
  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))

const SupplierTableCell = ({ supplier, handleViewClick, handleEditClick, handleDeleteClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const handleActionClick = (action) => {
    handlePopoverClose()
    switch (action) {
      case 'view':
        handleViewClick(supplier)
        break
      case 'edit':
        handleEditClick(supplier)
        break
        // case "delete":
        //   handleDeleteClick(supplier);
        break
      default:
        break
    }
  }

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? 'primary.main' : 'inherit'
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
          <Button
            onClick={() => handleActionClick('view')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <ViewIcon />
              <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
                View
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick('edit')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <EditIcon />
              <Typography fontFamily={'poppins'} fontSize={'16px'} color={'#333333'} fontWeight={500}>
                Edit
              </Typography>
            </Box>
          </Button>
          {/* <IconButton
            onClick={() => handleActionClick("delete")}
            title="Delete"
          >
            <DeleteIcon />
          </IconButton> */}
        </Box>
      </Popover>
    </Box>
  )
}
const SupplierList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleDeleteClient,
  viewMode,
  selectedSupplier,
  setSelectedSupplier,
  onChangeFilter,
  filterData,
  currentPage,
  itemsPerPage
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [selectedSupplierData, setSelectedSupplierData] = useState([])
  const theme = useTheme()
  const defaultColor = '#D13438'
  const primary_color = theme.palette?.primary?.main || defaultColor
  const secondary_color = theme.palette?.secondary?.main || defaultColor
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [selectedFilterKey, setSelectedFilterKey] = useState('')
  const [anchorElFilter, setAnchorElFilter] = useState(null)
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: '',
    name: '',
    filter: [],
    sort: 0,
    id: '_id',
    displayKey: 'name',
    fetchApi: () => {},
    options: [],
    loadMoreButton: false,
    searchKey: ''
  })
  const startIndex = currentPage * itemsPerPage
  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget)
    setSelectedFilterKey(filterKey)
    setOpenFilterModal(true)
    setSelectedFilterData(filterData?.columns.find((x) => x?.key == filterKey))
  }
  const handleSaveFilter = (selectedOptions, filterKey, sortOrder, searchKey) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey
    })
    handleCloseFilter()
  }

  const handleCloseFilter = () => {
    setAnchorElFilter(null)
    setOpenFilterModal(false)
  }

  const handleDeleteClick = (supplier) => {
    setSelectedSupplier(supplier)
    setOpenConfirmation(true)
  }

  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await supplierService.delete(selectedSupplier._id)
      onDeleteData && onDeleteData()
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleViewClick = (supplier) => {
    setSelectedSupplier(supplier)
    setOpenViewModal(true)
  }
  useEffect(() => {
    setSelectedSupplierData(
      data?.map((supplier) => ({
        ...supplier,
        action: (
          <SupplierTableCell
            supplier={supplier}
            handleViewClick={handleViewClick}
            handleEditClick={onEditData}
            //handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])
  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '300px' }}
      />
    </div>
  )
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: '1px',
        maxHeight: '100%',
        paddingBottom: 5,
        overflow: 'auto'
      }}
      container
      spacing={2}
    >
      {selectedSupplierData?.map((supplier, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              position: 'relative',
              borderRadius: '6px',
              border: '1px solid #D0D5DD'
            }}
          >
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography fontWeight="500" sx={{ textAlign: 'start' }} fontFamily="Poppins" color="#333333">
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10
                  }}
                >
                  {supplier?.action}
                </div>
              </div>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Name
                  </Typography>

                  {filterData.columns.find((x) => x.key === 'name')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'name')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={supplier?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {supplier?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Client
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={supplier?.client?.business_name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {supplier?.client?.business_name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Email
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'email')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'email')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={supplier?.email} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {supplier?.email}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Address 1
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'address.address1')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'address.address1')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={supplier?.address?.address1} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {supplier?.address?.address1}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    City
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'address.city')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'address.city')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={supplier?.address?.city} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {supplier?.address?.city}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={'flex'} alignItems={'center'}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Province
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'address.province')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'address.province')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={supplier?.address?.province} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {supplier?.address?.province}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Grid item xs={5} display={'flex'} alignContent={'center '}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: 'start' }}
                    fontFamily={'poppins'}
                    color={'#333333'}
                  >
                    Country
                  </Typography>
                  {filterData.columns.find((x) => x.key === 'address.country')?.sort === 1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === 'address.country')?.sort === -1 ? (
                    <Icon sx={{ display: 'flex', alignItems: 'center' }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={supplier?.status} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: 'start' }}
                      fontWeight="500"
                      fontFamily={'poppins'}
                      color={'#333333'}
                    >
                      : {supplier?.address?.country}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 20,
        marginTop: 5
      }}
    >
      {isMobile() ? (
        renderGridView()
      ) : (
        <TableContainer
          sx={{
            overflowY: 'auto',
            paddingInline: 0.4,
            maxHeight: '100%'
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: '1px solid #EAECF0'
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                      //marginLeft: 20,
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'name')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'name')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={'poppins'}
                    >
                      SUPPLIER NAME
                    </Typography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'name')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'name')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'email')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'email')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      EMAIL
                    </CustomTypography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'email')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'email')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={'poppins'}
                    textAlign={'start'}
                  >
                    ADDRESS 1
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'address.city')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'address.city')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      CITY
                    </CustomTypography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'city')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'address.city')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'province')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'province')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      PROVINCE
                    </CustomTypography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'province')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'address.province')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    {filterData.columns.find((x) => x.key === 'address.country')?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === 'address.country')?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <CustomTypography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                      COUNTRY
                    </CustomTypography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find((item) => item.name.toLowerCase() === 'country')?.filter
                          ?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, 'address.country')}
                      >
                        <SortingIcon fill={primary_color || '#DC585C'} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {selectedSupplierData.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                selectedSupplierData?.map((supplier, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'Poppins'}
                        style={{
                          marginLeft: 7,
                          minWidth: 50,
                          // marginLeft: 22,
                          maxWidth: 50
                        }}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <Tooltip title={supplier?.name}>
                      <StyledTableCell>
                        <Typography
                          style={{
                            minWidth: 200,
                            // marginLeft: 22,
                            maxWidth: 200,
                            whiteSpace: 'nowrap', // Ensure single line
                            overflow: 'hidden', // Prevent text overflow
                            textOverflow: 'ellipsis' // Show ellipsis for overflow text
                          }}
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={'Poppins'}
                          title={supplier?.name} // Add a title attribute to show full text on hover
                        >
                          {supplier?.name}
                        </Typography>
                      </StyledTableCell>
                    </Tooltip>

                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'poppins'}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px'
                        }}
                      >
                        {supplier?.email}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={'poppins'}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '250px'
                        }}
                      >
                        {supplier?.address?.address1}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                        {supplier?.address?.city}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                        {supplier?.address?.province}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography fontSize="12px" color="#667085" fontWeight={400} fontFamily={'poppins'}>
                        {supplier?.address?.country}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                      {supplier?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )

  return (
    <div sx={{ minWidth: '100%' }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: 'scroll',
            display: 'flex',
            maxHeight: isMobile() ? `calc(100vh - 210px)` : `calc(100vh - 250px)`,
            width: '100%',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::WebkitScrollbar': { width: 0 }
          }}
        >
          {viewMode === 'grid' ? renderGridView() : renderListView()}
        </div>
      )}
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableScrollLock={true}
      >
        <FilterModal
          open={openFilterModal}
          onClose={handleCloseFilter}
          options={selectedfilterData?.options}
          selectedValues={selectedfilterData?.filter}
          onSave={handleSaveFilter}
          currentSortOrder={selectedfilterData?.sort}
          filterKey={selectedFilterKey}
          hasMore={selectedfilterData?.loadMoreButton}
          displayKey={selectedfilterData?.displayKey}
          id={selectedfilterData?.id}
          fetchApi={selectedfilterData?.fetchApi}
          searchKey={selectedfilterData?.searchKey}
        />
      </Popover>
      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedSupplier?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          if (client) {
            handleDeleteClient && handleDeleteClient(selectedSupplier)
            setOpenConfirmation(false)
          } else {
            confirmDelete(e)
          }
        }}
      />

      <SupplierDetailsModal
        supplier={selectedSupplier}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  )
}

export default SupplierList
