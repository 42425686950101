import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import { supplierService } from "../../utils/services/supplierService";
import buildQuery from "../../utils/query-builder";

const SupplierAutocomplete = ({
  selectedSupplier,
  onSupplierChange,
  width = 250,
  label = "Select Supplier",
  clientId,
  hideLabel,
  errorMessage,
  backgroundColor,
}) => {
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [supplierSearchValue, setSupplierSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const fetchSupplierData = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const query = buildQuery(
          { limit: 15, skip: pageNumber * 15 },
          pageNumber
        );
        const response = await supplierService.fetch(
          searchQuery,
          clientId,
          query
        );

        if (pageNumber === 0) {
          setAllSuppliers(response.data);
        } else {
          setAllSuppliers((prevSuppliers) => [
            ...prevSuppliers,
            ...response.data,
          ]);
        }

        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    [clientId]
  );

  useEffect(() => {
    fetchSupplierData(supplierSearchValue, page);
  }, [supplierSearchValue, page, fetchSupplierData]);

  useEffect(() => {
    if (supplierSearchValue === "") {
      fetchSupplierData("", 0);
    }
  }, [supplierSearchValue, fetchSupplierData]);

  const handleSupplierChange = (event, value) => {
    if (value && value.name === "Load More") {
      return;
    }
    if (onSupplierChange) {
      onSupplierChange(value);
    }
    setSupplierSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSupplierSearchValue(value);
    setPage(0);
    setAllSuppliers([]);
  };

  const handleLoadMoreClick = () => {
    if (allSuppliers.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const optionsWithLoadMore = [...allSuppliers];
  if (allSuppliers.length < total) {
    optionsWithLoadMore.push({ name: "Load More" });
  }

  return (
    <Autocomplete
      size="small"
      id="supplier-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.name || ""}
      value={selectedSupplier || null}
      onChange={handleSupplierChange}
      renderOption={(props, option) =>
        option.name === "Load More" ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <li {...props}>{option.name}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={hideLabel ? "" : label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={!!errorMessage}
          helperText={errorMessage}
          style={{ backgroundColor: backgroundColor }}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default SupplierAutocomplete;
