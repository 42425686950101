import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Autocomplete,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { weightPackageService } from "../../utils/services/weightPackageService";
import { wmsService } from "../../utils/services/wmsProvider";
import ClientAutocomplete from "../clientAutocomplete";
import InputWithUnit from "../Input-with-unit";
import { toast } from "react-toastify";
import { convertWeight } from "../../utils/weightUnits";
import DimensionsAndWeightInputWithUnit from "../dimension-input";
const WeightPackageModal = ({
  open,
  handleClose,
  onAddData,
  selectedWeightPackage,
}) => {
  const client_id = localStorage.getItem("client_id");
  const [formData, setFormData] = useState({
    name: "",
    type: "",
  });
  const [dimensions, setDimensions] = useState({
    width: "",
    length: "",
    height: "",
    width_unit: "",
    length_unit: "",
    height_unit: "",
  });
  const [weightPackageData, setWeightPackageData] = useState({
    minWeight: "",
    maxWeight: "",
    pkgWeight: "",
    minWeightUnit: "",
    maxWeightUnit: "",
    pkgWeightUnit: "",
  });
  const [warehouses, setWarehouses] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    name: "",
    business_name: "",
    _id: "",
  });
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  // Helper function to convert weight based on unit

  const validateForm = () => {
    const errors = {};

    // Dimension validations
    if (!dimensions.width) {
      errors.width = "Width is required";
    } else if (dimensions.width < 0) {
      errors.width = "Width must be greater than 0";
    }
    if (dimensions?.length_unit < 0) {
      errors.length_unit = "Length unit is required";
    }
    if (!dimensions.width_unit) {
      errors.width_unit = "Width unit is required";
    }
    if (!dimensions?.height_unit) {
      errors.height_unit = "Height unit is required";
    }
    if (!dimensions.length) {
      errors.length = "Length is required";
    } else if (dimensions.length < 0) {
      errors.length = "Length must be greater than 0";
    }
    if (!dimensions.length_unit) {
      errors.length_unit = "Length unit is required";
    }
    if (!dimensions.height) {
      errors.height = "Height is required";
    } else if (dimensions.height < 0) {
      errors.height = "Height must be greater than 0";
    }

    // Weight package data validations
    if (weightPackageData?.minWeight < 0) {
      errors.minWeight = "Min Weight must be greater than 0";
    }
    if (weightPackageData?.maxWeight < 0) {
      errors.maxWeight = "Max Weight must be greater than 0";
    }
    if (!weightPackageData?.maxWeight) {
      errors.maxWeight = "Max Weight is required";
    }
    if (!weightPackageData?.minWeight) {
      errors.minWeight = "Min Weight is required";
    }
    if (!weightPackageData.minWeightUnit) {
      errors.minWeightUnit = "Minimum weight unit is required";
    }
    if (!weightPackageData.maxWeightUnit) {
      errors.maxWeightUnit = "Maximum weight unit is required";
    }
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.type) {
      errors.type = "Type is required";
    }
    // Convert minWeight and maxWeight to grams for comparison
    try {
      const minWeightInGrams = convertWeight(
        weightPackageData?.minWeightUnit,
        "g",
        weightPackageData?.minWeight
      );
      const maxWeightInGrams = convertWeight(
        weightPackageData?.maxWeightUnit,
        "g",
        weightPackageData?.maxWeight
      );

      // Ensure both weight and their units are valid and comparable
      if (
        weightPackageData?.minWeight >= 0 &&
        weightPackageData?.maxWeight >= 0 &&
        minWeightInGrams > maxWeightInGrams
      ) {
        errors.minWeight = "Min Weight must be less than Max Weight";
        errors.maxWeight = "Max Weight must be greater than Min Weight";
      }
    } catch (error) {
      errors.unitConversion = "Error in weight unit conversion";
    }

    if (!weightPackageData?.pkgWeight) {
      errors.pkgWeight = "Package weight is required";
    } else if (weightPackageData?.pkgWeight < 0) {
      errors.pkgWeight = "Package weight must be greater than 0";
    }
    if (!weightPackageData.pkgWeightUnit) {
      errors.pkgWeightUnit = "Package weight unit is required";
    }

    // Client and warehouse validations
    if (!selectedClient.business_name) {
      errors.client = "Client is required";
    }
    if (!selectedWarehouse) {
      errors.warehouse = "Warehouse is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchWarehouses = async (clientId) => {
    if (clientId) {
      try {
        const response = await wmsService.fetch(clientId, 15, 0);
        setWarehouses(response.data);
      } catch (error) {
        console.error("Fetch failed:", error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (selectedClient) {
      fetchWarehouses(client_id ?? selectedClient._id ?? selectedClient);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedWeightPackage) {
      setDimensions({
        ...(selectedWeightPackage?.dimension || {
          width: "",
          length: "",
          height: "",
        }),
        width_unit: selectedWeightPackage?.dimension?.width_unit || "",
        height_unit: selectedWeightPackage?.dimension?.height_unit || "",
        length_unit: selectedWeightPackage?.dimension?.length_unit || "",
      });
      setWeightPackageData({
        ...weightPackageData,
        minWeight: selectedWeightPackage?.min_weight || "",
        maxWeight: selectedWeightPackage?.max_weight || "",
        pkgWeight: selectedWeightPackage?.pkg_weight || "",
        pkgWeightUnit: selectedWeightPackage?.pkg_weight_unit || "",
        minWeightUnit: selectedWeightPackage?.min_weight_unit || "",
        maxWeightUnit: selectedWeightPackage?.max_weight_unit || "",
      });
      setSelectedClient({
        name: selectedWeightPackage?.client_id?.name || "",
        _id: selectedWeightPackage?.client_id?._id || "",
        business_name: selectedWeightPackage?.client_id?.business_name || "",
      });
      setSelectedWarehouse(
        selectedWeightPackage?.warehouse || { provider: "", _id: "" }
      );
      setFormData({
        ...formData,
        name: selectedWeightPackage?.name || "",
        type: selectedWeightPackage?.type || "",
      });
    } else {
      initialData();
    }
  }, [selectedWeightPackage]);

  const initialData = () => {
    setDimensions({
      width: "",
      length: "",
      height: "",
      width_unit: "",
      length_unit: "",
      height_unit: "",
    });
    setWeightPackageData({
      minWeight: "",
      maxWeight: "",
      pkgWeight: "",
      minWeightUnit: "",
      maxWeightUnit: "",
      pkgWeightUnit: "",
    });
    setSelectedClient({
      name: "",
      business_name: "",
      _id: "",
    });
    setFormData({ name: "", type: "" });
    setSelectedWarehouse(null);
    setFormErrors({});
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await weightPackageService.edit(selectedWeightPackage._id, {
        dimension: dimensions,
        min_weight: weightPackageData?.minWeight,
        min_weight_unit: weightPackageData?.minWeightUnit,
        max_weight: weightPackageData?.maxWeight,
        max_weight_unit: weightPackageData?.maxWeightUnit,
        pkg_weight: weightPackageData?.pkgWeight,
        pkg_weight_unit: weightPackageData?.pkgWeightUnit,
        client_id: client_id || selectedClient._id,
        warehouse: selectedWarehouse._id,
        name: formData.name,
        type: formData.type,
      });
      onAddData && onAddData();
      toast.success("Package mapping edited successfully!");
      initialData();
    } catch (e) {
      console.error(e);
    }
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await weightPackageService.create({
        dimension: dimensions,
        min_weight: weightPackageData?.minWeight,
        min_weight_unit: weightPackageData?.minWeightUnit,
        max_weight: weightPackageData?.maxWeight,
        max_weight_unit: weightPackageData?.maxWeightUnit,
        pkg_weight: weightPackageData?.pkgWeight,
        pkg_weight_unit: weightPackageData?.pkgWeightUnit,
        client_id: client_id || selectedClient._id,
        warehouse: selectedWarehouse._id,
        name: formData.name,
        type: formData.type,
      });
      onAddData && onAddData();
      toast.success("Package mapping Created successfully!");
      initialData();
    } catch (e) {
      console.error(e);
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle id="form-dialog-title">
          {selectedWeightPackage ? "Edit" : "Create"} Weight Package
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <CustomTypography
              fontSize="14px"
              fontWeight={400}
              fontFamily={"poppins"}
            >
              Name <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="name"
              autoFocus
              size="small"
              placeholder="Enter Name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomTypography
              fontSize="14px"
              fontWeight={400}
              fontFamily={"poppins"}
            >
              Type <span style={{ color: "red" }}>*</span>
            </CustomTypography>

            <Select
              labelId="type-label"
              name="type"
              value={formData.type}
              onChange={handleChange}
              size="small"
              fullWidth
              sx={{ mt: 1 }}
            >
              <MenuItem value="polybag">Polybag</MenuItem>
              <MenuItem value="carton">Carton</MenuItem>
            </Select>
          </Grid>

          {client_id ? (
            <></>
          ) : (
            <Grid item xs={12} sm={6}>
              <CustomTypography
                fontSize="14px"
                fontWeight={400}
                fontFamily={"poppins"}
              >
                Client <span style={{ color: "red" }}>*</span>
              </CustomTypography>
              <ClientAutocomplete
                hideLabel={true}
                selectedClient={selectedClient}
                onClientChange={(client) => {
                  setSelectedClient(client);
                  // onSelectClient && onSelectClient(client);
                }}
                errorMessage={formErrors.client}
              />
            </Grid>
          )}
          <Grid item xs={client_id ? 6 : 12} sm={client_id ? 12 : 6}>
            <CustomTypography
              fontSize="14px"
              fontWeight={400}
              fontFamily={"poppins"}
            >
              Warehouse <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <Autocomplete
              disabled={
                client_id ? false : !selectedClient?._id || !selectedClient
              }
              size="small"
              options={warehouses}
              getOptionLabel={(option) => option.provider.name}
              value={selectedWarehouse}
              onChange={(event, newValue) => {
                setSelectedWarehouse(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Warehouse"
                  margin="dense"
                  error={!!formErrors.warehouse}
                  helperText={formErrors.warehouse}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              hideUnit
              unitValue={dimensions?.width_unit}
              inputValue={dimensions.width}
              onChangeText={(e) => setDimensions({ ...dimensions, width: e })}
              unitType={"distance"}
              fieldName={"Width"}
              numberType
              errorMessage={formErrors.width}
              unitError={formErrors.width_unit}
              requiredField={true}
            ></InputWithUnit>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputWithUnit
              hideUnit
              unitValue={dimensions?.length_unit}
              inputValue={dimensions.length}
              onChangeText={(e) => setDimensions({ ...dimensions, length: e })}
              unitType={"distance"}
              fieldName={"Length"}
              numberType
              errorMessage={formErrors.length}
              unitError={formErrors.length_unit}
              requiredField={true}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              hideUnit
              unitValue={dimensions?.height_unit}
              inputValue={dimensions.height}
              onChangeText={(e) => setDimensions({ ...dimensions, height: e })}
              unitType={"distance"}
              fieldName={"Height"}
              numberType
              errorMessage={formErrors.height}
              unitError={formErrors.height_unit}
              requiredField={true}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DimensionsAndWeightInputWithUnit
              dimensionUnitValue={dimensions?.height_unit}
              onChange={(e) =>
                setDimensions({
                  ...dimensions,
                  width_unit: e.value,
                  length_unit: e.value,
                  height_unit: e.value,
                })
              }
              requiredField
              sm={12}
              showOnlyDimensionUnit
              showOnlyUnitInput
            ></DimensionsAndWeightInputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              hideUnit
              unitValue={weightPackageData?.minWeightUnit}
              inputValue={weightPackageData?.minWeight}
              onChangeText={(e) =>
                setWeightPackageData({ ...weightPackageData, minWeight: e })
              }
              unitType={"weight"}
              fieldName={"Min Weight"}
              numberType
              errorMessage={formErrors.minWeight}
              unitError={formErrors.minWeightUnit}
              requiredField={true}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              hideUnit
              unitValue={weightPackageData?.maxWeightUnit}
              inputValue={weightPackageData?.maxWeight}
              onChangeText={(e) =>
                setWeightPackageData({ ...weightPackageData, maxWeight: e })
              }
              unitType={"weight"}
              fieldName={"Max Weight"}
              numberType={true}
              errorMessage={formErrors.maxWeight}
              unitError={formErrors.maxWeightUnit}
              requiredField={true}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              hideUnit
              unitValue={weightPackageData?.pkgWeightUnit}
              inputValue={weightPackageData?.pkgWeight}
              onChangeText={(e) =>
                setWeightPackageData({ ...weightPackageData, pkgWeight: e })
              }
              unitType={"weight"}
              fieldName={"Package Weight"}
              numberType={true}
              errorMessage={formErrors.pkgWeight}
              unitError={formErrors.pkgWeightUnit}
              requiredField={true}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DimensionsAndWeightInputWithUnit
              weightUnitValue={weightPackageData.minWeightUnit}
              onChange={(e) =>
                setWeightPackageData({
                  ...weightPackageData,
                  minWeightUnit: e.value,
                  maxWeightUnit: e.value,
                  pkgWeightUnit: e.value,
                })
              }
              requiredField
              sm={12}
              showOnlyWeightUnit
              showOnlyUnitInput
            ></DimensionsAndWeightInputWithUnit>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedWeightPackage ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
        >
          {selectedWeightPackage ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WeightPackageModal;
