import axiosInstance from "../axiosInstance";
export const manifestService = {
  fetchManifest: async (client_id, query, signal) => {
    try {
      let apiQuery = `/manifest?$sort[createdAt]=-1`;

      if (client_id) {
        apiQuery += `&client_id=${client_id}`;
      }
      // if (searchValue) apiQuery += `&manifest.id[$search]=${searchValue}`;
      if (query.length) apiQuery += `&${query}`;
      const response = await axiosInstance.get(apiQuery, signal);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  manifestDocument: async (obj) => {
    try {
      let response = await axiosInstance.post("/manifest-document", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  packingList: async (obj) => {
    try {
      let response = await axiosInstance.post("/manifest-packing-list", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchIds: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(`/fetch-manifest-id?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchEntryPoint: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/manifest-entrypoint-list?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCarrier: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/fetch-manifest-carrier?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
