import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
  ListItem,
  Tooltip,
  Paper,
  List,
} from "@mui/material";
import { productService } from "../../utils/services/productService"; // Assuming you have this service
import debounce from "lodash.debounce";
import { isMobile } from "../../globalStyles/mobile";

const ItemAutocomplete = ({
  selectedItem,
  onItemChange,
  clientId,
  width = 200,
  label = "Select Item",
  errorMessage,
  hideLabel,
}) => {
  const [skuList, setSKUList] = useState([]);
  const [itemSearchValue, setItemSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  // Fetch SKU data
  const fetchSKU = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await productService.fetchSKU(
          clientId,
          searchQuery,
          10,
          pageNumber
        );
        if (pageNumber === 0) {
          setSKUList(response.data);
        } else {
          setSKUList((prevData) => [...prevData, ...response.data]);
        }
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    [clientId]
  );

  console.log(page);

  // Fetch the data when the search value or page changes
  useEffect(() => {
    fetchSKU(itemSearchValue, page);
  }, [itemSearchValue, page, fetchSKU]);

  // Reset search when the search value is empty
  useEffect(() => {
    if (itemSearchValue === "") {
      fetchSKU("", 0); // Fetch all items when search value is empty
    }
  }, [itemSearchValue, fetchSKU]);

  // Handle the item change selection
  const handleItemChange = (event, value) => {
    if (value && value.name === "Load More") {
      return;
    }
    if (onItemChange) {
      onItemChange(value);
    }
    setItemSearchValue("");
  };

  // Handle input value change for search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setItemSearchValue(value);
    setPage(0);
    setSKUList([]);
  };

  // Handle Load More action
  const handleLoadMoreClick = () => {
    if (!loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add "Load More" option every 10 items if more data is available
  const optionsWithLoadMore = [...skuList];
  if (skuList.length % 10 === 0) {
    optionsWithLoadMore.push({ name: "Load More" });
  }

  return (
    <Autocomplete
      size="small"
      id="item-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) =>
        `${option?.variants?.sku || ""} ${
          option?.name ? `(${option.name})` : ""
        }` || ""
      }
      value={selectedItem || null}
      onChange={handleItemChange}
      renderOption={(props, option) =>
        option.name === "Load More" ? (
          <li
            {...props}
            onClick={() => handleLoadMoreClick()}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <ListItem
            {...props}
            title={`${option?.variants?.sku || ""} ${
              option?.name ? `(${option.name})` : ""
            }`}
          >
            <Typography
              variant="body2"
              sx={{
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {`${option?.variants?.sku || ""} ${
                option?.name ? `(${option.name})` : ""
              }`}
            </Typography>
          </ListItem>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={hideLabel ? "" : label}
          error={!!errorMessage}
          helperText={errorMessage}
          placeholder="Select Item"
          margin="dense"
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{
        minWidth: width,
        marginRight: 10,
        boxShadow: isMobile() ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none",
      }}
    />
  );
};

export default ItemAutocomplete;
