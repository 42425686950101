import React, { useEffect, useState } from 'react'
import { Modal, Box, Button } from '@mui/material'
import styles from './index.module.css'
import { toast } from 'react-toastify'

function PasswordGeneratorModal({ open, setOpen, href, apiSecretKey, validateKey, email }) {
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const openWindows = {}
  useEffect(() => {
    // Attempt to open a new window
    if (apiSecretKey && validateKey && email) {
      // Check if the href is already open
      if (openWindows[href] && !openWindows[href].closed) {
        console.log('Window is already open.')
        return
      } else {
        const newWindow = window.open(href, '_blank', 'noopener,noreferrer')
        // Save a reference to the newly opened window
        openWindows[href] = newWindow
      }
    }
  }, [apiSecretKey, validateKey])
  return (
    // <Modal
    //   open={open}
    //   // onClose={handleClose}
    //   aria-labelledby="password-generator-modal-title"
    //   aria-describedby="password-generator-modal-description"
    //   slotProps={{
    //     backdrop: {
    //       style: {
    //         backgroundColor: '#FFFFFF33',
    //         backdropFilter: 'blur(11.299999237060547px)'
    //       }
    //     } // Semi-transparent white background
    //   }}
    // >
    <Box className={styles.modalBox}>
      {/* Password Generator content inside the modal */}
      <main className={styles.container}>
        <div className={styles.content}>
          <section className={styles.imageColumn}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0725fc004080d8eda178997cbdaabda6396d3672422be76aec4e327381de86f6?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
              className={styles.passwordImage}
              alt="Password generation illustration"
            />
          </section>
          <section className={styles.textColumn}>
            <div className={styles.textContent}>
              {/* <button
                  onClick={handleClose}
                  className={styles.generateButton}
                  aria-label="Generate Password"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/84021e35c7ebc14ca634924231d1e051098e5cc7816121f4f56321dacd7d6834?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                    className={styles.buttonIcon}
                    alt=""
                  />
                </button> */}
              <h1 className={styles.title}>Generate Password</h1>
              <p className={styles.description}>
                To complete the process, click on the "Generate Password" button below.
                <br />
                <br />
                <a style={{ color: '#046AFF' }} href={href} target="_blank" rel="noopener noreferrer">
                  Click here to generate your password
                </a>
                <br />
                <br />
                Once you click the button, a secure password will be created for your account.
              </p>
              <p className={styles.note}>
                Note:
                <ul style={{ marginTop: 0, marginLeft: -10 }}>
                  <li>Make sure to save the password securely.</li>
                  <li>You can use this password to log in to your account.</li>
                </ul>
              </p>
            </div>
          </section>
        </div>
        <div className={styles.warningText}>
          <p style={{ fontWeight: '600' }}>
            Warning: If you have already generated a password, please refresh the window.
          </p>
        </div>
      </main>
    </Box>
    // </Modal>
  )
}

export default PasswordGeneratorModal
