import React, { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Grid
} from '@mui/material'
import { ShopifyAppConfigService } from '../../utils/services/shopify-app-config-service'
import DashboardContainer from '../../components/dashboard-container'
import ShopifyAppConfigList from '../../components/shopify-config-list'

const ShopifyAppConfig = () => {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    shop_name: '',
    api_key: '',
    api_secret: '',
    name: '',
    business_name: '',
    email: '',
    contact: '+1',
    install_url: ''
  })
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const [shopifyConfigData, setShopifyConfigData] = useState([])
  const [loading, setLoading] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setFormData({
      shop_name: '',
      api_key: '',
      api_secret: '',
      name: '',
      business_name: '',
      email: '',
      company_website: '',
      contact: '+1',
      install_url: ''
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    // Ensure contact always starts with +1
    if (name === 'contact') {
      if (!value.startsWith('+1')) {
        return setFormData({ ...formData, [name]: '+1' + value.replace(/^\+1/, '') })
      }
    }
    setFormData({ ...formData, [name]: value })
  }

  const fetchConfigData = async (data) => {
    setLoading(true)
    try {
      const response = await ShopifyAppConfigService.fetch(rowsPerPage, page)
      setShopifyConfigData(response.data)
      setLoading(false)
    } catch (error) {
      console.error('Fetch failed:', error)
      setLoading(false)
    }
  }

  const createShopifyConfig = async (data) => {
    setLoading(true)
    try {
      const response = await ShopifyAppConfigService.create(data)
      fetchConfigData()
      return response
    } catch (error) {
      console.error('Fetch failed:', error)
      setLoading(false)
    }
  }

  const handleSubmit = () => {
    createShopifyConfig(formData)
    console.log('Form Data:', formData)
    handleClose()
  }

  useEffect(() => {
    fetchConfigData()
  }, [])

  return (
    <div>
      <DashboardContainer
        headerButtons={
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Add Shopify Config
            </Button>
          </div>
        }
        header={'Shopify Config List'}
        hideUploadButton
        hideFilterClient
        hideAddButton
        manifest
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
              color: 'white'
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <ShopifyAppConfigList
            onDeleteSuccess={fetchConfigData}
            dataCount={dataCount}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            data={shopifyConfigData}
          />
        )}
      </DashboardContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add Shop Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <TextField
                label="Name "
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Business Name "
                name="business_name"
                value={formData.business_name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email "
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact "
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                fullWidth
                required
                inputProps={{
                  pattern: '\\+1[0-9]*'
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Shop Name "
                name="shop_name"
                value={formData.shop_name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="API Key "
                name="api_key"
                value={formData.api_key}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="API Secret Key "
                name="api_secret"
                value={formData.api_secret}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Install URL "
                name="install_url"
                value={formData.install_url}
                onChange={handleChange}
                fullWidth
                required
                placeholder="https://..."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ShopifyAppConfig
