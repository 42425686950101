import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import AppContext from '../context/context'
import { toast } from 'react-toastify'
import { forgotPasswordService } from '../../utils/services/loginService.js'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
const isStrongPassword = (password) => {
  const strongPasswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).*$/
  return strongPasswordRegex.test(password)
}

function Logo() {
  return (
    <svg
      alt="Company Logo"
      className="self-center max-w-full aspect-[1.22] w-[119px]"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
        <path
          d="M529 4306 c-49 -34 -73 -86 -67 -148 6 -61 31 -96 88 -125 l44 -23
1278 0 1278 0 0 -589 0 -588 22 -33 c13 -18 36 -43 51 -54 28 -21 37 -21 685
-24 l657 -3 117 -152 118 -152 0 -412 0 -413 -154 0 -154 0 -21 63 c-56 167
-221 329 -396 387 -333 111 -704 -68 -814 -392 l-18 -53 -477 0 -477 0 -28 72
c-56 142 -163 260 -301 332 -97 51 -184 73 -290 73 -113 1 -188 -16 -285 -62
-157 -76 -285 -217 -332 -367 l-16 -52 -231 -3 c-254 -3 -272 -7 -320 -71 -31
-42 -30 -140 2 -185 45 -63 62 -67 319 -70 l231 -4 22 -58 c48 -127 176 -273
297 -338 129 -68 319 -92 458 -57 203 51 373 201 446 392 l24 63 481 0 480 0
18 -55 c23 -74 104 -193 174 -255 65 -58 209 -133 290 -149 84 -17 219 -13
307 9 188 48 354 195 428 380 l26 65 258 5 c247 5 260 6 295 28 21 13 45 40
57 66 20 41 21 56 21 587 0 326 -4 558 -10 579 -6 20 -77 121 -166 235 l-156
200 -240 480 c-132 264 -251 495 -265 514 -46 60 -55 61 -452 61 l-359 0 -4
109 c-3 121 -13 144 -78 189 l-33 22 -1396 0 -1396 0 -36 -24z m3691 -936 c88
-176 160 -322 160 -325 0 -3 -205 -5 -455 -5 l-455 0 0 325 0 325 295 0 295 0
160 -320z m-2443 -1641 c76 -29 143 -93 178 -171 24 -54 27 -69 23 -142 -6
-99 -36 -165 -104 -224 -146 -129 -371 -101 -476 60 -90 137 -73 296 44 410
88 85 216 111 335 67z m2227 -8 c199 -97 245 -366 88 -518 -155 -151 -412
-110 -509 80 -91 181 0 394 197 458 50 16 172 5 224 -20z"
        />
        <path
          d="M354 3656 c-61 -27 -101 -108 -85 -177 12 -53 38 -86 85 -109 43 -20
53 -20 774 -18 723 3 731 3 759 24 15 11 38 36 51 54 46 68 18 176 -58 219
-37 21 -42 21 -766 21 -588 -1 -735 -3 -760 -14z"
        />
        <path
          d="M90 3018 c-61 -31 -92 -86 -87 -151 5 -54 27 -91 77 -124 l33 -23
737 0 737 0 33 23 c48 32 72 69 77 118 6 64 -28 127 -87 157 l-44 22 -716 0
-716 0 -44 -22z"
        />
        <path
          d="M343 2380 c-85 -52 -103 -171 -36 -242 54 -58 56 -58 491 -58 398 0
399 0 443 23 88 47 114 164 52 237 -51 61 -43 60 -500 60 -397 0 -420 -1 -450
-20z"
        />
      </g>
    </svg>
  )
}

function InputField({
  icon,
  placeholder,
  onChange,
  type,
  value,
  endAdornment,
  disableIcon,
  togglePasswordVisibility,
  isPasswordVisible,
  disable,
  defaultIcon
}) {
  return (
    <div className="flex gap-5 px-3 py-3.5 mt-5 uppercase whitespace-nowrap rounded border border-white border-solid leading-[143%]">
      {disableIcon ? null : defaultIcon ? (
        defaultIcon
      ) : (
        <img loading="lazy" src={icon} alt="" className="shrink-0 w-5 aspect-square" />
      )}
      <input
        onWheel={(e) => {
          if (type === 'number') e.target.blur() // Blurs the input to prevent scrolling
        }}
        disabled={disable}
        onChange={(e) => onChange(e.target.value)}
        type={type}
        placeholder={placeholder}
        value={value}
        className={`flex-auto my-auto bg-transparent border-none placeholder-grey focus:outline-none ${
          type === 'number' ? 'no-spinner' : ''
        } ${disable ? 'border-gray-500 text-gray-400' : 'border-white text-white'} border`}
      />
      {togglePasswordVisibility && (
        <div className="cursor-pointer w-5 aspect-square" onClick={togglePasswordVisibility}>
          {isPasswordVisible ? (
            <VisibilityOff sx={{ color: '#FFF' }} />
          ) : (
            <Visibility sx={{ color: '#FFF' }} />
          )}
        </div>
      )}
      {endAdornment}
    </div>
  )
}

function ForgotPasswordForm({ setIsAuthenticated }) {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState('')
  const [showOTPForm, setShowOTPForm] = React.useState(false)
  const [otp, setOTP] = React.useState('')
  const [showPasswordForm, setShowPasswordForm] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = React.useState(false)
  const { user, setUser } = React.useContext(AppContext)
  const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible)
  const toggleConfirmPasswordVisibility = () => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
  const handleSendOTP = async (e) => {
    e.preventDefault()
    try {
      if (!email) {
        toast.error('Please enter email', { autoClose: 3000 })
        return
      }
      const response = await forgotPasswordService.sendOTP(email)
      if (response) {
        if (response.password_generation_pending) {
          return navigate(`/onboard-login?email=${email}&otpsent=${true}`)
        }
      }
      setUser(response?.user)
      if (response?.accessToken) {
        setIsAuthenticated(true)
        localStorage.setItem('token', response.accessToken)
        localStorage.setItem('user', JSON.stringify(response.user))
      }
      setShowOTPForm(true)
      toast.success('OTP sent successfully!', { autoClose: 3000 })
    } catch (error) {
      console.error('Error sending OTP:', error)
      toast.error('Failed to send OTP. Please try again.', { autoClose: 3000 })
    }
  }

  const handleSubmitOTP = async (e) => {
    e.preventDefault()
    setShowPasswordForm(false)
    try {
      if (!otp) {
        toast.error('Please enter OTP', { autoClose: 3000 })
        return
      }
      const response = await forgotPasswordService.validateOTP(email, otp)

      if (response.message) {
        toast.success('OTP Verified', { autoClose: 3000 })
        setShowPasswordForm(true)
      } else {
        toast.error(response.message, { autoClose: 3000 })
      }
    } catch (error) {
      console.error('Error validating OTP:', error)
      toast.error('An error occurred. Please try again.', { autoClose: 3000 })
    }
  }

  const handleSubmitPassword = async (e) => {
    e.preventDefault()
    console.log(password, confirmPassword)
    try {
      if (!password || !confirmPassword) {
        toast.error('Please enter password and confirm password', {
          autoClose: 3000
        })
        return
      } else if (password !== confirmPassword) {
        toast.error('Passwords do not match', { autoClose: 3000 })
        return
      } else if (!isStrongPassword(confirmPassword)) {
        toast.error(
          'Password must be at least 4 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character!',
          {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          }
        )
        return
      } else {
        const response = await forgotPasswordService.createPassword(email, password)
        if (response) {
          toast.success('Password confirmed successfully', { autoClose: 3000 })
          setShowPasswordForm(false)
          setShowOTPForm(false)
          navigate('/login')
        } else {
          toast.error(response.message, { autoClose: 3000 })
        }
      }
    } catch (error) {
      console.error('Error confirming password:', error)
      toast.error('An error occurred. Please try again.', { autoClose: 3000 })
    }
  }

  return (
    <form className="flex relative flex-col max-w-full w-[370px] max-md:my-10">
      <>
        <Box mb={4}>
          <Typography gutterBottom fontFamily={'poppins'} color={'#fff'} fontSize={18} fontWeight={600}>
            Reset Password
          </Typography>
          <InputField
            defaultIcon={<PermIdentityIcon sx={{ color: '#FFF' }}></PermIdentityIcon>}
            disable={showOTPForm || showPasswordForm}
            onChange={setEmail}
            type="text"
            placeholder="Email"
            value={email}
          />
          {showOTPForm ? (
            <>
              <Box mb={2}>
                <InputField
                  disable={showPasswordForm}
                  onChange={setOTP}
                  type="number"
                  placeholder="Enter OTP"
                  value={otp}
                  disableIcon={true}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
          {showPasswordForm ? (
            <>
              <Box mb={4}>
                <InputField
                  onChange={setPassword}
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder="Enter new Password"
                  value={password}
                  disableIcon={true}
                  togglePasswordVisibility={togglePasswordVisibility}
                  isPasswordVisible={isPasswordVisible}
                />
                <InputField
                  onChange={setConfirmPassword}
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  disableIcon={true}
                  togglePasswordVisibility={toggleConfirmPasswordVisibility}
                  isPasswordVisible={isConfirmPasswordVisible}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Button
          onClick={(e) => {
            if (showPasswordForm) {
              handleSubmitPassword(e)
            } else if (showOTPForm) {
              handleSubmitOTP(e)
            } else {
              handleSendOTP(e)
            }
          }}
          type="submit"
          className="justify-center items-center w-full py-3 text-lg font-semibold text-white uppercase whitespace-nowrap bg-[#FFC531] rounded-md shadow-sm"
          style={{
            backgroundColor: '#FEC50F',
            color: '#000',
            textTransform: 'none',
            fontFamily: 'poppins',
            fontSize: 16,
            fontWeight: 700
          }}
        >
          {showPasswordForm ? 'Submit' : showOTPForm ? 'Submit OTP' : 'Send OTP'}
        </Button>
      </>
    </form>
  )
}

const ForgotPassword = () => {
  const { setIsAuthenticated, setUser } = React.useContext(AppContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  const isMobile = useMediaQuery('(max-width:750px)')

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuthenticated(true)
      setUser(JSON.parse(localStorage.getItem('user')))
      navigate('/')
    }
  }, [])
  return (
    <main
      style={{ backgroundColor: '#fff' }}
      className="flex flex-row justify-center items-center w-full min-h-screen"
    >
      {!isMobile ? (
        <div
          style={{
            backgroundColor: '#fff',
            display: 'flex',
            flex: 1,
            height: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              marginTop: 20,
              marginLeft: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start'
            }}
          >
            <img
              src="/DropSkipLogo.png"
              alt="Company Logo"
              className="max-w-full aspect-[2.0] w-[137px]"
              style={{ marginBottom: 10, marginLeft: 20 }}
            />
            <Typography
              fontFamily={'poppins'}
              fontSize={'16px'}
              fontWeight={600}
              color={'black'}
              style={{ textAlign: 'center' }}
            >
              Powered by Redefined Logistics
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1
            }}
          >
            <img src="/mobile.png" alt="Company Logo" />
          </div>
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#151D48',
          height: '100vh',
          position: 'relative'
        }}
      >
        {isMobile && (
          <div style={{ position: 'absolute', top: 20, left: 20 }}>
            <img src="/DropSkipLogo.png" alt="Dropskip Logo" className="max-w-full aspect-[2.0] w-[100px]" />
            <Typography
              fontFamily={'poppins'}
              fontSize={'10px'}
              fontWeight={500}
              color={'#fff'}
              style={{ marginTop: 5, textAlign: 'left' }}
            >
              Powered by Redefined Logistics
            </Typography>
          </div>
        )}

        <Button
          variant="outlined"
          style={{
            backgroundColor: '#FFC531',
            color: '#000',
            textTransform: 'none',
            position: 'absolute',
            right: 20,
            top: 25
          }}
          onClick={() => window.open('https://www.redefinedlogistics.com/contact', '_blank')}
        >
          Contact Us
        </Button>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <ForgotPasswordForm />
        </div>
      </div>
    </main>
  )
}

export default ForgotPassword
