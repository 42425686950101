// api.js

import axiosInstance from '../axiosInstance'

export const ShopifyAppConfigService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/shopify-app-config', {
        ...data
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  fetch: async (limit, skip, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/shopify-app-config?$limit=${limit || 15}&$skip=${skip || 0}&${
          searchValue ? `&name[$search]=${searchValue}` : ''
        }`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/shopify-app-config/${id}`, {
        ...data
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/shopify-app-config/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
