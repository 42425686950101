import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  IconButton,
  Grid,
  Paper,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import { wmsService } from "../../utils/services/wmsProvider";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";

const WMSProviderForm = ({
  selectedClient,
  handleClose,
  selectedData,
  onAddProviderData,
  onDeleteSuccess,
  setLoading,
  providerList,
}) => {
  const InitialData = [
    {
      provider: {},
      country: "",
      priority: "",
      consignor_name: "",
      email: "",
      contact_number: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      postal: "",
      endpoint: "",
      authDetails: [{ key: "", value: "" }],
    },
  ];
  const [providers, setProviders] = useState(InitialData);

  const handleAddProvider = () => {
    setProviders((prevProviders) => [
      ...prevProviders,
      {
        provider: {},
        country: "",
        priority: "",
        consignor_name: "",
        email: "",
        contact_number: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        postal: "",
        endpoint: "",
        authDetails: [{ key: "", value: "" }],
      },
    ]);
  };

  const handleRemoveProvider = async (index, provider) => {
    setLoading(true);
    if (selectedData?.length) {
      try {
        const wmsResponse = await wmsService.delete(provider?._id);
        if (wmsResponse) {
          onDeleteSuccess && onDeleteSuccess();
          setProviders((prevProviders) =>
            prevProviders.filter((_, i) => i !== index)
          );
          setLoading(false);
          handleClose && handleClose();
        }
      } catch (error) {
        console.error("Delete failed:", error);
        handleClose && handleClose();
        throw error;
      }
    } else {
      setProviders((prevProviders) =>
        prevProviders.filter((_, i) => i !== index)
      );
      setLoading(false);
    }
  };

  const handleChange = (index, field, value) => {
    const newProviders = [...providers];
    newProviders[index][field] = value;
    setProviders(newProviders);
  };

  const addNewAuthDetails = (e, index) => {
    e.preventDefault();
    const newProviders = [...providers];
    newProviders[index].authDetails = [
      ...(newProviders[index].authDetails || []),
      { key: "", value: "" },
    ];
    setProviders(newProviders);
  };
  const manageAuthDetails = (index, authIndex, field, value) => {
    const newProviders = [...providers];
    newProviders[index].authDetails = newProviders[index].authDetails.map(
      (x, i) => (i == authIndex ? { ...x, [field]: value } : x)
    );
    setProviders(newProviders);
  };
  function isValidConfiguration(config) {
    const requiredFields = [
      "provider",
      "country",
      "priority",
      "consignor_name",
      "email",
      "contact_number",
      "address1",
      "city",
      "province",
      "postal",
      "endpoint",
      "authDetails",
    ];

    return requiredFields.every((field) => config[field] != null);
  }
  useEffect(() => {
    setProviders(selectedData);
  }, [selectedData]);

  async function addConfig(configurations) {
    for (const config of configurations) {
      console.log(config);
      if (isValidConfiguration(config)) {
        if (selectedData?.length) {
          try {
            const response = await wmsService.edit(
              {
                provider: config.provider,
                country: config.country,
                priority: config.priority,
                consignor_name: config.consignor_name,
                email: config.email,
                contact_number: config.contact_number,
                address1: config.address1,
                address2: config.address2,
                city: config.city,
                province: config.province,
                postal: config.postal,
                endpoint: config.endpoint,
                authDetails: config.authDetails,
              },
              config?._id
            );
            console.log(
              `Successfully added configuration for ${config.provider}`
            );
            console.log(response.data);
            handleClose && handleClose();
          } catch (error) {
            console.error(
              `Error adding configuration for ${config.provider}:`,
              error.message
            );
            return;
          }
        } else {
          try {
            // Assuming you have an API endpoint to add configurations
            const response = await wmsService.create(config);
            console.log(
              `Successfully added configuration for ${config.provider}`
            );
            console.log(response.data);
            handleClose && handleClose(); // Optionally, log the response data
          } catch (error) {
            console.error(
              `Error adding configuration for ${config.provider}:`,
              error.message
            );
            return;
          }
        }

        setProviders(InitialData);
        onAddProviderData && onAddProviderData();
      } else {
        console.warn("Skipping configuration:", config, "Incomplete fields");
        toast.error("Please provide all required Information!", {
          autoClose: 3000,
        });
        return;
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    addConfig(
      providers.map((item) => ({ ...item, client_id: selectedClient?._id }))
    );
  };

  const removeAuthDetail = (providerIndex, detailIndex) => {
    setProviders((prevState) => {
      const newState = [...prevState];
      if (newState[providerIndex]?.authDetails) {
        newState[providerIndex].authDetails = newState[
          providerIndex
        ].authDetails.filter((_, i) => i !== detailIndex);
      }
      return newState;
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {providers.map((provider, index) => (
        <Paper
          key={index}
          variant="outlined"
          style={{
            padding: "16px",
            marginTop: "16px",
            background: "#EEEEEE",
            borderRadius: "4px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontWeight={500}>{`Provider ${
                index + 1
              }`}</CustomTypography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                style={{
                  color: "#D13438",
                  padding: 0,
                  background: "#D1343833",
                  height: "30px",
                  width: "30px",
                  borderRadius: "5px",
                  marginLeft: "10px",
                  boxShadow: "0px 0px 4px 0px #00000040",
                  marginTop: "5px",
                }}
                aria-label="delete"
                onClick={() => handleRemoveProvider(index, provider)}
              >
                <img
                  style={{ height: "15px", width: "15px" }}
                  alt="delete"
                  src="/delete.png"
                ></img>
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Provider <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  style={{
                    marginTop: 5,
                    fontSize: 13,
                    backgroundColor: "white",
                    height: 33,
                  }}
                  placeholder="Select Provider"
                  size="small"
                  type="text"
                  value={provider.provider}
                  onChange={(e) =>
                    handleChange(index, "provider", e.target.value)
                  }
                >
                  {providerList.map((data) => (
                    <MenuItem value={data._id} sx={{ height: 30 }}>
                      {data?.name}
                    </MenuItem>
                  ))}
                  {/* <option value="rl">rl</option>
                    <option value="evcargo">evcargo</option> */}
                </Select>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Priority <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Priority"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="number"
                  value={provider.priority}
                  onChange={(e) =>
                    handleChange(index, "priority", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Consignor Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Consignor Name"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.consignor_name}
                  onChange={(e) =>
                    handleChange(index, "consignor_name", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Email <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Email"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.email}
                  onChange={(e) => handleChange(index, "email", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Contact Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Contact Number"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.contact_number}
                  onChange={(e) =>
                    handleChange(index, "contact_number", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Address1 <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Consignor Address1"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.address1}
                  onChange={(e) =>
                    handleChange(index, "address1", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Address2 <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Consignor Address2"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.address2}
                  onChange={(e) =>
                    handleChange(index, "address2", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  City <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="City"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.city}
                  onChange={(e) => handleChange(index, "city", e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Province <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Province"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.province}
                  onChange={(e) =>
                    handleChange(index, "province", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Postal <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Postal"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.postal}
                  onChange={(e) =>
                    handleChange(index, "postal", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Country <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Country"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.country}
                  onChange={(e) =>
                    handleChange(index, "country", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                  Endpoint <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Endpoint"
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: 5,
                    padding: 5,
                    marginTop: 5,
                    fontSize: 13,
                  }}
                  type="text"
                  value={provider.endpoint}
                  onChange={(e) =>
                    handleChange(index, "endpoint", e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                Authentication Details
              </label>

              {provider.authDetails?.map((x, i) => (
                <Grid container spacing={2} key={i} style={{ marginTop: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <label style={{ fontFamily: "Poppins", fontSize: 12 }}>
                      Key <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      placeholder="Key"
                      style={{
                        border: "1px solid #D1D1D1",
                        borderRadius: 5,
                        padding: 5,
                        marginTop: 5,
                        fontSize: 13,
                        width: "100%",
                      }}
                      type="text"
                      value={x.key}
                      onChange={(e) =>
                        manageAuthDetails(index, i, "key", e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <label style={{ fontFamily: "Poppins", fontSize: 12 }}>
                      Value <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ display: "flex", width: "100%" }}>
                      <input
                        placeholder="Value"
                        style={{
                          border: "1px solid #D1D1D1",
                          borderRadius: 5,
                          padding: 5,
                          marginTop: 5,
                          fontSize: 13,
                          width: "100%",
                        }}
                        type="text"
                        value={x.value}
                        onChange={(e) =>
                          manageAuthDetails(index, i, "value", e.target.value)
                        }
                      />
                      <IconButton
                        style={{
                          backgroundColor:
                            i === provider.authDetails.length - 1
                              ? "#DC585C"
                              : "transparent",
                          width: 30,
                          height: 30,
                          borderRadius: "4px",
                          marginLeft: 10,
                          color: "#fff",
                          marginTop: 3,
                        }}
                        onClick={(e) =>
                          i === provider.authDetails.length - 1
                            ? addNewAuthDetails(e, index)
                            : removeAuthDetail(index, i)
                        }
                      >
                        {i === provider.authDetails.length - 1 ? (
                          <AddIcon style={{ color: "#fff" }} />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Paper>
      ))}
      <div
        style={{
          marginTop: "16px",
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {selectedData?.length ? (
          <></>
        ) : (
          <Button onClick={handleAddProvider} style={{ textTransform: "none" }}>
            <CustomTypography
              fontSize={"14px"}
              color={"#0038FF"}
              fontWeight={400}
            >{`+ Add Provider`}</CustomTypography>
          </Button>
        )}
      </div>

      <DialogActions style={{ padding: "16px" }}>
        <Button
          style={{
            fontSize: "12px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          style={{
            fontSize: "12px",
            fontFamily: "Poppins",
            textTransform: "none",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </form>
  );
};

const WMSProviderModal = ({
  setOpen,
  onClose,
  selectedClient,
  selectedData,
  onAddProviderData,
  setLoading,
  loading,
  onDeleteSuccess,
  providerList,
}) => {
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <WMSProviderForm
      onDeleteSuccess={onDeleteSuccess}
      setLoading={setLoading}
      loading={loading}
      onAddProviderData={onAddProviderData}
      selectedData={selectedData}
      handleClose={handleClose}
      selectedClient={selectedClient}
      providerList={providerList}
    />
  );
};

export default WMSProviderModal;
