import axiosInstance from "../axiosInstance";
export const shipmentService = {
  fetch: async (searchValue, client, query) => {
    try {
      let apiQuery = `/shipment?$sort[createdAt]=-1`;

      if (client) {
        apiQuery += `&client_id=${client}`
      }
      // if (searchValue) apiQuery += `&manifest.id[$search]=${searchValue}`;
      if (query.length) apiQuery += `&${query}`;
      const response = await axiosInstance.get(apiQuery);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchEntryPoint: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/manifest-entrypoint-list?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCarrier: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/fetch-manifest-carrier?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  cancelShipment: async (obj) => {
    try {
      let response = await axiosInstance.post("/cancel-shipment", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  cancelLabel: async (obj) => {
    try {
      let response = await axiosInstance.post("/cancel-label", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
