import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  styled,
  tableCellClasses,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  InputAdornment
} from '@mui/material'
import Pagination from '../pagination'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import ViewIcon from '../../globalStyles/icons/vieewIcon'
import CustomTypography from '../../globalStyles/fonts'
import { ShopifyAppConfigService } from '../../utils/services/shopify-app-config-service'
import DeleteConfirmationModal from '../deleteConfirmationModal'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  }
}))

const ShopifyAppConfigList = ({
  data,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  onDeleteSuccess
}) => {
  const [configData, setConfigData] = useState([])
  const startIndex = page * rowsPerPage
  const [openConfirm, setOpenConfirm] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [openViewModal, setOpenViewModal] = useState(false)
  const handleOpenConfirm = (item) => {
    setItemToDelete(item)
    setOpenConfirm(true)
  }

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
    setItemToDelete(null)
  }

  const confirmDelete = () => {
    deleteItem()
    // Implement delete logic here
    handleCloseConfirm()
  }
  useEffect(() => {
    setConfigData(data)
  }, [data])
  const deleteItem = async () => {
    try {
      const response = await ShopifyAppConfigService.delete(itemToDelete?._id)
      onDeleteSuccess && onDeleteSuccess()
      return response
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
    // alert(`Copied: ${text}`) // You can replace this with a Snackbar or toast notification
  }

  const handleView = (item) => {
    setOpenViewModal(true)
    setSelectedItem(item)
  }

  const renderListView = () => (
    <div style={{ width: '100%', overflow: 'auto', paddingBottom: 20, marginTop: 5 }}>
      <TableContainer sx={{ overflowY: 'auto', paddingInline: 0.4, maxHeight: '100%' }}>
        <Table stickyHeader aria-label="Shopify App Configurations" sx={{ border: '1px solid #EAECF0' }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <CustomTypography fontWeight={500} fontSize={14}>
                  SL NO
                </CustomTypography>
              </StyledTableCell>
              <StyledTableCell>
                <CustomTypography fontWeight={500} fontSize={14}>
                  Shop Name
                </CustomTypography>
              </StyledTableCell>
              <StyledTableCell>
                <CustomTypography fontWeight={500} fontSize={14}>
                  App URL
                </CustomTypography>
              </StyledTableCell>
              <StyledTableCell>
                <CustomTypography fontWeight={500} fontSize={14}>
                  Callback URL
                </CustomTypography>
              </StyledTableCell>
              <StyledTableCell>
                <CustomTypography fontWeight={500} fontSize={14}>
                  Action
                </CustomTypography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {configData.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={5} align="center">
                  <Typography>No data available</Typography>
                </TableCell>
              </StyledTableRow>
            ) : (
              configData.map((item, index) => (
                <StyledTableRow key={item._id}>
                  <StyledTableCell>{startIndex + index + 1}</StyledTableCell>
                  <StyledTableCell>
                    <CustomTypography fontSize={14}>{item.shop_name}</CustomTypography>
                  </StyledTableCell>
                  <StyledTableCell style={{ maxWidth: 200 }}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CustomTypography fontSize={14}>{item.app_url}</CustomTypography>
                      <Tooltip title="Copy App URL">
                        <IconButton size="small" onClick={() => handleCopy(item.app_url)}>
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell style={{ maxWidth: 200 }}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CustomTypography fontSize={14}>{item.callback_url}</CustomTypography>
                      <Tooltip title="Copy Callback URL">
                        <IconButton size="small" onClick={() => handleCopy(item.callback_url)}>
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell style={{ display: 'flex' }}>
                    <Button onClick={() => handleView(item)} style={{ textTransform: 'none' }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        width="100%"
                        gap={1}
                      >
                        <ViewIcon />
                      </Box>
                    </Button>

                    <Button onClick={() => handleOpenConfirm(item)} style={{ textTransform: 'none' }}>
                      <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
                        <DeleteIcon />
                      </Box>
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )

  return (
    <>
      <div>
        {renderListView()}
        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          title={`Are you sure to delete this shop ${itemToDelete?.shop_name}`}
          handleDelete={confirmDelete}
          handleClose={handleCloseConfirm}
          open={openConfirm}
        ></DeleteConfirmationModal>
        {/* <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="delete-confirmation-dialog"
          aria-describedby="delete-confirmation-description"
        >
          <DialogTitle id="delete-confirmation-dialog">Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-confirmation-description">
              Are you sure you want to delete this configuration? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog> */}
        <Dialog open={openViewModal} onClose={() => setOpenViewModal(false)}>
          <DialogTitle>View Configuration</DialogTitle>
          <DialogContent style={{ width: '30vw' }}>
            {selectedItem && (
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  sx={{ marginTop: 1 }}
                  label="Shop Name"
                  value={selectedItem.shop_name}
                  variant="outlined"
                  fullWidth
                  disabled
                />
                <TextField
                  label="App URL"
                  value={selectedItem.app_url}
                  variant="outlined"
                  fullWidth
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Copy App URL">
                          <IconButton onClick={() => handleCopy(selectedItem.app_url)}>
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  label="Callback URL"
                  value={selectedItem.callback_url}
                  variant="outlined"
                  fullWidth
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Copy Callback URL">
                          <IconButton onClick={() => handleCopy(selectedItem.callback_url)}>
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenViewModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default ShopifyAppConfigList
