// api.js

import axiosInstance from "../axiosInstance";

export const zoneService = {
  create: async (zoneData) => {
    try {
      let response = await axiosInstance.post("/zone", {
        ...zoneData,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, text) => {
    try {
      let response = await axiosInstance.get(
        `/zone?$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}` : ""
        }&${text ? `&name[$search]=${text}` : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (zoneData, id) => {
    try {
      let response = await axiosInstance.patch(`/zone/${id}`, {
        ...zoneData,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/zone/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
