import React, { useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Popover,
  Button,
  styled,
  tableCellClasses,
  Badge,
} from "@mui/material";
import AppContext from "../context/context";
import { isMobile } from "../../globalStyles/mobile";
import Pagination from "../pagination";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import FilterModal from "../filterModal";
import { useTheme } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import OverpackViewModal from "../overpack-view-modal";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import { shipmentService } from "../../utils/services/shipmentService";
import { toast } from "react-toastify";
import ShipmentViewModal from "../shipment-view-modal";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const Shipments = ({
  data,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  onChangeFilter,
  filterData,
  onDeleteData,
}) => {
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;
  const { user } = useContext(AppContext);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: "",
    filter: [],
    sort: 0,
    id: "_id",
    displayKey: "name",
    fetchApi: () => { },
    loadMoreButton: false,
    options: [],
    searchKey: "",
  });
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const openPopover = Boolean(anchorElPopover);
  const startIndex = page * rowsPerPage;
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [type, setType] = useState("");
  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilterModal(true);
    setSelectedFilterData(filterData.columns.find((x) => x.key == filterKey));
  };

  const handleSaveFilter = (
    selectedOptions,
    filterKey,
    sortOrder,
    searchKey
  ) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey,
    });
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilterModal(false);
  };

  const handleViewClick = (item) => {
    console.log(item);
    setOpenViewModal(true);
    handlePopoverClose();
  };
  const handleDelete = async () => {
    try {
      if (type === "shipment") {
        await shipmentService.cancelShipment({
          tms: selectedShipment.tms,
          order: selectedShipment.order,
          shipment: selectedShipment.shipment,
          _id: selectedShipment._id
        });
        toast.success("Shipment deleted successfully");
      } else {
        await shipmentService.cancelLabel({
          tms: selectedShipment.tms,
          response: selectedShipment.response,
          order: selectedShipment.order,
          shipment: selectedShipment.shipment,
          _id: selectedShipment._id
        });
        toast.success("Label cancelled successfully");
      }
      onDeleteData && onDeleteData();
      setOpenConfirmation(false);
    } catch (e) {
      // toast.error("Failed to process");
      console.error(e);
    }
  };
  const handlePopoverOpen = (event, item) => {
    event.stopPropagation();
    setAnchorElPopover(event.currentTarget);
    setSelectedShipment(item);
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", minHeight: "100%" }}
      />
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Leg
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.leg}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Code
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.code}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Description
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.description}
                        </Typography>
                      </Grid>
                      {user.role == "admin" ? (
                        <Grid item xs={6}>
                          <Typography
                            fontSize={14}
                            fontFamily="poppins"
                            color="##333333"
                            fontWeight={600}
                            textAlign="left"
                          >
                            Optional
                          </Typography>
                        </Grid>
                      ) : null}
                      {user.role == "admin" ? (
                        <Grid item xs={6}>
                          <Typography
                            fontSize={14}
                            fontFamily="Poppins"
                            color="#606060"
                            fontWeight={500}
                            noWrap
                            overflow="hidden"
                            textOverflow="ellipsis"
                            textAlign="left"
                          >
                            : {x?.optional == true ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                      ) : (
                        <> </>
                      )}
                      {user.role == "admin" ? (
                        <Grid item xs={6}>
                          <Typography
                            fontSize={14}
                            fontFamily="poppins"
                            color="##333333"
                            fontWeight={600}
                            textAlign="left"
                          >
                            Sysytem Generated
                          </Typography>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {user.role == "admin" ? (
                        <Grid item xs={6}>
                          <Typography
                            fontSize={14}
                            fontFamily="Poppins"
                            color="#606060"
                            fontWeight={500}
                            noWrap
                            overflow="hidden"
                            textOverflow="ellipsis"
                            textAlign="left"
                          >
                            : {x?.system_generated == true ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Box>

                  <Box mt={2} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Shipment ID
                    </Typography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find(
                          (item) => item.key.toLowerCase() === "shipment"
                        )?.filter?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, "shipment")}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Order ID
                    </Typography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find(
                          (item) => item.key.toLowerCase() === "order_id"
                        )?.filter?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenFilter(event, "order_id")}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Tracking Number
                    </Typography>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find(
                          (item) => item.key.toLowerCase() === "tracking_number"
                        )?.filter?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) =>
                          handleOpenFilter(event, "tracking_number")
                        }
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Weight
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Dimension
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Overpack
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Manifest
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  ></Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={7} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                data?.map((x, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        ml={1}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.shipment}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.order_id}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {
                          x?.response?.shipment?.labels?.find(
                            (x) => !x.cancelled
                          )?.tracking_number
                        }
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {`${x?.weight}(${x?.weight_unit})`}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          fontFamily: "poppins",
                        }}
                      >
                        {`${Math.round(
                          x?.dimension?.length || 0
                        )} * ${Math.round(
                          x?.dimension?.width || 0
                        )} * ${Math.round(x?.dimension?.height || 0)} (${x?.dimension?.unit
                          })`}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          fontFamily: "poppins",
                        }}
                      >
                        {x?.overpack ? "Done" : ""}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          fontFamily: "poppins",
                        }}
                      >
                        {x?.manifest ? "Done" : ""}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                      <Box sx={{ display: "flex", boxShadow: "none" }}>
                        <IconButton
                          aria-label="more actions"
                          onClick={(e) => handlePopoverOpen(e, x)}
                          sx={{
                            color: openPopover ? "primary" : "inherit",
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Popover
                          open={Boolean(anchorElPopover)}
                          anchorEl={anchorElPopover}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: isMobile() ? "bottom" : "top",
                            horizontal: isMobile() ? "center" : "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #EAECF0",
                              borderRadius: "4px",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "10px",
                              boxShadow: "none",
                              gap: 2,
                            }}
                          >
                            <Button
                              style={{
                                color: "#333333",
                                justifyContent: "flex-start",
                                textTransform: "none",
                              }}
                              onClick={() => handleViewClick(x)}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                width="100%"
                                gap={1}
                              >
                                <ViewIcon />
                                <Typography
                                  fontFamily={"poppins"}
                                  fontSize={"16px"}
                                  color={"#333333"}
                                  fontWeight={500}
                                >
                                  View
                                </Typography>
                              </Box>
                            </Button>

                            <Button
                              style={{
                                justifyContent: "flex-start",
                                textTransform: "none",
                                cursor: selectedShipment?.overpack || selectedShipment?.response.shipment.labels.find((x) => x.cancelled)
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setType("label");
                                if (selectedShipment?.response.shipment.labels.find((x) => !x.cancelled) && !selectedShipment?.overpack) {
                                  setOpenConfirmation(true);
                                }
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                width="100%"
                                gap={1}
                              >
                                <DeleteIcon
                                  fill={
                                    selectedShipment?.overpack || selectedShipment?.response.shipment.labels.find((x) => x.cancelled) ? "grey" : null
                                  }
                                />
                                <Typography
                                  fontFamily={"poppins"}
                                  fontSize={"16px"}
                                  color={
                                    selectedShipment?.overpack || selectedShipment?.response.shipment.labels.find((x) => x.cancelled)
                                      ? "grey"
                                      : primary_color
                                  }
                                  fontWeight={500}
                                >
                                  Cancel Label
                                </Typography>
                              </Box>
                            </Button>
                            <Button
                              style={{
                                // color: "#333333",
                                justifyContent: "flex-start",
                                textTransform: "none",
                                cursor: selectedShipment?.overpack
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setType("shipment");
                                if (!selectedShipment?.overpack) {
                                  setOpenConfirmation(true);
                                }
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                width="100%"
                                gap={1}
                              >
                                <DeleteIcon
                                  fill={
                                    selectedShipment?.overpack ? "grey" : null
                                  }
                                />
                                <Typography
                                  fontFamily={"poppins"}
                                  fontSize={"16px"}
                                  color={
                                    selectedShipment?.overpack
                                      ? "grey"
                                      : primary_color
                                  }
                                  fontWeight={500}
                                >
                                  Delete Shipment
                                </Typography>
                              </Box>
                            </Button>
                          </Box>
                        </Popover>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {renderListView()}
        </div>

        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          disableNext={dataCount <= (page + 1) * filterData.limit}
        />
        <ShipmentViewModal
          data={selectedShipment}
          open={openViewModal}
          onClose={() => {
            setOpenViewModal(false);
          }}
          primary_color={primary_color}
        />
        <DeleteConfirmationModal
          title={`Are you sure you want to  ${type == "shipment" ? "delete shipment" : "cancel label"
            } ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={(e) => {
            handleDelete();
          }}
          deleteButtonTitle={type == "shipment" ? "Delete" : "Okay"}
        />
        <Popover
          open={Boolean(anchorElFilter)}
          anchorEl={anchorElFilter}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
        >
          <FilterModal
            open={openFilterModal}
            onClose={handleCloseFilter}
            options={selectedfilterData?.options}
            selectedValues={selectedfilterData?.filter}
            onSave={handleSaveFilter}
            currentSortOrder={selectedfilterData?.sort}
            filterKey={selectedfilterData?.key}
            hasMore={selectedfilterData?.loadMoreButton}
            displayKey={selectedfilterData?.displayKey}
            id={selectedfilterData?.id}
            fetchApi={selectedfilterData?.fetchApi}
            searchKey={selectedfilterData?.searchKey}
          />
        </Popover>
      </div>
    </>
  );
};

export default Shipments;
