import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const EventDetailsModal = ({ event, open, onClose, clientId }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Event Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize="14px" fontWeight={400}>
                Leg
              </CustomTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                type="text"
                size="small"
                margin="dense"
                placeholder="Event Name"
                value={event?.leg || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTypography fontSize={14} fontWeight={400}>
                Code
              </CustomTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={event?.code || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>

            {clientId ? (
              <></>
            ) : (
              <Grid item xs={12} md={6}>
                <CustomTypography fontSize={14} fontWeight={400}>
                  Optional
                </CustomTypography>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#7C7C7C",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                  size="small"
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={
                    event?.optional !== undefined
                      ? event.optional
                        ? "Yes"
                        : "No"
                      : ""
                  }
                  InputProps={{
                    readOnly: true,
                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                  }}
                />
              </Grid>
            )}
            {clientId ? (
              <></>
            ) : (
              <Grid item xs={12} md={6}>
                <CustomTypography fontSize={14} fontWeight={400}>
                  System Generated
                </CustomTypography>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#7C7C7C",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                  size="small"
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={
                    event?.system_generated !== undefined
                      ? event.system_generated
                        ? "Yes"
                        : "No"
                      : ""
                  }
                  InputProps={{
                    readOnly: true,
                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <CustomTypography fontSize={14} fontWeight={400}>
                Description
              </CustomTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={event?.description || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
                multiline
                rows={6}
              />
            </Grid>
            {clientId && (
              <Grid item xs={12} md={12}>
                <CustomTypography fontSize={14} fontWeight={400}>
                  Client Description
                </CustomTypography>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#7C7C7C",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                  size="small"
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={event?.client_description || ""}
                  InputProps={{
                    readOnly: true,
                    style: { color: "#000", backgroundColor: "#f0f0f0" },
                  }}
                  multiline
                  rows={6}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EventDetailsModal;
