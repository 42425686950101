// api.js

import axios from "axios";
import axiosInstance from "../axiosInstance";
import config from "../../config";
export const orderService = {
  fetch: async (client_id, searchValue, query, signal) => {
    try {
      let api_query = `/order?`;

      if (client_id) {
        api_query += `&client_id=${client_id}`;
      }

      if (searchValue) {
        api_query += `&order_id[$search]=${searchValue}`;
      }

      if (query.length) {
        api_query += `&${query}&$sort[createdAt]=-1`;
      }
      // if (operationFlag) {
      //   api_query += `&publishToClient=${true}`;
      // }

      const response = await axiosInstance.get(api_query, signal);
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    }
  },

  get: async (id) => {
    try {
      let response = await axiosInstance.get(
        `/order/${id}?$populate=client_id`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/order/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  trackOrder: async (orderId) => {
    try {
      const response = await axiosInstance.get(`/track-order/${orderId}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Error fetching tracking details: Page not found");
        return { error: "Tracking details not found" };
      } else {
        console.error("Error fetching tracking details:", error);
        return { error: "An unexpected error occurred" };
      }
    }
  },

  fetchOrderTrail: async (orderId) => {
    try {
      const response = await axiosInstance.get(
        `/order-trail-custom/${orderId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchOrderTrailWithoutAuth: async (orderId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}/order-trail-custom/${orderId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  exportOrders: async (clientId, query) => {
    try {
      let api_query = `/export-orders?`;

      if (clientId) {
        api_query += `&client_id=${clientId}`;
      }
      if (query.length) {
        api_query += `&${query}`;
      }
      let response = await axiosInstance.get(api_query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  cancel: async (obj) => {
    try {
      let response = await axiosInstance.post("/cancel-order", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetchOrderId: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client_id=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }

      let response = await axiosInstance.get(`/fetch-orderid?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCustomerName: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client_id=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }

      let response = await axiosInstance.get(`/fetch-customer-name?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCustomerState: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client_id=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }

      let response = await axiosInstance.get(`/fetch-customer-state?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchShipmentId: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client_id=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }

      let response = await axiosInstance.get(`/fetch-shipmentid?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchOrderDate: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client_id=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }

      let response = await axiosInstance.get(`/fetch-order-date?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetchStats: async (obj) => {
    try {
      let response = await axiosInstance.post("/stats", obj);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  fetchTrackingNumber: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client_id=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }

      let response = await axiosInstance.get(`/fetch-tracking-number?${query}`);
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
