import axios from 'axios'
import axiosInstance from '../axiosInstance'
import config from '../../config'

export const clientService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/client', data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetch: async (limit, skip, name, email) => {
    try {
      let response = await axiosInstance.get(
        `/client?$sort[_id]=-1&$limit=${limit || 15}&$skip=${skip || 0}&${
          name ? `business_name[$search]=${name}` : ''
        }&${email ? `email=${email}` : ''}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  // fetch: async (query) => {
  //   try {
  //     let api_query = `/client?$sort[_id]=-1`;
  //     if (query.length) api_query += `&${query}`;
  //     const response = await axiosInstance.get(api_query);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  get: async (id) => {
    try {
      let response = await axiosInstance.get(`/client/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/client/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/client/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  sendActivationKey: async (id, email) => {
    try {
      let response = await axiosInstance.post('/send-activation-key', {
        clientId: id,
        email: email
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetchClientByUser: async (id) => {
    try {
      let response = await axiosInstance.get(`/client_user_mapping?user=${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  approveClient: async (data) => {
    try {
      let response = await axiosInstance.post(`/approve-client`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  rejectClient: async (data) => {
    try {
      let response = await axiosInstance.post(`/reject-client`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  createPassowrd: async (data) => {
    try {
      let response = await axiosInstance.post(`/create-password`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  verifyOtp: async (data) => {
    try {
      let response = await axiosInstance.post(`/verify-otp`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  sendOtp: async (data) => {
    try {
      let response = await axiosInstance.post(`/send-otp`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  updatePassword: async (data) => {
    try {
      let response = await axiosInstance.post(`/change-password`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetchBusinessName: async (searchKey, limit = 15, skip = 0) => {
    try {
      let api_query = `/client?$sort[_id]=-1&$limit=${limit}&$skip=${skip}`
      if (searchKey) api_query += `&business_name[$search]=${searchKey}`
      const response = await axiosInstance.get(api_query)
      return response.data
    } catch (error) {
      throw error
    }
  },
  checkIfRegisted: async (id) => {
    try {
      let response = await axios.get(`${config.baseURL}/check-registrations/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
