import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
  Box,
  Tooltip,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";
import { toast } from "react-toastify";
import { overpackService } from "../../utils/services/overpackService";

const OverpackViewModal = ({
  data,
  open,
  onClose,
  edit = false,
  primary_color,
  onEditData
}) => {
  const [overpack, setOverpack] = useState({});
  const [removedShipments, setRemovedShipments] = useState([]);
  useEffect(() => {
    if (data) {
      setOverpack(data);
    }
  }, [data]);

  const onUpdate = async () => {
    try {
      console.log(overpack);
      console.log(removedShipments);
      await overpackService.updateOverpack({
        _id: overpack._id,
        client_id: overpack.client_id,
        overpack_id: overpack.overpack.id,
        shipment_ids: removedShipments
      });
      onEditData && onEditData();
      toast.success("Overpack edited successfully!");
      onCloseClick()

    } catch (e) {
      // toast.error("Failed to edit Overpack.");
      console.error(e);
    }
  };

  const removeShipment = (shipment) => {
    const updatedShipments = overpack.overpack.shipments.filter(
      (s) => s.id !== shipment.id
    );
    setRemovedShipments((prevRemovedShipments) => {
      if (!prevRemovedShipments.includes(shipment.id)) {
        return [...prevRemovedShipments, shipment.id];
      }
      return prevRemovedShipments; // Return unchanged if ID already exists
    });
    setOverpack({
      ...overpack,
      overpack: { ...overpack.overpack, shipments: updatedShipments },
    });
  };

  const onCloseClick = () => {
    setOverpack({});
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseClick} maxWidth="md">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>
            {edit ? "Update Overpack" : "Overpack Details"}
          </DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onCloseClick}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Id
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="overpack Name"
                value={overpack?.overpack?.id || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Carrier
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={overpack?.overpack?.carrier || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Entry Point
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="overpack Name"
                value={overpack?.overpack?.entry_point || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Exit Point
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="overpack Name"
                value={overpack?.overpack?.exit_point || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            {overpack?.overpack?.shipments?.map((shipment) => (
              <Grid
                sx={{
                  border: "1px solid #E0E0E0",
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
                item
                xs={12}
                sm={12}
              >
                <Grid item xs={12} sm={edit ? 2.5 : 3} sx={{ marginRight: 2 }}>
                  <CustomTypography
                    color="#11193F"
                    fontSize="14px"
                    fontWeight={400}
                  >
                    Shipment
                  </CustomTypography>
                  <TextField
                    type="text"
                    size="small"
                    margin="dense"
                    placeholder="overpack Name"
                    value={shipment.id}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { color: "#000", backgroundColor: "#f0f0f0" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={edit ? 2.5 : 3} sx={{ marginRight: 2 }}>
                  <CustomTypography
                    color="#11193F"
                    fontSize="14px"
                    fontWeight={400}
                  >
                    Label ID
                  </CustomTypography>
                  <TextField
                    type="text"
                    size="small"
                    margin="dense"
                    placeholder="overpack Name"
                    value={shipment.label_id}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { color: "#000", backgroundColor: "#f0f0f0" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={edit ? 5 : 6} sx={{ marginRight: 2 }}>
                  <CustomTypography
                    color="#11193F"
                    fontSize="14px"
                    fontWeight={400}
                  >
                    Tracking Number
                  </CustomTypography>
                  <TextField
                    type="text"
                    size="small"
                    margin="dense"
                    placeholder="overpack Name"
                    value={shipment.tracking_number}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { color: "#000", backgroundColor: "#f0f0f0" },
                    }}
                  />
                </Grid>
                {edit ? (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    sx={{ alignContent: "center", cursor: "pointer" }}
                  >
                    <Tooltip title="Remove this shipment?">
                      {/* <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      width="100%"
                      gap={1}
                    > */}
                      <Button
                        onClick={() => removeShipment(shipment)}
                        variant="outlined"
                        style={{
                          textTransform: "none",
                          //   borderRadius: "50%",
                          height: 30,
                          width: 20,
                          marginTop: 20,
                        }}
                        disabled={overpack?.overpack?.shipments.length === 1}

                      >
                        <CloseIcon
                          fill={primary_color}
                          height={15}
                          width={15}
                        />
                      </Button>
                      {/* </Box> */}
                    </Tooltip>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onCloseClick}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          {edit ? (
            <Button
              onClick={() => onUpdate()}
              variant="contained"
              style={{ textTransform: "none" }}
            >
              Update
            </Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default OverpackViewModal;
