import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Box,
  Popover,
  Tooltip,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { emailTemplateService } from "../../utils/services/email-templateService";
import DashboardContainer from "../../components/dashboard-container";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { isMobile } from "../../globalStyles/mobile";
import CustomPagination from "../../components/pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import { tableCellClasses } from "@mui/material/TableCell";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import { useTheme } from "@mui/styles";
import { styled } from "@mui/material/styles";

const EmailTemplateScreen = () => {
  const [templates, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateContent, setTemplateContent] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [total, setTotal] = useState(0);
  const [viewMode, setViewMode] = useState("list");
  // Pagination states

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [formErrors, setFormErrors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const startIndex = page * rowsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EEEEEE",
      border: "1px solid #EAECF0",
      padding: "4px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "6px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #EAECF0",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F6",
      border: "1px solid #EAECF0",
    },
    "&:lastChild td, &:lastChild th": {
      border: 0,
    },
  }));
  const handleOpenDeleteDialog = (template) => {
    setSelectedTemplate(template);
    setOpenDeleteDialog(true);
  };
  const fetchTemplates = () => {
    emailTemplateService
      .fetch(rowsPerPage, page * rowsPerPage)
      .then((response) => {
        setTemplates(response.data);
        setDataCount(response.total);
        setTotal(response.total);
      })
      .catch((e) => {
        throw e;
      });
  };
  useEffect(() => {
    // Fetch templates from the API
    fetchTemplates();
  }, [page, rowsPerPage]);

  const handleSaveTemplate = () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (selectedTemplate) {
      // Edit existing template
      emailTemplateService
        .edit(selectedTemplate._id, {
          name: templateName,
          content: templateContent,
          subject: templateSubject,
        })
        .then(() => {
          setTemplates((prev) =>
            prev.map((template) =>
              template.id === selectedTemplate._id
                ? {
                    ...template,
                    name: templateName,
                    content: templateContent,
                    subject: templateSubject,
                  }
                : template
            )
          );
          setFormErrors({});
          toast.success("Template updated successfully!");
        })
        .catch((error) => {
          toast.error("Failed to update template.");
        });
    } else {
      // Add new template
      emailTemplateService
        .create({
          name: templateName,
          content: templateContent,
          subject: templateSubject,
        })
        .then((response) => {
          setTemplates([...templates, response.data]);
          setFormErrors({});
          toast.success("Template created successfully!");
        })
        .catch((error) => {
          toast.error("Failed to create template.");
        });
    }

    fetchTemplates();
    setOpenDialog(false);
  };

  const validateForm = () => {
    const errors = {};

    if (!templateName) {
      errors.templateName = "Template Name is required.";
    }

    if (!templateSubject) {
      errors.templateSubject = "Template Subject is required.";
    }

    if (!templateContent || templateContent === "<p><br></p>") {
      errors.templateContent = "Template Content is required.";
    }
    setFormErrors(errors);
    console.log("Form errors:", errors);
    return Object.keys(errors).length === 0;
  };

  const handleEditTemplate = (template) => {
    setOpenDialog(true);
    setTemplateName(template.name);
    setTemplateContent(template.content);
    setTemplateSubject(template.subject);
    setSelectedTemplate(template);
  };

  const handleDeleteTemplate = () => {
    emailTemplateService.delete(selectedTemplate?._id).then(() => {
      setTemplates(
        templates.filter((template) => template._id !== selectedTemplate?._id)
      );
      toast.success("Template deleted successfully!");
      setOpenDeleteDialog(false);
    });
  };
  const handleViewTemplate = (template) => {
    setSelectedTemplate(template);
    setOpenViewDialog(true);
  };
  const handlePopoverOpen = (event, item) => {
    event.stopPropagation();
    setSelectedTemplate(item);
    setAnchorEl(event.currentTarget);
    setAnchorPosition({
      top: event.clientY - 15,
      left: event.clientX - 15,
    });
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFormErrors({});
  };
  const data = templates.map((item) => ({
    ...item,
    action: (
      <Box>
        <IconButton
          aria-label="more actions"
          onClick={(e) => handlePopoverOpen(e, item)}
          sx={{
            color:
              anchorEl && selectedTemplate?._id === item._id
                ? "primary.main"
                : "inherit",
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={anchorEl && selectedTemplate?._id === item._id}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableRestoreFocus
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "none",
              border: "1px solid #EEEEEE",
              borderRadius: "4px",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
            }}
          >
            <Button
              style={{
                color: "#333333",
                justifyContent: "flex-start",
                textTransform: "none",
              }}
              aria-label="view"
              onClick={() => {
                handleViewTemplate(item);
                handlePopoverClose(); // Close the Popover
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                gap={1}
              >
                <ViewIcon />
                <Typography
                  fontFamily={"poppins"}
                  fontSize={"16px"}
                  color={"#333333"}
                  fontWeight={500}
                >
                  View
                </Typography>
              </Box>
            </Button>

            <Button
              style={{
                color: "#333333",
                justifyContent: "flex-start",
                textTransform: "none",
              }}
              aria-label="edit"
              onClick={() => {
                handleEditTemplate(item);
                handlePopoverClose(); // Close the Popover
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                gap={1}
              >
                <EditIcon />
                <Typography
                  fontFamily={"poppins"}
                  fontSize={"16px"}
                  color={"#333333"}
                  fontWeight={500}
                >
                  Edit
                </Typography>
              </Box>
            </Button>

            <Button
              style={{
                justifyContent: "flex-start",
                textTransform: "none",
              }}
              aria-label="delete"
              onClick={() => {
                handleOpenDeleteDialog(item);
                handlePopoverClose();
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                gap={1}
              >
                <DeleteIcon />
                <Typography
                  fontFamily={"poppins"}
                  fontSize={"16px"}
                  fontWeight={500}
                  color={"#333333"}
                >
                  Delete
                </Typography>
              </Box>
            </Button>
          </div>
        </Popover>
      </Box>
    ),
  }));

  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: "1px",
        maxHeight: "100%",
        paddingBottom: 5,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {data?.map((template, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: "pointer",
              width: "100%",
              height: "100%",
              position: "relative",
              borderRadius: "6px",
              border: "1px solid #D0D5DD",
            }}
          >
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight="500"
                  sx={{ textAlign: "start" }}
                  fontFamily="Poppins"
                  color="#333333"
                >
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  {template?.action}
                </div>
              </div>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Template Name
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={template?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {template?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "300px" }}
      />
    </div>
  );
  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        renderGridView()
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    TEMPLATE NAME
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                data.map((template, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        style={{ marginLeft: 7 }}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        style={{ marginLeft: 7 }}
                      >
                        {template?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                      {template?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <DashboardContainer
      hideFilterClient
      onSearchData={(x) => {
        console.log(x);
      }}
      onClickAddButton={() => {
        setOpenDialog(true);
        setTemplateName("");
        setTemplateContent("");
        setTemplateSubject("");
        setSelectedTemplate(null);
      }}
      hideUploadButton={true}
      header={"Template List"}
      title={"+ Add template"}
      setViewMode={setViewMode}
    >
      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
          width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
        }}
      >
        {viewMode === "grid" ? renderGridView() : renderListView()}
      </div>
      {/* Modal for adding/editing template */}
      <Dialog
        open={openDialog}
        onClose={() => handleClose()}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle
            id="form-dialog-title"
            style={{
              fontFamily: "Poppins",
              fontSize: 24,
              fontWeight: 500,
              color: "#000000",
            }}
          >
            {selectedTemplate ? "Edit" : "Create"} Template
          </DialogTitle>
          <IconButton onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ marginBottom: 10 }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#11193F",
                }}
              >
                Template Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                size="small"
                required
                placeholder="Enter Template Name"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                fullWidth
                margin="dense"
                error={!!formErrors.templateName}
                helperText={formErrors.templateName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#11193F",
                }}
              >
                Template Subject <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                required
                size="small"
                placeholder="Enter Template Subject"
                value={templateSubject}
                onChange={(e) => setTemplateSubject(e.target.value)}
                fullWidth
                margin="dense"
                error={!!formErrors.templateSubject}
                helperText={formErrors.templateSubject}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#11193F",
                }}
              >
                Template Content <span style={{ color: "red" }}>*</span>
              </Typography>
              <ReactQuill
                style={{ height: 150, marginTop: 10 }}
                value={templateContent}
                onChange={setTemplateContent}
                theme="snow"
              />
              {formErrors.templateContent && (
                <Typography color="error" variant="body2" sx={{ mt: 7 }}>
                  {formErrors.templateContent}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
          <Button
            onClick={handleClose}
            style={{
              fontFamily: "Poppins",
              fontSize: 14,
              fontWeight: 600,
              color: "#007DFF",
              textTransform: "none",
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveTemplate}
            color="primary"
            variant="contained"
            style={{
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontSize: 14,
              fontWeight: 600,
              width: 100,
              textTransform: "none",
            }}
          >
            {selectedTemplate ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <CustomTypography>
            Are you sure you want to delete this template?
          </CustomTypography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "red" }}
            onClick={handleDeleteTemplate}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Template Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={() => setOpenViewDialog(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          {selectedTemplate && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <CustomTypography
                    color={"#11193F"}
                    fontSize={14}
                    fontWeight={400}
                  >
                    Template Name
                  </CustomTypography>
                  <TextField
                    size="small"
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    type="text"
                    fullWidth
                    value={selectedTemplate?.name || ""}
                    InputProps={{
                      readOnly: true,
                      style: { color: "#000", backgroundColor: "#f0f0f0" },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <CustomTypography
                    color={"#11193F"}
                    fontSize={14}
                    fontWeight={400}
                  >
                    Template Subject
                  </CustomTypography>
                  <TextField
                    size="small"
                    autoFocus
                    margin="dense"
                    variant="outlined"
                    type="text"
                    fullWidth
                    value={selectedTemplate?.subject || ""}
                    InputProps={{
                      readOnly: true,
                      style: { color: "#000", backgroundColor: "#f0f0f0" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomTypography
                    color={"#11193F"}
                    fontSize={14}
                    fontWeight={400}
                  >
                    Template Content
                  </CustomTypography>
                  <ReactQuill
                    value={selectedTemplate?.content}
                    readOnly={true}
                    theme="snow"
                    style={{ marginTop: 10, backgroundColor: "#f0f0f0" }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={() => setOpenViewDialog(false)}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </DashboardContainer>
  );
};

export default EmailTemplateScreen;
