import React from "react";
import {
  Box,
  IconButton,
  TablePagination,
  useMediaQuery,
  Stack,
  Pagination,
} from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";

const CustomPagination = ({
  dataCount,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
  disableNext,
}) => {
  const isMobileScreen = useMediaQuery("(max-width:750px)"); // Change the width as per your requirement

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#ffffff",
        padding: "2px",
        borderTop: "1px solid #dddddd",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {isMobileScreen ? (
        <Stack
          spacing={2}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "8px",
            borderTop: "1px solid #dddddd",
            alignItems: "end",
          }}
        >
          <Pagination
            color="primary"
            count={Math.ceil(dataCount / rowsPerPage)}
            page={page + 1}
            shape="rounded"
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
          />
        </Stack>
      ) : (
        <TablePagination
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          nextIconButton={
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          }
          backIconButton={
            <IconButton>
              <KeyboardArrowLeft />
            </IconButton>
          }
          slotProps={{
            actions: {
              nextButton: {
                disabled: disableNext,
              },
            },
          }}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "2px",
            borderTop: "1px solid #dddddd",
          }}
        />
      )}
    </Box>
  );
};

export default CustomPagination;
