import React, { useEffect, useState, useContext } from "react";
import DashboardContainer from "../../components/dashboard-container";
import AddModal from "../../components/add-modal";
import { carrierService } from "../../utils/services/carrierService";
import CarrierList from "../../components/carrier-list";
import TablePagination from "@mui/material/TablePagination";
import { CircularProgress, Box, Container, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { clientService } from "../../utils/services/clientService";
import AppContext from "../../components/context/context";
import TextField from "@mui/material/TextField/TextField";
import { Autocomplete, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../components/pagination";

export default function Carrier() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [name, setName] = useState("");
  const [carrierData, setCarrierData] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [formErrors, setFormErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState({
    name: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [clientData, setClientData] = useState([]);
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const { user } = useContext(AppContext);
  const [viewMode, setViewMode] = useState("list");
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = "Carrier name is required";
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = 'Please select Client'
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch();
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    }
  };
  useEffect(() => {
    fetchClientData();
  }, []);

  const handleClientFilterChange = (event, newValue) => {
    setClientFilter(newValue);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const fetchCarrierData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const clientId = localStorage.getItem("client_id");
      const response = await carrierService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      );
      setCarrierData(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCarrierData();
  }, [page, rowsPerPage, clientFilter, selectedCarrier, searchValue]);

  const createCarrier = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      const response = await carrierService.create(name, client_id);
      toast.success("Carrier list Created successfully!");
      fetchCarrierData();
      setShowAddModal(false);
      setName("");
      setSelectedClient({ name: "" });
    } catch (error) {
      console.error("Create failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_carrier.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );
  return (
    <>
      <DashboardContainer
        searchBarPlaceholder={"Search by Carrier Name"}
        hideFilterClient
        onSearchData={(x) => {
          setSearchValue(x);
          setPage(0);
        }}
        onClickAddButton={() => {
          setShowAddModal(true);
          setFormErrors({});
        }}
        hideUploadButton={true}
        header={"Carrier List"}
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        selectedClient={selectedClient?.name}
        setViewMode={setViewMode}
        title={"+ Add Carrier"}
      >
        <AddModal
          title={"Carrier"}
          onClickAdd={(e) => {
            createCarrier(e);
          }}
          onSelectClient={(newData) => {
            setSelectedClient(newData);
          }}
          onChangeName={(e) => setName(e)}
          handleClose={() => setShowAddModal(false)}
          open={showAddModal}
          formErrors={formErrors}
          selectedCarrier={selectedCarrier}
          carrier
        ></AddModal>

        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit"></CircularProgress>
          </Box>
        )}
        {carrierData?.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NoDataImage />
          </div>
        ) : (
          <>
            <CarrierList
              onDeleteData={() => fetchCarrierData()}
              onEditData={() => fetchCarrierData()}
              data={carrierData}
              viewMode={viewMode}
              deleteTitle={"Delete Carrier"}
              currentPage={page}
              itemsPerPage={rowsPerPage}
            ></CarrierList>
          </>
        )}
        {/* <TablePagination
          // slotProps={{
          //   actions: {
          //     nextButton: {
          //       disabled: dataCount < rowsPerPage ? true : false,
          //     },
          //   },
          // }}
          // nextIconButton={
          //   <IconButton>
          //     <KeyboardArrowRight />
          //   </IconButton>
          // }
          // backIconButton={
          //   <IconButton>
          //     <KeyboardArrowLeft />
          //   </IconButton>
          // }
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "2px",
            borderTop: "1px solid #dddddd",
          }}
        /> */}

        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </DashboardContainer>
    </>
  );
}
