import axiosInstance from "../axiosInstance";

export const weightPackageService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/weight-package-mapping", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (searchValue, client_id, query = "") => {
    try {
      let api_query = `/weight-package-mapping?`;
      if (client_id) {
        api_query += `&client_id=${client_id}`;
      }

      // Add search value
      if (searchValue) api_query += `&type[$search]=${searchValue}`;
      if (query.length) api_query += `&${query}`;
      const response = await axiosInstance.get(api_query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(
        `/weight-package-mapping/${id}`,
        obj
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(
        `/weight-package-mapping/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
