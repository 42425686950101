import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { orderService } from '../../utils/services/orderService'
import CustomTypography from '../../globalStyles/fonts'
import CheckIcon from '@mui/icons-material/Check'
import styles from './order-tracking.module.css'
import AppContext from '../../components/context/context'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
export default function OrderTrackingHistory() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const trackingStatus = searchParams.get('status')
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const dummyTrackingDetails = [
    {
      name: 'Ordered',
      sl: 1,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been placed successfully.'
      }
    },
    {
      name: 'Accepted',
      sl: 2,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been accepted and is being processed.'
      }
    },
    {
      name: 'Confirmed',
      sl: 3,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been confirmed. We’re getting it ready.'
      }
    },
    {
      name: 'Fulfilled',
      sl: 4,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been fulfilled and is ready for the next steps.'
      }
    },
    { name: 'Shipped', sl: 5, reached: false, subSteps: [] },
    { name: 'In Transit', sl: 6, reached: false, subSteps: [] },
    { name: 'Out for Delivery', sl: 7, reached: false, subSteps: [] },
    { name: 'Delivered', sl: 8, reached: false, subSteps: [] }
  ]

  const [trackData, setTrackData] = useState(dummyTrackingDetails)
  const [orderTrackData, setOrderTrackData] = useState([])
  const [activeStep, setActiveStep] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex)
    // setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }
  const setTrackingData = () => {
    const isCancelled = orderTrackData.find((x) => x.status === 'Cancelled')
    const isConfirmed = orderTrackData.find((x) => x.status === 'Confirmed')
    const updatedTrack = isCancelled
      ? [
          {
            name: 'Ordered',
            sl: 1,
            reached: false,
            subSteps: [],
            details: {
              text: 'Your order has been placed successfully.'
            }
          },

          {
            name: 'Cancelled',
            sl: 9,
            reached: false,
            subSteps: [],
            details: {
              text: 'Your order has been cancelled.'
            }
          }
        ]
      : isConfirmed
      ? dummyTrackingDetails
      : dummyTrackingDetails
    let allTrackingStatus = orderTrackData.map((x) => x.status)
    setTrackData(
      updatedTrack.map((item) => ({
        ...item,
        reached: allTrackingStatus?.includes(item.name) ? true : false,
        subSteps: orderTrackData.find((x) => x.status.toLowerCase() === item.name.toLowerCase())?.values
      }))
    )
  }

  const fetchTrackingData = async () => {
    try {
      const response = await orderService.fetchOrderTrailWithoutAuth(id)
      setOrderTrackData(response)
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }
  useEffect(() => {
    if (id) {
      fetchTrackingData()
    }
  }, [id])
  useEffect(() => {
    if (orderTrackData.length) {
      setTrackingData()
    }
  }, [orderTrackData])
  function getLastReachedStep(trackingDetails) {
    return trackingDetails.reduce((lastReached, currentStep) => {
      return currentStep.reached ? currentStep : lastReached
    }, null)
  }

  return (
    <div
      className="scrollableDiv"
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'scroll',
        width: '100%',
        height: '100%',
        position: 'relative',
        scrollbarWidth: 'none', // For Firefox
        msOverflowStyle: 'none',
        padding: 20 // F
      }}
    >
      <div className={styles.container}>
        {/* Vertical line */}

        <div
          className={styles.leftContainer}
          style={{
            marginLeft: '30px',
            padding: 15,
            maxHeight: currentUser?._id ? '98%' : '92%',
            display: 'flex',
            flexDirection: 'column',
            borderWidth: 2,
            borderRadius: 8,
            backgroundColor: '#FFF',
            width: '95%'
          }}
        >
          <header className={styles.trackingHeader}>
            <h1 className={styles.trackingNumber}>
              Tracking Number :{' '}
              <span style={{ fontSize: 17 }}>
                {!trackingStatus || trackingStatus == 'undefined' ? '' : trackingStatus}
              </span>
            </h1>
          </header>
          <div className={styles.statusBadge}>
            <div className={styles.badgeContent}>{getLastReachedStep(trackData)?.name}</div>
          </div>
          <section className={styles.orderInfo}>
            <div className={styles.orderDetails}>
              <div className={styles.orderDateWrapper}>
                <span className={styles.orderDateLabel}>Order date:</span>
                <time className={styles.orderDate} dateTime="2024-08-16">
                  {new Date(
                    trackData?.find((x) => x.name === 'Ordered').subSteps[0]?.createdAt
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </time>
              </div>
              <div className={styles.separator} />
              <div className={styles.estimatedDelivery}>
                <img src="/truck-tick.png" alt="" className={styles.icon} />
                <span className={styles.estimatedDeliveryText}>{`Estimated delivery: ${''}`}</span>
              </div>
            </div>
          </section>
          <div
            style={{
              width: '100%',
              marginTop: 30,
              maxHeight: '99%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto'
            }}
          >
            {trackData?.map((step, index) => (
              <div
                onClick={() => handleStepClick(index)}
                key={step.sl}
                style={{
                  marginBottom: '10px',
                  cursor: 'pointer',
                  position: 'relative',
                  backgroundColor: index === activeStep ? '#E4FFE8' : ''
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: step.reached ? 'green' : 'white',
                      marginRight: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderWidth: 1,
                      border: !step.reached ? '1px solid #D9D9D9' : ''
                    }}
                  >
                    {step.reached && <CheckIcon style={{ color: '#fff', fontSize: '16px' }} />}
                  </div>
                  <span
                    style={{
                      fontSize: 14,
                      color: step.reached ? '#06B217' : '#333333',
                      fontWeight: '600'
                    }}
                  >
                    {step?.name}
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  {index === trackData?.length - 1 ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        minHeight: 50,
                        height: 'calc(100% - 24px)', // Subtract the height of the circle
                        marginLeft: 12,
                        marginTop: 5,
                        borderLeft: step.reached ? '1px dotted #06B217' : '1px dotted #757477',
                        width: 2,
                        position: 'absolute',
                        top: 24 // Start from the bottom of the circle
                      }}
                    ></div>
                  )}
                  <div
                    style={{
                      paddingLeft: '20px',
                      textAlign: 'left',
                      // maxHeight: '100px',
                      overflowY: 'auto',
                      marginTop: '8px'
                    }}
                  >
                    {step.details && (
                      <>
                        <CustomTypography style={{ fontSize: 10 }} variant="body2">
                          {step?.subSteps?.length ? step?.subSteps[0]?.message : ''}
                        </CustomTypography>
                        <CustomTypography style={{ marginTop: 1, fontSize: 10 }} variant="caption">
                          {step?.subSteps?.length
                            ? new Date(step?.subSteps[0]?.createdAt).toLocaleString()
                            : ''}
                        </CustomTypography>
                      </>
                    )}
                    {step.subSteps?.length > 0 ? (
                      step.subSteps
                        ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                        ?.map((step, subIndex) => (
                          <div
                            key={step.sl}
                            style={{
                              marginBottom: '5px',
                              position: 'relative',
                              marginTop: 10
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  marginRight: '8px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderWidth: 1,
                                  border: !step.reached ? '1px solid #D9D9D9' : ''
                                }}
                              >
                                <div
                                  style={{
                                    height: 12,
                                    width: 12,
                                    borderRadius: 50,
                                    backgroundColor: '#06B217'
                                  }}
                                ></div>
                              </div>
                              <span
                                style={{
                                  fontSize: 12,
                                  color: step.reached ? '#06B217' : '#333333',
                                  fontWeight: '600'
                                }}
                              >
                                {step.message}
                              </span>
                            </div>

                            <div style={{ display: 'flex' }}>
                              {/* Only show vertical line if there's more than one substep AND not the last item */}
                              {trackData[index]?.subSteps?.length > 1 &&
                              subIndex !== trackData[index]?.subSteps?.length - 1 ? (
                                <div
                                  style={{
                                    marginLeft: 12,
                                    marginTop: 5,
                                    borderLeft: '1px dotted #06B217',
                                    width: 2
                                  }}
                                ></div>
                              ) : (
                                <div style={{ marginLeft: 14 }} /> // Maintain spacing without line
                              )}
                              <div
                                style={{
                                  paddingLeft: '20px',
                                  textAlign: 'left',
                                  maxHeight: '100px',
                                  overflowY: 'auto',
                                  marginTop: '5px',
                                  marginLeft: 3
                                }}
                              >
                                <CustomTypography
                                  style={{ fontSize: 13, marginBottom: '3px' }}
                                  variant="body2"
                                >
                                  {step?.location || ''}
                                </CustomTypography>
                                <CustomTypography style={{ fontSize: 10, marginTop: '5px' }} variant="body2">
                                  {new Date(step.time || step.createdAt).toLocaleString()}
                                </CustomTypography>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {drawerOpen ? (
          <div
            className={styles.rightContainer}
            style={{
              marginLeft: 10,
              borderWidth: 2,
              borderRadius: 8,
              display: 'flex',
              overflow: 'auto',
              flexDirection: 'column',
              maxHeight: '95%'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: currentUser?._id ? 10 : 0,
                right: 20,
                cursor: 'pointer'
              }}
              onClick={handleDrawerClose}
            >
              <CloseIcon></CloseIcon>
            </div>
            {activeStep !== null && (
              <div style={{ padding: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: trackData[activeStep].reached ? 'green' : 'white',
                      marginRight: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderWidth: 1,
                      border: !trackData[activeStep].reached ? '1px solid #D9D9D9' : ''
                    }}
                  >
                    {trackData[activeStep].reached && (
                      <CheckIcon style={{ color: '#fff', fontSize: '16px' }} />
                    )}
                  </div>
                  <span
                    style={{
                      color: trackData[activeStep].reached ? '#06B217' : '#333333',
                      fontWeight: '600'
                    }}
                  >
                    {trackData[activeStep]?.name}
                  </span>
                </div>
                {/* <h6>{trackData[activeStep].name} - Details</h6> */}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {drawerOpen ? (
          <Dialog
            className={styles.showForMobile}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              Tracking Details
              <IconButton
                aria-label="close"
                onClick={() => setDrawerOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {activeStep !== null && (
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    maxHeight: 500
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: 20
                    }}
                  >
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: trackData[activeStep].reached ? 'green' : 'white',
                        marginRight: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderWidth: 1,
                        border: !trackData[activeStep].reached ? '1px solid #D9D9D9' : ''
                      }}
                    >
                      {trackData[activeStep].reached && (
                        <CheckIcon style={{ color: '#fff', fontSize: '16px' }} />
                      )}
                    </div>
                    <span
                      style={{
                        color: trackData[activeStep].reached ? '#06B217' : '#333333',
                        fontWeight: '600'
                      }}
                    >
                      {trackData[activeStep]?.name}
                    </span>
                  </div>
                  {trackData[activeStep].subSteps?.length > 0 ? (
                    trackData[activeStep].subSteps?.map((step, index) => (
                      <div
                        key={step.sl}
                        style={{
                          marginBottom: '5px',
                          position: 'relative',
                          marginLeft: 40
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '50%',
                              marginRight: '8px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              border: !step.reached ? '1px solid #D9D9D9' : ''
                            }}
                          >
                            <div
                              style={{
                                height: 12,
                                width: 12,
                                borderRadius: 50,
                                backgroundColor: '#06B217'
                              }}
                            ></div>
                          </div>
                          <span
                            style={{
                              color: step.reached ? '#06B217' : '#333333',
                              fontWeight: '600'
                            }}
                          >
                            {step.order_number}
                          </span>
                        </div>
                        <div style={{ display: 'flex' }}>
                          {index === trackData[activeStep].subSteps?.length - 1 ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                height: 50,
                                marginLeft: 12,
                                marginTop: 5,
                                borderLeft: '1px dotted #06B217',
                                width: 2
                              }}
                            ></div>
                          )}
                          <div
                            style={{
                              paddingLeft: '20px',
                              textAlign: 'left',
                              maxHeight: '100px',
                              overflowY: 'auto',
                              marginTop: '8px'
                            }}
                          >
                            <CustomTypography style={{ fontSize: 10 }} variant="body2">
                              {new Date(step.createdAt).toLocaleString()}
                            </CustomTypography>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No substeps available</p>
                  )}
                </div>
              )}
            </DialogContent>
          </Dialog>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
