import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const Invoice = () => {
  const screenshotUrl = "/invoice.png";
  const downloadFile = "/RedefinedInvoice.xlsx";

  const downloadTransactions = () => {
    const link = document.createElement("a");
    link.href = downloadFile;
    link.download = "RedefinedInvoice.xlsx";
    link.click();
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "#f0f0f0",
      }}
    >
      <img
        src={screenshotUrl}
        alt="Invoice Screenshot"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
      <a
        onClick={() => downloadTransactions()}
        href="#"
        style={{
          position: "absolute",
          bottom: "60px",
          left: "50%",
          color: "blue",
          textDecoration: "none",
          textDecorationLine: "underline",
        }}
      >
        {`Download the transactions`}
      </a>
    </div>
  );
};

export default Invoice;
