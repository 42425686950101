import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { clientService } from "../../utils/services/clientService";
import debounce from "lodash.debounce";

const ClientAutocomplete = ({
  selectedClient,
  onClientChange,
  width = 200,
  label = "Select Client",
  errorMessage,
  hideLabel,
}) => {
  const [allClients, setAllClients] = useState([]);
  const [clientSearchValue, setClientSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const fetchClientData = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await clientService.fetch(
          10,
          pageNumber * 10,
          searchQuery
        );
        if (pageNumber === 0) {
          setAllClients(response.data);
        } else {
          setAllClients((prevClients) => [...prevClients, ...response.data]);
        }
        setTotal(response.total);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchClientData(clientSearchValue, page);
  }, [clientSearchValue, page]);

  const handleClientChange = (event, value) => {
    if (value && value.business_name === "Load More") {
      return;
    }
    if (onClientChange) {
      onClientChange(value);
    }
    setClientSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setClientSearchValue(value);
    setPage(0);
    setAllClients([]);
  };

  const handleLoadMoreClick = () => {
    if (allClients.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add "Load More" option every 10 clients if more data is available
  const optionsWithLoadMore = [...allClients];
  if (allClients.length < total && allClients.length % 10 === 0) {
    optionsWithLoadMore.push({ business_name: "Load More" });
  }

  return (
    <Autocomplete
      size="small"
      id="client-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.business_name || option.name || ""}
      value={selectedClient}
      onChange={handleClientChange}
      renderOption={(props, option) =>
        option.business_name === "Load More" ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}{" "}
          </li>
        ) : (
          <li {...props}>{option.business_name}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={hideLabel ? "" : label}
          placeholder="Select Client"
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default ClientAutocomplete;
