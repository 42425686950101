import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Popover,
  Icon,
  Tooltip,
  CircularProgress,
  Badge,
} from "@mui/material";
import { weightPackageService } from "../../utils/services/weightPackageService";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import CustomPagination from "../pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import FilterModal from "../filterModal";
import { useTheme } from "@mui/styles";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import EditIcon from "../../globalStyles/icons/editIcon";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import UpIcon from "@mui/icons-material/ArrowUpwardOutlined";
import DownIcon from "@mui/icons-material/ArrowDownwardOutlined";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import PackageDetailsModal from "../weight-package-details-modal";
import DimensionFilterComponent from "../dimension-filter-modal";
import WeightFilterComponent from "../weight-filter-modal";
import PackageWeightFilter from "../package-weight-filter-modal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "4px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "4px",
    textAlign: "start",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));
const PackageTableCell = ({
  x,
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "view":
        handleViewClick(x);
        break;
      case "edit":
        handleEditClick(x);
        break;
      case "delete":
        handleDeleteClick(x);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "inherit",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: 1 }}>
          <Button
            onClick={() => handleActionClick("view")}
            sx={{ textTransform: "none", justifyContent: "flex-start" }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <ViewIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                View
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("edit")}
            sx={{ textTransform: "none", justifyContent: "flex-start" }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <EditIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Edit
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("delete")}
            sx={{ textTransform: "none", justifyContent: "flex-start" }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <DeleteIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Delete
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

const WeightPackageListView = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedWeightPackage,
  setSelectedWeightPackage,
  onChangeFilter,
  filterData,
  onChangeDimensionFilter,
  onChangeWeightFilter,
  onChangePackageWeightFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [selectedFilterKey, setSelectedFilterKey] = useState("");
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElDimensionFilter, setAnchorElDimensionFilter] = useState(null);
  const [anchorElWeightFilter, setAnchorElWeightFilter] = useState(null);
  const [anchorElPackageWeightFilter, setAnchorElPackageWeightFilter] =
    useState(null);
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: "",
    filter: [],
    sort: 0,
    id: "_id",
    displayKey: "name",
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: "",
    type: "list",
    name: "",
  });
  const [dimensionFilter, setDimensionFilter] = useState({
    from: {
      length: null,
      width: null,
      height: null,
      unit: null,
    },
    to: {
      length: null,
      width: null,
      height: null,
      unit: null,
    },
  });
  const [weightFilter, setWeightFilter] = useState({
    from: {
      min_weight: null,
      max_weight: null,
      unit: null,
    },
    to: {
      min_weight: null,
      max_weight: null,
      unit: null,
    },
  });
  const [packageWeightFilter, setPackageWeightFilter] = useState({
    from: {
      weight: null,
      unit: null,
    },
    to: {
      weight: null,
      unit: null,
    },
  });
  const [sortOrder, setSortOrder] = useState(0);
  const startIndex = page * rowsPerPage;

  const handleDimensionFilterConfirm = (filterData) => {
    const { filters, sortOrder } = filterData;
    setDimensionFilter(filterData.filters);
    setSortOrder(filterData.sortOrder);
    onChangeDimensionFilter({
      filters,
      sort: sortOrder,
    });
  };
  const handleWeightFilterConfirm = (filterData) => {
    const { filters, sortOrder } = filterData;
    setWeightFilter(filterData.filters);
    setSortOrder(filterData.sortOrder);
    onChangeWeightFilter({
      filters,
      sort: sortOrder,
    });
  };
  const handlePackageWeightFilterConfirm = (filterData) => {
    const { filters, sortOrder } = filterData;
    setPackageWeightFilter(filterData.filters);
    setSortOrder(filterData.sortOrder);
    onChangePackageWeightFilter({
      filters,
      sort: sortOrder,
    });
  };
  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget);
    setSelectedFilterKey(filterKey);
    setOpenFilterModal(true);
    setSelectedFilterData(filterData?.columns.find((x) => x?.key == filterKey));
  };
  const handleOpenDimensionFilter = (event) => {
    setAnchorElDimensionFilter(event.currentTarget);
  };
  const handleOpenWeightFilter = (event) => {
    setAnchorElWeightFilter(event.currentTarget);
  };
  const handleOpenPackageWeightFilter = (event) => {
    setAnchorElPackageWeightFilter(event.currentTarget);
  };
  const handleSaveFilter = (
    selectedOptions,
    filterKey,
    sortOrder,
    searchKey
  ) => {
    onChangeFilter({
      key: filterKey,
      filter: selectedOptions,
      sort: sortOrder,
      searchKey: searchKey,
    });
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilterModal(false);
  };
  const handleCloseDimensionFilter = () => {
    setAnchorElDimensionFilter(null);
  };
  const handleCloseWeightFilter = () => {
    setAnchorElWeightFilter(null);
  };
  const handleClosePackageWeightFilter = () => {
    setAnchorElPackageWeightFilter(null);
  };

  const deleteData = async (id) => {
    try {
      setLoading(false);
      await weightPackageService.delete(id);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Package mapping deleted successfully!");
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (x) => {
    setSelectedWeightPackage(x);
    setOpenConfirmation(true);
  };
  const handleViewClick = (x) => {
    setSelectedWeightPackage(x);
    setOpenViewModal(true);
  };
  useEffect(() => {
    setPackageData(
      data?.map((x) => ({
        ...x,
        action: (
          <PackageTableCell
            x={x}
            handleViewClick={handleViewClick}
            handleEditClick={onEditData}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      }))
    );
  }, [data]);
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: "1px",
        maxHeight: "100%",
        paddingBottom: 5,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {packageData?.map((x, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: "pointer",
              width: "100%",
              height: "100%",
              position: "relative",
              borderRadius: "6px",
              border: "1px solid #D0D5DD",
            }}
          >
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight="500"
                  sx={{ textAlign: "start" }}
                  fontFamily="Poppins"
                  color="#333333"
                >
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  {x?.action}
                </div>
              </div>
              <Grid container spacing={1} mt={2}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Client
                  </Typography>
                  {/* {filterData.columns.find(
                    (x) => x.key === "client_id.business_name"
                  )?.sort === 1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")?.sort ===
                    -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={x?.client_id?.business_name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.client_id?.business_name}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Warehouse
                  </Typography>
                  {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                  1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "email")
                      ?.sort === -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={x?.warehouse?.provider?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {x?.warehouse?.provider?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Weight
                  </Typography>
                  {/* {filterData.columns.find((x) => x.key === "address.address1")
                    ?.sort === 1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find(
                      (x) => x.key === "address.address1"
                    )?.sort === -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip
                    title={`${x?.min_weight}/${x?.min_weight_unit} - ${x?.max_weight}/${x?.max_weight_unit}`}
                    arrow
                  >
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      :{" "}
                      {`${x?.min_weight}/${x?.min_weight_unit} - ${x?.max_weight}/${x?.max_weight_unit}`}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Dimension
                  </Typography>
                  {/* {filterData.columns.find((x) => x.key === "address.city")
                    ?.sort === 1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "address.city")
                      ?.sort === -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip
                    title={`${x?.dimension?.length} * ${x?.dimension?.width} * ${x?.dimension.height}`}
                    arrow
                  >
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      :{" "}
                      {`${x?.dimension?.length} * ${x?.dimension?.width} * ${x?.dimension.height} (${x?.dimension.height_unit})`}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Package Weight
                  </Typography>
                  {/* {filterData.columns.find((x) => x.key === "address.province")
                    ?.sort === 1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find(
                      (x) => x.key === "address.province"
                    )?.sort === -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={`${x.pkg_weight} ${x.pkg_weight_unit}`} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {`${x.pkg_weight} ${x.pkg_weight_unit}`}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Weight
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                          noWrap
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          :{" "}
                          {`${x?.min_weight}/${x?.min_weight_unit} - ${x?.max_weight}/${x?.max_weight_unit}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Dimension
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                          noWrap
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          :{" "}
                          {`${x?.dimension.length} * ${x?.dimension.width} * ${x?.dimension.height} (${x?.dimension.height_unit})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Package Weight
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                          noWrap
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          : {`${x?.pkg_weight} ${x?.pkg_weight_unit}`}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                    textAlign={"left"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                {/* <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {filterData?.columns.find(
                      (x) => x.key === "client_id.business_name"
                    )?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData?.columns.find(
                        (x) => x.key === "client_id.business_name"
                      )?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Client
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenFilter(event, "client_id")}
                    >
                      <SortingIcon fill={primary_color || "#DC585C"} />
                    </IconButton>
                  </div>
                </StyledTableCell> */}
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                    textAlign={"left"}
                  >
                    Name
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                    textAlign={"left"}
                  >
                    Type
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {filterData?.columns.find(
                      (x) => x.key === "client_id.business_name"
                    )?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData?.columns.find(
                        (x) => x.key === "client_id.business_name"
                      )?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Warehouse
                    </Typography>
                    {/* <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) =>
                        handleOpenFilter(event, "warehouse.provider.name")
                      }
                    >
                      <SortingIcon fill={primary_color || "#DC585C"} />
                    </IconButton> */}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {filterData?.columns.find((x) => x.name === "weight")
                      ?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData?.columns.find((x) => x.name === "weight")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Weight
                    </Typography>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find(
                          (item) => item.name.toLowerCase() === "weight"
                        )?.filter?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenWeightFilter(event)}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {filterData?.columns.find((x) => x.name === "dimension")
                      ?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData?.columns.find((x) => x.name === "dimension")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Dimension
                    </Typography>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !filterData?.columns?.find(
                          (item) => item.name.toLowerCase() === "dimension"
                        )?.filter?.length
                      }
                      overlap="circular"
                    >
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={(event) => handleOpenDimensionFilter(event)}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </Badge>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {filterData?.columns.find(
                      (x) => x.name === "Package Weight"
                    )?.sort === 1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData?.columns.find(
                        (x) => x.name === "Package Weight"
                      )?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null}
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Package Weight
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      onClick={(event) => handleOpenPackageWeightFilter(event)}
                    >
                      <SortingIcon fill={primary_color || "#DC585C"} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {packageData.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                packageData.map((x, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        ml={0.5}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.type}
                      </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.client_id?.business_name || x?.client_id?.name}
                      </Typography>
                    </StyledTableCell> */}
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        ml={0.5}
                      >
                        {x?.warehouse?.provider?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {`${x?.min_weight}/${x?.min_weight_unit} - ${x?.max_weight}/${x?.max_weight_unit}`}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {`${x?.dimension?.length} * ${x?.dimension?.width} * ${x?.dimension.height} (${x?.dimension.height_unit})`}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {`${x.pkg_weight} ${x.pkg_weight_unit}`}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                      {x?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
              width: "100%",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::WebkitScrollbar": { width: 0 },
            }}
          >
            {viewMode === "grid" ? renderGridView() : renderListView()}
          </div>
        )}
        <Popover
          open={Boolean(anchorElFilter)}
          anchorEl={anchorElFilter}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
        >
          <FilterModal
            open={openFilterModal}
            onClose={handleCloseFilter}
            selectedFilterData={selectedfilterData}
            options={selectedfilterData?.options}
            selectedValues={selectedfilterData?.filter}
            onSave={handleSaveFilter}
            currentSortOrder={selectedfilterData?.sort}
            filterKey={selectedFilterKey}
            hasMore={selectedfilterData?.loadMoreButton}
            displayKey={selectedfilterData?.displayKey}
            id={selectedfilterData?.id}
            fetchApi={selectedfilterData?.fetchApi}
            searchKey={selectedfilterData?.searchKey}
          />
        </Popover>
        <Popover
          open={Boolean(anchorElDimensionFilter)}
          anchorEl={anchorElDimensionFilter}
          onClose={handleCloseDimensionFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
        >
          <DimensionFilterComponent
            currentSortOrder={sortOrder}
            onConfirm={handleDimensionFilterConfirm}
            onCancel={handleCloseDimensionFilter}
            initialFilter={dimensionFilter}
          />
        </Popover>
        <Popover
          open={Boolean(anchorElWeightFilter)}
          anchorEl={anchorElWeightFilter}
          onClose={handleCloseWeightFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
        >
          <WeightFilterComponent
            currentSortOrder={sortOrder}
            onConfirm={handleWeightFilterConfirm}
            onCancel={handleCloseWeightFilter}
            initialFilter={weightFilter}
          />
        </Popover>
        <Popover
          open={Boolean(anchorElPackageWeightFilter)}
          anchorEl={anchorElPackageWeightFilter}
          onClose={handleCloseWeightFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
        >
          <PackageWeightFilter
            currentSortOrder={sortOrder}
            onConfirm={handlePackageWeightFilterConfirm}
            onCancel={handleClosePackageWeightFilter}
            initialFilter={packageWeightFilter}
          />
        </Popover>
        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          title={`Are you sure you want to delete this item?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData(selectedWeightPackage?._id);
          }}
        />
        <PackageDetailsModal
          weight={selectedWeightPackage}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        />
      </div>
    </>
  );
};

export default WeightPackageListView;
