import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { eventService } from "../../utils/services/eventService";
import DownloadIcon from "../../globalStyles/icons/download-icon";
import { manifestService } from "../../utils/services/manifestService";
import FileSaver from "file-saver";

const ManifestDownloadModal = ({ open, handleClose, selectedManifest }) => {
  const initialData = {
    hawb_prefix: "",
    hawb_suffix: "",
    sender_name: "",
    sender_orgname: "",
    sender_address1: "",
    sender_address2: "",
    sender_district: "",
    sender_city: "",
    sender_state: "",
    sender_zip5: "",
    sender_zip4: "",
    sender_country: "",
    sender_phone: "",
    sender_email: "",
    sender_url: "",
    sort_code: "",
  };
  const [manifestData, setManifestData] = useState(initialData);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const validateForm = () => {
    const errors = {};
    if (!manifestData.hawb_prefix) {
      errors.hawb_prefix = "Hawb prefix is required";
      return false;
    }
    if (!manifestData.hawb_suffix) {
      errors.hawb_suffix = "Hawb suffix is required";
      return false;
    }
    if (!manifestData.sender_name) {
      errors.sender_name = "Sender Name is required";
      return false;
    }
    if (!manifestData.sender_orgname) {
      errors.sender_orgname = "Sender org name is required";
      return false;
    }
    if (!manifestData.sender_address1) {
      errors.sender_address1 = "Sender address is required";
      return false;
    }
    if (!manifestData.sender_address2) {
      errors.sender_address2 = "Sender address is required";
      return false;
    }
    if (!manifestData.sender_district) {
      errors.sender_district = "Sender district is required";
      return false;
    }
    if (!manifestData.sender_city) {
      errors.sender_city = "Sender city is required";
      return false;
    }
    if (!manifestData.sender_state) {
      errors.sender_state = "Sender state is required";
      return false;
    }
    if (!manifestData.sender_zip5) {
      errors.sender_zip5 = "Sender zip5 is required";
      return false;
    }
    if (!manifestData.sender_zip4) {
      errors.sender_zip4 = "Sender zip4 is required";
      return false;
    }
    if (!manifestData.sender_state) {
      errors.sender_state = "Sender state is required";
      return false;
    }
    if (!manifestData.sender_country) {
      errors.sender_country = "Sender country is required";
      return false;
    }
    if (!manifestData.sender_phone) {
      errors.sender_phone = "Sender phone is required";
      return false;
    }
    if (!manifestData.sender_email) {
      errors.sender_email = "Sender email is required";
      return false;
    }
    if (!manifestData.sender_url) {
      errors.sender_url = "Sender url is required";
      return false;
    }
    if (!manifestData.sort_code) {
      errors.sort_code = "Sort code is required";
      return false;
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    setValidated(validateForm());
  }, [manifestData]);
  const handleDownload = async () => {
    try {
      const response = await manifestService.packingList({
        manifest: selectedManifest.manifest.id.toString(),
        hawb_prefix: manifestData.hawb_prefix,
        hawb_suffix: manifestData.hawb_suffix,
        sort_code: manifestData.sort_code,
      });
      let sliceSize = 1024;
      let byteCharacters = atob(response.file);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      let blob = new Blob(byteArrays, { type: response.contentType });
      FileSaver.saveAs(new Blob([blob], {}), response.filename);
      // handleDownload(response)

      // Assuming 'response' contains an array of objects
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
      }
    }
  };
  const handleDownloadManifest = async () => {
    if (!validateForm()) return;
    try {
      const response = await manifestService.manifestDocument({
        ...manifestData,
        manifest: selectedManifest.manifest.id.toString(),
      });
      let sliceSize = 1024;
      let byteCharacters = atob(response.file);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      let blob = new Blob(byteArrays, { type: response.contentType });
      FileSaver.saveAs(new Blob([blob], {}), response.filename);
      // handleDownload(response)

      // Assuming 'response' contains an array of objects
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
      }
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setManifestData(initialData);
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          Download Manifest Document
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Hawb Prefix <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="hawb_prefix"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.hawb_prefix}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  hawb_prefix: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.hawb_prefix}
              disabled={showButton}
            />
            {!!formErrors.hawb_prefix && (
              <FormHelperText error>{formErrors.hawb_prefix}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Hawb Suffix <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="hawb_suffix"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.hawb_suffix}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  hawb_suffix: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.hawb_suffix}
              disabled={showButton}
            />
            {!!formErrors.hawb_suffix && (
              <FormHelperText error>{formErrors.hawb_suffix}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Name <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_name"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_name}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_name: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_name}
              disabled={showButton}
            />
            {!!formErrors.sender_name && (
              <FormHelperText error>{formErrors.sender_name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Orgname <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_orgname"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_orgname}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_orgname: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_orgname}
              disabled={showButton}
            />
            {!!formErrors.sender_orgname && (
              <FormHelperText error>{formErrors.sender_orgname}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Address1 <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_address1"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_address1}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_address1: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_address1}
              disabled={showButton}
            />
            {!!formErrors.sender_address1 && (
              <FormHelperText error>
                {formErrors.sender_address1}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Address2 <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_address2"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_address2}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_address2: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_address2}
              disabled={showButton}
            />
            {!!formErrors.sender_address2 && (
              <FormHelperText error>
                {formErrors.sender_address2}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender District <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_district"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_district}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_district: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_district}
              disabled={showButton}
            />
            {!!formErrors.sender_district && (
              <FormHelperText error>
                {formErrors.sender_district}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender City <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_city"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_city}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_city: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_city}
              disabled={showButton}
            />
            {!!formErrors.sender_city && (
              <FormHelperText error>{formErrors.sender_city}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender State <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_state"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_state}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_state: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_state}
              disabled={showButton}
            />
            {!!formErrors.sender_state && (
              <FormHelperText error>{formErrors.sender_state}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Zip5 <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_zip5"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_zip5}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_zip5: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_zip5}
              disabled={showButton}
            />
            {!!formErrors.sender_zip5 && (
              <FormHelperText error>{formErrors.sender_zip5}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Zip4 <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="description"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_zip4}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_zip4: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_zip4}
              disabled={showButton}
            />
            {!!formErrors.sender_zip4 && (
              <FormHelperText error>{formErrors.sender_zip4}</FormHelperText>
            )}
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Country <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_country"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_country}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_country: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_country}
              disabled={showButton}
            />
            {!!formErrors.sender_country && (
              <FormHelperText error>{formErrors.sender_country}</FormHelperText>
            )}
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Phone <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_phone"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_phone}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_phone: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_phone}
              disabled={showButton}
            />
            {!!formErrors.sender_phone && (
              <FormHelperText error>{formErrors.sender_phone}</FormHelperText>
            )}
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Email <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_email"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_email}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_email: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_email}
              disabled={showButton}
            />
            {!!formErrors.sender_email && (
              <FormHelperText error>{formErrors.sender_email}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sender Url <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sender_url"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sender_url}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sender_url: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sender_url}
              disabled={showButton}
            />
            {!!formErrors.sender_url && (
              <FormHelperText error>{formErrors.sender_url}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Sort Code <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="sort_code"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={manifestData.sort_code}
              onChange={(e) =>
                setManifestData({
                  ...manifestData,
                  sort_code: e.target.value,
                })
              }
              fullWidth
              margin="dense"
              error={!!formErrors.sort_code}
              disabled={showButton}
            />
            {!!formErrors.sort_code && (
              <FormHelperText error>{formErrors.sort_code}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          size="small"
          style={{
            textTransform: "none",
          }}
          variant="outlined"
          color="primary"
          onClick={() => {
            handleClose();
            setManifestData(initialData);
          }}
        >
          <Typography
            fontSize="14px"
            fontWeight={500}
            color={"primary"}
            fontFamily={"poppins"}
          >
            Cancel
          </Typography>
        </Button>
        {!showButton && (
          <Button
            size="small"
            style={{
              textTransform: "none",
            }}
            variant="contained"
            color="primary"
            onClick={() => setShowButton(true)}
            disabled={!validated}
          >
            <Typography
              fontSize="14px"
              fontWeight={500}
              color={"#ffff"}
              fontFamily={"poppins"}
            >
              Ok
            </Typography>
          </Button>
        )}
        {showButton && (
          <>
            <Button
              size="small"
              style={{
                textTransform: "none",
              }}
              variant="contained"
              color="primary"
              onClick={() => handleDownloadManifest()}
            >
              <DownloadIcon fill={"#ffff"} />
              <Typography
                fontSize="14px"
                fontWeight={500}
                sx={{
                  color: "#ffff",
                  marginLeft: 1,
                }}
                fontFamily={"poppins"}
              >
                Manifest Document
              </Typography>
            </Button>
            <Button
              size="small"
              style={{
                textTransform: "none",
              }}
              variant="contained"
              color="primary"
              onClick={() => handleDownload()}
            >
              <DownloadIcon fill={"#ffff"} />
              <Typography
                fontSize="14px"
                fontWeight={500}
                sx={{
                  color: "#ffff",
                  marginLeft: 1,
                }}
                fontFamily={"poppins"}
              >
                PackingList
              </Typography>
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ManifestDownloadModal;
