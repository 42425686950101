import React, { useState, useEffect } from 'react'
import { Box, Checkbox, TextField, Typography, Button, InputAdornment, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // Main style file
import 'react-date-range/dist/theme/default.css' // Theme CSS file
import { changeDateFormat } from '../../utils/utility'

const FilterModal = ({
  open,
  onClose,
  options = [],
  selectedValues = [],
  onSave,
  currentSortOrder,
  filterKey,
  hasMore,
  displayKey,
  id,
  fetchApi,
  searchKey,
  type
}) => {
  const [localSelection, setLocalSelection] = useState(selectedValues || [])
  const [sortOrder, setSortOrder] = useState(currentSortOrder)
  const [searchValue, setSearchValue] = useState('')
  const [optionList, setOptionList] = useState([])
  const clientId = localStorage.getItem('client_id')
  const [page, setPage] = useState(1)
  const limit = 15
  const currentDate = new Date()
  const [selectionRange, setSelectionRange] = useState({
    startDate: null, // new Date(currentDate.setMonth(currentDate.getMonth() - 2)),
    endDate: null, // new Date(new Date().setDate(new Date().getDate() + 2)),
    key: 'selection'
  })

  useEffect(() => {
    if (open) {
      if (type && type === 'date-range') {
        setSelectionRange(selectedValues)
      } else {
        setLocalSelection(selectedValues || [])
      }
      setSortOrder(currentSortOrder)
      setSearchValue('') // Reset search value when modal opens
    }
  }, [open, selectedValues, currentSortOrder])

  useEffect(() => {
    setSearchValue(searchKey)
  }, [searchKey])

  const handleCheckboxChange = (optionId) => {
    if (['All', 'all'].includes(optionId)) {
      if (localSelection.includes(optionId)) {
        setLocalSelection([])
      } else {
        setLocalSelection(optionList.map((x) => x[id]))
      }
    } else {
      setLocalSelection((prevSelection) => {
        if (prevSelection.includes(optionId)) {
          return prevSelection.filter((id) => id !== optionId)
        } else {
          return [...prevSelection, optionId]
        }
      })
    }
    if (localSelection.find((x) => ['all', 'All'].includes(x))) {
      setLocalSelection((prev) => prev.filter((x) => !['all', 'All'].includes(x)))
    }
  }

  const handleSortChange = (order) => {
    if (sortOrder == order) setSortOrder(0)
    else setSortOrder(order)
  }

  const handleSave = () => {
    onSave(
      type == 'date-range'
        ? {
            ...selectionRange,
            startDate: changeDateFormat(selectionRange.startDate),
            endDate: changeDateFormat(selectionRange.endDate)
          }
        : localSelection,
      filterKey,
      sortOrder,
      searchValue
    ) // Save the selected values
    onClose() // Close the modal after saving
  }

  const clearFilter = () => {
    onSave(type == 'date-range' ? { startDate: null, endDate: null } : [], filterKey, 0, '')
    onClose()
  }

  const handleSearchChange = (event) => {
    const value = event.target.value
    setSearchValue(value)
    setPage(1)
  }

  useEffect(() => {
    if (options && options.length)
      setOptionList([
        { [id]: 'All', [displayKey]: 'All' },
        ...options.filter((x) => x[displayKey].toLowerCase().includes((searchValue || '').toLowerCase()))
      ])
  }, [options, searchValue])

  useEffect(() => {
    if (fetchApi && type != 'date-range') {
      fetchApi(searchValue, clientId, limit, (page - 1) * limit).then((res) => {
        if (res.data) {
          if (page == 1) {
            setOptionList([{ [id]: 'All', [displayKey]: 'All' }, ...res.data])
          } else {
            setOptionList([...optionList, ...res.data])
          }
        }
      })
    }
  }, [fetchApi, searchValue, page])

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection)
  }
  function formatKeyToReadableText(key) {
    if (!key) return '' // Handle empty input gracefully

    return key
      .split('.') // Split the key by dot notation
      .map((word) => {
        // Split words containing underscores, capitalize them, and join with a space
        return word
          .split('_') // Split by underscores
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
          .join(' ') // Join the parts back with a space
      })
      .reverse() // Reverse the array for "Name in Shipping Address"
      .map((word, index) => (index === 0 ? word : `in ${word}`)) // Add "in" where appropriate
      .join(' ') // Join the words to create the final string
  }

  return (
    open && (
      <Box
        sx={{
          width: 350,
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 2,
          height: '50vh'
        }}
      >
        <Box display="flex" flexDirection="row" alignItems={'start'} mb={2} gap={2}>
          <Button
            onClick={() => handleSortChange(1)}
            size="small"
            style={{ width: 150 }}
            variant={sortOrder === 1 ? 'contained' : 'outlined'}
          >
            Sort by A to Z
          </Button>
          <Button
            onClick={() => handleSortChange(-1)}
            size="small"
            style={{ width: 150, fontFamily: 'Poppins', fontSize: 13 }}
            variant={sortOrder === -1 ? 'contained' : 'outlined'}
          >
            Sort by Z to A
          </Button>
        </Box>

        {type == 'date-range' ? (
          <DateRange
            ranges={[selectionRange]}
            onChange={handleSelect}
            editableDateInputs={false}
            moveRangeOnFirstSelection={false}
            startDatePlaceholder="From"
            endDatePlaceholder="To"
          />
        ) : (
          <div style={{ maxHeight: '31vh' }}>
            <TextField
              fullWidth
              placeholder={`Search by ${formatKeyToReadableText(filterKey)}`}
              value={searchValue}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />

            <Box sx={{ maxHeight: '27vh', overflowY: 'auto' }}>
              {optionList.map((option, index) => (
                <Box
                  mt={1}
                  key={'option - ' + option[id] + ' - ' + index}
                  display="flex"
                  alignItems="center"
                  mb={1}
                  justifyContent={'flex-start'}
                >
                  <Checkbox
                    style={{
                      padding: 2
                    }}
                    checked={localSelection.includes(option[id])}
                    onChange={() => handleCheckboxChange(option[id])}
                  />

                  <Tooltip title={option[displayKey]} arrow>
                    <Typography
                      fontFamily={'Poppins'}
                      fontSize={13}
                      noWrap
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: 250
                      }}
                    >
                      {option[displayKey]}
                    </Typography>
                  </Tooltip>
                </Box>
              ))}
              {hasMore && optionList.length % limit == 0 && optionList.length == limit * page ? (
                <Box key={`load-more`} display="flex" justifyContent="center" alignItems="center" mb={1}>
                  <Button onClick={() => setPage(page + 1)} variant="outlined">
                    Load More
                  </Button>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </div>
        )}

        <Box paddingBottom={1} display="flex" justifyContent="flex-end" gap={2} marginTop={2}>
          <Button onClick={onClose} style={{ textTransform: 'none' }} variant="outlined">
            Cancel
          </Button>
          <Button onClick={clearFilter} style={{ textTransform: 'none' }} variant="outlined">
            Clear Filter
          </Button>
          <Button onClick={handleSave} variant="contained" style={{ textTransform: 'none' }}>
            Ok
          </Button>
        </Box>
      </Box>
    )
  )
}

export default FilterModal
