import React, { useState, useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { clientService } from '../../utils/services/clientService'

const ThemeWrapper = ({ children }) => {
  const [clientDetails, setClientDetails] = useState(null)
  const clientId = localStorage.getItem('client_id')
  const user = localStorage.getItem('user')

  const fetchClientById = async () => {
    try {
      const client = await clientService.get(clientId)
      setClientDetails(client)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (clientId) {
      fetchClientById() // Removed unnecessary user parameter
    }
  }, [clientId]) // Changed dependency to clientId instead of user

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              // Correct selector for input
              color: 'black',
              backgroundColor: 'transparent',
              '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 30px white inset !important',
                '-webkit-text-fill-color': 'black !important',
                transition: 'background-color 5000s ease-in-out 0s',
                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                WebkitTextFillColor: 'black'
              }
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: 'black',
            '&:-webkit-autofill': {
              '-webkit-box-shadow': '0 0 0 30px white inset !important',
              '-webkit-text-fill-color': 'black !important',
              transition: 'background-color 5000s ease-in-out 0s',
              WebkitBoxShadow: '0 0 0 1000px transparent inset',
              WebkitTextFillColor: 'black',
              caretColor: 'black'
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#000'
          }
        }
      }
    },
    palette: {
      primary: {
        main: clientDetails?.primary_color || '#DC585C'
      },
      secondary: {
        main: clientDetails?.secondary_color || '#7C7C7C'
      },
      text: {
        primary: clientDetails?.text_color || '#000' // Changed from 'main' to 'primary'
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeWrapper
