// api.js

import axiosInstance from "../axiosInstance";

export const assignUserService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post("/client-team", {
        ...data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/client_user_mapping?$limit=${limit || 15}&$skip=${skip || 0}${
          clientId ? `&client=${clientId}` : ""
        }${searchValue ? `&name[$search]=${searchValue}` : ""}&$populate=user`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/users/${id}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/users/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
