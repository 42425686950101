import React, { useEffect, useReducer, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { Box, CircularProgress } from "@mui/material";
import buildQuery from "../../utils/query-builder";
import { shipmentService } from "../../utils/services/shipmentService";
import Shipments from "../../components/shipments";
import { orderService } from "../../utils/services/orderService";

const initialState = {
  columns: [
    {
      key: "order_id",
      sort: 0,
      id: "_id",
      filter: [],
      displayKey: "_id",
      fetchApi: (searchKey = "", client_id, limit = 15, skip = 0) =>
        orderService.fetchOrderId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    },
    {
      key: "shipment",
      sort: 0,
      id: "_id",
      filter: [],
      displayKey: "_id",
      fetchApi: (searchKey = "", client_id, limit = 15, skip = 0) =>
        orderService.fetchShipmentId(searchKey, client_id, limit, skip),
      loadMoreButton: true,
      searchKey: "",
    }
  ],
  limit: 15,
  // populate: ["order"],
};
function filterReducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        columns: state?.columns.map((x) =>
          x?.key === action?.payload?.key ? { ...x, ...action.payload } : x
        ),
      };
    case "SET_OPTIONS":
      return {
        ...state,
        columns: state.columns.map((col) =>
          col.key === action.payload.key
            ? { ...col, options: action.payload.options }
            : col
        ),
      };
    case "UPDATE_LIMIT":
      return { ...state, limit: action.payload };

    default:
      return state;
  }
}
const Shipment = () => {
  const [filterData, dispatch] = useReducer(filterReducer, initialState);
  const [shipmentList, setShipmentList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [clientFilter, setClientFilter] = useState(null);

  const fetchShipment = async () => {
    setLoading(true);
    try {
      const response = await shipmentService.fetch(
        searchValue,
        clientFilter?._id,
        buildQuery(filterData, page)
      );

      setShipmentList(response.data);
      setDataCount(response?.total || 0);
    } catch (error) {
      console.error("Fetch failed:", error);
      setShipmentList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShipment();
  }, [page, filterData, searchValue, clientFilter]);

  const handleFilterChange = (newFilter) => {
    dispatch({ type: "SET_FILTER", payload: newFilter });
    setPage(0);
  };

  return (
    <>
      <DashboardContainer
        onChangeFilter={handleFilterChange}
        filterData={filterData}
        fields={filterData.columns}
        header={"Shipments"}
        hideUploadButton
        hideAddButton
        manifest
        onClickRefresh={fetchShipment}
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        selectedClient={clientFilter?.name}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Shipments
            dataCount={dataCount}
            page={page}
            rowsPerPage={filterData.limit}
            setRowsPerPage={(e) =>
              dispatch({
                type: "UPDATE_LIMIT",
                payload: e,
              })
            }
            setPage={setPage}
            data={shipmentList}
            onChangeFilter={handleFilterChange}
            filterData={filterData}
            onDeleteData={fetchShipment}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default Shipment;
