import axiosInstance from "../axiosInstance";
export const overpackService = {
  fetch: async (client_id, query = "", signal) => {
    try {
      let query_obj = `$sort[createdAt]=-1&${query}`;
      if (client_id) {
        query_obj += `&client_id=${client_id}`;
      }
      let response = await axiosInstance.get(`/overpack?${query_obj}`, signal);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  fetchEntryPoint: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/manifest-entrypoint-list?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCarrier: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }
      let response = await axiosInstance.get(
        `/fetch-manifest-carrier?${query}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateOverpack: async (obj) => {
    try {
      let response = await axiosInstance.post(`/update-overpack`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
