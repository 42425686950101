import React, { useState } from "react";
import { Box, Button, Typography, TextField, MenuItem } from "@mui/material";
import { weightUnits } from "../../utils/weightUnits";

const PackageWeightFilter = ({
  onCancel,
  onConfirm,
  initialFilter = {
    from: { weight: null, unit: null },
    to: { weight: null, unit: null },
  },
  currentSortOrder = 0,
}) => {
  const [formValues, setFormValues] = useState(initialFilter);
  const [sortOrder, setSortOrder] = useState(currentSortOrder);

  const handleChange = (section, field, value) => {
    setFormValues((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const handleSortChange = (order) => {
    setSortOrder(sortOrder === order ? 0 : order);
  };

  const handleConfirm = () => {
    const convertedFrom = {
      weight: formValues.from.weight,
      unit: formValues.from.unit,
      valid: formValues.from.weight && formValues.from.unit ? true : false,
    };

    const convertedTo = {
      weight: formValues.from.weight,
      unit: formValues.to.unit,
      valid: formValues.to.weight && formValues.to.unit ? true : false,
    };

    const filterCriteria = {
      from: convertedFrom,
      to: convertedTo,
    };

    onConfirm({ filters: filterCriteria, sortOrder });
  };

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "16px",
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          marginBottom: "16px",
          width: "100%",
        }}
      >
        <Button
          onClick={() => handleSortChange(1)}
          variant={sortOrder === 1 ? "contained" : "outlined"}
          size="small"
          fullWidth
          sx={{ textTransform: "none", fontSize: "14px" }}
        >
          Sort Lowest to Highest
        </Button>
        <Button
          onClick={() => handleSortChange(-1)}
          variant={sortOrder === -1 ? "contained" : "outlined"}
          size="small"
          fullWidth
          sx={{ textTransform: "none", fontSize: "14px" }}
        >
          Sort Highest to Lowest
        </Button>
      </Box>

      <Typography
        sx={{
          marginBottom: "8px",
        }}
        color={"#333333"}
        fontFamily={"poppins"}
        fontSize={"14px"}
        fontWeight={400}
      >
        From
      </Typography>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <TextField
          label="Weight"
          value={formValues?.from?.weight || ""}
          onChange={(e) => handleChange("from", "weight", e.target.value)}
          size="small"
          type="number"
        />

        <TextField
          select
          label="Unit"
          value={formValues?.from?.unit || ""}
          onChange={(e) => handleChange("from", "unit", e.target.value)}
          size="small"
          sx={{ minWidth: "100px" }}
        >
          {weightUnits.map((unit) => (
            <MenuItem key={unit.abbreviation} value={unit.abbreviation}>
              {unit.name} ({unit.abbreviation})
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Typography
        sx={{ marginBottom: "8px", marginTop: "10px" }}
        color={"#333333"}
        fontFamily={"poppins"}
        fontSize={"14px"}
        fontWeight={400}
      >
        To
      </Typography>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <TextField
          label="Weight"
          type="number"
          value={formValues?.to?.weight || ""}
          onChange={(e) => handleChange("to", "weight", e.target.value)}
          size="small"
        />

        <TextField
          select
          label="Unit"
          value={formValues?.to?.unit || ""}
          onChange={(e) => handleChange("to", "unit", e.target.value)}
          size="small"
          sx={{ minWidth: "100px" }}
        >
          {weightUnits.map((unit) => (
            <MenuItem key={unit.abbreviation} value={unit.abbreviation}>
              {unit.name} ({unit.abbreviation})
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          marginTop: "16px",
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="primary">
          OK
        </Button>
      </Box>
    </Box>
  );
};

export default PackageWeightFilter;
