import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Paper,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import Button from "@mui/material/Button/Button";
import Card from "@mui/material/Card/Card";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomTypography from "../../globalStyles/fonts";
import { carrierService } from "../../utils/services/carrierService";
import { clientService } from "../../utils/services/clientService";
import { omsService } from "../../utils/services/omsProvider";
import { tmsService } from "../../utils/services/tmsService";
import { wmsService } from "../../utils/services/wmsProvider";
import ClientProviderModal from "../client-provider-modal";
import AppContext from "../context/context";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import OMSManagement from "../webhook";
import { isMobile } from "../../globalStyles/mobile";
import ClientDetailsModal from "../clientViewDetailsModal";
import { omsLocationService } from "../../utils/services/omsLocationService";
import WarehouseSelectionModal from "../oms-location-modal";
import ProfileIcon from "@mui/icons-material/RoomPreferences";
import { userService } from "../../utils/services/userService";
import { useNavigate } from "react-router-dom";
import { providerService } from "../../utils/services/providerService";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/styles";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    color: theme.palette.common.white,
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme, isExpandable }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#F6F6F6",
    border: "1px solid #EAECF0",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ClientMobileCard = ({
  data,
  handleMenuOpen,
  handleStatusToggle,
  ListItem,
  IOSSwitch,
  ActionButtons,
  menuPosition,
  menuRef,
  Action,
}) => {
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_client.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "300px" }}
      />
    </div>
  );
  return (
    <Box sx={{ width: "100%", padding: 0.5 }}>
      {data.length === 0 ? (
        <NoDataImage />
      ) : (
        data?.map((client, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffff",
              borderRadius: 4,
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <ListItem
                    value={`: ${client.business_name || client.name}`}
                    header={"Name"}
                  ></ListItem>
                  <ListItem
                    value={`: ${client.contact}`}
                    header={"Mobile No"}
                  ></ListItem>
                  <ListItem
                    value={`: ${client.email}`}
                    header={"Email"}
                  ></ListItem>
                  <ListItem
                    value={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          maxWidth: 120,
                        }}
                      >
                        <CustomTypography
                          style={{ marginRight: 2, minWidth: 50 }}
                          fontSize="13px"
                          color={
                            client?.status === "Rejected"
                              ? "red"
                              : client?.status === "Active"
                              ? "#007D14"
                              : client?.status === "Pending"
                              ? "#FF8A00"
                              : client?.status === "Approved"
                              ? "#FF8A00"
                              : "#757477"
                          }
                          fontWeight={500}
                        >
                          : {client.status}
                        </CustomTypography>
                        {client.status === "Rejected" ? (
                          <></>
                        ) : (
                          <div
                            onClick={(e) => {
                              if (
                                !["Pending", "Uninstalled"].includes(
                                  client.status
                                )
                              ) {
                                e.preventDefault();
                                e.stopPropagation();
                                handleStatusToggle(
                                  client._id,
                                  client.status,
                                  e,
                                  client.email
                                );
                              }
                            }}
                            style={{
                              pointerEvents: [
                                "Pending",
                                "Uninstalled",
                              ].includes(client.status)
                                ? "none"
                                : "auto",
                              opacity: ["Pending", "Uninstalled"].includes(
                                client.status
                              )
                                ? 0.5
                                : 1,
                            }}
                          >
                            {client.status === "Uninstalled" ? (
                              <></>
                            ) : (
                              <IOSSwitch
                                disabled={["Pending"].includes(client.status)}
                                checked={client.status == "Active"}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    }
                    header={"Status"}
                  ></ListItem>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ListItem
                  gridView={true}
                  value={
                    <ActionButtons
                      index={index}
                      gridView={true}
                      view
                      client={client}
                    ></ActionButtons>
                  }
                  // header={'Action'}
                ></ListItem>
                <IconButton
                  aria-haspopup="true"
                  onClick={(e) => handleMenuOpen(e, client)}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        ))
      )}
      {menuPosition && (
        <div
          ref={menuRef}
          style={{
            position: "absolute",
            top: menuPosition.y,
            left: menuPosition.x - 20,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            width: "200px",
          }}
        >
          <Action></Action>
        </div>
      )}
    </Box>
  );
};

export default ClientMobileCard;
