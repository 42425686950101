import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { globalNetworkService } from "../../utils/services/globalNetworkService";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";

const GlobalNetworkDesignAddEditModal = ({
  open,
  onClose,
  onAddData,
  selectedNetwork,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const clientId = localStorage.getItem("client_id");
  const [formData, setFormData] = useState({
    supplier_location: "",
    fulfillment_location: "",
    shipping_service: "",
    package_type: "",
    weight: "",
  });
  const initialData = () => {
    setFormData({
      supplier_location: "",
      fulfillment_location: "",
      shipping_service: "",
      package_type: "",
      weight: "",
    });
    setFormErrors({});
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.shipping_service) {
      errors.shipping = " Shipping Service is required";
    }
    if (!formData.supplier_location) {
      errors.supplier = "Supplier Location is required";
    }
    if (!formData.fulfillment_location) {
      errors.fulfillment = "Fulfillment Location is required";
    }
    if (!formData.package_type) {
      errors.package = "Package is required";
    }
    if (!formData.weight) {
      errors.weight = "Weight is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    if (selectedNetwork) {
      setFormData({
        supplier_location: selectedNetwork?.supplier_location || "",
        fulfillment_location: selectedNetwork?.fulfillment_location || "",
        shipping_service: selectedNetwork?.shipping_service || "",
        package_type: selectedNetwork?.package_type || "",
        weight: selectedNetwork?.weight || "",
      });
    } else {
      initialData();
    }
  }, [selectedNetwork]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await globalNetworkService.edit(selectedNetwork._id, {
        supplier_location: formData.supplier_location,
        fulfillment_location: formData.fulfillment_location,
        shipping_service: formData.shipping_service,
        package_type: formData.package_type,
        weight: formData.weight,
        client_id: clientId,
      });
      onAddData && onAddData();
      toast.success("Global Network edited successfully!");
      initialData();
    } catch (e) {
      console.error(e);
    }
    initialData();
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await globalNetworkService.create({
        supplier_location: formData.supplier_location,
        fulfillment_location: formData.fulfillment_location,
        shipping_service: formData.shipping_service,
        package_type: formData.package_type,
        weight: formData.weight,
        client_id: clientId,
      });
      onAddData && onAddData();
      toast.success("Global Network created successfully!");
      handleClose();
      initialData();
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setFormData({
      supplier_location: "",
      fulfillment_location: "",
      shipping_service: "",
      package_type: "",
      weight: "",
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: isMobile() ? 16 : 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedNetwork ? "Edit" : "Create"} Global Network
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              fontSize="14px"
              fontWeight={400}
              fontFamily={"poppins"}
            >
              Supplier Location <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="supplier_location"
              autoFocus
              size="small"
              placeholder="Enter Supplier Location"
              type="text"
              value={formData.supplier_location}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.supplier}
              helperText={formErrors.supplier}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Fulfillment Location <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              sx={{ marginTop: 1 }}
              name="fulfillment_location"
              type="text"
              size="small"
              placeholder="Enter Fulfillment Location"
              variant="outlined"
              value={formData.fulfillment_location}
              onChange={handleInputChange}
              fullWidth
              autoFocus
              error={!!formErrors.fulfillment}
              helperText={formErrors.fulfillment}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Shipping Service <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              sx={{ marginTop: 1 }}
              name="shipping_service"
              type="text"
              size="small"
              placeholder="Enter Shipping Service"
              variant="outlined"
              value={formData.shipping_service}
              onChange={handleInputChange}
              fullWidth
              autoFocus
              error={!!formErrors.shipping}
              helperText={formErrors.shipping}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Package Type <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              sx={{ marginTop: 1 }}
              name="package_type"
              type="text"
              size="small"
              placeholder="Enter Package Type"
              variant="outlined"
              value={formData.package_type}
              onChange={handleInputChange}
              fullWidth
              autoFocus
              error={!!formErrors.package}
              helperText={formErrors.package}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography fontSize={15}>
              Weight <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              sx={{ marginTop: 1 }}
              name="weight"
              type="number"
              size="small"
              placeholder="Enter Weight"
              variant="outlined"
              value={formData.weight}
              onChange={handleInputChange}
              fullWidth
              autoFocus
              error={!!formErrors.weight}
              helperText={formErrors.weight}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedNetwork ? handleEditData(e) : handleSave(e))}
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedNetwork ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalNetworkDesignAddEditModal;
