import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Paper,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import Button from "@mui/material/Button/Button";
import Card from "@mui/material/Card/Card";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomTypography from "../../globalStyles/fonts";
import { carrierService } from "../../utils/services/carrierService";
import { clientService } from "../../utils/services/clientService";
import { omsService } from "../../utils/services/omsProvider";
import { tmsService } from "../../utils/services/tmsService";
import { wmsService } from "../../utils/services/wmsProvider";
import ClientProviderModal from "../client-provider-modal";
import AppContext from "../context/context";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import OMSManagement from "../webhook";
import { isMobile } from "../../globalStyles/mobile";
import ClientDetailsModal from "../clientViewDetailsModal";
import { omsLocationService } from "../../utils/services/omsLocationService";
import WarehouseSelectionModal from "../oms-location-modal";
import ProfileIcon from "@mui/icons-material/RoomPreferences";
import { userService } from "../../utils/services/userService";
import { useNavigate } from "react-router-dom";
import { providerService } from "../../utils/services/providerService";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/styles";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import ClientMobileCard from "./client-mobile-card";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    color: theme.palette.common.white,
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme, isExpandable }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#F6F6F6",
    border: "1px solid #EAECF0",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        border: `6px solid ${theme.palette.mode === "dark" ? "#fff" : "#fff"}`,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ClientList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleEditClient,
  handleDeleteClient,
  onSelectClient,
  viewMode,
  onViewItem,
  onChangeStatus,
  onSuccess,
  currentPage,
  itemsPerPage,
  onChangeFilter,
  filterData,
}) => {
  const [clientData, setClientData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectClientId, setSelectClientId] = useState(null);
  const [wms, setWms] = useState(false);
  const [tms, setTms] = useState(false);
  const { user, setUser } = useContext(AppContext);
  const [openOmsModal, setOpenOmsModal] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const [providerData, setProviderData] = useState({
    oms: [],
    tms: [],
    wms: [],
  });
  const [openClientModal, setOpenClientModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openWarehouseList, setOpenWarehouseList] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [selectedfilterData, setSelectedFilterData] = useState({
    key: "",
    name: "",
    filter: [],
    sort: 0,
    id: "_id",
    displayKey: "name",
    fetchApi: () => {},
    loadMoreButton: false,
    options: [],
    searchKey: "",
  });
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;
  const secondary_color = theme.palette?.secondary?.main || defaultColor;
  const navigate = useNavigate();
  const startIndex = currentPage * itemsPerPage;
  const [menuPosition, setMenuPosition] = useState(null);
  const menuRef = useRef(null);
  const [selectedProvider, setSelectedProvider] = useState({});

  const handleMenuOpen = (event, client) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const menuHeight = 120; // Approx height of the menu (adjust as needed)
    const menuWidth = 200; // Approx width of the menu (adjust as needed)
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    let calculatedX = rect.left + window.scrollX;
    let calculatedY = rect.top + window.scrollY + rect.height;

    // Adjust for bottom boundary overflow
    if (calculatedY + menuHeight > viewportHeight) {
      calculatedY = viewportHeight - menuHeight - 10; // 10px padding
    }

    // Adjust for right boundary overflow
    if (calculatedX + menuWidth > viewportWidth) {
      calculatedX = viewportWidth - menuWidth - 10; // 10px padding
    }

    setMenuPosition({ x: calculatedX, y: calculatedY });
    setSelectedClient(client);
  };

  const handleMenuClose = () => {
    setMenuPosition(null);
    setSelectedClient(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleMenuClose();
      }
    };

    if (menuPosition) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuPosition]);

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const handleOpenFilter = (event, filterKey) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilterModal(true);
    setSelectedFilterData(filterData?.columns.find((x) => x?.key == filterKey));
  };
  // const handleSaveFilter = (
  //   selectedOptions,
  //   filterKey,
  //   sortOrder,
  //   searchKey
  // ) => {
  //   onChangeFilter({
  //     key: filterKey,
  //     filter: selectedOptions,
  //     sort: sortOrder,
  //     searchKey: searchKey,
  //   });
  //   handleCloseFilter();
  // };
  const handleSaveFilter = () => {
    console.log("button clicked");
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setOpenFilterModal(false);
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    try {
      const response = await providerService.fetch(100, 0);
      setProviderList(response?.data);
    } catch (error) {
      console.error("Fetch failed:", error);
      throw error;
    }
  };
  const fetchProviderDetails = async (id) => {
    if (id) {
      setLoading(true);
      try {
        const omsResponse = await omsService.fetch(id);
        const tmsResponse = await tmsService.fetch(id);
        const wmsResponse = await wmsService.fetch(id);
        setProviderData({
          oms: omsResponse.data,
          tms: tmsResponse.data,
          wms: wmsResponse.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Fetch failed:", error);
        throw error;
      }
    }
  };
  const changeClientStatus = async (id, status) => {
    setLoading(true);
    try {
      const response = await clientService.edit(
        {
          status: status,
        },
        id
      );
      if (onChangeStatus) onChangeStatus();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch failed:", error);
      throw error;
    }
  };

  const handleEnterClientProfile = async (data) => {
    setLoading(true);
    try {
      const response = await userService.fetchUserByEmail(data.email);
      console.log(response);
      const adminData = localStorage.getItem("user");
      localStorage.setItem("adminUser", adminData);
      let admin_token = localStorage.getItem("token");
      localStorage.setItem("token", response.accessToken);
      localStorage.setItem("adminToken", admin_token);

      localStorage.setItem("user", JSON.stringify(response.user));
      setUser(response.user);
      localStorage.setItem("client_id", data._id);
      navigate("/", { replace: true });
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch failed:", error);
      throw error;
    }
  };
  const confirmDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await carrierService.delete(selectedClient._id);
      if (onDeleteData) onDeleteData();
      setOpenConfirmation(false);
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const sendActivationKey = async (id, email) => {
    try {
      setLoading(true);
      const response = await clientService.sendActivationKey(id, email);
      setLoading(false);
    } catch (error) {
      console.error("Delete failed:", error);
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const handleStatusToggle = (clientId, status, e, email) => {
    e.preventDefault();
    e.stopPropagation();
    if (status === "Approved") {
      changeClientStatus(clientId, "Active");
      sendActivationKey(clientId, email);
    } else if (status === "Active") {
      changeClientStatus(clientId, "Inactive");
    } else {
      changeClientStatus(clientId, "Active");
    }
    // Implement the logic to handle the status toggle
    console.log(`Client ID: ${clientId}, Status: ${status}`);
  };
  const ActionButtons = ({
    client,
    view,
    gridView,
    index,
    status,
    showActiveKey,
  }) => {
    const isDisabled = ["Inactive", "Uninstalled"].includes(status);
    return (
      <React.Fragment>
        <div style={{ width: "100%" }}>
          <Button
            disabled={isDisabled}
            onClick={(e) => {
              e.stopPropagation();
              setOpenOmsModal(true);
              setSelectedClient(client);
            }}
            style={{
              // backgroundColor: isDisabled ? "#F0F0F0" : "#007BFF",
              padding: 0,
              marginRight: 8,
              // paddingLeft: 10,
              // paddingRight: 10,
              minHeight: 30,
              // marginTop: 1,
              // marginBottom: 1,
              border: isDisabled ? "1px solid #BDBDBD" : "1px solid #0070FF",
            }}
            aria-label="oms"
            variant="outlined"
            startIcon={
              <AddIcon sx={{ color: isDisabled ? "#BDBDBD" : "#0070FF" }} />
            }
          >
            <Typography
              fontSize="12px"
              color={isDisabled ? "#BDBDBD" : "#0070FF"}
              fontWeight={500}
              fontFamily={"poppins"}
            >
              OMS
            </Typography>
          </Button>
          <Button
            disabled={isDisabled}
            onClick={(e) => {
              setTms(true);
              setOpenOmsModal(true);
              e.stopPropagation();
              setSelectedClient(client);
            }}
            style={{
              // backgroundColor: isDisabled ? "#F0F0F0" : "#28A745",
              padding: 0,
              marginRight: 8,
              // paddingLeft: 10,
              // paddingRight: 10,
              minHeight: 30,
              // marginTop: 1,
              // marginBottom: 1,
              border: isDisabled ? "1px solid #BDBDBD" : "1px solid #007613",
            }}
            aria-label="oms"
            variant="outlined"
            startIcon={
              <AddIcon sx={{ color: isDisabled ? "#BDBDBD" : "#007613" }} />
            }
          >
            <Typography
              fontSize="13px"
              color={isDisabled ? "#BDBDBD" : "#007613"}
              fontWeight={500}
              fontFamily={"poppins"}
            >
              TMS
            </Typography>
          </Button>
          <Button
            disabled={isDisabled}
            onClick={(e) => {
              setWms(true);
              setOpenOmsModal(true);
              e.stopPropagation();
              setSelectedClient(client);
            }}
            style={{
              // backgroundColor: isDisabled ? "#F0F0F0" : "#FD7E14",
              padding: 0,
              marginRight: 8,
              // paddingLeft: 10,
              // paddingRight: 10,
              minHeight: 30,
              // marginTop: 2,
              // marginBottom: 1,
              border: isDisabled ? "1px solid #BDBDBD" : "1px solid #FD7E14",
            }}
            aria-label="oms"
            variant="outlined"
            startIcon={
              <AddIcon sx={{ color: isDisabled ? "#BDBDBD" : "#FD7E14" }} />
            }
          >
            <Typography
              fontSize="13px"
              color={isDisabled ? "#BDBDBD" : "#FD7E14"}
              fontWeight={500}
              fontFamily={"poppins"}
            >
              WMS
            </Typography>
          </Button>
        </div>
      </React.Fragment>
    );
  };
  const Action = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
        }}
      >
        <Button
          style={{
            color: "#333333",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="edit"
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(null);
            handleEditClient(selectedClient);
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <EditIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              color={"#333333"}
              fontWeight={500}
            >
              Edit
            </Typography>
          </Box>
        </Button>

        <Button
          style={{
            color: "#333333",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
          aria-label="view"
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(null);
            setOpenViewModal(true);
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            gap={1}
          >
            <ViewIcon />
            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              color={"#333333"}
              fontWeight={500}
            >
              View
            </Typography>
          </Box>
        </Button>

        {["Pending", "Uninstalled"].includes(selectedClient?.status) ? (
          <></>
        ) : (
          <Button
            style={{
              color: "#333333",
              justifyContent: "flex-start",
              textTransform: "none",
            }}
            aria-label="profile"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(null);
              handleEnterClientProfile(selectedClient);
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ProfileIcon color="error" />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Profile
              </Typography>
            </Box>
          </Button>
        )}
      </div>
    );
  };
  useEffect(() => {
    setClientData(
      data?.map((client) => {
        const isDisabled = ["Inactive", "Uninstalled"].includes(client.status);
        const isSelected = selectedClient === client;

        return {
          ...client,
          action: (
            <div>
              <IconButton
                aria-label="more"
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                  setSelectedClient(client);
                }}
                disabled={isDisabled}
                sx={{
                  color: isDisabled
                    ? "#EAECF0"
                    : isSelected
                    ? "primary.main"
                    : "inherit",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={(e) => {
                  e.stopPropagation();
                  setAnchorEl(null);
                  setSelectedClient(null);
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    boxShadow: "none",
                    border: "1px solid #EEEEEE",
                    borderRadius: "4px",
                  },
                }}
              >
                <Action></Action>
              </Popover>
            </div>
          ),
        };
      })
    );
  }, [data, anchorEl]);
  const ListItem = ({ header, value, jsx, gridView }) => {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        {gridView ? (
          <></>
        ) : (
          <div
            style={{
              width: "40%",
              display: "flex",
              paddingInline: 10,
              paddingTop: 7,
              paddingBottom: 5,
            }}
          >
            <CustomTypography
              style={{ textAlign: "left", fontSize: 14 }}
              fontWeight={600}
              color={"#333333"}
            >
              {header}
            </CustomTypography>
          </div>
        )}
        <div
          style={{
            width: gridView && isMobile ? "100%" : "60%",
            display: "flex",
            paddingInline: 10,
            paddingTop: 7,
            paddingBottom: 5,
          }}
        >
          <Tooltip title={typeof value === "string" ? value : ""}>
            <p
              style={{
                textAlign: "left",
                maxWidth: "99%",
                display: "flex",
                flexWrap: typeof value === "string" ? "nowrap" : "wrap",
                overflow: typeof value === "string" ? "auto" : "visible",
                textOverflow: "ellipsis",
                whiteSpace: typeof value === "string" ? "nowrap" : "normal",
                fontFamily: "Poppins",
                fontSize: 13,
                color: "#606060",
                fontWeight: 500,
              }}
            >
              {value}
            </p>
          </Tooltip>
        </div>
      </div>
    );
  };
  const renderGridView = () => (
    <Grid sx={{ padding: 1 }} container spacing={2}>
      {data?.map((client, index) => (
        <Grid
          sx={{ paddingBottom: 5 }}
          item
          xs={12}
          sm={6}
          md={4}
          key={client.id}
        >
          <Card sx={{ boxShadow: 3, minHeight: 50 }}>
            <div
              style={{
                display: "flex",
                padding: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <ListItem value={client.name} header={"Client Name"}></ListItem>
                <ListItem
                  value={client.contact ? client.contact : ""}
                  header={"Mobile No"}
                ></ListItem>
                <ListItem value={client.email} header={"Email"}></ListItem>
                <ListItem
                  value={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        maxWidth: 120,
                      }}
                    >
                      <CustomTypography
                        style={{ marginRight: 2, minWidth: 50 }}
                        fontSize="13px"
                        color={
                          client?.status === "Rejected"
                            ? "red"
                            : client?.status === "Active"
                            ? "#007D14"
                            : client?.status === "Pending"
                            ? "#FF8A00"
                            : client?.status === "Approved"
                            ? "#FF8A00"
                            : "#757477"
                        }
                        fontWeight={500}
                      >
                        {client.status}
                      </CustomTypography>
                      {client.status === "Rejected" ? (
                        <></>
                      ) : (
                        <div
                          onClick={(e) => {
                            if (
                              !["Pending", "Uninstalled"].includes(
                                client.status
                              )
                            ) {
                              e.preventDefault();
                              e.stopPropagation();
                              handleStatusToggle(
                                client._id,
                                client.status,
                                e,
                                client.email
                              );
                            }
                          }}
                          style={{
                            pointerEvents: ["Pending", "Uninstalled"].includes(
                              client.status
                            )
                              ? "none"
                              : "auto",
                            opacity: ["Pending", "Uninstalled"].includes(
                              client.status
                            )
                              ? 0.5
                              : 1,
                          }}
                        >
                          {client.status === "Uninstalled" ? (
                            <></>
                          ) : (
                            <IOSSwitch
                              disabled={["Pending"].includes(client.status)}
                              checked={client.status == "Active"}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  }
                  header={"Status"}
                ></ListItem>
                <ListItem
                  gridView={true}
                  value={
                    <ActionButtons
                      index={index}
                      gridView={true}
                      view
                      client={client}
                    ></ActionButtons>
                  }
                  // header={'Action'}
                ></ListItem>
              </div>
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile() ? "column" : "row",
        }}
      > */}
      {isMobile() ? (
        <ClientMobileCard
          data={data}
          handleMenuOpen={handleMenuOpen}
          handleStatusToggle={handleStatusToggle}
          ListItem={ListItem}
          IOSSwitch={IOSSwitch}
          ActionButtons={ActionButtons}
          menuPosition={menuPosition}
          menuRef={menuRef}
          Action={Action}
        />
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      SL NO
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        CLIENT NAME
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //onClick={}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        BUSINESS NAME
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //onClick={}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        MOBILE NO
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //onClick={}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        EMAIL
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //onClick={}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        STATUS
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //onClick={}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        PROVIDERS
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //onClick={}
                      >
                        <SortingIcon fill={primary_color || "#DC585C"} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    ></Typography>
                  </StyledTableCell>
                </>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {clientData?.map((client, index) => (
                <React.Fragment key={index}>
                  <StyledTableRow
                    isExpandable={openRow === client._id}
                    onClick={() => {
                      if (client.status !== "Uninstalled") {
                        handleRowClick(index);
                        if (openRow !== index)
                          fetchProviderDetails(client?._id);
                        setSelectClientId(client?._id);
                        setSelectedClient(client);
                      }
                    }}
                    style={{
                      cursor:
                        client.status === "Uninstalled"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                      border: "none",
                      padding: 0,
                      "&:lastChild td, &:lastChild th": {
                        border: "none",
                      },
                    }}
                  >
                    {client ? (
                      <>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"Poppins"}
                          >
                            {startIndex + index + 1}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"Poppins"}
                          >
                            {client?.name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"Poppins"}
                          >
                            {client?.business_name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"Poppins"}
                          >
                            {client?.contact}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"Poppins"}
                          >
                            {client?.email}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              maxWidth: 120,
                            }}
                          >
                            {client.status === "Active" ? (
                              <img
                                src="/Dot.png"
                                alt="dot"
                                style={{ marginRight: "8px" }}
                              />
                            ) : client.status === "Pending" ? (
                              <img
                                src="/pending.png"
                                alt="pending"
                                style={{ marginRight: "8px" }}
                              />
                            ) : (
                              <img
                                src="/unnstall.png"
                                alt="uninstalled"
                                style={{ marginRight: "8px" }}
                              />
                            )}

                            <Typography
                              style={{ marginRight: 2, minWidth: 50 }}
                              fontSize="13px"
                              color={
                                client?.status === "Rejected"
                                  ? "red"
                                  : client?.status === "Active"
                                  ? "#007D14"
                                  : client?.status === "Pending"
                                  ? "#FF8A00"
                                  : client?.status === "Approved"
                                  ? "#FF8A00"
                                  : "#757477"
                              }
                              fontWeight={500}
                            >
                              {client.status}
                            </Typography>
                            {client.status === "Rejected" ? (
                              <></>
                            ) : (
                              <div
                                onClick={(e) => {
                                  if (
                                    !["Pending", "Uninstalled"].includes(
                                      client.status
                                    )
                                  ) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleStatusToggle(
                                      client._id,
                                      client.status,
                                      e,
                                      client.email
                                    );
                                  }
                                }}
                                style={{
                                  pointerEvents: [
                                    "Pending",
                                    "Uninstalled",
                                  ].includes(client.status)
                                    ? "none"
                                    : "auto",
                                  opacity: ["Pending", "Uninstalled"].includes(
                                    client.status
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              >
                                {client.status === "Uninstalled" ? (
                                  <></>
                                ) : (
                                  <IOSSwitch
                                    disabled={["Pending"].includes(
                                      client.status
                                    )}
                                    checked={client.status == "Active"}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <ActionButtons
                            status={client.status}
                            client={client}
                          ></ActionButtons>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "3%", textAlign: "center" }}
                        >
                          {client?.action}
                        </StyledTableCell>
                      </>
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: 0,
                      }}
                      colSpan={8}
                    >
                      <Collapse
                        in={openRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          {loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                zIndex: 9999,
                                color: "white",
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </Box>
                          ) : (
                            <TableContainer
                              sx={{ padding: 1 }}
                              component={Paper}
                            >
                              <OMSManagement
                                onClickLocation={() =>
                                  setOpenWarehouseList(true)
                                }
                                onEditWms={(data) => {
                                  fetchProviderDetails(client?._id);
                                  setSelectedData(data);
                                  setSelectedClient(client);
                                  setOpenOmsModal(true);
                                  setWms(true);
                                  setTms(false);
                                }}
                                onEditTms={(data) => {
                                  fetchProviderDetails(client?._id);
                                  setSelectedData(data);
                                  setSelectedClient(client);
                                  setOpenOmsModal(true);
                                  setWms(false);
                                  setTms(true);
                                }}
                                onEditOms={(data) => {
                                  fetchProviderDetails(client?._id);
                                  setSelectedData(data);
                                  setSelectedClient(client);
                                  setOpenOmsModal(true);
                                }}
                                providerData={providerData}
                                clientId={client?._id}
                                setSelectedProvider={(provider) =>
                                  setSelectedProvider(provider)
                                }
                              ></OMSManagement>
                            </TableContainer>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* </div> */}
    </div>
  );
  return (
    <div sx={{ minWidth: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <></>
      )}
      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: isMobile() ? "70vh" : `calc(100vh - 250px)`,
          width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
          paddingBottom: isMobile() ? 25 : 0,
        }}
      >
        {viewMode === "grid" ? renderGridView() : renderListView()}
      </div>
      <DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedClient?.name}?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          if (client) {
            if (handleDeleteClient) handleDeleteClient(selectedClient);
            setOpenConfirmation(false);
          } else {
            confirmDelete(e);
          }
        }}
      />
      <Dialog
        open={openClientModal}
        onClose={() => setOpenClientModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <OMSManagement
            onClickLocation={() => setOpenWarehouseList(true)}
            onEditWms={(data) => {
              fetchProviderDetails(client?._id);
              setSelectedData(data);
              setOpenOmsModal(true);
              setWms(true);
              setTms(false);
            }}
            onEditTms={(data) => {
              fetchProviderDetails(client?._id);
              setSelectedData(data);
              setOpenOmsModal(true);
              setWms(false);
              setTms(true);
            }}
            onEditOms={(data) => {
              fetchProviderDetails(client?._id);
              setSelectedData(data);
              setOpenOmsModal(true);
            }}
            providerData={providerData}
            clientId={client?._id}
          ></OMSManagement>
        </DialogContent>
      </Dialog>
      <ClientProviderModal
        onDeleteSuccess={() => {
          fetchProviderDetails(selectClientId);
          setOpenOmsModal(false);
        }}
        loading={loading}
        setLoading={setLoading}
        onAddProviderData={() => fetchProviderDetails(selectClientId)}
        selectedData={selectedData.map((x) => ({
          ...x,
          provider: x.provider._id,
        }))}
        selectedClient={selectedClient}
        wms={wms}
        tms={tms}
        onClose={() => {
          setWms(false);
          setTms(false);
          setSelectedData([]);
        }}
        setOpen={setOpenOmsModal}
        open={openOmsModal}
        providerList={providerList}
      ></ClientProviderModal>
      <ClientDetailsModal
        onSuccess={onSuccess}
        client={selectedClient}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
      <WarehouseSelectionModal
        selectedProvider={selectedProvider}
        onClose={() => setOpenWarehouseList(false)}
        open={openWarehouseList}
        providerList={providerList}
        providers={providerList.filter((x) => x.type === "WMS")}
        filterData={filterData}
        selectedfilterData={selectedfilterData}
        handleSaveFilter={handleSaveFilter}
        anchorElFilter={anchorElFilter}
        openFilterModal={openFilterModal}
        setOpenFilterModal={setOpenFilterModal}
        setSelectedFilterData={setSelectedFilterData}
        setAnchorElFilter={setAnchorElFilter}
        selectedClient={selectedClient}
        setSelectedProvider={setSelectedProvider}
      ></WarehouseSelectionModal>
    </div>
  );
};

export default ClientList;
