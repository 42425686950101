import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import IconButton from "@mui/material/IconButton/IconButton";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import EditIcon from "../../globalStyles/icons/editIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import { carrierServiceManagement } from "../../utils/services/serviceManagement";
import AppContext from "../context/context";
import CloseIcon from "@mui/icons-material/Close";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  Popover,
  Box,
  TableRow,
  Tooltip,
  TableContainer,
  CircularProgress,
  TableHead,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import AssignClient from "../client-assign";
import ServiceListDetailsModal from "../serviceListViewDetailsModal";
import { isMobile } from "../../globalStyles/mobile";
import ClientAssignIcon from "../../globalStyles/icons/client-assign-icon";
import { useTheme } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const ServiceTableCell = ({
  service,
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
  handleClientAssignClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "view":
        handleViewClick(service);
        break;
      case "edit":
        handleEditClick(service);
        break;
      case "delete":
        handleDeleteClick(service);
        break;
      case "assign":
        handleClientAssignClick(service);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "inherit",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
          <Button
            onClick={() => handleActionClick("edit")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <EditIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Edit
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("view")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ViewIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                View
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick("delete")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <DeleteIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Delete
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("assign")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ClientAssignIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Client Assign
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
const ServiceList = ({
  data,
  onEditData,
  onDeleteData,
  carrierList,
  viewMode,
  setCarrierSearchValue,
  fetchCarrierData,
  handleScroll,
  setPage,
  setCarrierList,
  currentPage,
  itemsPerPage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState(""); // State for selected carrier in Autocomplete
  const [openConfirmation, setOpenConfirmation] = useState(false); // State for confirmation dialog
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [formErrors, setFormErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const { user } = useContext(AppContext);
  const [openViewModal, setOpenViewModal] = useState(false);
  const clientId = localStorage.getItem("client_id");
  const [selectedServiceData, setSelectedServiceData] = useState([]);
  const startIndex = currentPage * itemsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;

  const handleEditClick = (carrier) => {
    setSelectedService(carrier);
    setServiceName(carrier.name);
    setSelectedCarrier({
      name: carrier?.carrier_id?.name,
      _id: carrier?.carrier_id?._id,
    });
    setSelectedClient({
      name: carrier.client_id?.name,
      _id: carrier?.client_id?._id,
    });
    setOpenModal(true);
    setFormErrors({});
  };

  const handleDeleteClick = (carrier) => {
    setSelectedService(carrier);
    setOpenConfirmation(true);
  };

  const confirmDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await carrierServiceManagement.delete(
        selectedService._id
      );
      setLoading(false);
      onDeleteData && onDeleteData();
      toast.success("Carrier Service deleted successfully");
      setAnchorEl(null);
      setOpenConfirmation(false);
    } catch (error) {
      console.error("Delete failed:", error);
      setLoading(false);
      setOpenConfirmation(false);
    }
  };
  const handleClientAssignClick = (x) => {
    setSelectedService(x);
    setModalOpen(true);
  };
  useEffect(() => {
    setSelectedServiceData(
      data?.map((service) => ({
        ...service,
        action: (
          <ServiceTableCell
            service={service}
            handleViewClick={handleViewClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            handleClientAssignClick={handleClientAssignClick}
          />
        ),
      }))
    );
  }, [data]);

  const validateForm = () => {
    const errors = {};
    if (!serviceName) {
      errors.serviceName = "Service name is required";
    }
    if (!selectedCarrier.name) {
      errors.selectedCarrier = "Carrier is required";
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = "Please select Client";
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleEditService = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      const response = await carrierServiceManagement.edit(
        serviceName,
        selectedService._id,
        selectedCarrier._id,
        client_id
      );
      setLoading(false);
      onEditData && onEditData();
      toast.success("Service edited succesfully");
    } catch (error) {
      console.error("Edit failed:", error);
      setLoading(false);
    }
    setOpenModal(false); // Close the modal after adding carrier
  };
  const serviceData = data?.map((service) => ({
    ...service,
    label: service.carrier_id?.name,
  }));

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_service.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );
  const handleViewClick = (service) => {
    {
      setSelectedService(service);
      setOpenViewModal(true);
    }
  };
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: "1px",
        maxHeight: "100%",
        paddingBottom: 5,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {selectedServiceData?.map((item, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: "pointer",
              width: "100%",
              height: "100%",
              position: "relative",
              borderRadius: "6px",
              border: "1px solid #D0D5DD",
            }}
          >
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight="500"
                  sx={{ textAlign: "start" }}
                  fontFamily="Poppins"
                  color="#333333"
                >
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  {item?.action}
                </div>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Service Name
                  </Typography>

                  {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")?.sort ===
                    -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={item?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {item?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={5} display={"flex"} alignItems={"center"}>
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily={"poppins"}
                    color={"#333333"}
                  >
                    Carrier Name
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Tooltip title={item?.carrier_id?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {item?.carrier_id?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  // const MobileCardView = ({ data }) => {
  //   return (
  //     <Box sx={{ width: "100%", padding: 0.5 }}>
  //       {data?.map((service, index) => (
  //         <Card
  //           key={index}
  //           sx={{
  //             marginBottom: 2,
  //             backgroundColor: "#ffffff",
  //             borderRadius: 4,
  //             position: "relative",
  //           }}
  //         >
  //           <CardContent>
  //             <Box position="absolute" top={2} right={5}>
  //               {service?.action}
  //             </Box>
  //             <Box display="flex" flexDirection="column" mt={2}>
  //               <Grid container spacing={2}>
  //                 <Grid item xs={6}>
  //                   <Typography
  //                     fontSize={14}
  //                     fontFamily="poppins"
  //                     color="#000000"
  //                     fontWeight={600}
  //                     textAlign="left"
  //                   >
  //                     SERVICE NAME
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item xs={6}>
  //                   <Typography
  //                     fontSize={14}
  //                     fontFamily="poppins"
  //                     color="#606060"
  //                     fontWeight={500}
  //                     textAlign="left"
  //                     noWrap
  //                     sx={{
  //                       overflow: "hidden",
  //                       textOverflow: "ellipsis",
  //                       whiteSpace: "nowrap",
  //                       maxWidth: "100%",
  //                     }}
  //                   >
  //                     : {service?.name}
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item xs={6}>
  //                   <Typography
  //                     fontSize={14}
  //                     fontFamily="poppins"
  //                     color="#000000"
  //                     fontWeight={600}
  //                     textAlign="left"
  //                   >
  //                     CARRIER NAME
  //                   </Typography>
  //                 </Grid>
  //                 <Grid item xs={6}>
  //                   <Typography
  //                     fontSize={14}
  //                     fontFamily="poppins"
  //                     color="#606060"
  //                     fontWeight={500}
  //                     textAlign="left"
  //                     noWrap
  //                     sx={{
  //                       overflow: "hidden",
  //                       textOverflow: "ellipsis",
  //                       whiteSpace: "nowrap",
  //                       maxWidth: "100%",
  //                     }}
  //                   >
  //                     : {service?.carrier_id?.name}
  //                   </Typography>
  //                 </Grid>
  //               </Grid>
  //             </Box>
  //           </CardContent>
  //         </Card>
  //       ))}
  //     </Box>
  //   );
  // };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        renderGridView()
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                    1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "name")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      SERVICE NAME
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                    1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "email")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    >
                      CARRIER NAME
                    </CustomTypography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //   onClick={(event) => handleOpenFilter(event, "email")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {selectedServiceData?.map((service, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <Tooltip title={service?.name}>
                    <StyledTableCell>
                      <Typography
                        style={{
                          // marginLeft: 22,
                          maxWidth: 100,
                          whiteSpace: "nowrap", // Ensure single line
                          overflow: "hidden", // Prevent text overflow
                          textOverflow: "ellipsis", // Show ellipsis for overflow text
                        }}
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                        title={service?.name} // Add a title attribute to show full text on hover
                      >
                        {service?.name}
                      </Typography>
                    </StyledTableCell>
                  </Tooltip>

                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"poppins"}
                      //   sx={{
                      //     whiteSpace: "nowrap",
                      //     overflow: "hidden",
                      //     textOverflow: "ellipsis",
                      //     maxWidth: "200px",
                      //   }}
                      ml={0.5}
                    >
                      {service?.carrier_id?.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                    {service?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
  return (
    <div sx={{ minWidth: "100%" }}>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {serviceData?.length == 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <NoDataImage />
        </div>
      ) : (
        <>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
              width: "100%",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::WebkitScrollbar": { width: 0 },
            }}
          >
            {viewMode === "grid" ? renderGridView() : renderListView()}
          </div>

          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            maxWidth="md"
            fullWidth
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle>{"Edit Service"}</DialogTitle>
              <IconButton onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider style={{ marginBottom: 10 }} />

            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomTypography
                    fontSize={14}
                    fontWeight={400}
                    color={"#11193F"}
                  >
                    Service Name <span style={{ color: "red" }}>*</span>
                  </CustomTypography>

                  <TextField
                    size="small"
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                    error={!!formErrors.serviceName}
                    helperText={formErrors.serviceName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTypography
                    fontSize={14}
                    fontWeight={400}
                    color={"#11193F"}
                  >
                    Choose Carrier <span style={{ color: "red" }}>*</span>
                  </CustomTypography>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option._id === value.id
                    }
                    size="small"
                    value={selectedCarrier}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedCarrier({
                          carrier_id: newValue,
                          ...newValue,
                        });
                      } else {
                        setSelectedCarrier(null);
                        setCarrierSearchValue("");
                        fetchCarrierData("", 0); // Fetch all carriers again if necessary
                      }
                    }}
                    ListboxProps={{
                      onScroll: handleScroll, // Attach scroll handler here
                    }}
                    options={carrierList}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography variant="body1">{option.name}</Typography>
                      </li>
                    )}
                    onOpen={() => {
                      setPage(0); // Reset the page to 0
                      setCarrierList([]); // Clear current list
                      fetchCarrierData("", 0); // Fetch all data when the dropdown is opened
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "input") {
                        setCarrierSearchValue(newInputValue);
                        setCarrierList([]); // Clear current list
                        fetchCarrierData(newInputValue, 0); // Fetch data based on input value
                      } else if (reason === "clear") {
                        setSelectedCarrier(null);
                        setPage(0);
                        setCarrierSearchValue("");
                        setCarrierList([]); // Clear current list
                        fetchCarrierData("", 0); // Fetch all carriers again when input is cleared
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Carrier"
                        margin="dense"
                        error={!!formErrors.selectedCarrier}
                        helperText={formErrors.selectedCarrier}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: 10 }}>
              <Button
                onClick={() => setOpenModal(false)}
                variant="outlined"
                style={{ textTransform: "none" }}
              >
                Cancel
              </Button>
              <Button
                className="add-button-style"
                variant="contained"
                color="primary"
                onClick={(e) => handleEditService(e)}
                style={{ textTransform: "none" }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <DeleteConfirmationModal
            DeleteConfirmationModal
            title={`Are you sure you want to delete ${selectedService?.name} ?`}
            open={openConfirmation}
            handleClose={() => setOpenConfirmation(false)}
            handleDelete={(e) => confirmDelete(e)}
          />
          <AssignClient
            selectedData={selectedService}
            mapKey="service_type"
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          />
          <ServiceListDetailsModal
            service={selectedService}
            open={openViewModal}
            onClose={() => setOpenViewModal(false)}
          />
        </>
      )}
    </div>
  );
};

export default ServiceList;
