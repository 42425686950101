import { FC, useEffect, useState } from 'react'
import { JsonForms } from '@jsonforms/react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import schema from '../../utils/schema.json'
import uischema from '../../utils/uischema.json'
import {
  Box,
  createTheme,
  TextField,
  ThemeProvider,
  Modal,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@mui/material'
import { isObjectControl, rankWith } from '@jsonforms/core'
import { generateJsonSchema, generateUiSchema } from '../../utils/jsonSchemaGenerator'
import CircularProgress from '@mui/material/CircularProgress'

const classes = {
  container: {
    padding: '1em',
    width: '100%'
  },
  title: {
    textAlign: 'center',
    padding: '0.25em'
  },
  demoform: {
    margin: 'auto',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    maxWidth: '100%', // Increased from 70% to allow more space on smaller screens
    maxHeight: '100vh', // Limit overall modal height
    overflow: 'hidden' // Prevent outer container from scrolling
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '8px',
    maxHeight: '90vh', // Reduced from 100vh to leave some margin
    overflow: 'auto', // Enable vertical and horizontal scrolling
    overscrollBehavior: 'contain', // Prevent scroll bouncing
    padding: '1rem',
    width: '70%',
    boxSizing: 'border-box',
    minWidth: '600px' // Ensure a minimum width for horizontal scrolling
  }
}

// Custom MUI theme with Poppins font and left-aligned headers
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif' // Ensure Poppins font applies globally
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          textAlign: 'left' // Make all headers left-aligned
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: '100%', // Set table width to 50%
          overflowX: 'auto',
          maxWidth: '95%' // Enable horizontal scrolling if needed
        }
      }
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          '&.MuiGrid-container.MuiGrid-direction-xs-column': {
            overflow: 'auto', // Add auto overflow for column grid containers
            maxHeight: '100%', // Ensure the grid can scroll if content exceeds height
            scrollbarWidth: 'thin', // For Firefox
            maxWidth: '60vw',
            '&::-webkit-scrollbar': {
              width: '8px' // Thin scrollbar for WebKit browsers
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)', // Light scrollbar thumb
              borderRadius: '4px'
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          minWidth: '100px',
          maxWidth: '90%',
          marginBottom: '1rem',
          '& .MuiInputBase-root': {
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: '100px',
          padding: '8px',
          textAlign: 'left'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          minWidth: 100,
          maxWidth: '90%'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginTop: '10px',
          minWidth: 100
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          marginTop: '10px'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          marginTop: '10px'
        }
      }
    }
  }
})

// Fallback Renderer for unhandled fields
const FallbackRenderer = ({ data, path }) => (
  <TextField
    value={typeof data === 'object' ? JSON.stringify(data, null, 2) : data}
    disabled
    fullWidth
    label={`Unhandled Field at ${path}`}
    multiline
  />
)

// Fallback Tester for lowest priority
const fallbackTester = () => -1

// Custom line item renderer
const customLineItemRenderer = ({ data, path }) => (
  <TextField
    label={`Line Item ${path}`}
    value={data}
    sx={{ minWidth: '100px' }}
    disabled
    onChange={(e) => {
      // Handle change here
    }}
  />
)

const renderers = [
  ...materialRenderers,
  {
    tester: rankWith(2, isObjectControl),
    renderer: customLineItemRenderer
  },
  {
    tester: fallbackTester,
    renderer: FallbackRenderer
  }
]

const JsonFormModal = ({
  renderData,
  isModalOpen,
  setModalOpen,
  readonly = false,
  disableFields = [''],
  layout = 'vertical',
  onSaveData
}) => {
  const [data, setData] = useState(renderData)
  const [dynamicUISchema, setDynamicUISchema] = useState(uischema)
  // Generate schemas dynamically based on input data
  const [schema, setSchema] = useState(null)
  const [uiSchema, setUiSchema] = useState(null)
  const [currentData, setCurrentData] = useState(renderData)
  // Regenerate schemas if renderData changes
  useEffect(() => {
    if (renderData && isModalOpen && !schema && !uiSchema) {
      const newSchema = generateJsonSchema(renderData, ['extra_data', 'input'])
      const newUiSchema = generateUiSchema(
        renderData,
        {
          readonly: disableFields,
          layout
        },
        '',
        ['extra_data', 'input']
      )

      setData(renderData)
      setCurrentData(renderData)
      setSchema(newSchema)
      setUiSchema(newUiSchema)
    }
  }, [renderData, isModalOpen])

  const handleSave = () => {
    // onSaveData && onSaveData(currentData)
    // setModalOpen(false)
    console.log(currentData)
  }

  const handleClose = () => {
    setModalOpen(false)
    setSchema(null)
    setUiSchema(null)
  }

  const handleChange = ({ data }) => {
    setCurrentData(data)
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isModalOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Work flow data</DialogTitle>
        <DialogContent>
          {!schema && !uiSchema ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress sx={{ color: 'grey', backgroundColor: 'grey', marginTop: 5 }} />
            </Box>
          ) : (
            <JsonForms
              readonly={readonly}
              schema={schema}
              uischema={uiSchema}
              data={data}
              renderers={renderers}
              cells={materialCells}
              onChange={handleChange}
            />
          )}
        </DialogContent>
        <DialogActions sx={classes.dialogActions}>
          <Button sx={{ color: 'red' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default JsonFormModal
