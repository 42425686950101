// productService.js

import axiosInstance from "../axiosInstance";

export const productService = {
  create: async (productData) => {
    try {
      let response = await axiosInstance.post("/product", productData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetch: async (client_id, searchValue, query, signal) => {
    try {
      let api_query = `/product?$sort[createdAt]=-1`;
      if (client_id) {
        api_query += `&client_id=${client_id}`;
      }

      // Add search value
      if (searchValue) api_query += `&name[$search]=${searchValue}`;
      if (query.length) api_query += `&${query}`;
      const response = await axiosInstance.get(api_query, signal);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetchSKU: async (clientId, searchValue, limit, skip) => {
    try {
      let query = `/fetch-sku?limit=${limit || 15}&page=${skip || 0}&`;
      if (searchValue) {
        query += `sku=${searchValue}`;
      }
      if (clientId) {
        query += `&client_id=${clientId}`;
      }
      let response = await axiosInstance.get(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchById: async (id) => {
    try {
      let query = `/product/${id}?$populate=client_id&$populate=vendor`;
      let response = await axiosInstance.get(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/product/${id}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/product/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteMultipleProduct: async (data) => {
    try {
      let response = await axiosInstance.post(`/delete-multiple-product`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  bulkUpload: async (data) => {
    const formData = new FormData();
    formData.append("file", data);
    try {
      let response = await axiosInstance.post("/product-bulk-upload", formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchBulkUploadData: async (limit, skip) => {
    try {
      let response = await axiosInstance.get(
        `/bulk-upload-status?$limit=${limit || 15}&$skip=${skip || 0}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchBulkUploadResult: async (id, limit, skip) => {
    try {
      let response = await axiosInstance.get(
        `/bulk-upload-results?$limit=${limit || 15}&$skip=${
          skip || 0
        }&status_id=${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  downloadSample: async () => {
    try {
      let response = await axiosInstance.post(`/download-product-sample`, {
        dataType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  syncProduct: async (data) => {
    try {
      let response = await axiosInstance.post(`/sync-product`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchTempProduct: async (limit, skip, client_id, searchValue) => {
    try {
      let query = `/temp-product?client_id=${client_id}&$limit=${
        limit || 15
      }&$skip=${skip || 0}`;
      if (searchValue) {
        query += `&$or[0][name][$search]=${searchValue}&$or[1][product_type][$search]=${searchValue}`;
      }
      let response = await axiosInstance.get(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  discardAllTempProduct: async (data) => {
    try {
      let response = await axiosInstance.post(`/delete-temp-product`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  importAllProduct: async (data) => {
    try {
      let response = await axiosInstance.post(`/import-products`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchCategory: async (searchKey, client_id, limit = 15, skip = 0) => {
    try {
      let query = `limit=${limit}&skip=${skip}`;
      if (client_id) {
        query += `&client_id=${client_id}`;
      }
      if (searchKey) {
        query += `&searchkey=${searchKey}`;
      }

      let response = await axiosInstance.get(`/fetch-category?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchNames: async (searchKey, client_id, limit = 15, skip = 0) => {
    let query = `limit=${limit}&skip=${skip}`;
    if (client_id) {
      query += `&client_id=${client_id}`;
    }
    if (searchKey) {
      query += `&searchkey=${searchKey}`;
    }
    try {
      let response = await axiosInstance.get(`/fetch-product-name?${query}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
