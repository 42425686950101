import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
  Button,
  Popover,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import AppContext from "../context/context";
import CustomTypography from "../../globalStyles/fonts";
import { partnerService } from "../../utils/services/partnerService";
import PartnerDetailsModal from "../partnerDetailsModal";
import { isMobile } from "../../globalStyles/mobile";
import { toast } from "react-toastify";
import Pagination from "../pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const PartnerList = ({
  viewMode,
  data,
  onEditData,
  onDeleteData,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
  selectedPartner,
  setSelectedPartner,
}) => {
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [partnerData, setPartnerData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [anchorPositionFilter, setAnchorPositionFilter] = useState(null);
  const startIndex = rowsPerPage * page;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EEEEEE",
      border: "1px solid #EAECF0",
      padding: "4px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "4px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #EAECF0",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F6",
      border: "1px solid #EAECF0",
    },
    "&:lastChild td, &:lastChild th": {
      border: 0,
    },
  }));

  const deleteData = async () => {
    try {
      await partnerService.delete(deletingItemId);
      setOpenConfirmation(false);
      onDeleteData && onDeleteData();
      toast.success("Partner deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete");
      console.error("Delete failed:", error);
    }
  };
  const handleViewClick = (shipping) => {
    setSelectedPartner(shipping);
    setOpenViewModal(true);
  };
  const handleDeleteClick = (item) => {
    setSelectedPartner(item);
    setDeletingItemId(item._id);
    setOpenConfirmation(true);
  };
  const handlePopoverOpen = (event, item) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorEl(event.currentTarget);
    setAnchorPositionFilter({
      top: rect.top,
      left: rect.left,
    });
    setSelectedPartner(item);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnchorPosition(null);
  };
  useEffect(() => {
    setPartnerData(
      data?.map((item) => ({
        ...item,
        action: (
          <Box>
            <IconButton
              aria-label="more actions"
              onClick={(e) => handlePopoverOpen(e, item)}
              sx={{
                color:
                  anchorEl && selectedPartner?._id === item._id
                    ? "primary.main"
                    : "inherit",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              anchorReference="anchorPosition"
              anchorPosition={
                anchorPositionFilter
                  ? {
                      top: anchorPositionFilter.top,
                      left: anchorPositionFilter.left,
                    }
                  : undefined
              }
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  border: "1px solid #EEEEEE",
                  borderRadius: "4px",
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                padding="8px"
                minWidth="150px"
              >
                <Button
                  style={{
                    color: "#333333",
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  aria-label="view"
                  onClick={() => {
                    handleViewClick(selectedPartner);
                    handlePopoverClose();
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    gap={1}
                  >
                    <ViewIcon />
                    <Typography
                      fontFamily={"poppins"}
                      fontSize={"16px"}
                      color={"#333333"}
                      fontWeight={500}
                    >
                      View
                    </Typography>
                  </Box>
                </Button>

                <Button
                  style={{
                    color: "#333333",
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  aria-label="edit"
                  onClick={() => {
                    onEditData && onEditData(selectedPartner);
                    handlePopoverClose();
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    gap={1}
                  >
                    <EditIcon />
                    <Typography
                      fontFamily={"poppins"}
                      fontSize={"16px"}
                      color={"#333333"}
                      fontWeight={500}
                    >
                      Edit
                    </Typography>
                  </Box>
                </Button>
                <Button
                  style={{
                    justifyContent: "flex-start",
                    textTransform: "none",
                  }}
                  aria-label="delete"
                  onClick={() => {
                    handleDeleteClick(selectedPartner);
                    handlePopoverClose();
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="100%"
                    gap={1}
                  >
                    <DeleteIcon />
                    <Typography
                      fontFamily={"poppins"}
                      fontSize={"16px"}
                      fontWeight={500}
                      color={"#333333"}
                    >
                      Delete
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Popover>
          </Box>
        ),
      }))
    );
  }, [data, anchorEl]);

  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {partnerData?.map((x) => (
        <Grid item xs={12} sm={6} md={3} key={x._id}>
          <Card sx={{ boxShadow: 3, cursor: "pointer" }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    // marginLeft: 15,
                  }}
                >
                  <Tooltip title={x?.username} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.username}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={x?.password} arrow>
                    <div
                      style={{
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography style={{ fontWeight: "500" }} variant="h7">
                        {x?.password}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {x.action}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {partnerData.length === 0 ? (
          <NoDataImage />
        ) : (
          partnerData?.map((x, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          User Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.username}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="##333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          Password
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="Poppins"
                          color="#606060"
                          fontWeight={500}
                          noWrap
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="left"
                        >
                          : {x?.password}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2} justifyContent={"flex-end"} display="flex">
                    {x?.action}
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 100,
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          className="custom-container"
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    User Name
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Password
                  </Typography>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {partnerData?.map((x, index) => (
                <React.Fragment>
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.username}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.password}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                      {x?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <>
      <div sx={{ minWidth: "100%" }}>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "70vh",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
        {/* <TablePagination
          slotProps={{
            actions: {
              nextButton: {
                disabled: dataCount < rowsPerPage ? true : false
              }
            }
          }}
          nextIconButton={
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          }
          backIconButton={
            <IconButton>
              <KeyboardArrowLeft />
            </IconButton>
          }
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#ffffff',
            padding: '2px',
            borderTop: '1px solid #dddddd'
          }}
        /> */}
        <Pagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <DeleteConfirmationModal
          DeleteConfirmationModal
          title={`Are you sure you want to delete ${selectedPartner?.username} ?`}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleDelete={() => {
            deleteData();
          }}
        />
        <PartnerDetailsModal
          partner={selectedPartner}
          open={openViewModal}
          onClose={() => setOpenViewModal(false)}
        />
      </div>
    </>
  );
};

export default PartnerList;
