import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField
} from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'
import { assignUserService } from '../../utils/services/assignUserService'

const AssignUserModal = ({ open, handleClose, onAddData, selectedUser }) => {
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phone_no: '',
    password: ''
  })
  const initialData = () => {
    setUserDetails({
      name: '',
      email: '',
      phone_no: '',
      password: ''
    })
    setFormErrors({})
  }
  const [formErrors, setFormErrors] = useState({})
  const clientId = localStorage.getItem('client_id')
  const validateForm = () => {
    const errors = {}
    if (!userDetails.name) errors.name = 'Name is required.'
    if (!userDetails.email) errors.email = 'Email is required.'
    if (!userDetails.password) errors.password = 'Password is required.'
    if (!userDetails.phone_no) errors.phone_no = 'Phone number is required.'

    // Phone number validation
    if (userDetails.phone_no) {
      // Regex for validating phone numbers
      // Supports formats like:
      // +1 (123) 456-7890
      // 123-456-7890
      // 1234567890
      const phoneRegex = /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/
      if (!phoneRegex.test(userDetails.phone_no)) {
        errors.phone_no = 'Invalid phone number format.'
      }
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }
  useEffect(() => {
    if (selectedUser) {
      setUserDetails({
        name: selectedUser?.user?.name || '',
        email: selectedUser?.user?.email || '',
        phone_no: selectedUser?.user?.phone_no || '',
        password: selectedUser?.user?.password || ''
      })
    } else {
      initialData()
    }
  }, [selectedUser])
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setUserDetails((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleEditData = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      await assignUserService.edit(
        {
          name: userDetails.name,
          email: userDetails.email,
          phone_no: userDetails.phone_no,
          password: userDetails.password
        },
        selectedUser.user._id
      )
      onAddData && onAddData()
      toast.success('User edited successfully!')
      initialData()
    } catch (e) {
      console.error(e)
    }
    initialData()
    handleClose()
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      await assignUserService.create({
        name: userDetails.name,
        email: userDetails.email,
        phone_no: userDetails.phone_no,
        password: userDetails.password,
        client_id: clientId
      })
      onAddData && onAddData()
      toast.success('User created successfully!')
      handleClose()
      initialData()
    } catch (e) {
      toast.error('Failed to create User.')
      console.error(e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        initialData()
        handleClose()
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            color: '#000000'
          }}
        >
          {selectedUser ? 'Edit User' : 'Create User'}
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Name </CustomTypography>
            <TextField
              size="small"
              name="name"
              value={userDetails.name}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Email </CustomTypography>
            <TextField
              size="small"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Mobile </CustomTypography>
            <TextField
              type="tel"
              size="small"
              name="phone_no"
              value={userDetails.phone_no}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.phone_no}
              helperText={formErrors.phone_no}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>Password </CustomTypography>
            <TextField
              disabled={selectedUser ? true : false}
              size="small"
              name="password"
              value={selectedUser ? '**************' : userDetails.password}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.password}
              helperText={formErrors.password}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          onClick={() => {
            handleClose()
            initialData()
          }}
          variant="outlined"
          style={{
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedUser ? handleEditData(e) : handleSave(e))}
          variant="contained"
          style={{
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600
          }}
        >
          {selectedUser ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignUserModal
