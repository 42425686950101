import React, { useEffect, useState, useRef, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  TextField,
  Button,
  Menu,
  List,
  ListItem,
  Box,
  Card,
  CardContent,
  Typography,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Popover,
  Tooltip,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { zoneService } from "../../utils/services/zoneService";
import { carrierServiceManagement } from "../../utils/services/serviceManagement";
import { rateCardService } from "../../utils/services/rateCardService";
import UploadStatusModal from "../upload-status-cards";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { clientService } from "../../utils/services/clientService";
import { carrierService } from "../../utils/services/carrierService";
import FilterIcon from "../../globalStyles/icons/filter-icon.js";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CustomTypography from "../../globalStyles/fonts";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteConfirmationModal from "../deleteConfirmationModal/index.js";
import debounce from "lodash.debounce";
import { isMobile } from "../../globalStyles/mobile/index.js";
import ClientAutocomplete from "../clientAutocomplete/index.js";
import ZoneAutocomplete from "../zoneAutocomplete/index.js";
import { toast } from "react-toastify";
import CustomPagination from "../pagination/index.js";
import { tableCellClasses } from "@mui/material/TableCell";
import SortingIcon from "../../globalStyles/icons/sorting-icon";
import { useTheme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import EditIcon from "../../globalStyles/icons/editIcon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const RateCardDataTableCell = ({
  ratecard,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "edit":
        handleEditClick(ratecard);
        break;
      case "delete":
        handleDeleteClick(ratecard);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "inherit",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
          <Button
            onClick={() => handleActionClick("edit")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <EditIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Edit
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("delete")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <DeleteIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Delete
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

const RateCardDataTable = ({
  data,
  onEditData,
  onDeleteRateCard,
  onFilterServiceType,
  onFilterCarrier,
  onFilterZone,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  uploadData,
  onDeleteStatus,
  dataCount,
  onClickUploadStatus,
  onFilterClient,
  sortFilter,
  itemsPerPage,
  currentPage,
}) => {
  const [serviceTypeFilter, setServiceTypeFilter] = useState(null);
  const [zoneFilter, setZoneFilter] = useState(null);
  const [carrierFilter, setCarrierFilter] = useState(null);
  const [zones, setZones] = useState([]);
  const [services, setServices] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [rateCardData, setRateCardData] = useState([]);
  const [showUploadStatusModal, setShowUploadStatusModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [count, setCount] = useState(15);
  const [clientData, setClientData] = useState([]);
  const [clientFilter, setClientFilter] = useState(null);
  const [anchorElService, setAnchorElService] = useState(null);
  const [anchorElZone, setAnchorElZone] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [anchorElCarrier, setAnchorElCarrier] = useState(null);
  const [anchorElClient, setAnchorElClient] = useState(null);
  const autocompleteRefService = useRef(null);
  const autocompleteRefZone = useRef(null);
  const autocompleteRefCarrier = useRef(null);
  const autocompleteRefClient = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [zoneSearchValue, setZoneSearchValue] = useState("");
  const [clientSearchValue, setClientSearchValue] = useState("");
  const [zonePage, setZonePage] = useState(0);
  const startIndex = currentPage * itemsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;

  useEffect(() => {
    setRateCardData(
      data?.map((ratecard) => ({
        ...ratecard,
        action: (
          <RateCardDataTableCell
            ratecard={ratecard}
            handleEditClick={onEditData}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      }))
    );
  }, [data]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClientFilterChange = (newValue) => {
    if (newValue.name === "None") {
      setClientFilter(null);
    } else {
      setClientFilter(newValue);
    }
    onFilterClient && onFilterClient(newValue);
  };
  const filteredClient = [
    { name: "None" },
    ...clientData.filter((client) => {
      return (
        client.business_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        client.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }),
  ];

  const filteredCarrier = [
    { name: "None" },
    ...carriers.filter((carrier) =>
      carrier.name?.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  ];
  const filteredZones = [
    { name: "None" },
    ...zones.filter((zone) =>
      zone.name?.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  ];

  const handleZoneFilterChange = (newValue) => {
    if (newValue.name === "None") {
      setZoneFilter(null);
    } else {
      setZoneFilter(newValue);
    }
    onFilterZone && onFilterZone(newValue);
  };

  const handleFilterClientClick = (event) => {
    setAnchorElClient(event.currentTarget);
  };

  const handleFilterZoneClick = (event) => {
    console.log("Filter icon clicked");
    setAnchorElZone(event.currentTarget);
  };
  const handleSort = (field, order) => {
    if (field === "weight" || field === "price") {
      setSelectedFilter(field);
      setSortOrder(order);
      sortFilter && sortFilter(field, order, carrierFilter, zoneFilter);
    } else {
      setSelectedFilter(field);
      setSortOrder(order === "asc" ? "desc" : "asc");
      sortFilter &&
        sortFilter(
          field,
          order === "asc" ? "desc" : "asc",
          carrierFilter,
          zoneFilter
        );
    }
  };

  const handleMenuClose = () => {
    setAnchorElService(null);
    setAnchorElZone(null);
    setAnchorElCarrier(null);
    setAnchorElClient(null);
  };

  const clientId = localStorage.getItem("client_id");

  const fetchZoneData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await zoneService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );

        if (pageNumber === 0) {
          setZones(response.data);
        } else {
          setZones((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  const fetchClientData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await clientService.fetch(
          10,
          pageNumber * 10,
          searchQuery
        );

        if (pageNumber === 0) {
          setClientData(response.data);
        } else {
          setClientData((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  const fetchServiceData = async () => {
    const response = await carrierServiceManagement.fetch(15, 0, clientId);
    setServices(response.data);
  };
  const fetchCarrierData = async () => {
    const response = await carrierService.fetch(15, 0, clientId);
    setCarriers(response.data);
  };

  useEffect(() => {
    fetchZoneData(zoneSearchValue, zonePage);
  }, [zoneSearchValue, zonePage, fetchZoneData]);

  useEffect(() => {
    fetchClientData(clientSearchValue, zonePage);
  }, [clientSearchValue, zonePage, fetchClientData]);

  const handleDeleteClick = (id) => {
    setDeletingItemId(id);
    setOpenConfirmation(true);
  };

  const deleteData = async () => {
    try {
      await rateCardService.delete(deletingItemId?._id);
      setOpenConfirmation(false);
      onDeleteRateCard && onDeleteRateCard();
      toast.success("Ratecard deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete Ratecard");
      console.error("Delete failed:", error);
    }
  };

  useEffect(() => {
    fetchServiceData();
    fetchCarrierData();
  }, []);

  // useEffect(() => {
  //   setRateCardData(
  //     data?.map((item) => ({
  //       ...item,
  //       action: (
  //         <div>
  //           <IconButton
  //             onClick={() => onEditData && onEditData(item)}
  //             style={{
  //               color: '#FEC50F',
  //               padding: 0,
  //               background: '#FEC50F33',
  //               height: 30,
  //               width: 30,
  //               borderRadius: 5,
  //               marginLeft: 15,
  //               boxShadow: '0px 0px 4px 0px #00000040'
  //             }}
  //           >
  //             <BorderColorIcon sx={{ height: 20, width: 20 }} />
  //           </IconButton>
  //           <IconButton
  //             onClick={() => {
  //               setDeletingItemId(item._id)
  //               setOpenConfirmation(true)
  //             }}
  //             style={{
  //               color: '#D13438',
  //               padding: 0,
  //               background: '#D1343833',
  //               height: 30,
  //               width: 30,
  //               borderRadius: 5,
  //               marginLeft: 10,
  //               boxShadow: '0px 0px 4px 0px #00000040'
  //             }}
  //           >
  //             <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
  //           </IconButton>
  //         </div>
  //       )
  //     }))
  //   )
  // }, [data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_rateCard.png"
        alt="No data available"
        style={{ objectFit: "fill", height: "300px" }}
      />
    </div>
  );
  useEffect(() => {
    if (anchorElCarrier && autocompleteRefCarrier.current) {
      autocompleteRefCarrier.current.focus();
    }
  }, [anchorElCarrier]);

  const filteredData = rateCardData.filter((item) => {
    const serviceMatch = serviceTypeFilter
      ? item.service_type?.name === serviceTypeFilter.name
      : true;
    const zoneMatch = zoneFilter ? item.zone?.name === zoneFilter.name : true;
    const carrierMatch = carrierFilter
      ? item.carrier_id?.name === carrierFilter.name
      : true;
    const clientMatch = clientFilter
      ? item.client_id?.business_name === clientFilter.business_name ||
        item.client_id?.name === clientFilter.name
      : true;

    return serviceMatch && zoneMatch && carrierMatch && clientMatch;
  });
  const handleScroll = (event, type) => {
    if (event?.target) {
      const bottom =
        event.target.scrollHeight ===
          Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
        event.target.scrollHeight ===
          Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
        event.target.scrollHeight ===
          Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1;
      if (
        bottom &&
        (type === "client"
          ? clientData.length < total
          : zones.length < total) &&
        !loading
      ) {
        setZonePage((prevPage) => prevPage + 1);
      }
    }
  };
  const getSortIcon = (field) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ArrowDropUpIcon
          fontSize="small"
          style={{
            color:
              selectedFilter === field && sortOrder === "asc"
                ? "black"
                : "black",
            opacity: selectedFilter === field && sortOrder === "asc" ? 0.5 : 1,
            cursor: "pointer",
            marginBottom: -8,
          }}
          onClick={() => handleSort(field, "asc")}
        />
        <ArrowDropDownIcon
          fontSize="small"
          style={{
            color:
              selectedFilter === field && sortOrder === "desc"
                ? "black"
                : "black",
            opacity: selectedFilter === field && sortOrder === "desc" ? 0.5 : 1,
            cursor: "pointer",
          }}
          onClick={() => handleSort(field, "desc")}
        />
      </div>
    );
  };

  // const MobileCardView = ({ data }) => {
  //   return (
  //     <Box sx={{ width: "100%", padding: 0.5 }}>
  //       {data.length === 0 ? (
  //         <NoDataImage />
  //       ) : (
  //         data?.map((row, index) => (
  //           <Card
  //             key={index}
  //             sx={{
  //               marginBottom: 2,
  //               backgroundColor: "#ffff",
  //               borderRadius: 4,
  //             }}
  //           >
  //             <CardContent>
  //               <div style={{ width: "100%" }}>
  //                 <Box
  //                   display="flex"
  //                   flexDirection="column"
  //                   alignItems="flex-start"
  //                 >
  //                   <Grid container spacing={2} sx={{ marginTop: 1 }}>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#333333"
  //                         fontWeight={600}
  //                         textAlign="left"
  //                       >
  //                         Service Name
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#606060"
  //                         fontWeight={500}
  //                         textAlign="left"
  //                       >
  //                         : {row?.service_type?.name}
  //                       </Typography>
  //                     </Grid>

  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#333333"
  //                         fontWeight={600}
  //                         textAlign="left"
  //                       >
  //                         Zone Name
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#606060"
  //                         fontWeight={500}
  //                         textAlign="left"
  //                       >
  //                         : {row?.zone?.name}
  //                       </Typography>
  //                     </Grid>

  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#333333"
  //                         fontWeight={600}
  //                         textAlign="left"
  //                       >
  //                         Weight
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#606060"
  //                         fontWeight={500}
  //                         textAlign="left"
  //                       >
  //                         : {row?.weight}
  //                       </Typography>
  //                     </Grid>

  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#333333"
  //                         fontWeight={600}
  //                         textAlign="left"
  //                       >
  //                         Price
  //                       </Typography>
  //                     </Grid>
  //                     <Grid item xs={6}>
  //                       <Typography
  //                         fontSize={14}
  //                         fontFamily="poppins"
  //                         color="#606060"
  //                         fontWeight={500}
  //                         textAlign="left"
  //                       >
  //                         : {row?.price}
  //                       </Typography>
  //                     </Grid>
  //                   </Grid>
  //                 </Box>

  //                 <Box mt={1} justifyContent={"flex-end"} display="flex">
  //                   {row.action}
  //                 </Box>
  //               </div>
  //             </CardContent>
  //           </Card>
  //         ))
  //       )}
  //     </Box>
  //   );
  // }
  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          rateCardData?.map((row, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
                position: "relative",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    {startIndex + index + 1}
                  </Typography>

                  <div
                  // style={{
                  //   position: "absolute",
                  //   top: 10,
                  //   right: 10,
                  // }}
                  >
                    {row?.action}
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          SERVICE NAME
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.service_type?.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          ZONE NAME
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.zone?.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          WEIGHT
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.weight}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#333333"
                          fontWeight={600}
                          textAlign="left"
                        >
                          PRICE
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          fontSize={14}
                          fontFamily="poppins"
                          color="#606060"
                          fontWeight={500}
                          textAlign="left"
                        >
                          : {row?.price}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  return (
    <div
      style={{ height: "100%", paddingTop: 0, width: "100%" }}
      className="table-container"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: isMobile() ? 8 : 0,
        }}
      >
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            alignItems: "center",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {isMobile() ? (
            <div style={{ gap: 5, display: "flex" }}>
              <ClientAutocomplete
                selectedClient={clientFilter}
                onClientChange={(client) => {
                  setClientFilter(client);
                  onFilterClient && onFilterClient(client);
                }}
                label="Filter by Client"
              />

              <ZoneAutocomplete
                onZoneChange={(zone) => {
                  setZoneFilter(zone);
                  onFilterZone && onFilterZone(zone);
                }}
                selectedZone={zoneFilter}
                label={"Filter by Zone"}
              />
              <FormControl
                size="small"
                margin="dense"
                style={{ minWidth: 150, marginLeft: 5 }}
              >
                <InputLabel>Filter by</InputLabel>
                <Select
                  value={selectedFilter}
                  onChange={(event) =>
                    handleSort(event.target.value, sortOrder)
                  }
                  label="Filter by"
                >
                  <MenuItem value="weight">Weight</MenuItem>
                  <MenuItem value="price">Price</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                size="small"
                margin="dense"
                style={{ minWidth: 150, marginLeft: 5 }}
              >
                <InputLabel>Sort Order</InputLabel>
                <Select
                  value={sortOrder}
                  onChange={(event) =>
                    handleSort(selectedFilter, event.target.value)
                  }
                  label="Sort Order"
                >
                  <MenuItem value="asc">Ascending</MenuItem>
                  <MenuItem value="desc">Descending</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : (
            <></>
          )}
          <Button
            size="small"
            style={{
              marginLeft: isMobile() ? 8 : 3,
              marginTop: isMobile() ? 5 : 0,
              minHeight: 30,
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => {
              setShowUploadStatusModal(true);
              onClickUploadStatus && onClickUploadStatus();
            }}
          >
            <Typography
              fontFamily={"poppins"}
              fontSize={"12px"}
              fontWeight={500}
              color={"#fff"}
            >
              Show Upload Status
            </Typography>
          </Button>
          {/* {user.role === "admin" ? (
            <Autocomplete
              getOptionLabel={(option) => option.name}
              value={clientFilter}
              onChange={handleClientFilterChange}
              options={clientData}
              renderInput={(params) => (
                <TextField {...params} label="Select Client" size="small" />
              )}
              style={{
                marginBottom: "16px",
                marginInline: 5,
                marginTop: 10,
                minWidth: 200,
              }}
            />
          ) : (
            <></>
          )} */}
        </div>
      </div>
      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: isMobile() ? `calc(100vh-270px)` : `calc(100vh - 310px)`,
          width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
          marginTop: "5px",
        }}
      >
        {isMobile() ? (
          <MobileCardView data={rateCardData} />
        ) : (
          // <TableContainer
          //   sx={{
          //     maxHeight: "60vh",
          //     overflowY: "auto",
          //     paddingInline: 0.4,
          //   }}
          // >
          //   <Table
          //     sx={{
          //       borderCollapse: "separate",
          //       borderSpacing: "0 7px",
          //       paddingBottom: 7,
          //     }}
          //     stickyHeader
          //     aria-label="sticky table"
          //   >
          //     <TableHead>
          //       <TableRow
          //         style={{
          //           backgroundColor: "#EEEEEE",
          //           borderRadius: "8px",
          //           boxShadow: "0px 0px 4px 0px #00000040",
          //         }}
          //       >
          //         <TableCell
          //           style={{
          //             backgroundColor: "#EEEEEE",
          //             borderBottom: "none",
          //             textAlign: "center",
          //             padding: 5,
          //           }}
          //         >
          //           <CustomTypography
          //             fontSize="14px"
          //             title={true}
          //             color="#000"
          //             fontWeight={600}
          //           >
          //             Service Name
          //           </CustomTypography>
          //         </TableCell>
          //         <TableCell
          //           style={{
          //             backgroundColor: "#EEEEEE",
          //             borderBottom: "none",
          //             textAlign: "center",
          //             width: 100,
          //             padding: 5,
          //           }}
          //         >
          //           <div
          //             style={{
          //               display: "flex",
          //               width: 100,
          //               alignItems: "center",
          //             }}
          //           >
          //             <CustomTypography
          //               fontSize="14px"
          //               title={true}
          //               color="#000"
          //               fontWeight={600}
          //             >
          //               Zone
          //             </CustomTypography>
          //             <IconButton
          //               aria-label="filter"
          //               size="small"
          //               onClick={handleFilterZoneClick}
          //             >
          //               <FilterIcon />
          //             </IconButton>
          //           </div>
          //           <Menu
          //             anchorEl={anchorElZone}
          //             open={Boolean(anchorElZone)}
          //             onClose={handleMenuClose}
          //             PaperProps={{
          //               style: {
          //                 maxHeight: "300px",
          //                 overflow: "auto",
          //               },
          //               onScroll: (event) => handleScroll(event, "zone"),
          //             }}
          //           >
          //             <div
          //               style={{
          //                 padding: "10px",
          //               }}
          //             >
          //               <TextField
          //                 label="Search Zone"
          //                 variant="outlined"
          //                 fullWidth
          //                 value={searchQuery}
          //                 onChange={handleSearchChange}
          //                 size="small"
          //               />
          //               <List>
          //                 {filteredZones.map((zone, index) => (
          //                   <ListItem
          //                     onClick={() => handleZoneFilterChange(zone)}
          //                     sx={{
          //                       cursor: "pointer",
          //                       borderWidth: 0.5,
          //                       margin: 0.5,
          //                       backgroundColor:
          //                         zone?.name === zoneFilter?.name &&
          //                         zone?.name !== "None"
          //                           ? "#C3E1FF"
          //                           : "#FFF",
          //                     }}
          //                     key={zone._id}
          //                   >
          //                     {zone?.name}
          //                   </ListItem>
          //                 ))}
          //               </List>
          //             </div>
          //           </Menu>
          //         </TableCell>
          //         <TableCell
          //           style={{
          //             backgroundColor: "#EEEEEE",
          //             borderBottom: "none",
          //             textAlign: "center",
          //             width: 100,
          //             padding: 5,
          //           }}
          //         >
          //           <div
          //             style={{
          //               display: "flex",
          //               width: 100,
          //               alignItems: "center",
          //             }}
          //           >
          //             <CustomTypography
          //               fontSize="14px"
          //               title={true}
          //               color="#000"
          //               fontWeight={600}
          //             >
          //               Client
          //             </CustomTypography>
          //             <IconButton
          //               aria-label="filter"
          //               size="small"
          //               onClick={handleFilterClientClick}
          //             >
          //               <FilterIcon />
          //             </IconButton>
          //           </div>
          //           <Menu
          //             anchorEl={anchorElClient}
          //             open={Boolean(anchorElClient)}
          //             onClose={handleMenuClose}
          //             PaperProps={{
          //               style: {
          //                 maxHeight: "300px",
          //                 overflow: "auto",
          //               },
          //               onScroll: (event) => handleScroll(event, "client"),
          //             }}
          //           >
          //             <div
          //               style={{
          //                 padding: "10px",
          //               }}
          //             >
          //               <TextField
          //                 label="Search Client"
          //                 variant="outlined"
          //                 fullWidth
          //                 value={searchQuery}
          //                 onChange={handleSearchChange}
          //                 size="small"
          //               />
          //               <List>
          //                 {filteredClient.map((client, index) => (
          //                   <ListItem
          //                     onClick={() => handleClientFilterChange(client)}
          //                     sx={{
          //                       cursor: "pointer",
          //                       borderWidth: 0.5,
          //                       margin: 0.5,
          //                       backgroundColor:
          //                         (clientFilter?.business_name &&
          //                           client.business_name ===
          //                             clientFilter.business_name) ||
          //                         (!clientFilter?.business_name &&
          //                           client.name === clientFilter?.name &&
          //                           client.name !== "None")
          //                           ? "#C3E1FF"
          //                           : "#FFF",
          //                     }}
          //                     key={client._id}
          //                   >
          //                     {client?.business_name || client?.name}
          //                   </ListItem>
          //                 ))}
          //               </List>
          //             </div>
          //           </Menu>
          //         </TableCell>
          //         <TableCell
          //           style={{
          //             backgroundColor: "#EEEEEE",
          //             borderBottom: "none",
          //             // textAlign: "center",
          //             // display: "flex",
          //             alignItems: "center",
          //             justifyContent: "center",
          //             padding: 5,
          //             width: 150,
          //           }}
          //         >
          //           <div
          //             style={{
          //               display: "flex",
          //               alignItems: "center",
          //               justifyContent: "center",
          //             }}
          //           >
          //             <CustomTypography
          //               fontSize="14px"
          //               title={true}
          //               color="#000"
          //               fontWeight={600}
          //             >
          //               Weight
          //             </CustomTypography>
          //             {getSortIcon("weight")}
          //           </div>
          //         </TableCell>
          //         <TableCell
          //           style={{
          //             backgroundColor: "#EEEEEE",
          //             borderBottom: "none",
          //             textAlign: "center",
          //             padding: 0,
          //             // display: 'flex',
          //             // alignItems: 'center',
          //             // justifyContent: 'center'
          //           }}
          //         >
          //           <div
          //             style={{
          //               display: "flex",
          //               flexDirection: "row",
          //               alignItems: "center",
          //               justifyContent: "center",
          //             }}
          //           >
          //             <CustomTypography
          //               fontSize="14px"
          //               title={true}
          //               color="#000"
          //               fontWeight={600}
          //             >
          //               Price
          //             </CustomTypography>
          //             {getSortIcon("price")}
          //           </div>
          //         </TableCell>

          //         <TableCell
          //           style={{
          //             backgroundColor: "#EEEEEE",
          //             borderTopRightRadius: "8px",
          //             borderBottomRightRadius: 8,
          //             borderBottom: "none",
          //             textAlign: "center",
          //             padding: 5,
          //           }}
          //         >
          //           <CustomTypography
          //             fontSize="14px"
          //             title={true}
          //             color="#000"
          //             fontWeight={600}
          //           >
          //             Actions
          //           </CustomTypography>
          //         </TableCell>
          //       </TableRow>
          //     </TableHead>
          //     <TableBody>
          //       {filteredData.length === 0 ? (
          //         <TableRow>
          //           <TableCell colSpan={5} style={{ borderBottom: "none" }}>
          //             <NoDataImage />
          //           </TableCell>
          //         </TableRow>
          //       ) : (
          //         filteredData.map((row, index) => (
          //           <TableRow
          //             style={{
          //               marginBottom: 10,
          //               backgroundColor: "#fff",
          //               borderRadius: 8,
          //               boxShadow: "0px 0px 4px 0px #00000040",
          //             }}
          //             key={index}
          //           >
          //             <TableCell
          //               align="center"
          //               style={{
          //                 borderBottom: "none",
          //                 borderRadius: "8px 0 0 8px",
          //                 cursor: "pointer",
          //                 textAlign: "center",
          //                 padding: 5,
          //               }}
          //             >
          //               <CustomTypography
          //                 fontSize="13px"
          //                 color="#333333"
          //                 fontWeight={400}
          //               >
          //                 {" "}
          //                 {row.service_type?.name}
          //               </CustomTypography>
          //             </TableCell>
          //             <TableCell
          //               align="left"
          //               style={{ borderBottom: "none", padding: 4 }}
          //             >
          //               <CustomTypography
          //                 fontSize="13px"
          //                 color="#333333"
          //                 fontWeight={400}
          //               >
          //                 {row.zone?.name}
          //               </CustomTypography>
          //             </TableCell>
          //             <TableCell
          //               align="left"
          //               style={{ borderBottom: "none", padding: 4 }}
          //             >
          //               <CustomTypography
          //                 fontSize="13px"
          //                 color="#333333"
          //                 fontWeight={400}
          //               >
          //                 {row.client_id?.business_name || row?.client_id?.name}
          //               </CustomTypography>
          //             </TableCell>
          //             <TableCell
          //               align="center"
          //               style={{ borderBottom: "none", padding: 4 }}
          //             >
          //               <CustomTypography
          //                 fontSize="13px"
          //                 color="#333333"
          //                 fontWeight={400}
          //               >
          //                 {" "}
          //                 {row.weight}
          //               </CustomTypography>
          //             </TableCell>
          //             <TableCell
          //               align="center"
          //               style={{ borderBottom: "none", padding: 4 }}
          //             >
          //               <CustomTypography
          //                 fontSize="13px"
          //                 color="#333333"
          //                 fontWeight={400}
          //               >
          //                 {" "}
          //                 {row.price}
          //               </CustomTypography>
          //             </TableCell>
          //             <TableCell
          //               align="center"
          //               style={{
          //                 borderBottom: "none",
          //                 borderTopRightRadius: 8,
          //                 borderBottomRightRadius: 8,
          //                 padding: 7,
          //               }}
          //             >
          //               <CustomTypography
          //                 fontSize="13px"
          //                 color="#333333"
          //                 fontWeight={400}
          //               >
          //                 {" "}
          //                 {row.action}
          //               </CustomTypography>
          //             </TableCell>
          //           </TableRow>
          //         ))
          //       )}
          //     </TableBody>
          //   </Table>
          // </TableContainer>
          <TableContainer
            sx={{
              overflowY: "auto",
              paddingInline: 0.4,
              maxHeight: "100%",
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                border: "1px solid #EAECF0",
              }}
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      SL NO
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        //marginLeft: 20,
                      }}
                    >
                      {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")
                      ?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        SERVICE NAME
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //sonClick={(event) => handleOpenFilter(event, "name")}
                      >
                        <SortingIcon fill={primary_color} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      {/* {filterData.columns.find((x) => x.key === "email")?.sort ===
                  1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "email")
                      ?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                      >
                        ZONE
                      </CustomTypography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //   onClick={(event) => handleOpenFilter(event, "email")}
                      >
                        <SortingIcon fill={primary_color} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        //marginLeft: 20,
                      }}
                    >
                      {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")
                      ?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        CLIENT
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //sonClick={(event) => handleOpenFilter(event, "name")}
                      >
                        <SortingIcon fill={primary_color} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        //marginLeft: 20,
                      }}
                    >
                      {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")
                      ?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        WEIGHT
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //sonClick={(event) => handleOpenFilter(event, "name")}
                      >
                        <SortingIcon fill={primary_color} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        //marginLeft: 20,
                      }}
                    >
                      {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")
                      ?.sort === -1 ? (
                    <Icon>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        PRICE
                      </Typography>
                      <IconButton
                        aria-label="filter"
                        size="small"
                        //sonClick={(event) => handleOpenFilter(event, "name")}
                      >
                        <SortingIcon fill={primary_color} />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        //marginLeft: 20,
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        ACTION
                      </Typography>
                    </div>
                  </StyledTableCell>
                  {/* <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rateCardData?.map((ratecard, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {startIndex + index + 1}
                      </Typography>
                    </StyledTableCell>
                    <Tooltip title={ratecard?.service_type?.name}>
                      <StyledTableCell>
                        <Typography
                          style={{
                            // marginLeft: 22,
                            maxWidth: 100,
                            whiteSpace: "nowrap", // Ensure single line
                            overflow: "hidden", // Prevent text overflow
                            textOverflow: "ellipsis", // Show ellipsis for overflow text
                          }}
                          fontSize="12px"
                          color="#667085"
                          fontWeight={400}
                          fontFamily={"Poppins"}
                          title={ratecard?.service_type?.name} // Add a title attribute to show full text on hover
                        >
                          {ratecard?.service_type?.name}
                        </Typography>
                      </StyledTableCell>
                    </Tooltip>

                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"poppins"}
                        //   sx={{
                        //     whiteSpace: "nowrap",
                        //     overflow: "hidden",
                        //     textOverflow: "ellipsis",
                        //     maxWidth: "200px",
                        //   }}
                        ml={0.5}
                      >
                        {ratecard?.zone?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"poppins"}
                        //   sx={{
                        //     whiteSpace: "nowrap",
                        //     overflow: "hidden",
                        //     textOverflow: "ellipsis",
                        //     maxWidth: "200px",
                        //   }}
                        ml={0.5}
                      >
                        {ratecard?.client_id?.business_name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"poppins"}
                        //   sx={{
                        //     whiteSpace: "nowrap",
                        //     overflow: "hidden",
                        //     textOverflow: "ellipsis",
                        //     maxWidth: "200px",
                        //   }}
                        ml={0.5}
                      >
                        {ratecard?.weight}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"poppins"}
                        //   sx={{
                        //     whiteSpace: "nowrap",
                        //     overflow: "hidden",
                        //     textOverflow: "ellipsis",
                        //     maxWidth: "200px",
                        //   }}
                        ml={0.5}
                      >
                        {ratecard?.price}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                      {ratecard?.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {/* <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount < rowsPerPage ? true : false,
            },
          },
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
      /> */}
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />

      <DeleteConfirmationModal
        title={`Are you sure you want to delete this ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={deleteData}
      />
      <UploadStatusModal
        open={showUploadStatusModal}
        handleClose={() => setShowUploadStatusModal(false)}
        uploadData={uploadData}
        onDeleteStatus={onDeleteStatus}
      />
    </div>
  );
};

export default RateCardDataTable;
