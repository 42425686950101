import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { DropSkipIcon } from '../../images/drop-skip-icon'
import { useMediaQuery, Typography, Button } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { clientService } from '../../utils/services/clientService'
const SignUpForm = ({ handleSubmitData, shopName, email }) => {
  let countryData = [
    {
      name: {
        common: 'United States',
        official: 'United States of America',
        nativeName: {
          eng: {
            official: 'United States of America',
            common: 'United States'
          }
        }
      },
      tld: ['.us'],
      cca2: 'US',
      ccn3: '840',
      cca3: 'USA',
      cioc: 'USA',
      independent: true,
      status: 'officially-assigned',
      unMember: true,
      currencies: {
        USD: {
          name: 'United States dollar',
          symbol: '$'
        }
      },
      idd: {
        root: '+1'
      },
      capital: ['Washington, D.C.'],
      altSpellings: ['US', 'USA', 'United States of America'],
      region: 'Americas',
      subregion: 'North America',
      languages: {
        eng: 'English'
      },
      translations: {
        ara: {
          official: 'الولايات المتحدة الامريكية',
          common: 'الولايات المتحدة'
        },
        bre: {
          official: 'Stadoù-Unanet Amerika',
          common: 'Stadoù-Unanet'
        },
        ces: {
          official: 'Spojené státy americké',
          common: 'Spojené státy'
        },
        cym: {
          official: 'United States of America',
          common: 'United States'
        },
        deu: {
          official: 'Vereinigte Staaten von Amerika',
          common: 'Vereinigte Staaten'
        },
        est: {
          official: 'Ameerika Ühendriigid',
          common: 'Ameerika Ühendriigid'
        },
        fin: {
          official: 'Amerikan yhdysvallat',
          common: 'Yhdysvallat'
        },
        fra: {
          official: "Les états-unis d'Amérique",
          common: 'États-Unis'
        },
        hrv: {
          official: 'Sjedinjene Države Amerike',
          common: 'Sjedinjene Američke Države'
        },
        hun: {
          official: 'Amerikai Egyesült Államok',
          common: 'Amerikai Egyesült Államok'
        },
        ita: {
          official: "Stati Uniti d'America",
          common: "Stati Uniti d'America"
        },
        jpn: {
          official: 'アメリカ合衆国',
          common: 'アメリカ合衆国'
        },
        kor: {
          official: '아메리카 합중국',
          common: '미국'
        },
        nld: {
          official: 'Verenigde Staten van Amerika',
          common: 'Verenigde Staten'
        },
        per: {
          official: 'ایالات متحده آمریکا',
          common: 'ایالات متحده آمریکا'
        },
        pol: {
          official: 'Stany Zjednoczone Ameryki',
          common: 'Stany Zjednoczone'
        },
        por: {
          official: 'Estados Unidos da América',
          common: 'Estados Unidos'
        },
        rus: {
          official: 'Соединенные Штаты Америки',
          common: 'Соединённые Штаты Америки'
        },
        slk: {
          official: 'Spojené štáty Americké',
          common: 'Spojené štáty americké'
        },
        spa: {
          official: 'Estados Unidos de América',
          common: 'Estados Unidos'
        },
        srp: {
          official: 'Сједињене Америчке Државе',
          common: 'Сједињене Америчке Државе'
        },
        swe: {
          official: 'Amerikas förenta stater',
          common: 'USA'
        },
        tur: {
          official: 'Amerika Birleşik Devletleri',
          common: 'Amerika Birleşik Devletleri'
        },
        urd: {
          official: 'ریاستہائے متحدہ امریکا',
          common: 'ریاستہائے متحدہ'
        },
        zho: {
          official: '美利坚合众国',
          common: '美国'
        }
      },
      latlng: [38, -97],
      landlocked: false,
      borders: ['CAN', 'MEX'],
      area: 9372610,
      demonyms: {
        eng: {
          f: 'American',
          m: 'American'
        },
        fra: {
          f: 'Américaine',
          m: 'Américain'
        }
      },
      flag: '🇺🇸',
      maps: {
        googleMaps: 'https://goo.gl/maps/e8M246zY4BSjkjAv6',
        openStreetMaps: 'https://www.openstreetmap.org/relation/148838#map=2/20.6/-85.8'
      },
      population: 329484123,
      gini: {
        2018: 41.4
      },
      fifa: 'USA',
      car: {
        signs: ['USA'],
        side: 'right'
      },
      timezones: [
        'UTC-12:00',
        'UTC-11:00',
        'UTC-10:00',
        'UTC-09:00',
        'UTC-08:00',
        'UTC-07:00',
        'UTC-06:00',
        'UTC-05:00',
        'UTC-04:00',
        'UTC+10:00',
        'UTC+12:00'
      ],
      continents: ['North America'],
      flags: {
        png: 'https://flagcdn.com/w320/us.png',
        svg: 'https://flagcdn.com/us.svg',
        alt: 'The flag of the United States of America is composed of thirteen equal horizontal bands of red alternating with white. A blue rectangle, bearing fifty small five-pointed white stars arranged in nine rows where rows of six stars alternate with rows of five stars, is superimposed in the canton.'
      },
      coatOfArms: {
        png: 'https://mainfacts.com/media/images/coats_of_arms/us.png',
        svg: 'https://mainfacts.com/media/images/coats_of_arms/us.svg'
      },
      startOfWeek: 'sunday',
      capitalInfo: {
        latlng: [38.89, -77.05]
      },
      postalCode: {
        format: '#####-####',
        regex: '^\\d{5}(-\\d{4})?$'
      }
    }
  ]
  const isMobile = useMediaQuery('(max-width:750px)')

  const [countries, setCountries] = useState([])

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    business_name: '',
    country: '',
    contact_code: '',
    contact_number: '',
    company_website: '',
    referral: '',
    product_description: '',
    order_volume: '',
    productSource: ''
  })
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedSourceOptions, setSelectedSourceOptions] = useState([])
  const chooseBoxRef = useRef(null)
  const handleChooseBoxClick = () => {
    setIsOpen(true)
  }

  const handleCheckboxChange = (event) => {
    const value = event.target.value
    setSelectedSourceOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter((option) => option !== value)
        : [...prevSelectedOptions, value]
    )
  }

  const handleClickOutside = (event) => {
    if (chooseBoxRef.current && !chooseBoxRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }
  const options = [
    { value: 'Asia Pacific', label: 'Asia Pacific' },
    { value: 'North America', label: 'North America' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Other', label: 'Other' }
  ]

  const countryPhoneCodes = countryData.reduce((acc, country) => {
    if (country.name.common && country.idd.root) {
      const phoneCode = country.idd.root
      acc.push({
        name: country.name.common,
        code: phoneCode
      })
    }

    return acc
  }, [])

  useEffect(() => {
    setCountries(countryPhoneCodes)
  }, [])
  useEffect(() => {
    setUserData({ ...userData, email: email })
  }, [email])
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const autocompleteRef = useRef(null)

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleReferralClick = (option) => {
    setUserData((prevState) => ({
      ...prevState,
      referral: option
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Example: Validate form data
    if (!userData.order_volume) {
      toast.warn('Please fill in the required fields.')
      return
    }
    if (selectedSourceOptions.length === 0) {
      toast.warn('Please choose product source')
      return
    }

    handleSubmitData &&
      handleSubmitData({
        ...userData,
        country: countries[0]?.name,
        contact_code: countries[0]?.code,
        company_website: shopName,
        product_source: selectedSourceOptions,
        contact: `${countries[0].code} ${userData.contact_number}`
      })
    // Example: Send data to a server

    // You can replace the above console.log with an API call to submit the form data
    // For example:
    // axios.post('/api/signup', userData)
    //  .then(response => {
    //    console.log('Success:', response.data);
    //  })
    //  .catch(error => {
    //    console.error('There was an error submitting the form!', error);
    //  });

    // Reset the form after submission
  }

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          maxWidth: '65vw'
        }}
      >
        <div style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="name">
            Name <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            required
            placeholder="Enter name"
            value={userData?.name}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontFamily: 'Poppins',
              marginTop: 5,
              backgroundColor: '#151D48',
              color: '#FFF'
            }}
          />
        </div>
        <div style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="email">
            Email-ID <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            disabled={true}
            id="email"
            name="email"
            type="email"
            required
            placeholder="Enter business email"
            value={userData?.email}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginTop: 5,
              backgroundColor: '#151D48',
              color: '#FFF'
            }}
          />
        </div>

        <div style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="business_name">
            Business Name <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id="business_name"
            name="business_name"
            type="text"
            required
            placeholder="Enter company name"
            value={userData?.business_name}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginTop: 5,
              backgroundColor: '#151D48',
              color: '#FFF'
            }}
          />
        </div>
        <div style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="country">
            Country
          </label>
          <select
            disabled
            id="country"
            name="country"
            required
            value={countries[0]?.name}
            // onChange={handleChange}
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginTop: 5,
              color: 'black',
              backgroundColor: '#f0f0f0'
            }}
          >
            <option value="">Select a country</option>
            {countries?.map((country) => (
              <option key={country?.name} value={country?.name}>
                {country?.name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ flex: '1 1 45%', display: 'flex', gap: '10px' }}>
          <div style={{ flex: '1 1 30%' }}>
            <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="contact_code">
              Code
            </label>
            <input
              //   onChange={handleChange}
              id="contact_code"
              name="contact_code"
              type="text"
              value={countries[0]?.code}
              readOnly
              style={{
                width: '100%',
                padding: '7px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                marginTop: 5,
                backgroundColor: '#151D48',
                color: '#FFF'
              }}
            />
          </div>
          <div style={{ flex: '1 1 70%' }}>
            <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="contact_number">
              Contact No. <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              id="contact_number"
              name="contact_number"
              type="text"
              required
              placeholder="Enter Contact Number"
              value={userData.contact_number}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '7px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                marginTop: 5,
                backgroundColor: '#151D48',
                color: '#FFF'
              }}
            />
          </div>
        </div>
        <div ref={autocompleteRef} style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="company_website">
            Store URL
          </label>
          <input
            disabled
            readOnly
            id="company_website"
            name="company_website"
            type="text"
            placeholder="Enter company website"
            value={shopName}
            // onChange={handleChange}
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginTop: 5,
              color: '#FFF'
            }}
          />
        </div>
        <div style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="order">
            Monthly order volume <span style={{ color: 'red' }}>*</span>
          </label>
          <select
            id="order_volume"
            name="order_volume"
            required
            value={userData?.order_volume}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginTop: 5,
              color: 'black',
              backgroundColor: '#151D48',
              color: '#FFF'
            }}
          >
            <option value="">Monthly Order Volume</option>
            {[
              { name: '< 3,000' },
              { name: '3,001-5,000' },
              { name: '5,001-10,000' },
              { name: '> 10,000' }
            ]?.map((order) => (
              <option key={order?.name} value={order?.name}>
                {order?.name}
              </option>
            ))}
          </select>
        </div>
        <div ref={chooseBoxRef} style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="country">
            Product sourced from <span style={{ color: 'red' }}>*</span>
          </label>
          <div
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginTop: 5,
              color: 'black',
              //   backgroundColor: '#f0f0f0',
              position: 'relative',
              cursor: 'pointer'
            }}
            onClick={handleChooseBoxClick}
          >
            {selectedSourceOptions.length > 0 ? (
              <span style={{ color: '#FFF' }}> {selectedSourceOptions.join(', ')}</span>
            ) : (
              <span style={{ color: '#FFF' }}>Select source</span>
            )}
            {isOpen && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100%',
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '7px',
                  zIndex: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  {options.map((option) => (
                    <label key={option.value} style={{ marginBottom: 5 }}>
                      <input
                        type="checkbox"
                        value={option.value}
                        checked={selectedSourceOptions.includes(option.value)}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: 5 }}
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ flex: '1 1 45%' }}>
          <label style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }} htmlFor="country">
            Describe your best product(s)
          </label>
          <textarea
            id="product_description"
            name="product_description"
            placeholder="Describe your best product"
            value={userData.product_description}
            onChange={handleChange}
            style={{
              width: '100%',
              padding: '7px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginTop: 5,
              resize: 'vertical', // Allows resizing the textarea vertically
              minHeight: '40px',
              backgroundColor: '#151D48',
              color: '#FFF'
              // Ensures a minimum height for the textarea
            }}
            rows={2} // Sets the number of visible rows
          />
        </div>

        <div style={{ flex: '-1 1 100%' }}>
          <p style={{ fontFamily: 'Poppins', display: 'flex', color: '#FFF' }}>How did you hear about us?</p>
          {['Shopify App Store', 'Google', 'Redefined Logistics Sales team', 'Others'].map(
            (option, index) => (
              <button
                key={option}
                type="button"
                onClick={() => handleReferralClick(option)}
                style={{
                  color: userData.referral === option ? '#FFF' : '#000',
                  margin: index === 0 ? 0 : '5px',
                  padding: '10px 10px',
                  borderRadius: '20px',
                  background: userData.referral === option ? 'blue' : '#FFF',
                  border: userData.referral === option ? '2px solid #1567E1' : '1px solid #ccc',
                  fontFamily: 'Poppins',
                  fontSize: 12,
                  cursor: 'pointer'
                }}
              >
                {option}
              </button>
            )
          )}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '30px'
        }}
      >
        <button
          type="submit"
          style={{
            padding: '10px 30px',
            borderRadius: '5px',
            background: '#1567E1',
            color: '#FFF',
            fontFamily: 'Poppins',
            fontSize: 15,
            border: 'none',
            cursor: 'pointer'
          }}
        >
          Register
        </button>
      </div>
    </form>
  )
}

export default SignUpForm
