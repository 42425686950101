import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  Typography,
  styled,
  TableCell,
  TableRow,
  TableBody,
  tableCellClasses,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const ManifestViewModal = ({ manifest, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Manifest Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Manifest
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="manifest Name"
                value={manifest?.manifest.id || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Carrier
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={manifest?.manifest.carrier || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Entry Point
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="manifest Name"
                value={manifest?.manifest.entry_point || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
          </Grid>
          <TableContainer
            sx={{
              overflowY: "auto",
              paddingInline: 0.4,
              maxHeight: "100%",
              marginTop: 5,
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                border: "1px solid #EAECF0",
              }}
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        Overpack
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        Entry Point
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        title={true}
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        Exit Point
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      No of Shipments
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Dimension
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {manifest?.manifest?.overpacks?.map((x, index) => (
                  <StyledTableRow key={x.id + "manifest-view" + index}>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.id}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#667085"
                        fontWeight={400}
                        fontFamily={"Poppins"}
                      >
                        {x?.entry_point}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          fontFamily: "poppins",
                        }}
                      >
                        {x?.exit_point}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          fontFamily: "poppins",
                        }}
                      >
                        {x?.total_shipments}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          fontFamily: "poppins",
                        }}
                      >
                        {`${x?.length} * ${x?.width} * ${x?.height}`}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ManifestViewModal;
