import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { globalNetworkService } from "../../utils/services/globalNetworkService";
import TablePagination from "@mui/material/TablePagination";
import { CircularProgress, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GlobalNetworkDesignAddEditModal from "../../components/global-network-design-modal";
import GlobalNetworkDesignList from "../../components/global-network-design-list";
import CustomPagination from "../../components/pagination";

export default function GlobalNetworkDesign() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [networkData, setNetworkData] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [formErrors, setFormErrors] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const [viewMode, setViewMode] = useState("list");
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const fetchGlobalNetworkDataData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const clientId = localStorage.getItem("client_id");
      const response = await globalNetworkService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      );
      setNetworkData(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchGlobalNetworkDataData();
  }, [page, rowsPerPage, clientFilter, selectedNetwork, searchValue]);

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_carrier.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );
  return (
    <>
      <DashboardContainer
        filterButton={false}
        searchBarPlaceholder={"Search by Supplier Location"}
        globalDesign
        hideFilterClient
        onSearchData={(x) => {
          setSearchValue(x);
          setPage(0);
        }}
        onClickAddButton={() => {
          setOpenModal(true);
          setFormErrors({});
        }}
        hideUploadButton={true}
        header={"Global Network Design"}
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        setViewMode={setViewMode}
        title={"+ Add"}
      >
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit"></CircularProgress>
          </Box>
        )}
        {networkData?.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NoDataImage />
          </div>
        ) : (
          <>
            <GlobalNetworkDesignList
              onDeleteData={() => {
                fetchGlobalNetworkDataData();
              }}
              onEditData={(e) => {
                setOpenModal(true);
                setSelectedNetwork(e);
              }}
              data={networkData}
              currentPage={page}
              itemsPerPage={rowsPerPage}
              viewMode={viewMode}
            ></GlobalNetworkDesignList>
          </>
        )}
        <GlobalNetworkDesignAddEditModal
          selectedNetwork={selectedNetwork}
          onAddData={fetchGlobalNetworkDataData}
          onClose={() => {
            setOpenModal(false);
            setSelectedNetwork(null);
          }}
          open={openModal}
        ></GlobalNetworkDesignAddEditModal>
        <CustomPagination
          dataCount={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </DashboardContainer>
    </>
  );
}
