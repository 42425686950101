import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardContent from "@mui/material/CardContent/CardContent";
import IconButton from "@mui/material/IconButton/IconButton";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import { carrierService } from "../../utils/services/carrierService";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UpIcon from "@mui/icons-material/ArrowUpwardOutlined";
import DownIcon from "@mui/icons-material/ArrowDownwardOutlined";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Icon,
  Popover,
} from "@mui/material";
import AppContext from "../context/context";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import AssignClient from "../client-assign";
import CarrierDetailsModal from "../carrierViewDetailsModal";
import { isMobile } from "../../globalStyles/mobile";
import { useTheme } from "@mui/styles";
import ClientAssignIcon from "../../globalStyles/icons/client-assign-icon";
import DeleteIcon from "../../globalStyles/icons/deleteIcon";
import EditIcon from "../../globalStyles/icons/editIcon";
import ViewIcon from "../../globalStyles/icons/vieewIcon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SortingIcon from "../../globalStyles/icons/sorting-icon";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    color: theme.palette.common.black,
    padding: "4px",
    textAlign: "start",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
    textAlign: "start",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const CarrierTableCell = ({
  carrier,
  handleViewClick,
  handleEditClick,
  handleDeleteClick,
  handleClientAssignClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleActionClick = (action) => {
    handlePopoverClose();
    switch (action) {
      case "view":
        handleViewClick(carrier);
        break;
      case "edit":
        handleEditClick(carrier);
        break;
      case "delete":
        handleDeleteClick(carrier);
        break;
      case "assign":
        handleClientAssignClick(carrier);
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? "primary.main" : "inherit",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
          <Button
            onClick={() => handleActionClick("edit")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <EditIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Edit
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("view")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ViewIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                View
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick("delete")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <DeleteIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Delete
              </Typography>
            </Box>
          </Button>
          <Button
            onClick={() => handleActionClick("assign")}
            style={{ textTransform: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              gap={1}
            >
              <ClientAssignIcon />
              <Typography
                fontFamily={"poppins"}
                fontSize={"16px"}
                color={"#333333"}
                fontWeight={500}
              >
                Client Assign
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
const CarrierList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleDeleteClient,
  viewMode,
  currentPage,
  itemsPerPage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carrierName, setCarrierName] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const clientId = localStorage.getItem("client_id");
  const { user } = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedCarrierData, setSelectedCarrierData] = useState([]);
  const startIndex = currentPage * itemsPerPage;
  const theme = useTheme();
  const defaultColor = "#D13438";
  const primary_color = theme.palette?.primary?.main || defaultColor;

  const handleEditClick = (carrier) => {
    setSelectedCarrier(carrier);
    setCarrierName(carrier.name);
    setSelectedClient({
      name: carrier?.client_id?.name,
      _id: carrier.client_id?._id,
    });
    setOpenModal(true);
  };

  const handleDeleteClick = (carrier) => {
    setSelectedCarrier(carrier);
    setOpenConfirmation(true);
  };

  useEffect(() => {
    setSelectedCarrierData(
      data?.map((x) => ({
        ...x,
        action: (
          <CarrierTableCell
            carrier={x}
            handleViewClick={handleViewClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            handleClientAssignClick={handleClientAssignClick}
          />
        ),
      }))
    );
  }, [data]);

  const confirmDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await carrierService.delete(selectedCarrier._id);
      onDeleteData && onDeleteData();
      setAnchorEl(null);
      setOpenConfirmation(false);
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!carrierName) {
      errors.name = "Carrier name is required";
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = 'Please select Client'
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleEditCarrier = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      const response = await carrierService.edit(
        carrierName,
        selectedCarrier._id,
        client_id
      );
      onEditData && onEditData();
      setFormErrors({});
    } catch (error) {
      console.error("Edit failed:", error);
    } finally {
      setOpenModal(false);
      setAnchorEl(null);
      setLoading(false);
    }
  };
  const handleClientAssignClick = (x) => {
    setSelectedCarrier(x);
    setModalOpen(true);
  };
  const handleViewClick = (carrier) => {
    setSelectedCarrier(carrier);
    setOpenViewModal(true);
  };
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: "1px",
        maxHeight: "100%",
        paddingBottom: 5,
        overflow: "auto",
      }}
      container
      spacing={2}
    >
      {selectedCarrierData?.map((item, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: "pointer",
              width: "100%",
              height: "100%",
              position: "relative",
              borderRadius: "6px",
              border: "1px solid #D0D5DD",
            }}
          >
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight="500"
                  sx={{ textAlign: "start" }}
                  fontFamily="Poppins"
                  color="#333333"
                >
                  {startIndex + index + 1}
                </Typography>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  {item?.action}
                </div>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={5} display="flex" alignItems="center">
                  <Typography
                    fontWeight="500"
                    sx={{ textAlign: "start" }}
                    fontFamily="Poppins"
                    color="#333333"
                  >
                    Carrier Name
                  </Typography>

                  {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                  1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <UpIcon color="disabled" />
                    </Icon>
                  ) : filterData.columns.find((x) => x.key === "name")?.sort ===
                    -1 ? (
                    <Icon sx={{ display: "flex", alignItems: "center" }}>
                      <DownIcon color="disabled" />
                    </Icon>
                  ) : null} */}
                </Grid>

                <Grid item xs={7}>
                  <Tooltip title={item?.name} arrow>
                    <Typography
                      noWrap
                      sx={{ textAlign: "start" }}
                      fontWeight="500"
                      fontFamily={"poppins"}
                      color={"#333333"}
                    >
                      : {item?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  // const MobileCardView = ({ data }) => {
  //   return (
  //     <Box sx={{ width: "100%", padding: 0.5 }}>
  //       {data?.map((carrier, index) => (
  //         <Card
  //           key={index}
  //           sx={{
  //             marginBottom: 2,
  //             backgroundColor: "#ffff",
  //             borderRadius: 4,
  //           }}
  //         >
  //           <CardContent>
  //             <div style={{ width: "100%" }}>
  //               <Box
  //                 display="flex"
  //                 flexDirection="column"
  //                 alignItems="flex-start"
  //               >
  //                 <Grid container spacing={2}>
  //                   <Grid item xs={6}>
  //                     <Typography
  //                       fontSize={14}
  //                       fontFamily="poppins"
  //                       color="#333333"
  //                       fontWeight={600}
  //                       textAlign="left"
  //                     >
  //                       Carrier Name
  //                     </Typography>
  //                   </Grid>
  //                   <Grid item xs={6}>
  //                     <Typography
  //                       fontSize={14}
  //                       fontFamily="poppins"
  //                       color="#606060"
  //                       fontWeight={500}
  //                       textAlign="left"
  //                     >
  //                       : {carrier?.name}
  //                     </Typography>
  //                   </Grid>
  //                 </Grid>
  //               </Box>

  //               <Box mt={2} justifyContent={"flex-end"} display="flex" gap={2}>
  //                 <IconButton
  //                   style={{
  //                     color: "#007DFF",
  //                     padding: 0,
  //                     background: "#C3E1FF",
  //                     height: 30,
  //                     width: 30,
  //                     borderRadius: 5,
  //                     // marginLeft: 10,
  //                     boxShadow: "0px 0px 4px 0px #00000040",
  //                   }}
  //                   aria-label="view"
  //                   onClick={() => {
  //                     handleViewClick(carrier);
  //                   }}
  //                 >
  //                   <img
  //                     style={{ height: 14, width: 20 }}
  //                     alt="view"
  //                     src="/eye.png"
  //                   ></img>
  //                 </IconButton>
  //                 {user.role == "admin" ? (
  //                   <>
  //                     <IconButton
  //                       style={{
  //                         color: "#007613",
  //                         padding: 0,
  //                         background: "#00761333",
  //                         borderRadius: 5,
  //                         // marginLeft: 10,
  //                         height: 30,
  //                         width: 30,
  //                         padding: 0,
  //                         boxShadow: "0px 0px 4px 0px #00000040",
  //                       }}
  //                       aria-label="view"
  //                       onClick={() => {
  //                         handleClientAssignClick();
  //                         setSelectedCarrier(carrier);
  //                       }}
  //                     >
  //                       <img
  //                         style={{ height: 14, width: 20 }}
  //                         alt="client"
  //                         src="/client-assign.png"
  //                       ></img>
  //                     </IconButton>
  //                     <IconButton
  //                       style={{
  //                         color: "#FEC50F",
  //                         padding: 0,
  //                         background: "#FEC50F33",
  //                         height: 30,
  //                         width: 30,
  //                         borderRadius: 5,
  //                         // marginLeft: 15,
  //                         boxShadow: "0px 0px 4px 0px #00000040",
  //                         // marginTop: 5,
  //                       }}
  //                       aria-label="edit"
  //                       onClick={() => handleEditClick(carrier)}
  //                     >
  //                       <BorderColorIcon sx={{ height: 20, width: 20 }} />
  //                     </IconButton>
  //                     <IconButton
  //                       style={{
  //                         color: "#D13438",
  //                         padding: 0,
  //                         background: "#D1343833",
  //                         height: 30,
  //                         width: 30,
  //                         borderRadius: 5,
  //                         // marginLeft: 10,
  //                         boxShadow: "0px 0px 4px 0px #00000040",
  //                         // marginTop: 5,
  //                       }}
  //                       aria-label="delete"
  //                       onClick={() => handleDeleteClick(carrier)}
  //                     >
  //                       <img
  //                         style={{ height: 15, width: 15 }}
  //                         alt="delete"
  //                         src="/delete.png"
  //                       ></img>
  //                     </IconButton>
  //                   </>
  //                 ) : (
  //                   <></>
  //                 )}
  //               </Box>
  //             </div>
  //           </CardContent>
  //         </Card>
  //       ))}
  //     </Box>
  //   );
  // };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 20,
        marginTop: 5,
      }}
    >
      {isMobile() ? (
        // <MobileCardView data={selectedCarrierData} />
        renderGridView()
      ) : (
        <TableContainer
          sx={{
            overflowY: "auto",
            paddingInline: 0.4,
            maxHeight: "100%",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: "1px solid #EAECF0",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography
                    fontSize="14px"
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                    style={{ marginLeft: 10 }}
                  >
                    SL NO
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      //marginLeft: 20,
                    }}
                  >
                    {/* {filterData.columns.find((x) => x.key === "name")?.sort ===
                    1 ? (
                      <Icon>
                        <UpIcon color="disabled" />
                      </Icon>
                    ) : filterData.columns.find((x) => x.key === "name")
                        ?.sort === -1 ? (
                      <Icon>
                        <DownIcon color="disabled" />
                      </Icon>
                    ) : null} */}
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      Carrier Name
                    </Typography>
                    <IconButton
                      aria-label="filter"
                      size="small"
                      //sonClick={(event) => handleOpenFilter(event, "name")}
                    >
                      <SortingIcon fill={primary_color} />
                    </IconButton>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                  ></CustomTypography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {selectedCarrierData?.map((carrier, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                      style={{ marginLeft: 10 }}
                    >
                      {startIndex + index + 1}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="12px"
                      color="#667085"
                      fontWeight={400}
                      fontFamily={"Poppins"}
                      sx={{ ml: 0.5 }}
                    >
                      {carrier.name}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                    {carrier?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  return (
    <div sx={{ minWidth: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: isMobile() ? "60vh" : `calc(100vh - 250px)`,
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::WebkitScrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
      )}
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setFormErrors({});
        }}
        maxWidth="md"
        // fullWidth
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{"Edit Carrier"}</DialogTitle>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">
                Carrier Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={{ minWidth: 300 }}
                autoFocus
                margin="dense"
                //label="Carrier Name"
                type="text"
                fullWidth
                value={carrierName}
                onChange={(e) => setCarrierName(e.target.value)}
                error={formErrors?.name}
                helperText={formErrors?.name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={() => {
              setOpenModal(false);
              setFormErrors({});
            }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleEditCarrier(e)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedCarrier?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          if (client) {
            handleDeleteClient && handleDeleteClient(selectedCarrier);
            setOpenConfirmation(false);
          } else {
            confirmDelete(e);
          }
        }}
      />
      <AssignClient
        selectedData={selectedCarrier}
        mapKey="carrier"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <CarrierDetailsModal
        carrier={selectedCarrier}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  );
};

export default CarrierList;
