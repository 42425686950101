import { SvgIcon } from "@mui/material";
import React, { useState } from "react";

const InProgress = ({ fill }) => {
  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2417_33310)">
          <path
            d="M1.96363 3.39361C1.98901 3.40699 2.01439 3.42037 2.04054 3.43415C2.06894 3.44906 2.09735 3.46397 2.12661 3.47934C2.26751 3.55655 2.40705 3.6357 2.54565 3.71699C2.58694 3.74086 2.58694 3.74086 2.62907 3.76521C3.18263 4.08854 3.18263 4.08854 3.25317 4.32026C3.29659 4.49974 3.27776 4.64461 3.19848 4.81244C3.1047 4.9478 2.98926 5.03276 2.82936 5.07394C2.60421 5.11327 2.4251 5.04183 2.23462 4.92866C2.2116 4.91541 2.18857 4.90216 2.16486 4.88851C2.11569 4.86012 2.06664 4.83151 2.01771 4.80271C1.9381 4.75626 1.85778 4.71127 1.77725 4.66643C1.72347 4.63596 1.6697 4.60545 1.61596 4.5749C1.59079 4.56096 1.56561 4.54703 1.53967 4.53268C1.35695 4.42675 1.21612 4.30675 1.1477 4.10151C1.12963 3.90148 1.12669 3.7168 1.25708 3.55463C1.45817 3.36972 1.69692 3.28923 1.96363 3.39361Z"
            fill="#D3A004"
          />
          <path
            d="M11.6255 8.98588C11.6976 9.02417 11.7683 9.06475 11.8391 9.10551C11.8647 9.12003 11.8904 9.13455 11.9168 9.1495C12.7377 9.61494 12.7377 9.61494 12.844 9.89848C12.8701 10.1076 12.8328 10.2627 12.7209 10.4385C12.5843 10.5751 12.459 10.6201 12.2717 10.6426C12.0199 10.6235 11.8088 10.4819 11.5964 10.3565C11.5661 10.3389 11.5359 10.3213 11.5047 10.3032C10.8461 9.91856 10.8461 9.91856 10.7351 9.68144C10.6973 9.49629 10.7009 9.32465 10.8 9.1602C11.0334 8.90313 11.3069 8.83163 11.6255 8.98588Z"
            fill="#D3A004"
          />
          <path
            d="M10.3638 1.23051C10.4955 1.34463 10.5981 1.45541 10.6183 1.63287C10.6282 1.88011 10.5713 2.03233 10.4458 2.24223C10.4244 2.27933 10.403 2.31647 10.3817 2.35363C10.3242 2.45326 10.2654 2.55205 10.2061 2.65062C10.1745 2.70348 10.1436 2.75668 10.1129 2.81004C9.99614 3.00858 9.89386 3.16498 9.68188 3.2642C9.49695 3.30194 9.32484 3.29856 9.16064 3.19926C9.01451 3.06404 8.90852 2.92638 8.89062 2.72416C8.89495 2.44799 9.05747 2.22299 9.19577 1.99259C9.24185 1.91548 9.28647 1.83768 9.3308 1.75954C9.3589 1.71077 9.38707 1.66203 9.41528 1.61332C9.42803 1.59095 9.44079 1.56859 9.45392 1.54554C9.5531 1.37875 9.66447 1.24891 9.84253 1.16557C10.0326 1.12677 10.1925 1.1379 10.3638 1.23051Z"
            fill="#D3A004"
          />
          <path
            d="M0.944851 6.39402C0.96923 6.39375 0.99361 6.39349 1.01873 6.39322C1.05799 6.39305 1.05799 6.39305 1.09805 6.39287C1.13882 6.39263 1.13882 6.39263 1.18042 6.3924C1.23794 6.39214 1.29547 6.39196 1.353 6.39185C1.44044 6.39159 1.52786 6.39074 1.61529 6.38988C1.67131 6.3897 1.72733 6.38956 1.78335 6.38945C1.80925 6.38911 1.83515 6.38878 1.86184 6.38843C2.06087 6.38909 2.23121 6.42223 2.40169 6.52831C2.54424 6.67493 2.58958 6.80653 2.60089 7.00789C2.59416 7.19476 2.51394 7.33621 2.37947 7.46483C2.20057 7.57318 2.03537 7.59013 1.82992 7.58777C1.78753 7.58783 1.78753 7.58783 1.74427 7.58789C1.68487 7.58784 1.62547 7.58753 1.56607 7.58696C1.47558 7.58617 1.38513 7.5864 1.29464 7.58675C1.23666 7.58658 1.17868 7.58635 1.1207 7.58606C1.09389 7.58614 1.06708 7.58622 1.03946 7.5863C0.819059 7.5833 0.627426 7.54482 0.465407 7.3828C0.35276 7.21992 0.315556 7.08759 0.328688 6.89061C0.37531 6.71029 0.452825 6.57112 0.615798 6.47362C0.731123 6.41596 0.81689 6.39514 0.944851 6.39402Z"
            fill="#D3A004"
          />
          <path
            d="M7.30841 11.4878C7.4455 11.5836 7.5392 11.7027 7.5733 11.8672C7.57613 11.911 7.57763 11.9549 7.57814 11.9987C7.57867 12.0378 7.57867 12.0378 7.57921 12.0776C7.57941 12.1056 7.57961 12.1335 7.57982 12.1623C7.58004 12.1914 7.58026 12.2204 7.58049 12.2502C7.58087 12.3116 7.58116 12.373 7.58137 12.4344C7.58169 12.497 7.58225 12.5595 7.58306 12.6221C7.58423 12.7127 7.58467 12.8032 7.58495 12.8938C7.58542 12.9215 7.5859 12.9492 7.5864 12.9777C7.58573 13.1974 7.54282 13.3688 7.39044 13.5318C7.21989 13.6412 7.06669 13.6916 6.86418 13.6585C6.70993 13.6206 6.60945 13.545 6.5069 13.4258C6.43241 13.2955 6.39433 13.1854 6.39355 13.0359C6.39338 13.0132 6.3932 12.9905 6.39302 12.967C6.39299 12.9427 6.39296 12.9183 6.39293 12.8932C6.39279 12.8551 6.39279 12.8551 6.39265 12.8163C6.39252 12.7626 6.39245 12.709 6.39246 12.6553C6.3924 12.5738 6.3919 12.4923 6.39138 12.4108C6.3913 12.3586 6.39125 12.3063 6.39122 12.254C6.39102 12.2299 6.39083 12.2058 6.39062 12.1809C6.39153 11.9327 6.41719 11.7404 6.59245 11.5572C6.79461 11.3778 7.07496 11.3532 7.30841 11.4878Z"
            fill="#D3A004"
          />
          <path
            d="M12.538 3.42307C12.6888 3.53235 12.7932 3.65077 12.8525 3.8281C12.8783 3.99241 12.8556 4.14962 12.7705 4.29294C12.6035 4.47423 12.401 4.59097 12.186 4.70652C12.1425 4.73036 12.1425 4.73036 12.0982 4.75469C12.0407 4.78619 11.9831 4.81753 11.9254 4.84871C11.8685 4.8795 11.8117 4.91053 11.7551 4.94179C11.7283 4.95657 11.7015 4.97135 11.6739 4.98658C11.6501 4.99979 11.6262 5.013 11.6017 5.02662C11.4519 5.1042 11.2859 5.11548 11.1237 5.06818C10.9627 4.99558 10.8465 4.87903 10.7705 4.71976C10.7177 4.55942 10.7212 4.38231 10.7915 4.228C10.8733 4.06918 11.043 3.97388 11.1934 3.88887C11.2175 3.87518 11.2175 3.87518 11.242 3.86121C11.2928 3.83236 11.3438 3.80374 11.3947 3.77512C11.4283 3.75607 11.4619 3.737 11.4955 3.71792C11.5611 3.68067 11.6269 3.6437 11.6929 3.60695C11.7526 3.57347 11.812 3.53949 11.871 3.50488C12.0954 3.37567 12.2864 3.33769 12.538 3.42307Z"
            fill="#D3A004"
          />
          <path
            d="M9.83127 10.8345C10.0416 10.986 10.1622 11.2761 10.2847 11.4981C10.3009 11.5272 10.3171 11.5563 10.3339 11.5862C10.3807 11.6705 10.4269 11.7551 10.4726 11.8399C10.4863 11.8651 10.5 11.8904 10.5141 11.9164C10.6093 12.0977 10.6464 12.236 10.6094 12.4415C10.5509 12.6049 10.4672 12.7149 10.3154 12.8003C10.1467 12.8711 10.0015 12.8835 9.82622 12.8293C9.53018 12.7021 9.40787 12.3829 9.25413 12.115C9.23646 12.0845 9.21878 12.054 9.20057 12.0226C8.87152 11.4512 8.87152 11.4512 8.91405 11.1836C8.97186 10.9868 9.10569 10.8657 9.27806 10.7632C9.47834 10.7167 9.65129 10.731 9.83127 10.8345Z"
            fill="#D3A004"
          />
          <path
            d="M4.24795 1.19285C4.46289 1.30363 4.57203 1.56551 4.68545 1.76536C4.70279 1.79555 4.72013 1.82574 4.738 1.85684C4.79047 1.94856 4.84223 2.04065 4.89395 2.13279C4.9123 2.16464 4.93066 2.19648 4.94902 2.22832C4.96638 2.25912 4.98373 2.28991 5.00161 2.32163C5.02514 2.36284 5.02514 2.36284 5.04914 2.40489C5.12502 2.57958 5.11313 2.76184 5.05288 2.93943C4.95278 3.0775 4.84153 3.18419 4.68032 3.24534C4.49912 3.27122 4.34332 3.26347 4.18301 3.17185C4.01001 3.01463 3.8998 2.80931 3.78652 2.60789C3.76844 2.57595 3.75035 2.54401 3.73226 2.51208C3.68166 2.42228 3.63152 2.33225 3.58145 2.24216C3.56295 2.20955 3.56295 2.20955 3.54407 2.17629C3.43602 1.98526 3.34146 1.81079 3.39004 1.58591C3.44206 1.41714 3.54371 1.29914 3.69082 1.2031C3.86677 1.11915 4.06897 1.11134 4.24795 1.19285Z"
            fill="#D3A004"
          />
          <path
            d="M4.76636 10.7889C4.91106 10.8733 5.00427 10.9847 5.07056 11.1375C5.12814 11.515 4.91733 11.7998 4.73389 12.1133C4.71785 12.1411 4.70181 12.1689 4.68529 12.1975C4.35529 12.7632 4.35529 12.7632 4.15625 12.8243C3.91425 12.8758 3.75352 12.8437 3.54785 12.7132C3.42047 12.598 3.3732 12.4288 3.34961 12.2637C3.3876 11.9978 3.5406 11.7652 3.67432 11.5374C3.70086 11.4915 3.70086 11.4915 3.72794 11.4446C4.24531 10.5554 4.24531 10.5554 4.76636 10.7889Z"
            fill="#D3A004"
          />
          <path
            d="M2.91493 8.93742C3.06965 9.01247 3.15493 9.11516 3.22725 9.26949C3.27859 9.42351 3.28003 9.58958 3.21358 9.73946C3.07119 9.97702 2.82465 10.1008 2.58809 10.2282C2.52918 10.2604 2.47029 10.2926 2.41142 10.3249C2.38309 10.3403 2.35476 10.3558 2.32557 10.3717C2.22444 10.4282 2.12588 10.4884 2.0278 10.5501C1.87839 10.6317 1.68803 10.639 1.52596 10.5916C1.36133 10.5175 1.25728 10.3985 1.16622 10.2453C1.1229 10.0591 1.1306 9.8824 1.22009 9.71124C1.35059 9.51103 1.62479 9.39785 1.82588 9.28317C1.85606 9.26572 1.88623 9.24827 1.91731 9.23029C2.06487 9.14561 2.21344 9.06304 2.36432 8.98441C2.38954 8.97126 2.41476 8.9581 2.44075 8.94454C2.59819 8.88096 2.75495 8.88433 2.91493 8.93742Z"
            fill="#D3A004"
          />
          <path
            d="M11.9808 6.41784C12.008 6.41749 12.0352 6.41714 12.0632 6.41678C12.0923 6.41672 12.1214 6.41666 12.1514 6.4166C12.1968 6.41632 12.1968 6.41632 12.2431 6.41604C12.307 6.41577 12.3709 6.41564 12.4348 6.41565C12.5322 6.41553 12.6296 6.41454 12.727 6.4135C12.7892 6.41334 12.8514 6.41323 12.9136 6.41318C12.9426 6.41279 12.9715 6.41239 13.0013 6.41199C13.2058 6.41324 13.348 6.44739 13.5093 6.58098C13.6329 6.7306 13.6811 6.86006 13.6676 7.0564C13.6391 7.26621 13.5551 7.37296 13.3976 7.5081C13.3082 7.5735 13.231 7.57798 13.1237 7.57949C13.0954 7.57993 13.0671 7.58037 13.0379 7.58082C12.9084 7.58204 12.7788 7.58316 12.6492 7.58379C12.581 7.58424 12.5128 7.58499 12.4445 7.58604C12.346 7.58754 12.2474 7.58813 12.1488 7.58853C12.1185 7.58915 12.0882 7.58977 12.057 7.59041C11.8423 7.58983 11.7105 7.54501 11.5531 7.39901C11.4481 7.28546 11.4043 7.16973 11.4023 7.01581C11.4144 6.81704 11.4681 6.67513 11.6202 6.54028C11.7395 6.45511 11.8344 6.41912 11.9808 6.41784Z"
            fill="#D3A004"
          />
          <path
            d="M7.38294 0.464892C7.49099 0.569499 7.56532 0.68193 7.58165 0.833584C7.58186 0.857395 7.58207 0.881205 7.58229 0.905737C7.58263 0.93294 7.58298 0.960143 7.58334 0.988171C7.5834 1.01728 7.58346 1.04639 7.58353 1.07639C7.58371 1.10663 7.58389 1.13687 7.58408 1.16803C7.58436 1.23194 7.58448 1.29586 7.58448 1.35978C7.58459 1.45718 7.58559 1.55455 7.58662 1.65194C7.58679 1.71416 7.58689 1.77638 7.58695 1.8386C7.58734 1.86754 7.58773 1.89649 7.58814 1.92631C7.58688 2.1308 7.55273 2.27298 7.41914 2.43428C7.27043 2.55709 7.14067 2.60581 6.94544 2.59429C6.77542 2.57184 6.65558 2.51056 6.54041 2.38066C6.45524 2.26143 6.41924 2.16653 6.41796 2.02014C6.41761 1.99294 6.41726 1.96574 6.4169 1.93771C6.41684 1.9086 6.41678 1.87948 6.41672 1.84949C6.41654 1.81925 6.41635 1.78901 6.41617 1.75785C6.41589 1.69393 6.41576 1.63002 6.41577 1.5661C6.41565 1.4687 6.41466 1.37133 6.41362 1.27394C6.41346 1.21172 6.41335 1.1495 6.4133 1.08728C6.41291 1.05834 6.41251 1.02939 6.41211 0.999569C6.41336 0.795077 6.44751 0.652896 6.5811 0.491595C6.82878 0.287064 7.12283 0.276189 7.38294 0.464892Z"
            fill="#D3A004"
          />
        </g>
        <defs>
          <clipPath id="clip0_2417_33310">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default InProgress;
