import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const PackageDetailsModal = ({ weight, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle
            fontSize={"20px"}
            fontFamily={"poppins"}
            fontWeight={500}
            color={"#333333"}
          >
            {"Weight Package Details"}
          </DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomTypography>Client</CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={weight?.client_id?.business_name || ""}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize={15}>Warehouse</CustomTypography>
              <TextField
                size="small"
                variant="outlined"
                value={weight?.warehouse?.provider?.name}
                fullWidth
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: "#7C7C7C", backgroundColor: "#f0f0f0" },
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize={15}>Weight</CustomTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                type="email"
                size="small"
                variant="outlined"
                value={`${weight?.min_weight}/${weight?.min_weight_unit} - ${weight?.max_weight}/${weight?.max_weight_unit}`}
                fullWidth
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: "#7C7C7C", backgroundColor: "#F0F0F0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize={15}>Dimension</CustomTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                type="email"
                size="small"
                variant="outlined"
                value={`${weight?.dimension.length} * ${weight?.dimension.width} * ${weight?.dimension.height} (${weight?.dimension.height_unit})`}
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: "#7C7C7C", backgroundColor: "#F0F0F0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize={15}>Package Weight</CustomTypography>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#7C7C7C",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
                type="email"
                size="small"
                variant="outlined"
                value={`${weight?.pkg_weight} ${weight?.pkg_weight_unit}`}
                fullWidth
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: "#7C7C7C", backgroundColor: "#F0F0F0" },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PackageDetailsModal;
